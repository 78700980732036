import { EventEmitter, Injectable, Output } from '@angular/core';
import { Logger } from '../../core/logger.service';
import { ResourceUpload } from '../../core/firebase/resource-upload';
import { Subscription } from 'rxjs';
import { GoogleService } from '../../core/google/google.service';
import { SmeService } from '../../core/sme/sme.service';
import { Router } from '@angular/router';
import { MapStateService } from '../map-state.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MapOperationType } from '../map-operations';
import { Storage } from '@angular/fire/storage';
import { Guid } from 'guid-typescript';
import { FirebaseService } from 'src/app/core/firebase/firebase.service';
import { Action } from 'rxjs/internal/scheduler/Action';
import { MapNewModule } from 'src/app/map-new/map-new.modules';

const logger: Logger = new Logger('ImportMapService');

@Injectable({
    providedIn: 'root'
})
export class ImportMapService {
    currentMapUpload: ResourceUpload | undefined;
    uploadMapSubscription: Subscription | undefined;
    createWorkMapSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;
    @Output() onMapImported = new EventEmitter<boolean>();
    @Output() onMapCreated = new EventEmitter<string>();
    @Output() onPopupBlocked = new EventEmitter<boolean>();

    constructor(private router: Router, private smeService: SmeService, private mapStateService: MapStateService,
        private firebaseService: FirebaseService, private authenticationService: AuthenticationService) { }

    //   openMap(file: any, newTab = true) {
    //     // let accessToken = '';
    //     let smxFolderId = '';
    //     // smxFolderId = '';
    //     // se apre da draganddrop
    //     let currentMapFile = file;
    //     if (file.target !== undefined) {
    //       // apre da comando openfile
    //       currentMapFile = file.target.files[0];
    //     }
    //     logger.info(`Opening ${currentMapFile.name}...`);
    //     const extension: string = currentMapFile.name.split('.').pop();
    //     if (extension === 'sme') {
    //       this.currentMapUpload = new ResourceUpload(currentMapFile);

    //       // JHUBA: FOLDER SUPERMAPPEX - uncomment
    //       this.googleService.getSMXFolderIds()
    //         .then((_smxFolderIds: any) => {
    //           smxFolderId = (_smxFolderIds && _smxFolderIds.smx ? _smxFolderIds.smx : '');
    //           if (this.uploadMapSubscription) { this.uploadMapSubscription.unsubscribe(); }
    //           if (this.currentMapUpload) {
    //             this.uploadMapSubscription = this.smeService.uploadMap(this.currentMapUpload)
    //               .subscribe((fileRef: any) => {
    //                 logger.info('Map uploaded');
    //                 if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
    //                 this.createWorkMapSubscription = this.smeService.createWorkMapFromStorage(fileRef, smxFolderId)
    //                   .subscribe({
    //                     next: (mapId: string) => {
    //                       if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    //                       this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
    //                       logger.info(`Map ${mapId} created`);
    //                       this.mapStateService.setBusyState(false);
    //                       if (newTab) {
    //                         const redirectUri = window.location.origin + '/map-edit/' + mapId;
    //                         window.open(redirectUri, '_blank');
    //                       } else {
    //                         this.router.navigate(['map-edit/', mapId]);
    //                         // window.location.href = 'map-edit/' + mapId;
    //                       }
    //                     },
    //                     error: (error: any) => {
    //                       logger.error('createWorkMapFromStorage().subscribe: ' + error);
    //                       let errorCode = '';
    //                       if (error.status === 504) {
    //                         errorCode = 'GATEWAY_TIMEOUT';
    //                       } else {
    //                         errorCode = (error.code) ? error.code : error.statusText;
    //                       }
    //                       this.mapStateService.setStateError(errorCode);
    //                     }
    //                   });
    //               });
    //           }
    //         }, (error: any) => {
    //           logger.error('uploadMap().subscribe: ' + error);
    //           let errorCode = '';
    //           if (error.status === 504) {
    //             errorCode = 'GATEWAY_TIMEOUT';
    //           } else {
    //             errorCode = (error.code) ? error.code : 'HOME_ERROR_GENERIC_MAP_LOAD';
    //           }
    //           this.mapStateService.setStateError(errorCode);
    //         }).catch((error) => {
    //           if (navigator.onLine) {
    //             this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
    //             logger.error('Home  getAccessToken error: ' + error);
    //           } else {
    //             const errorCode = (error.code) ? error.code : 'OFFLINE_GENERIC';
    //             this.mapStateService.setStateError(errorCode);
    //           }
    //         });
    //     } else {
    //       this.mapStateService.setStateError('ERROR_WRONG_FILETYPE');
    //     }
    //   }

    uploadMapPromise(file: ResourceUpload) {
        return new Promise((resolve, reject) => {
            this.smeService.uploadMap(file).toPromise().then((_fileRef) => {
                return resolve(_fileRef)
            });

        });
    }

    openMap(file: any, newTab: boolean) {




        // smxFolderId = '';
        // se apre da draganddrop
        let currentMapFile = file;
        if (file.target !== undefined) {
            // apre da comando openfile
            currentMapFile = file.target.files[0];
        } else {
            currentMapFile = file;
        }

        logger.info(`Opening ${currentMapFile.name}...`);
        const extension: string = currentMapFile.name.split('.').pop();
        if (extension === 'sme') {
            this.currentMapUpload = new ResourceUpload(currentMapFile);


            if (this.uploadMapSubscription) { this.uploadMapSubscription.unsubscribe(); }
            if (this.currentMapUpload) {

                let fileRef: any = '';


                this.uploadMapSubscription = this.smeService.uploadMap(this.currentMapUpload)
                    .subscribe({

                        next: (_fileRef) => {
                            fileRef = _fileRef;
                        },
                        complete: () => {


                            logger.info('Map uploaded');
                            let mapName = currentMapFile.name;
                            if (mapName && mapName !== '' && mapName.split('.').length > 1) {
                                mapName = mapName.split('.').slice(0, -1).join('.');
                                console.log(mapName);

                            }
                            if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
                            this.createWorkMapSubscription = this.smeService.createWorkMapFromStorage(fileRef, mapName, '')
                                .subscribe({
                                    next: (mapId: string) => {
                                        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
                                        this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
                                        logger.info(`Map ${mapId} created`);
                                        this.mapStateService.setBusyState(false);
                                        if (this.currentMapUpload) this.smeService.uploadMap(this.currentMapUpload, mapId)

                                        this.firebaseService.addSharedStateChangeListener('', mapId, this.authenticationService.getUserEmail()).then(() => {
                                            if (newTab) {
                                                const url = window.location.origin + '/map-open/' + mapId;
                                                this.onMapCreated.emit(url);
                                                // 
                                                // if (wh === null) {
                                                //     window.open(url, '_blank');
                                                // } else {
                                                //     wh.url = url;
                                                // }
                                                const redirectUri = window.location.origin + '/map-edit/' + mapId;

                                                const newWin = window.open(redirectUri, '_blank');

                                                if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                                                    //POPUP BLOCKED
                                                    this.onPopupBlocked.emit(true);
                                                }
                                                this.onMapImported.emit(true);
                                            } else {
                                                this.router.navigate(['map-edit/', mapId]);
                                                // window.location.href = 'map-edit/' + mapId;
                                            }
                                        });


                                    },
                                    error: (error: any) => {
                                        this.onMapImported.emit(false);
                                        logger.error('createWorkMapFromStorage().subscribe: ' + error);
                                        let errorCode = '';
                                        if (error.status === 504) {
                                            errorCode = 'GATEWAY_TIMEOUT';
                                        } else {
                                            errorCode = (error.code) ? error.code : error.statusText;
                                        }
                                        this.mapStateService.setStateError(errorCode);
                                    }
                                });
                        },
                        error: (error: any) => {
                            logger.error('uploadMap().subscribe: ' + error);
                            let errorCode = '';
                            if (error.status === 504) {
                                errorCode = 'GATEWAY_TIMEOUT';
                            }
                        }

                    });
            }
        } else {
            this.mapStateService.setStateError('ERROR_WRONG_FILETYPE');
        }
    }
}

//     openMap(file: any, newTab: boolean) {




//         // smxFolderId = '';
//         // se apre da draganddrop
//         let currentMapFile = file;
//         if (file.target !== undefined) {
//             // apre da comando openfile
//             currentMapFile = file.target.files[0];
//         }

//         logger.info(`Opening ${currentMapFile.name}...`);
//         const extension: string = currentMapFile.name.split('.').pop();
//         if (extension === 'sme') {
//             this.currentMapUpload = new ResourceUpload(currentMapFile);


//             if (this.uploadMapSubscription) { this.uploadMapSubscription.unsubscribe(); }
//             if (this.currentMapUpload) {

//                 let fileRef: any = '';
//                 this.uploadMapSubscription = this.smeService.uploadMap(this.currentMapUpload)
//                     .subscribe({

//                         next: (_fileRef) => {
//                             fileRef = _fileRef;
//                         },
//                         complete: () => {


//                             logger.info('Map uploaded');
//                             let mapName = currentMapFile.name;
//                             if (mapName && mapName !== '' && mapName.split('.').length > 1) {
//                                 mapName = mapName.split('.').slice(0, -1).join('.');
//                                 console.log(mapName);

//                             }
//                             if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
//                             this.createWorkMapSubscription = this.smeService.createWorkMapFromStorage(fileRef, mapName, '')
//                                 .subscribe({
//                                     next: (mapId: string) => {
//                                         if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
//                                         this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
//                                         logger.info(`Map ${mapId} created`);
//                                         this.mapStateService.setBusyState(false);
//                                         if (this.currentMapUpload) this.smeService.uploadMap(this.currentMapUpload, mapId)
//                                         this.onMapImported.emit(true);
//                                         this.firebaseService.addSharedStateChangeListener(mapId, this.authenticationService.getUserEmail()).then(() => {
//                                             if (newTab) {
//                                                 const url = window.location.origin + '/map-open/' + mapId;
//                                                 window.open(url, '_blank');
//                                                 // const redirectUri = window.location.origin + '/map-edit/' + mapId;
//                                                 // window.open(redirectUri, '_blank');
//                                             } else {
//                                                 this.router.navigate(['map-edit/', mapId]);
//                                                 // window.location.href = 'map-edit/' + mapId;
//                                             }
//                                         });


//                                     },
//                                     error: (error: any) => {
//                                         this.onMapImported.emit(false);
//                                         logger.error('createWorkMapFromStorage().subscribe: ' + error);
//                                         let errorCode = '';
//                                         if (error.status === 504) {
//                                             errorCode = 'GATEWAY_TIMEOUT';
//                                         } else {
//                                             errorCode = (error.code) ? error.code : error.statusText;
//                                         }
//                                         this.mapStateService.setStateError(errorCode);
//                                     }
//                                 });
//                         },
//                         error: (error: any) => {
//                             logger.error('uploadMap().subscribe: ' + error);
//                             let errorCode = '';
//                             if (error.status === 504) {
//                                 errorCode = 'GATEWAY_TIMEOUT';
//                             }
//                         }

//                     });
//             }
//         } else {
//             this.mapStateService.setStateError('ERROR_WRONG_FILETYPE');
//         }
//     }
// }
