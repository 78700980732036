<!-- <div class="pdf-recents">
    <div class="recent-list-container">
        <mat-list>

            <div class="recent-pdf-list">
                <mat-list-item class="recent-pdf-list-item" *ngFor="let recentPdf of recentPdfsSortedFiltered"> -->
<!-- <mat-list-item class="recent-map-list-item" *ngFor="let recentMap of recentMapsSortedFiltered">
                        <div class="recent-map-list-line" (click)="loadMapFromRecent(recentMap.id)"> -->
<mat-list-item class="recent-pdf-list-item" (click)="loadPdfFromRecent(recentPdf)">
    <div class="recent-pdf-list-line">
        <!-- <mat-icon mat-list-icon class="recent-pdf-list-item-icon color-smx-blue" svgIcon="recent_list_item" (click)="loadPdfFromRecent(recentPdf)" matTooltip="{{'OPEN_RECENT_MAP' | translate}}"></mat-icon> -->
        <mat-icon [ngStyle]="{'color': recentPdf.color}" class="material-icons" (click)="loadPdfFromRecent(recentPdf)">picture_as_pdf</mat-icon>
        <div class="recent-pdf-list-name-container">
            <span class="recent-pdf-list-name">{{recentPdf.name}}</span>
        </div>
        <div class="recent-pdf-list-info-container">

            <span class="recent-pdf-list-lastupdate"> {{recentPdf.dateImported?.toLocaleDateString()}} </span>
            <div class="recent-pdf-list-menubutton" color="primary" mat-icon-button matTooltip="{{'OTHER' | translate}}" [matMenuTriggerFor]="itemMenu" (click)="itemMenuClick(recentPdf, $event); false;">
                <mat-icon color="primary">more_vert</mat-icon>
                <mat-menu color="primary" #itemMenu="matMenu" [overlapTrigger]="false">
                    <app-pdf-menu-recents [recentPdf]="recentPdf" (removePdf)="removePdf(recentPdf)" (openPdf)="loadPdfFromRecent(recentPdf)" (renamePdf)="renamePdf(recentPdf)" (downloadRecent)="downloadRecent($event)" (changeIconColor)="changeIconColor($event)"></app-pdf-menu-recents>
                </mat-menu>
            </div>
        </div>
    </div>
</mat-list-item>
<!-- </mat-list-item>
            </div>
        </mat-list>
    </div>
</div> -->
