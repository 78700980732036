
import { throwError as observableThrowError, Subscription, Observable } from 'rxjs';
import { Logger } from '../../core/logger.service';
import { SmeService } from '../../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { FirebaseService } from '../../core/firebase/firebase.service';
import { HttpClient } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

const logger: Logger = new Logger('share-link.service');

const routes = {
    getLinkPreview: '/api/getlinkpreview2gen'
};

export interface ShareLink {
    title: string;
    description: string;
    image: any;
}


@Injectable({
    providedIn: 'root'
})

export class ShareLinkService {
    insertMapSharedSubscription: Subscription | undefined;
    imageUrl: string | undefined;

    constructor(
        private smeService: SmeService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private firebaseService: FirebaseService,
        private http: HttpClient) {
    }

    public getLinkToShare(mapId: string): string {
        const url = (<any>window).location.origin + '/map-shared/' + mapId;
        logger.info('GetLinkToShare: ' + url);
        return url;
    }

    public shareLink(mapId: string) {
        logger.info('share link');
        this.smeService.resolveWorkUrl(mapId, 'map.jpg')
            .then((imageUrl: any) => {
                this.imageUrl = imageUrl;
                logger.debug(`Facebook image URL: ${imageUrl}`);
            }).catch(() => {
                logger.info('Non riesco a caricare l\'immagine della mappa. Vado comunque avanti');
            });
        // Get page url
        const mapUrl = this.getLinkToShare(mapId);
        this.copyToClipboard(mapUrl);
    }

    public copyToClipboard(txt: string) {
        const text = document.createTextNode(txt);
        document.body.appendChild(text);
        const d = document.createRange();
        d.selectNodeContents(text);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(d);
        if (!navigator.clipboard) {
            document.execCommand('copy');
        } else {
            navigator.clipboard.writeText(txt);
        }
        window.getSelection()?.removeAllRanges();
        text.remove();
    }

    private proxy(url: string): string {
        let s = url;
        let i = url.indexOf('/o/');
        if (i >= 0) {
            s = window.location.origin + '/m/' + url.substr(i + 3);
            i = s.indexOf('&token=');
            if (i >= 0) {
                s = s.substr(0, i);
            }
        }
        //////////// DEBUG
        s = s.replace('http://localhost:4200', 'https://dev.supermappex.it');
        //////////////////
        return s;
    }

    public writeStaticMap(mapId: string, title: string, author: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const result = { ok: false, url: '' };
            let userId = '';
            const redirectUrl = this.getLinkToShare(mapId);
            let proxiedJpgUrl = '';
            let proxiedHtmlUrl = '';
            if (this.authenticationService.isAuthenticated()) {
                userId = this.authenticationService.credentials ? this.authenticationService.credentials?.firebaseUserId : '';
                if (userId) {
                    const prefixPath = 'maps/' + userId + '/work/' + mapId + '/';
                    const jpgPath = prefixPath + 'map.jpg';
                    const htmlPath = prefixPath + 'map.html';
                    this.firebaseService.getDownloadUri(jpgPath).then(url => {
                        proxiedJpgUrl = this.proxy(url);
                        logger.info('jpgUrl:' + url);
                        logger.info('Proxied jpgUrl:' + proxiedJpgUrl);
                        const html = '<html />';
                        // Upload placeholder file (to get download uri)
                        return this.firebaseService.uploadStringPromise(html, htmlPath, 'text/html');


                    }).then((res) => {
                        return this.firebaseService.getDownloadUri(htmlPath);
                    }).then((htmlUrl) => {
                        proxiedHtmlUrl = this.proxy(htmlUrl);
                        logger.info('htmlUrl:' + htmlUrl);
                        logger.info('Proxied htmlUrl:' + proxiedHtmlUrl);
                        // Upload complete static file
                        const html = this.getHtml(title, author, redirectUrl, proxiedJpgUrl, proxiedHtmlUrl);
                        return this.firebaseService.uploadStringPromise(html, htmlPath, 'text/html');
                    }).then((res) => {
                        // Complete!
                        result.ok = true;
                        result.url = proxiedHtmlUrl;
                        logger.info('Static map uploaded: ' + htmlPath);
                        logger.info('Static map proxied url: ' + proxiedHtmlUrl);
                        return resolve(result);
                    }).catch(error => {
                        logger.info('WriteStaticMap ERROR: ' + error);
                        return reject(error);
                    });
                } else {
                    logger.info('WriteStaticMap ERROR: User not authenticated');
                    return reject(new Error('UNAUTHENTICATED USER'));
                }
            } else return reject(new Error('UNAUTHENTICATED USER'));
        });
    }

    private getHtml(title: string, author: string, redirectUrl: string, jpgUrl: string, htmlUrl: string): string {
        const madeWith = this.translateService.instant(extract('MADE_WITH'));
        let html = '';
        html += '<html>\n';
        html += '  <head>\n';
        html += '    <meta charset="UTF-8" />\n';
        html += '    <title>' + title + '</title>\n';
        html += '    <meta name="description" content="' + title + ' (' + author + ') ' + madeWith + '" />\n';
        html += '    <link rel="shortcut icon" type="image/png" href="' + window.location.origin + '/m/assets%2FfaviconSMX16.png?alt=media" />\n';
        html += '    <meta property="og:url" content="' + htmlUrl + '" />\n';
        html += '    <meta property="og:type" content="website" />\n';
        html += '    <meta property="og:title" content="' + title + '" />\n';
        html += '    <meta property="og:image" content="' + jpgUrl + '" />\n';
        html += '    <meta property="og:description" content="' + title + ' (' + author + ') ' + madeWith + '" />\n';
        html += '    <meta property="fb:app_id" content="131948730714150" />\n';
        html += '  </head>\n';
        html += '  <body>\n';
        html += '    <script>\n';
        html += '      window.location.href = "' + redirectUrl + '"\n';
        html += '    </script>\n';
        html += '  </body>\n';
        html += '</html>\n';
        return html;
    }

    public getLinkPreview(url: string) {
        const body = { url: url };
        return this.http.post<ShareLink>(routes.getLinkPreview, body, {})
            .pipe(map((res: ShareLink) => res),
                catchError((error: any) => {
                    logger.error(error.json());
                    return observableThrowError(() => error.json());
                }));
    }
}
