import { VirtualKeyboardLayout } from 'mathlive';

export const IT_SETS_KEYBOARD: VirtualKeyboardLayout = {
    id: "SETS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                latex: '\\left\\vert\\placeholder{}\\right',
                tooltip: 'tale che'
            },
            {
                latex: '\\left.\\placeholder{}\\right\\vert',
                tooltip: 'tale che'

            },
            {
                latex: '\\cup',
                tooltip: 'unione'
            },
            {
                latex: '\\cap',
                tooltip: 'intersezione'
            },
            {
                latex: '\\in',
                tooltip: 'incluso in'
            },
            {
                latex: '\\not\\in',
                tooltip: 'non incluso in'
            },
            {
                latex: '\\lnot',
                tooltip: 'negazione'
            }
        ],
        // Second row
        [
            {
                latex: '\\subset',
                tooltip: 'include'
            },
            {
                latex: '\\subseteq',
                tooltip: 'include o è conincidente'
            },
            {
                latex: '\\supset',
                tooltip: 'è incluso'
            },
            {
                latex: '\\supseteq',
                tooltip: 'è incluso o coincidente'
            },
            {
                latex: '\\forall',
                tooltip: 'per ogni'
            },
            {
                latex: '\\exists',
                tooltip: 'esiste'
            },
            {
                latex: '\\nexists',
                tooltip: 'non esiste'
            }
        ],
        // Third row
        [
            {
                latex: '\\vee',
                tooltip: 'oppure'
            },
            {
                latex: '\\wedge',
                tooltip: 'e'
            },
            {
                latex: '\\veebar',
                tooltip: 'oppure esclusivo'
            },
            {
                latex: '\\implies',
                tooltip: 'implica'
            },
            {
                latex: '\\impliedby',
                tooltip: 'è implicato da'
            },
            {
                latex: '↔',
                tooltip: 'se e solo se'
            },
            {
                latex: ' \\overline{\\placeholder{}}',
                tooltip: 'insieme complementare'
            }
        ],
        // Fourth row
        [
            {
                latex: '∅',
                tooltip: 'insieme vuoto'
            },
            {
                latex: '\\mathbb{N} ',
                tooltip: 'insieme dei numeri naturali'
            },
            {
                latex: '\\mathbb{Z}',
                tooltip: 'insieme dei numeri interi'
            },
            {
                latex: '\\mathbb{Q}',
                tooltip: 'insieme dei numeri razionali'
            },
            {
                latex: '\\mathbb{R}',
                tooltip: 'insieme dei numeri reali'
            },
            {
                latex: '\\mathbb{C}',
                tooltip: 'insieme dei numeri complessi'
            },
            {
                latex: 'ℜ',
                tooltip: 'relazione'
            },
        ]
    ]
};
