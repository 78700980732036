import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MapStateService } from '../../shared/map-state.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Component({
    selector: 'app-invalid-credentials',
    templateUrl: './invalid-credentials.component.html',
    styleUrls: ['./invalid-credentials.component.scss']
})
export class InvalidCredentialsComponent implements OnInit {

    // public privacyForm: any;
    @Input() eligibleForDemo: boolean;
    @Output() requestDemo = new EventEmitter<boolean>();
    @Output() goLogin = new EventEmitter<boolean>();

    public userName = '';
    public userEmail = '';

    constructor(
        private mapStateService: MapStateService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
        this.eligibleForDemo = false;
    }

    ngOnInit() {
        const credentials = this.authenticationService.credentials;
        if (this.authenticationService.isAuthenticated()) {
            this.userName = this.authenticationService.getUserName();
            this.userEmail = this.authenticationService.getUserEmail();
        }

    }

    initializeUser(userName: string, userEmail: string) {
        this.userName = userName;
        this.userEmail = userEmail;
    }

    goToWebsiteClick(from: string, type: string) {
        this.mapStateService.goToWebsiteClick(from, type);
    }

    demoClick() {
        this.requestDemo.emit(true);
    }

    logoutClick() {
        if (this.authenticationService.isAuthenticated()) {
            this.authenticationService.logout().subscribe(() => {
                this.goLogin.emit(true);
                this.router.navigate(['login', '/home']);
            });
        } else {
            this.goLogin.emit(true);
            this.router.navigate(['login', '/home']);
        }



    }

}
