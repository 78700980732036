import { Component, EventEmitter, Output } from '@angular/core';
import { UiConstants } from 'src/app/shared/ui-constants';
import { PdfToolsPrefsDto } from '../pdf-master/pdf-tools-prefs-dto';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-pdf-config-tools',
    templateUrl: './pdf-config-tools.component.html',
    styleUrls: ['./pdf-config-tools.component.scss']
})

export class PdfConfigToolsComponent {


    public onSavedConfig = new EventEmitter<PdfToolsPrefsDto>();
    public onClosedConfig = new EventEmitter<any>();

    fontSizes = UiConstants.FONTS.fontSizes;
    colors = UiConstants.PALETTE.BASE;

    highlightColors = UiConstants.PALETTE.PDFHIGHLIGHTERS;


    configParams: PdfToolsPrefsDto | undefined;

    public selectedFontSize = '';
    public selectedFontColor = '';
    public selectedHighlightColor = '';
    public selectedUnderlineColor = '';
    selectedBrushColor = '';
    selectedRectColor = '';
    selectedRectBorderColor = '';
    selectedCircleColor = '';
    selectedCircleBorderColor = '';
    selectedArrowColor = '';
    selectedLineColor = '';
    selectedCheckColor = '';
    thumbLabel = true;


    title: string = this.translateService.instant('CONFIGPDF');
    underlineWidth = 1;
    brushWidth = 5;
    circleWidth = 1;
    rectWidth = 1;
    checkWidth = 12;
    lineWidth = 3;
    arrowWidth = 1;


    copyHighligthChecked = true;
    copyUnderlineChecked = true;

    fontOpacity = 100;
    //   underlineOpacity = 100;
    brushOpacity = 100;
    rectOpacity = 100;
    circleOpacity = 100;
    lineOpacity = 100;
    arrowOpacity = 100;
    checkOpacity = 100;


    fontColorTooltip = this.translateService.instant('MAIN_MENU_FILE-TEXT-COLOR');
    higlightColorTooltip = this.translateService.instant('MAIN_MENU_FILE-TEXT-BACKCOLOR');
    underlineColorTooltip = '';
    brushColorTooltip = '';
    rectBorderColorTooltip = '';
    rectColorTooltip = '';
    circleColorTooltip = '';
    circleBorderColorTooltip = '';

    arrowColorTooltip = '';
    lineColorTooltip = '';
    checkColorTooltip = '';


    colorUsedLabel = '';

    fontStyle = 'color:' + this.selectedFontColor + ';font-size:' + this.selectedFontSize + 'px; opacity:' + this.fontOpacity;
    highlightStyle = 'background-color:' + this.selectedHighlightColor + ';';//;+ '; opacity:' + this.fontOpacity;
    underlineStyle = ' text-decoration: underline;' +
        ' text-decoration-color: ' + this.selectedUnderlineColor + ';text-decoration-thickness:' + this.underlineWidth + 'px;';

    brushStyle = 'background-color:' + this.selectedBrushColor + ';border-radius: 50%;width:' + this.brushWidth * 2 + 'px;height:' + this.brushWidth * 2 + 'px;';
    // 'opacity: ' + this.brushOpacity + '; color: ' + this.brushColorIcon + ';'
    rectangleStyle = 'width: 40px; height:20px; border: 5px solid ' + this.selectedRectBorderColor + '; background-color: ' + this.selectedRectColor + ';';
    circleStyle = '';
    checkmark = '';
    checkmark_stem = '';
    checkmark_kick = '';
    checkColor = 'primary';
    public arrowColor = 'primary';
    public lineColor = 'primary';
    lineStyle = "stroke:rgb(255,0,0);stroke-width:4";

    //arrowStyle = '\'font-size\': \'45px\'; \'width\': \'50px\'; \'height\': \'50px\'; \'color\':\'red\'';


    constructor(private translateService: TranslateService) {

    }

    initParam(param: PdfToolsPrefsDto) {

        this.configParams = param;
        this.selectedFontColor = this.rgba2hex(param.default_text_color.toString());
        this.selectedFontSize = param.default_text_size.toString();
        this.fontStyle = 'color:' + this.selectedFontColor + ";font-size:" + this.selectedFontSize + "px; opacity:" + this.fontOpacity;
        this.selectedArrowColor = this.rgba2hex(param.default_arrow_color.toString());
        this.selectedArrowColor = this.rgba2hex(param.default_arrow_color.toString());
        this.selectedBrushColor = this.rgba2hex(param.default_brush_color.toString());
        this.selectedCheckColor = this.rgba2hex(param.default_check_color.toString());
        this.checkColor = this.rgba2hex(param.default_check_color.toString());
        this.selectedCircleBorderColor = this.rgba2hex(param.default_circle_border_color.toString());
        this.selectedCircleColor = this.rgba2hex(param.default_circle_color.toString());
        this.selectedHighlightColor = this.rgba2hex(param.default_highlight_color.toString()) + '4d';
        this.selectedLineColor = this.rgba2hex(param.default_line_color.toString());
        this.selectedRectBorderColor = this.rgba2hex(param.default_rectangle_border_color.toString());
        this.selectedRectColor = this.rgba2hex(param.default_rectangle_color.toString());
        this.circleWidth = param.default_circle_width;
        this.rectWidth = param.default_rectangle_width;
        this.selectedUnderlineColor = this.rgba2hex(param.default_underline_color.toString());
        this.lineOpacity = param.default_line_opacity;
        this.lineWidth = param.default_line_width;
        this.arrowOpacity = param.default_arrow_opacity;
        this.arrowWidth = param.default_arrow_width;
        this.checkOpacity = param.default_check_opacity;
        this.checkWidth = param.default_check_width / 12;


        this.brushWidth = param.default_brush_width;
        this.underlineWidth = param.default_underline_width;
        this.rectOpacity = param.default_rectangle_opacity;
        this.brushOpacity = param.default_brush_opacity;
        this.circleOpacity = param.default_circle_opacity;

        this.fontStyle = 'color:' + this.selectedFontColor + ';font-size:' + this.selectedFontSize + 'px; opacity:' + this.fontOpacity;
        this.highlightStyle = 'background-color:' + this.selectedHighlightColor + ';';
        this.underlineStyle = 'text-decoration: underline;' +
            ' text-decoration-color: ' + this.selectedUnderlineColor + ';text-decoration-thickness:' + this.underlineWidth + 'px;';

        let _opacity = Math.round(Math.min(Math.max(this.brushOpacity / 100 || 1, 0), 1) * 255);
        let color = this.selectedBrushColor + _opacity.toString(16).toUpperCase();
        this.brushStyle = 'background-color:' + color + ';border-radius: 50%;width:' + this.brushWidth * 2 + 'px;height:' + this.brushWidth * 2 + 'px;';

        _opacity = Math.round(Math.min(Math.max(this.rectOpacity / 100 || 1, 0), 1) * 255);
        color = this.selectedRectColor + _opacity.toString(16).toUpperCase();
        this.rectangleStyle = 'width: 40px; height:20px; border: ' + this.rectWidth + 'px solid ' + this.selectedRectBorderColor + '; background-color: ' + color + ';';
        _opacity = Math.round(Math.min(Math.max(this.circleOpacity / 100 || 1, 0), 1) * 255);
        color = this.selectedCircleColor + _opacity.toString(16).toUpperCase();
        this.circleStyle = 'width: 30px; height:30px;border-radius: 50%; border: ' + this.circleWidth + 'px solid ' + this.selectedCircleBorderColor + '; background-color: ' + color + ';';

        _opacity = Math.round(Math.min(Math.max(this.arrowOpacity / 100 || 1, 0), 1) * 255);
        color = this.selectedArrowColor + _opacity.toString(16).toUpperCase();
        this.arrowColor = color;

        _opacity = Math.round(Math.min(Math.max(this.lineOpacity / 100 || 1, 0), 1) * 255);
        color = this.selectedLineColor + _opacity.toString(16).toUpperCase();
        this.lineColor = color;
        this.lineStyle = 'stroke:' + color + ';stroke-width:' + this.lineWidth + ';';


        _opacity = Math.round(Math.min(Math.max(this.checkOpacity / 100 || 1, 0), 1) * 255);
        color = this.selectedCheckColor + _opacity.toString(16).toUpperCase();
        this.checkColor = color;
        // this.checkmark = 'display: inline-block; width: 44px; height: 44px; -webkit-transform: rotate(45deg);';
        // this.checkmark_stem = 'position: absolute; width: 6px; height: 18px; background-color: ' + this.selectedCheckColor + '; left: 22px; top: 12px; ';
        // this.checkmark_kick = 'position: absolute; width: 6px; height: 6px; background-color:  ' + this.selectedCheckColor + ';left: 16px; top: 24px;';

        this.copyHighligthChecked = param.copyHighligth;
        this.copyUnderlineChecked = param.copyUnderlined;
    }

    closeWin() {
        this.onClosedConfig.emit(this.configParams);
    }

    restoreDefault() {
        const configParams = new PdfToolsPrefsDto();
        this.initParam(configParams);

    }

    save() {
        this.onSavedConfig.emit(this.configParams);

    }

    onCopyHighligthChecked(e: any) {
        this.copyHighligthChecked = e.checked;
        if (this.configParams) this.configParams.copyHighligth = e.checked;

    }

    onCopyUnderlineChecked(e: any) {
        this.copyUnderlineChecked = e.checked;
        if (this.configParams) this.configParams.copyUnderlined = e.checked;
    }

    rgba2hex(rgb: string) {

        const res = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (res && res.length === 4) ? "#" +
            ("0" + parseInt(res[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(res[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(res[3], 10).toString(16)).slice(-2) : '';

    }

    hexToRgbA(hex: string, opacity: number) {
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            if (opacity > 0) {
                opacity = Math.round(Math.min(Math.max(opacity / 100 || 1, 0), 1) * 255);
            }
            //opacity = opacity / 100
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
        }
        else {
            console.log('Bad Hex');
            return hex;
        }
        // }


    }

    hexToRgb(hex: string) {
        // let start = 1;
        // if (hex.length > 7) {
        //     start = 3;


        // }
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return "rgb(" + r + ", " + g + ", " + b + ")";
    }







    changeFontSize(evt: any) {
        // this.selectedFontSize = parseInt(this.selectedFontSize, 10) + 'px';
        // const fontSizePx = parseInt(this.selectedFontSize, 10);
        const fontSizePx = parseInt(evt.currentTarget.innerText, 10);
        // this.selectedFontSize = Math.round(fontSizePx * 4 / 3) + 'px';
        this.selectedFontSize = fontSizePx + '';
        this.fontStyle = 'color:' + this.selectedFontColor + ";font-size:" + this.selectedFontSize + "px; opacity:" + this.fontOpacity;
        if (this.configParams) this.configParams.default_text_size = fontSizePx;

    }

    changeFontColor(e: any) {
        this.selectedFontColor = e;
        this.fontStyle = 'color:' + this.selectedFontColor + ";font-size:" + this.selectedFontSize + "px; opacity:" + this.fontOpacity;
        if (this.configParams) this.configParams.default_text_color = this.hexToRgbA(this.selectedFontColor, this.fontOpacity);
    }

    changeHighlightColor(e: any) {
        this.selectedHighlightColor = e;
        // const _opacity = Math.round(Math.min(Math.max(30 / 100 || 1, 0), 1) * 255);
        // this.selectedHighlightColor = this.selectedHighlightColor + _opacity.toString(16).toUpperCase();
        this.highlightStyle = 'background-color:' + this.selectedHighlightColor + ';';
        if (this.configParams) this.configParams.default_highlight_color = this.hexToRgb(this.selectedHighlightColor);
    }

    changeUnderlineColor(e: any) {
        this.selectedUnderlineColor = e;
        this.underlineStyle = ' text-decoration: underline;' +
            ' text-decoration-color: ' + this.selectedUnderlineColor + ';text-decoration-thickness:' + this.underlineWidth + 'px;';
        if (this.configParams) this.configParams.default_underline_color = this.hexToRgbA(this.selectedUnderlineColor, 100);
    }

    changeUnderlineWidth(e: any) {
        this.underlineWidth = parseInt(e.target.value, 10);
        this.underlineStyle = 'text-decoration: underline;' +
            ' text-decoration-color: ' + this.selectedUnderlineColor + '; text-decoration-thickness:' + this.underlineWidth + 'px;';
        if (this.configParams) this.configParams.default_underline_width = this.underlineWidth;
    }

    changeBrushColor(e: any) {
        this.selectedBrushColor = e;
        const _opacity = Math.round(Math.min(Math.max(this.brushOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedBrushColor + _opacity.toString(16).toUpperCase();

        this.brushStyle = 'background-color:' + color + ';border-radius: 50%;width:' + this.brushWidth * 2 + 'px;height:' + this.brushWidth * 2 + 'px;';
        if (this.configParams) this.configParams.default_brush_color = this.hexToRgbA(this.selectedBrushColor, this.brushOpacity);
    }

    changeBrushWidth(e: any) {
        this.brushWidth = parseInt(e.target.value, 10);
        const _opacity = Math.round(Math.min(Math.max(this.brushOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedBrushColor + _opacity.toString(16).toUpperCase();
        this.brushStyle = 'background-color:' + color + ';border-radius: 50%;width:' + this.brushWidth * 2 + 'px;height:' + this.brushWidth * 2 + 'px;';
        if (this.configParams) this.configParams.default_brush_width = this.brushWidth;
    }

    changeBrushOpacity(e: any) {
        this.brushOpacity = parseInt(e.target.value, 10);
        const _opacity = Math.round(Math.min(Math.max(this.brushOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedBrushColor + _opacity.toString(16).toUpperCase();

        this.brushStyle = 'background-color:' + color + ';border-radius: 50%;width:' + this.brushWidth * 2 + 'px;height:' + this.brushWidth * 2 + 'px;';
        if (this.configParams) {
            this.configParams.default_brush_opacity = this.brushOpacity;
            this.configParams.default_brush_color = this.hexToRgbA(this.selectedBrushColor, this.brushOpacity);
        }
    }

    changeRectBorderColor(e: any) {
        this.selectedRectBorderColor = e;
        const _opacity = Math.round(Math.min(Math.max(this.rectOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedRectColor + _opacity.toString(16).toUpperCase();

        this.rectangleStyle = 'width: 40px; height:20px; border: ' + this.rectWidth + 'px solid ' + this.selectedRectBorderColor + '; background-color: ' + color + ';';
        if (this.configParams) this.configParams.default_rectangle_border_color = this.hexToRgbA(this.selectedRectBorderColor, 100);
    }

    changeRectColor(e: any) {
        this.selectedRectColor = e;
        const _opacity = Math.round(Math.min(Math.max(this.rectOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedRectColor + _opacity.toString(16).toUpperCase();

        this.rectangleStyle = 'width: 40px; height:20px; border: ' + this.rectWidth + 'px solid ' + this.selectedRectBorderColor + '; background-color: ' + color + ';';
        //this.rectangleStyle = 'width: 60px; height:20px; border: 3px solid ' + this.selectedRectBorderColor + '; background-color: ' + this.selectedRectColor + ';';
        if (this.configParams) this.configParams.default_rectangle_color = this.hexToRgbA(this.selectedRectColor, this.rectOpacity);
    }
    changeRectOpacity(e: any) {
        this.rectOpacity = parseInt(e.target.value, 10);
        const _opacity = Math.round(Math.min(Math.max(this.rectOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedRectColor + _opacity.toString(16).toUpperCase();

        this.rectangleStyle = 'width: 40px; height:20px; border: ' + this.rectWidth + 'px solid ' + this.selectedRectBorderColor + '; background-color: ' + color + ';';
        if (this.configParams) {
            this.configParams.default_rectangle_color = this.hexToRgbA(this.selectedRectColor, this.rectOpacity);
            this.configParams.default_rectangle_opacity = this.rectOpacity;
        }
    }

    changeRectWidth(e: any) {
        this.rectWidth = parseInt(e.target.value, 10);
        const _opacity = Math.round(Math.min(Math.max(this.rectOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedRectColor + _opacity.toString(16).toUpperCase();
        this.rectangleStyle = 'width: 40px; height:20px; border: ' + this.rectWidth + 'px solid ' + this.selectedRectBorderColor + '; background-color: ' + color + ';';
        if (this.configParams) this.configParams.default_rectangle_width = this.rectWidth;
    }

    changeCircleColor(e: any) {
        this.selectedCircleColor = e;

        const _opacity = Math.round(Math.min(Math.max(this.circleOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCircleColor + _opacity.toString(16).toUpperCase();

        if (this.configParams) this.configParams.default_circle_color = this.hexToRgbA(this.selectedCircleColor, this.circleOpacity);
        this.circleStyle = 'width: 30px; height:30px;border-radius: 50%; border: border: ' + this.circleWidth + 'px solid ' + this.selectedCircleBorderColor + '; background-color: ' + color + ';';
    }

    changeCircleBorderColor(e: any) {
        this.selectedCircleBorderColor = e;
        if (this.configParams) this.configParams.default_circle_border_color = this.hexToRgbA(this.selectedCircleBorderColor, 100);
        const _opacity = Math.round(Math.min(Math.max(this.circleOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCircleColor + _opacity.toString(16).toUpperCase();
        this.circleStyle = 'width: 30px; height:30px;border-radius: 50%; border: ' + this.circleWidth + 'px solid ' + + this.selectedCircleBorderColor + '; background-color: ' + color + ';';
    }
    changeCircleOpacity(e: any) {
        this.circleOpacity = parseInt(e.target.value, 10);

        const _opacity = Math.round(Math.min(Math.max(this.circleOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCircleColor + _opacity.toString(16).toUpperCase();

        this.circleStyle = 'width: 30px; height:30px;border-radius: 50%; border: ' + this.circleWidth + 'px solid ' + this.selectedCircleBorderColor + '; background-color: ' + color + ';';
        if (this.configParams) {
            this.configParams.default_circle_opacity = this.circleOpacity;
            this.configParams.default_circle_color = this.hexToRgbA(this.selectedCircleColor, this.circleOpacity);
        }
    }
    changeCircleWidth(e: any) {
        this.circleWidth = parseInt(e.target.value, 10);
        const _opacity = Math.round(Math.min(Math.max(this.circleOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCircleColor + _opacity.toString(16).toUpperCase();
        this.circleStyle = 'width: 30px; height:30px;border-radius: 50%; border: ' + this.circleWidth + 'px solid ' + this.selectedCircleBorderColor + '; background-color: ' + color + ';';

        if (this.configParams) this.configParams.default_circle_width = this.circleWidth;
    }
    changeLineColor(e: any) {
        this.selectedLineColor = e;

        const _opacity = Math.round(Math.min(Math.max(this.lineOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedLineColor + _opacity.toString(16).toUpperCase();

        if (this.configParams) this.configParams.default_line_color = this.hexToRgbA(this.selectedLineColor, this.lineOpacity);
        this.lineColor = color;
        this.lineStyle = 'stroke:' + color + ';stroke-width:4';
    }

    changeArrowColor(e: any) {
        this.selectedArrowColor = e;

        const _opacity = Math.round(Math.min(Math.max(this.arrowOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedArrowColor + _opacity.toString(16).toUpperCase();

        if (this.configParams) this.configParams.default_arrow_color = this.hexToRgbA(this.selectedArrowColor, this.arrowOpacity);
        this.arrowColor = color;

    }
    changeCheckColor(e: any) {
        this.selectedCheckColor = e;

        const _opacity = Math.round(Math.min(Math.max(this.checkOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCheckColor + _opacity.toString(16).toUpperCase();

        if (this.configParams) this.configParams.default_check_color = this.hexToRgbA(this.selectedCheckColor, this.checkOpacity);
        this.checkColor = color;

    }

    changeLineOpacity(e: any) {
        this.lineOpacity = parseInt(e.target.value, 10);

        const _opacity = Math.round(Math.min(Math.max(this.lineOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedLineColor + _opacity.toString(16).toUpperCase();
        this.lineColor = color;

        if (this.configParams) {
            this.configParams.default_line_opacity = this.lineOpacity;
            this.configParams.default_line_color = this.hexToRgbA(this.selectedLineColor, this.lineOpacity);
        }
        this.lineStyle = 'stroke:' + color + ';stroke-width:' + this.lineWidth + ';';
    }
    changeArrowOpacity(e: any) {
        this.arrowOpacity = parseInt(e.target.value, 10);

        const _opacity = Math.round(Math.min(Math.max(this.arrowOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedArrowColor + _opacity.toString(16).toUpperCase();
        this.arrowColor = color;

        if (this.configParams) {
            this.configParams.default_arrow_opacity = this.arrowOpacity;
            this.configParams.default_arrow_color = this.hexToRgbA(this.selectedArrowColor, this.arrowOpacity);
        }

    }
    changeCheckOpacity(e: any) {
        this.checkOpacity = parseInt(e.event.target, 10);

        const _opacity = Math.round(Math.min(Math.max(this.checkOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCheckColor + _opacity.toString(16).toUpperCase();
        this.checkColor = color;

        if (this.configParams) {
            this.configParams.default_check_opacity = this.checkOpacity;
            this.configParams.default_check_color = this.hexToRgbA(this.selectedCheckColor, this.checkOpacity);
        }
    }

    changeLineWidth(e: any) {
        this.lineWidth = parseInt(e.target.value, 10);

        const _opacity = Math.round(Math.min(Math.max(this.lineOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedLineColor + _opacity.toString(16).toUpperCase();
        this.lineColor = color;
        this.lineStyle = 'stroke:' + color + ';stroke-width:' + this.lineWidth + 'px;';
        if (this.configParams) this.configParams.default_line_width = this.lineWidth;
    }

    changeArrowWidth(e: any) {
        this.arrowWidth = parseInt(e.target.value, 10);
        if (this.configParams) this.configParams.default_arrow_width = this.arrowWidth;
        const _opacity = Math.round(Math.min(Math.max(this.arrowOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedArrowColor + _opacity.toString(16).toUpperCase();
        this.arrowColor = color;

    }
    changeCheckWidth(e: any) {
        this.checkWidth = parseInt(e.event.target, 10);
        if (this.configParams) this.configParams.default_check_width = this.checkWidth * 12;
        const _opacity = Math.round(Math.min(Math.max(this.checkOpacity / 100 || 1, 0), 1) * 255);
        const color = this.selectedCheckColor + _opacity.toString(16).toUpperCase();
        this.checkColor = color;

    }

}
