import { Component, OnInit } from '@angular/core';
import { CustomSelectElement } from '../custom-select-element-dto';
import { SmService } from 'supermappe-core';
import { UiConstants } from '../../../../shared/ui-constants';

@Component({
  selector: 'app-size-toolbar',
  templateUrl: './size-toolbar.component.html',
  styleUrls: ['./size-toolbar.component.scss']
})
export class SizeToolbarComponent {

  public sizes: CustomSelectElement[];

  selectedSize = 'both';
  selectedSizeIcon = 'arrow-all';

  constructor(private smService: SmService) {
    this.sizes = UiConstants.SIZES;
  }

  changeSize(event: any) {
    this.selectedSize = event.value;
    this.selectedSizeIcon = event.icon;
    this.smService.resizeSelection(this.selectedSize);
  }
}

