import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-exit-dialog',
    templateUrl: './confirm-exit-dialog.component.html',
    styleUrls: ['./confirm-exit-dialog.component.scss']
})
export class ConfirmExitDialogComponent {
    public title = '';
    private _message = '';
    get message(): string {
        return this._message
    }
    set message(value: string) {
        this._message = value.replace(/\n/g, '<br/>');
    }

    public ok = true;
    public waitCaption = '';
    public exitCaption = '';

    constructor(public dialogRef: MatDialogRef<ConfirmExitDialogComponent>) { }

}
