import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { AdminMoveMapsComponent } from './admin-move-maps.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
    { path: '', redirectTo: '/admin-move-maps', pathMatch: 'full' },
    { path: 'admin-move-maps', component: AdminMoveMapsComponent, data: { title: extract('ADMIN_TITLE') }, canActivate: [AuthenticationGuard] }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})
export class AdminMoveMapsRoutingModule { }
