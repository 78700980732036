import * as QRCode from 'qrcode';

import { Injectable } from '@angular/core';
import { ShareLinkService } from './share-link.service';

@Injectable({
  providedIn: 'root'
})

export class QRCodesService {

  constructor(
    private shareLinkService: ShareLinkService
  ) { }

  injectQRCodesInPage(mapId: string, gdocFileId: string) {
    return new Promise((resolve, reject) => {
      const mapLink = this.shareLinkService.getLinkToShare(mapId);
      const gdocLink = (gdocFileId ? 'https://docs.google.com/document/d/' + gdocFileId + '/edit' : '');
      this.injectQRCodeElement(mapLink, 'map-qrcode').then(() => {
        return this.injectQRCodeElement(gdocLink, 'gdoc-qrcode');
      }).then(() => {
        return resolve('');
      }).catch((err: any) => {
        return reject(err);
      });
    });
  }

  injectQRCodeElement(text: string, elementName: string) {
    return new Promise((resolve, reject) => {
      const element = document.createElement('img');
      const parentElement = document.getElementById('div-' + elementName);

      if (parentElement) {
        const oldel = document.getElementById(elementName);
        if (oldel) { oldel.remove(); }

        parentElement.appendChild(element);
        if (element.parentElement) {
          element.id = element.parentElement.id.substring(4);
          // const element = document.getElementById(elementName);
          if (text && element) {
            this.createQRCode(text).then((dataUrl) => {
              const el: any = element;
              el.onload = () => {
                return resolve('');
              };
              el.src = dataUrl;
            });
          } else {
            return resolve('');
          }
        }
      } else { return reject(''); }
    });
  }

  createQRCode(text: string, size?: number): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!size) {
        size = 0;
      }
      QRCode.toDataURL(text, { errorCorrectionLevel: 'L', width: size })
        .then((dataUrl: string) => {
          return resolve(dataUrl);
        })
        .catch((err: any) => {
          console.error(err);
          return reject(err);
        });
    });
  }

}
