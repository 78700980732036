<div class="chat-container">
    <div class="chat-title-container">
        <div class="chat-title-icon">
            <mat-icon fontSet="material-icons-outlined">chat</mat-icon>
        </div>
        <div class="chat-title-text">
            <span>({{numUsers}}) </span><span translate>MAIN_TOOLBAR_CHAT</span>
        </div>
        <div class="chat-clear-button" *ngIf="isMapOwner">
            <button mat-icon-button color="primary" matTooltip="{{'CHAT_CLEAR' | translate}}" (click)="clearChat();">
                <mat-icon [attr.aria-label]="'CHAT_CLEAR' | translate" svgIcon="broom"></mat-icon>
            </button>
        </div>
        <div class="chat-close-button">
            <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" (click)="closeChat();">
                <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </div>
    </div>
    <div #chatHistory class="chat-history-container">
        <div class="chat-history">
            <div *ngFor="let item of items">
                <div class="message-item" [ngStyle]="{'opacity': (item.loggedIn ? 1 : 0.5)}">
                    <div *ngIf="item.type === 'unread'" #unreadMarker class="message-item-unread">
                        <span class="user-unread" translate>CHAT_MESSAGES_UNREAD</span>
                        <mat-icon svgIcon="arrow-down-circle" class="button-scroll-down" (click)="scrollDown()"></mat-icon>
                    </div>
                    <div *ngIf="item.type === 'date'" class="message-item-date">
                        <span class="user-date">{{item.date}}</span>
                    </div>
                    <div *ngIf="item.type === 'full-me'" class="message-item-full-me">
                        <!-- <span>FULL-ME</span><br /> -->
                        <span class="user-name" [ngStyle]="{'color': item.color}">{{item.name}}</span>
                        <img src="{{item.icon}}" class="user-icon-me" [ngStyle]="{'border-color': item.color}" />
                        <br />
                        <span class="user-message-me">{{item.message}}</span>
                    </div>
                    <div *ngIf="item.type === 'short-me'" class="message-item-short-me">
                        <!-- <span>SHORT-ME</span><br /> -->
                        <span class="user-message-me">{{item.message}}</span>
                    </div>
                    <div *ngIf="item.type === 'full-other'" class="message-item-full-other">
                        <!-- <span>FULL-OTHER</span><br /> -->
                        <img src="{{item.icon}}" class="user-icon-other" [ngStyle]="{'border-color': item.color}" />
                        <span class="user-name" [ngStyle]="{'color': item.color}">{{item.name}}</span>
                        <br />
                        <span class="user-message-other">{{item.message}}</span>
                    </div>
                    <div *ngIf="item.type === 'short-other'" class="message-item-short-other">
                        <!-- <span>SHORT-OTHER</span><br /> -->
                        <span class="user-message-other">{{item.message}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chat-input-line">
        <textarea #chatMessage matInput type="text" class="chat-input" autocomplete="off" (focus)="inputGotFocus()" (blur)="inputLostFocus()" (keypress)="keypress($event)"></textarea>
    </div>
</div>
<div class="over-panel" [ngStyle]="styleover" #overpanel *ngIf="resizing"></div>
