import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';
import { BrowsercheckService } from '../browsercheck.service';
import { Observable } from 'rxjs';

import { SmeService } from '../sme/sme.service';
import { MatomoTracker } from 'ngx-matomo-client';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';
import { Unsubscribe } from 'firebase/auth';


const log = new Logger('AuthenticationGuard');

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router,
        private smeService: SmeService,
        private matomoTracker: MatomoTracker,
        private firebaseAuthService: FirebaseAuthService,
        private authenticationService: AuthenticationService,
        private browsercheckService: BrowsercheckService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.browsercheckService.isBrowserAllowed()) {
                log.debug('Not an allowed browser, redirecting...');
                this.router.navigate(['/nobrowser'], { replaceUrl: true });
                return resolve(false);
            }
            //return this.authenticationService.isAuthenticated();
            // return this.firebaseAuthService.getTokenId()
            // Promise.resolve().then(() => {
            this.firebaseAuthService.getTokenId().then((token: string) => {
                return this.isLoggedin();
            }).then((e) => {
                if (e) {
                    const user = (this.authenticationService.credentials) ? this.authenticationService.credentials?.username : 'no logged User';
                    this.matomoTracker.setUserId(user);
                    this.matomoTracker.setDocumentTitle("ngx-Matomo Test");

                    return resolve(true);
                } else {
                    this.router.navigate(['/login', state.url], { replaceUrl: true });
                    return resolve(false);
                }
            }).catch(() => {
                this.router.navigate(['/login', state.url], { replaceUrl: true });
                return resolve(false);
            });
        });
    }

    isLoggedin(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {
                this.authenticationService.reloadCredentials();
                if (!this.authenticationService.isAuthenticated()) {
                    return Promise.resolve(false);
                } else {
                    return Promise.resolve(true);
                    //resolve(true);
                    // Potrebbe servire di nuovo in caso di cambio degli SCOPES
                    // Promise.resolve().then(() => {
                    //     return this.smeService.mustReloggedPromise();
                    // }).then((redoLogin: boolean) => {
                    //     if (redoLogin && this.authenticationService.credentials) {
                    //         this.smeService.revokeTokenPromise(this.authenticationService.credentials.accessToken)
                    //             .then((res: any) => {
                    //                 return resolve(false);
                    //             }).catch((error: any) => {
                    //                 return resolve(false);
                    //             });
                    //     } else {
                    //         return resolve(true);
                    //     }
                    // }).catch((error: any) => {
                    //     // this.router.navigate(['/login', state.url], { replaceUrl: true });
                    //     return resolve(false);
                    // });
                }
            }).then((res) => {
                return resolve(res); // res;
            });
        });
    }

}
