import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';

import { SmeService } from './sme/sme.service';

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { DeviceDetectorService } from 'ngx-device-detector';

import { MaterialModule } from '../material.module';
import { ShellComponent } from './shell/shell.component';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { I18nService } from './i18n.service';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { FirebaseService } from './firebase/firebase.service';
import { FirebaseAuthService } from './firebase/firebase-auth.service';
import { BackendService } from './backend/backend.service';
import { BrowsercheckService } from './browsercheck.service';
import { GoogleService } from './google/google.service';
import { DeviceService } from './device.service';
import { IframeLoginService } from './authentication/iframe-login-service';
import { CookieService } from 'ngx-cookie-service';
import { RegisteredUserService } from './authentication/registered-user.service';
import { TokenInterceptor } from './http/token.interceptor';
import { BrowsercheckGuard } from './browsercheck.guard';
import { environment } from 'src/environments/environment';



// export function createHttpService(backend: ConnectionBackend,
//   defaultOptions: RequestOptions,
//   httpCacheService: HttpCacheService,
//   firebaseAuthService: FirebaseAuthService) {
//   return new HttpService(backend, defaultOptions, httpCacheService, firebaseAuthService);
// }

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        MaterialModule,
        RouterModule,
    ],
    declarations: [
        ShellComponent
    ],
    providers: [
        BrowsercheckService,
        BrowsercheckGuard,
        SmeService,
        BackendService,
        CookieService,
        FirebaseService,
        FirebaseAuthService,
        AuthenticationService,
        RegisteredUserService,
        AuthenticationGuard,
        I18nService,
        GoogleService,
        HttpCacheService,
        DeviceService,
        DeviceDetectorService,
        IframeLoginService,
        HttpCacheService,
        ApiPrefixInterceptor,
        TokenInterceptor,
        ErrorHandlerInterceptor,
        {
            provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
        },
        // {
        //   provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true
        // },
        CacheInterceptor,
        {
            provide: HttpClient,
            useClass: HttpService
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        }
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // Import guard
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
