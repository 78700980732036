import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MapEditRoutingModule } from './map-edit-routing.module';
import { MapEditComponent } from './map-edit.component';
import { SmModule } from 'supermappe-core';
import { MapEditService } from './map-edit.service';
import { EditToolbarComponent } from './toolbar/edit-toolbar/edit-toolbar.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { SmEventBroker } from '../shared/sm-event-broker';
import { TtsService } from '../shared/commands/tts.service';
import { TranslateModule } from '@ngx-translate/core';
import { DirtyDataService } from '../shared/commands/dirty-data.service';
import { MapLanguageService } from '../shared/commands/map-language.service';
import { InteractionmodeService } from '../shared/commands/interactionmode.service';
import { EditMenuComponent } from './menu/edit-menu/edit-menu.component';
import { ConvertToImageService } from '../shared/commands/convert-to-image.service';
import { FbService } from '../shared/commands/fb.service';
import { PrintMapService } from '../shared/commands/print-map.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ZoomService } from '../shared/commands/zoom.service';
import { MapFoldingService } from '../shared/commands/map-folding.service';
import { CdkDrag } from '@angular/cdk/drag-drop';

import { EditDataService } from '../shared/commands/edit-data.service';
import { AutosaveService } from './commands/autosave.service';
import { MapStateService } from '../shared/map-state.service';
import { ExportSmeService } from './commands/export-sme.service';
import { GenerateSmeService } from './commands/generate-sme.service';
import { QuickEditService } from '../shared/commands/quick-edit.service';
import { YoutubeService } from '../shared/commands/youtube.service';

import { CopyAndOpenMapService } from '../shared/commands/copy-and-open-map.service';
import { CopyMapService } from '../shared/commands/copy-map.service';
import { HttpClientModule } from '@angular/common/http';

import { FormatTextToolbarComponent } from './toolbar/custom-toolbar/format-text-toolbar/format-text-toolbar.component';
import { CustomToolbarComponent } from './toolbar/custom-toolbar/custom-toolbar.component';
import { FontPickerModule } from 'ngx-font-picker';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { CustomizeToolbarService } from './toolbar/custom-toolbar/customize-toolbar.service';
import { ButtonColorPickerComponent } from './button-color-picker/button-color-picker.component';
import { FormatElementToolbarComponent } from './toolbar/custom-toolbar/format-element-toolbar/format-element-toolbar.component';
import { GenericToolbarComponent } from './toolbar/custom-toolbar/generic-toolbar/generic-toolbar.component';
import { AlignToolbarComponent } from './toolbar/custom-toolbar/align-toolbar/align-toolbar.component';
import { SizeToolbarComponent } from './toolbar/custom-toolbar/size-toolbar/size-toolbar.component';
import { TableToolbarComponent } from './toolbar/custom-toolbar/table-toolbar/table-toolbar.component';
import { FormatTableToolbarComponent } from './toolbar/custom-toolbar/format-table-toolbar/format-table-toolbar.component';
import { MenuColorPickerComponent } from './menu-color-picker/menu-color-picker.component';
import { ImageToolbarComponent } from './toolbar/custom-toolbar/image-toolbar/image-toolbar.component';
import { ImageMapsService } from './commands/image-maps.service';
import { ExtraService } from './commands/extra.service';
import { CoreModule } from '../core/core.module';
import { ImageMenuComponent } from './image-menu/image-menu.component';
import { ExtraPanelComponent } from './extra-panel/extra-panel.component';
import { ChatPanelComponent } from '../shared/chat/chat-panel.component';
import { ChatToolbarComponent } from '../shared/chat/chat-toolbar.component';
import '/src/assets/ckeditor/ckeditor.js';
import { CaptureCameraComponent } from './capture-camera/capture-camera.component';
import { NewTableComponent } from './new-table/new-table.component';
import { NewTableService } from './new-table/new-table.service';
import { SelectionMenuComponent } from './selection-menu/selection-menu.component';
import { LanguageToolbarComponent } from './toolbar/custom-toolbar/language-toolbar/language-toolbar.component';
import { SearchWebComponent } from './search-web/search-web.component';
import { ToggleFullscreenService } from '../shared/commands/toggle-fullscreen.service';
import { ResizableModule } from 'angular-resizable-element';
import { GoogleDocToolbarComponent } from './toolbar/google-doc-toolbar/google-doc-toolbar/google-doc-toolbar.component';
import { BrowserModule } from '@angular/platform-browser';
import { DesmosPanelComponent } from './desmos-panel/desmos-panel.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { WebPanelComponent } from './web-panel/web-panel.component';
import { DesmosService } from '../shared/commands/desmos.service';
import { OutlineService } from './outline/outline.service';
import { OutlineComponent } from './outline/outline.component';
import { NotepadComponent } from './notepad/notepad.component';
import { DesmosConfigComponent } from './desmos-config/desmos-config.component';
import { ArrangeService } from '../shared/commands/arrange.service';
import { PdfEditorComponent } from './pdf-editor/pdf-editor.component';
import { MathEditorComponent } from './math-editor/math-editor.component';
import { MathService } from '../shared/commands/math.service';
import { MathReaderBaseService } from '../shared/commands/math-reader/math-reader-base.service';
import { MathReaderItService } from '../shared/commands/math-reader/math-reader-it.service';
import { MathReaderEnService } from '../shared/commands/math-reader/math-reader-en.service';
import { MathKeyboardService } from '../shared/virtual-keyboards/math-keyboard.service';
import { AutodrawComponent } from './autodraw/autodraw.component';
import { PdfHomeComponent } from './pdf-editor/pdf-home/pdf-home.component';
import { PdfMenuRecentsComponent } from './pdf-editor/pdf-home/pdf-menu-recents/pdf-menu-recents.component';
import { PdfRecentComponent } from './pdf-editor/pdf-home/pdf-recent/pdf-recent.component';
import { PdfMasterComponent } from './pdf-editor/pdf-master/pdf-master.component';
import { PdfConfigToolsComponent } from './pdf-editor/pdf-config-tools/pdf-config-tools.component';
import { PdfPrintComponent } from './pdf-editor/pdf-print/pdf-print.component';
import { MathKeyboardChoiceComponent } from './math-keyboard-choice/math-keyboard-choice.component';
import { EditShowToolbarComponent } from "./toolbar/edit-show-toolbar/edit-show-toolbar.component";


@NgModule({
    imports: [
        CdkDrag,
        CoreModule,
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatTreeModule,
        MatSliderModule,
        MapEditRoutingModule,
        MatInputModule,
        MatMenuModule,
        MatListModule,
        MatCheckboxModule,
        MatSliderModule,
        SmModule,
        SharedModule,
        MaterialModule,
        HttpClientModule,
        FontPickerModule,
        MccColorPickerModule.forRoot({
            empty_color: 'transparent',
            selected_icon: '',
            disable_selected_icon: true,
            enable_alpha_selector: false
        }),
        ResizableModule,
        BrowserModule,
        TranslateModule.forRoot()
    ],
    providers: [
        MapEditService,
        SmEventBroker,
        DirtyDataService,
        MapLanguageService,
        InteractionmodeService,
        ZoomService,
        ConvertToImageService,
        TtsService,
        FbService,
        PrintMapService,
        MapFoldingService,
        AutosaveService,
        ToggleFullscreenService,
        EditDataService,
        MapStateService,
        ExportSmeService,
        GenerateSmeService,
        CopyAndOpenMapService,
        CopyMapService,
        QuickEditService,
        ArrangeService,
        YoutubeService,
        CustomizeToolbarService,
        ImageMapsService,
        NewTableService,
        DesmosService,
        ExtraService,
        OutlineService,
        MathService,
        MathReaderBaseService,
        MathReaderItService,
        MathReaderEnService,
        MathKeyboardService
    ],
    declarations: [
        MapEditComponent,
        EditToolbarComponent,
        EditShowToolbarComponent,
        EditMenuComponent,
        ButtonColorPickerComponent,
        LanguageToolbarComponent,
        FormatElementToolbarComponent,
        FormatTextToolbarComponent,
        CustomToolbarComponent,
        GenericToolbarComponent,
        AlignToolbarComponent,
        SizeToolbarComponent,
        TableToolbarComponent,
        FormatTableToolbarComponent,
        MenuColorPickerComponent,
        ImageToolbarComponent,
        ImageMenuComponent,
        CaptureCameraComponent,
        NewTableComponent,
        ExtraPanelComponent,
        ChatPanelComponent,
        ChatToolbarComponent,
        SelectionMenuComponent,
        SearchWebComponent,
        GoogleDocToolbarComponent,
        DesmosPanelComponent,
        DesmosConfigComponent,
        WebPanelComponent,
        NotepadComponent,
        OutlineComponent,
        DesmosConfigComponent,
        PdfEditorComponent,
        PdfHomeComponent,
        PdfMenuRecentsComponent,
        PdfRecentComponent,
        MathEditorComponent,
        AutodrawComponent,
        PdfMasterComponent,
        PdfConfigToolsComponent,
        PdfPrintComponent,
        MathKeyboardChoiceComponent

        // ],
        // entryComponents: [
        //     CaptureCameraComponent,
        //     NewTableComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class MapEditModule { }
