<div class="outer-container">
    <div class="container">
        <!-- <div class="brand">
            <img class="brand-logo" (click)="gotoHome()" src="assets/login/SuperMappeX_scritta.png?v=1" alt="logo" />

        </div> -->
        <div class="smx-logo-extended brand" routerLink="/home" translate>
            <img class="smx-logo-image-extended brand-logo" src="{{logoUrlExtended}}?v=1" />
        </div>
        <!-- <div *ngIf="compressLogo" class="smx-logo-compressed brand" routerLink="/home" translate>
            <img class="smx-logo-image-compressed brand-logo" src="{{logoUrlCompressed}}?v=1" />
        </div> -->

        <div class="box">
            <div id="posta">
                <div id="aereo"></div>
            </div>
            <div class="messaggio">
                <h1 translate>PASSWORD_RECOVERY</h1>
                <div translate>RESPONSE_RP_1<span>{{text}}</span></div>
                <div translate>RESPONSE_RP_3</div>
                <div translate>RESPONSE_RP_4 <a href="https://www.anastasis.it/assistenza/" target="_blank" translate>LINK_ASSISTANCE</a></div>

            </div>
        </div>
    </div>

</div>
