import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { DirtyData, SmService } from 'supermappe-core';
import { MapStateService } from '../shared/map-state.service';
import { ImageMapsService } from './commands/image-maps.service';
import { UiConstants } from '../shared/ui-constants';
import { ShareLinkService } from '../shared/commands/share-link.service';
import { Logger } from '../core/logger.service';
import { UserPreferenceService } from '../shared/user-preference.service';
import { Subscription } from 'rxjs';
import { MapOperationType } from '../shared/map-operations';

declare let $: any;

const logger: Logger = new Logger('MapEditService');

@Injectable({
    providedIn: 'root'
})
export class MapEditService implements OnDestroy {
    public onResize = new EventEmitter<any>();
    private getLinkPreviewSubscription: Subscription | undefined;

    constructor(private smService: SmService,
        private mapStateService: MapStateService,
        private imageMapsService: ImageMapsService,
        private shareLinkService: ShareLinkService,
        private userPreferenceService: UserPreferenceService) { }
    /**
     * Check if there are undo commands
     * return {boolean} True if it's possible at least an undo
     */
    public canUndo(dirtyData: DirtyData) {
        return (dirtyData.length > 0 && dirtyData.index > 0);
    }

    /**
     * Check if there are Redo commands
     */
    public canRedo(dirtyData: DirtyData) {
        return (dirtyData.length > 0 && dirtyData.index < dirtyData.length);
    }

    public notifyResize() {
        this.onResize.emit();
    }

    private isAMapFromSameDomain(mapLink: string, domain: string): boolean {
        let ok = false;
        try {
            const mapUrl = new URL(mapLink);
            const domainUrl = new URL(domain);
            if (mapUrl.host === domainUrl.host) {
                ok = true;
            } else if
                ((mapUrl.host.replace('dev.supermappex.it', 'localhost:4200.') === domainUrl.host) ||
                (mapUrl.host === domainUrl.host.replace('dev.supermappex.it', 'localhost:4200'))) {
                ok = true;
            }
        } catch (err) {
            // Not a link: new URL failed
        }
        return ok;
    }

    /**
     * Edit node from html content
     * param contentHtml
     * param X
     * param Y
     */
    public editNodeFromHtml(contentHtml: string, X: number, Y: number, linkText: string): any {
        const elem = document.createElement('div');
        elem.innerHTML = contentHtml;
        let containsYoutubeLink = false;
        let containsMapLink = false;
        let selectedItems: Array<any>;
        selectedItems = [];
        if (X === 0 && Y === 0) {
            selectedItems = this.smService.checkSelectionAndAddNewNode('');
        } else {
            this.smService.addNodeAndSelectInEmptySpace(X, Y);
            selectedItems.push(this.smService.getSelectedNode());
        }
        const links = elem.getElementsByTagName('a');
        if (links.length > 0) {
            if (links[0].href.includes('youtube') || links[0].href.includes('youtu.be')) {
                // if (X === 0 && Y === 0) {
                //   this.smService.checkSelectionAndAddNewNode('');
                // } else {
                //   this.smService.addNodeAndSelectInEmptySpace(X, Y);
                // }
                this.insertLinkContent(selectedItems[0], links[0].href, elem);
                containsYoutubeLink = true;
            } else if (this.isAMapFromSameDomain(links[0].href, window.location.origin)) {
                // From paste text map link
                containsMapLink = true;
                this.insertLinkContent(selectedItems[0], links[0].href, elem);
            } else {
                if (contentHtml.startsWith('<a')) {
                    contentHtml = '';
                }
                linkText = links[0].href;
            }
        } else if (this.isAMapFromSameDomain(linkText, window.location.origin)) {
            // From drop
            containsMapLink = true;
            this.insertLinkContent(selectedItems[0], linkText, elem);
        }
        if (!containsYoutubeLink && !containsMapLink) {
            const images = elem.getElementsByTagName('img');
            if (images.length > 0) {
                setTimeout(() => {
                    this.mapStateService.dropping = true;
                    this.imageMapsService.insertImageFromUri(images[0].src, '', selectedItems[0].id, true);
                    // Paste html text
                    if (contentHtml) {
                        this.insertHtmlWithDefaultStyle(selectedItems[0].id, contentHtml, elem);
                    }
                }, 100);
            } else {
                if (contentHtml || linkText) {
                    // // Paste only html text
                    // if (X === 0 && Y === 0) {
                    //   this.smService.checkSelectionAndAddNewNode('');
                    // } else {
                    //   this.smService.addNodeAndSelectInEmptySpace(X, Y);
                    // }
                    setTimeout(() => {
                        // Paste html text
                        if (contentHtml) {
                            this.insertHtmlWithDefaultStyle(selectedItems[0].id, contentHtml, elem);
                        } else {
                            if (linkText && (linkText.startsWith('http:') || (linkText.startsWith('https:')))) {
                                // Link
                                this.insertLinkContent(selectedItems[0], linkText, elem);
                            } else {
                                // Plain text
                                this.insertHtmlWithDefaultStyle(selectedItems[0].id, '<p>' + linkText + '</p>', elem);
                            }
                        }
                    }, 100);
                }
            }
        }
    }

    public editEdgeFromHtml(contentHtml: string, linkText: string) {
        if (contentHtml || linkText) {
            const selectedEdge = this.smService.getSelectedEdge();
            if (selectedEdge) {
                const elem = document.createElement('div');
                elem.innerHTML = contentHtml;
                if (contentHtml) {
                    this.insertHtmlWithDefaultStyle(selectedEdge.id, contentHtml, elem);
                } else if (linkText) {
                    this.insertHtmlWithDefaultStyle(selectedEdge.id, '<p>' + linkText + '</p>', elem);
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.getLinkPreviewSubscription) { this.getLinkPreviewSubscription.unsubscribe(); }
    }

    private insertLinkContent(node: any, linkText: string, elem: HTMLDivElement) {
        // const node = this.smService.getSelectedNode();
        this.smService.editMainLink(linkText, node);
        if (this.mapStateService.mapOperations) {
            if (linkText.includes('youtube') || linkText.includes('youtu.be')) {
                this.mapStateService.mapOperations.add(MapOperationType.STAT_ADDVIDEO);
            } else {
                this.mapStateService.mapOperations.add(MapOperationType.STAT_ADDLINK);
            }
        }
        this.getLinkPreviewSubscription = this.shareLinkService.getLinkPreview(linkText).subscribe((data: any) => {

            const d = data;
            // const node = this.smService.sm.map.getSelectedNode();
            if (node && node.video && node.video.preview) {
                this.mapStateService.dropping = true;
                this.imageMapsService.insertImageFromUri(node.video.preview, '', node.id, true);
                if (data.res.title) {
                    // const elem = document.createElement('div');
                    elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + data.res.title + '</span>', true);
                    this.insertHtmlText(elem, node.id);
                }
            } else if (d.res) {
                if (d.res.title || d.res.description) {
                    if (d.res.title.endsWith('.jpeg') || d.res.title.endsWith('.jpg') || d.res.title.endsWith('.png')) {
                        let l = linkText;
                        if (d.res.title.includes('http')) {
                            const s = d.res.title.split('http');
                            l = 'http' + s[1];
                        }
                        this.imageMapsService.insertImageFromUri(l);
                    } else {
                        if (d.res.description === null) { d.res.description = ''; }
                        // const elem = document.createElement('div');
                        elem.innerHTML = UiConstants.getDefaultHtmlForTitle('<span>' + data.res.title + '</span>', true);
                        this.insertHtmlText(elem, node.id);
                    }

                }
                if (d.res.image) {
                    this.imageMapsService.insertImageFromUri(d.res.image);
                }
            }
            this.smService.updateAllWidgets();
            this.mapStateService.forceAutoSave();
        });
    }

    private insertHtmlText(elem: any, elemId: string) {
        const node = this.smService.sm.map.nodes[elemId];
        const edge = this.smService.sm.map.edges[elemId];
        if (node) {
            const imgs = elem.getElementsByTagName('img');
            for (let index = imgs.length - 1; index >= 0; index--) {
                imgs[index].parentNode.removeChild(imgs[index]);
            }
            if (elem.innerHTML !== '') {
                this.smService.setNodeTitleCommand(elemId, elem.innerHTML);
            }
        } else if (edge) {
            if (elem.innerHTML !== '') {
                this.smService.setEdgeTitleCommand(elemId, elem.innerHTML);
            }
        }
    }

    private insertHtmlWithDefaultStyle(nodeId: string, contentHtml: string, elem: HTMLDivElement) {
        elem.innerHTML = contentHtml;
        const text = $(elem).text().trim();
        if (text !== '') {
            const html = UiConstants.getDefaultHtmlForTitle(text, true);
            elem.innerHTML = html;
            this.insertHtmlText(elem, nodeId);
        }
    }

    public getMapStyles() {
        logger.info('Get font styles from DB.');
        if (this.userPreferenceService.userPrefs) {
            UiConstants.nodeFontStyle = this.userPreferenceService.userPrefs.nodeTextStyle;
            UiConstants.edgeFontStyle = this.userPreferenceService.userPrefs.edgeTextStyle;
            UiConstants.deepFontStyle = this.userPreferenceService.userPrefs.deepTextStyle;
            UiConstants.noteFontStyle = this.userPreferenceService.userPrefs.noteTextStyle;
            UiConstants.nodeAspect = this.userPreferenceService.userPrefs.nodeAspect;
            UiConstants.edgeAspect = this.userPreferenceService.userPrefs.edgeAspect;
            UiConstants.frameNodeImage = this.userPreferenceService.userPrefs.framedNodeImage;
        }
    }

    public saveMapStyles() {
        logger.info('Set font styles on DB.');
        if (this.userPreferenceService.userPrefs) {
            this.userPreferenceService.userPrefs.nodeTextStyle = UiConstants.nodeFontStyle;
            this.userPreferenceService.userPrefs.edgeTextStyle = UiConstants.edgeFontStyle;
            this.userPreferenceService.userPrefs.deepTextStyle = UiConstants.deepFontStyle;
            this.userPreferenceService.userPrefs.noteTextStyle = UiConstants.noteFontStyle;
            this.userPreferenceService.userPrefs.framedNodeImage = UiConstants.frameNodeImage;
            this.userPreferenceService.userPrefs.nodeAspect = UiConstants.nodeAspect;
            this.userPreferenceService.userPrefs.edgeAspect = UiConstants.edgeAspect;
            this.userPreferenceService.setUserPreference(this.userPreferenceService.userPrefs);
        }
    }


    public htmlEncode(str: string): string {
        let i = str.length;
        const aRet = [];

        while (i--) {
            const iC = str.charCodeAt(i);
            if (iC < 65 || iC > 127 || (iC > 90 && iC < 97)) {
                aRet[i] = '&#' + iC + ';';
            } else {
                aRet[i] = str[i];
            }
        }
        return aRet.join('');
    }

}
