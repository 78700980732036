import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';

@Component({
    selector: 'app-new-table',
    templateUrl: './new-table.component.html',
    styleUrls: ['./new-table.component.scss']
})
export class NewTableComponent implements OnInit {

    @ViewChild('row') row: ElementRef | undefined;
    @ViewChild('col') col: ElementRef | undefined;

    colNum = 0;
    rowNum = 0;
    minRow = 0;
    minCol = 0;
    public error: string;
    public title = '';

    constructor(public dialogRef: MatDialogRef<NewTableComponent>, private translateService: TranslateService) {
        this.colNum = 4;
        this.rowNum = 4;
        this.error = this.translateService.instant(extract('TABLE_MAXCOLROW'));
    }

    onChangeCol(event: any) {
        const newValue = event && event.target?.value ? event.target.value : -1;
        if (newValue > -1) {
            this.minCol = 1;
            if (this.row?.nativeElement.valueAsNumber === 1) {
                this.minCol = 2;
            }

            if (newValue >= this.minCol && newValue <= 10) {
                this.colNum = newValue;
            } else {
                if (newValue < this.minCol) {
                    this.colNum = this.minCol;
                    if (this.col) {
                        this.col.nativeElement.value = this.minCol;
                    }
                } else {
                    this.colNum = 10;
                    if (this.col) {
                        this.col.nativeElement.value = 10;
                    }
                }
                if (this.col?.nativeElement.valueAsNumber === 1) {
                    this.minRow = 2;
                } else {
                    this.minRow = 1;
                }
                //  this.error = 'il valore deve essere tra 2 e 10';
            }
        }
    }

    onChangeRow(event: any) {
        const newValue = event && event.target?.value ? event.target.value : -1;
        if (newValue > -1) {
            const val: number = newValue;
            this.minRow = 1;
            if (this.col?.nativeElement.valueAsNumber === 1) {
                this.minRow = 2;
            }
            if (val >= this.minRow && val <= 10) {
                this.rowNum = val;
            } else {
                if (val < this.minRow) {
                    this.rowNum = this.minRow;
                    if (this.row) {
                        this.row.nativeElement.value = this.minRow;
                    }
                    // newValue = 2;
                } else {
                    if (this.row) {
                        this.row.nativeElement.value = 10;
                    }
                    this.rowNum = 10;
                }
            }
            if (this.row?.nativeElement.valueAsNumber === 1) {
                this.minCol = 2;
            } else {
                this.minCol = 1;
            }
        }
    }

    checkRange() {
        if (this.row?.nativeElement.valueAsNumber >= this.minRow && this.row?.nativeElement.valueAsNumber <= 10) {
            this.rowNum = this.row?.nativeElement.value;
            // this.error = '';
        } else {
            if (this.row?.nativeElement.valueAsNumber < this.minRow) {
                this.rowNum = this.minRow;
                if (this.row) {
                    this.row.nativeElement.value = this.minRow;
                }
                // newValue = 2;
            } else {
                if (this.row) {
                    this.row.nativeElement.value = 10;
                }
                this.rowNum = 10;
                // newValue = 10;
            }
            // this.error = 'il valore deve essere tra 2 e 10';
        }
        if (this.col?.nativeElement.valueAsNumber >= this.minCol && this.col?.nativeElement.valueAsNumber <= 10) {
            if (this.col) {
                this.colNum = this.col.nativeElement.value;
            }
            //  this.error = '';
        } else {
            if (this.col?.nativeElement.valueAsNumber < this.minCol) {
                this.colNum = this.minCol + 1;
                // newValue = 2;
                if (this.col) {
                    this.col.nativeElement.value = 2;
                }
            } else {
                this.colNum = 10;
                if (this.col) {
                    this.col.nativeElement.value = 10;
                }
                // newValue = 10;
            }
            //  this.error = 'il valore deve essere tra 2 e 10';
        }
    }


    ngOnInit() {
        this.minCol = 1;
        this.minRow = 1;
    }

    closeDialog() {
        this.checkRange();
        this.dialogRef.close({ row: this.rowNum, col: this.colNum });
    }

    cancelOp() {
        this.dialogRef.close();
    }

}
