import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { SpainDesktopComponent } from './spain-desktop.component';


const routes: Routes = [
    { path: 'spain-desktop', component: SpainDesktopComponent, data: {} }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class SpainDesktopRoutingModule { }
