import { Component, OnInit, OnDestroy, Input, EventEmitter } from '@angular/core';
import { GoogleImagesService } from '../../shared/commands/google.image.service';
import { ExtraItem, ExtraOpenDto, ExtraService } from '../commands/extra.service';
import { MapStateService } from '../../shared/map-state.service';
import { SmService } from 'supermappe-core';
import { Subscription } from 'rxjs';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { TtsService } from '../../shared/commands/tts.service';
import { WebSearchService } from '../../shared/commands/web-search.service';
import { MapEditService } from '../map-edit.service';
import { ResizeEvent } from 'angular-resizable-element';
import { DomSanitizer } from '@angular/platform-browser';
import { UserPreferenceService } from '../../shared/user-preference.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { PdfEditorComponent } from 'src/app/map-edit/pdf-editor/pdf-editor.component';
import { AutodrawComponent } from '../autodraw/autodraw.component';



@Component({
    selector: 'app-extra-panel',
    templateUrl: './extra-panel.component.html',
    styleUrls: ['./extra-panel.component.scss']
})
export class ExtraPanelComponent implements OnInit, OnDestroy {

    @Input() extraOpenDto: ExtraOpenDto;

    public style: object = {};
    public styleover: object = {};

    public mapinteraction = false;
    private newTop = 0;
    private newWidth = 0;
    onExtraPanelContentChanged: Subscription | undefined;
    onResizeSubscription: Subscription | undefined; //resize finestra
    mapMoving: Subscription | undefined;
    resizing = false;

    private fitTimeout: any = undefined;

    constructor(
        public googleImagesService: GoogleImagesService,
        private extraService: ExtraService,
        private smService: SmService,
        private quickEditService: QuickEditService,
        private ttsService: TtsService,
        private webSearchService: WebSearchService,
        private mapStateService: MapStateService,
        private mapEditService: MapEditService,
        private domSanitizer: DomSanitizer,
        private userPreferencesService: UserPreferenceService,
        private authService: AuthenticationService
    ) {
        this.extraOpenDto = new ExtraOpenDto(ExtraItem.CLOSE, this.domSanitizer.bypassSecurityTrustResourceUrl(''));
    }

    ngOnInit() {
        this.quickEditService.onCanEdit.emit(false);
        // gestisci resize finestra
        /*this.onResizeSubscription = this.mapEditService.onResize.subscribe((width: number) => {
        });*/

        this.onExtraPanelContentChanged = this.extraService.onExtraPanelContentChanged.subscribe((extra: ExtraOpenDto) => {
            if (this.fitTimeout !== undefined) {
                clearTimeout(this.fitTimeout);
                this.fitTimeout = undefined;
            }
            this.fitTimeout = setTimeout(() => { //ho messo un timeout ma non mi piace. Sarebbe da togliere
                if (extra.extraItem !== ExtraItem.CLOSE) {
                    this.fitExtraPanelByMinWidth();
                    this.fitExtraPanelBySavedWidthInCookies();
                    this.extraService.updateExtraPanel();
                }
            }, 50);

            this.saveLastOpenedExtra(extra);
        })

        this.fitExtraPanelBySavedWidthInCookies();
        this.saveLastOpenedExtra(this.extraOpenDto);
    }

    saveLastOpenedExtra(extra: ExtraOpenDto) {
        const usermail = this.authService.getUserEmail();
        if (usermail != "") {
            const mapId = this.mapStateService.id;
            this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_last_opened_extra", extra.extraItem.toString());
        }
    }

    fitExtraPanelByMinWidth() {
        const minWidth: number = this.getMinPanelWidthByExtraItem();

        const extraContainerElement = <HTMLElement>document.getElementById("extra-container");
        if (extraContainerElement && (extraContainerElement).clientWidth < minWidth) {
            //console.log((extraContainerElement).clientWidth + " " + minWidth);
            this.style = this.getStyleWidth(minWidth);
        }
    }

    fitExtraPanelByDefaultWidth() {
        const defWidth: number = this.getDefaultPanelWidthByExtraItem();

        const extraContainerElement = <HTMLElement>document.getElementById("extra-container");
        if (extraContainerElement && (extraContainerElement).clientWidth < defWidth) {
            //console.log((extraContainerElement).clientWidth + " " + minWidth);
            this.style = this.getStyleWidth(defWidth);
        }
    }

    fitExtraPanelBySavedWidthInCookies() {
        const savedWidth: number = this.getSavedPanelWidthByExtraItem();

        if (savedWidth > 0) {
            this.style = this.getStyleWidth(savedWidth);
        } else {
            this.fitExtraPanelByDefaultWidth();
        }
    }

    setSavedPanelWidthByExtraItem(width: number) {
        const i = this.extraOpenDto.extraItem;
        const usermail = this.authService.getUserEmail();
        if (usermail === '') return;
        const mapId = this.mapStateService.id;

        if (i) {
            switch (i) {
                case ExtraItem.OPEN_NOTEPAD:
                    this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_extrapanel_notepad_width", width.toString());
                    break;
                case ExtraItem.OPEN_OUTLINE:
                    this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_extrapanel_outline_width", width.toString());
                    break;
                case ExtraItem.OPEN_PDF:
                    this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_extrapanel_pdf_width", width.toString());
                    break;
                case ExtraItem.OPEN_BROWSER:
                    this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_extrapanel_web_width", width.toString());
                    break;
                case ExtraItem.OPEN_AUTODRAW:
                    this.userPreferencesService.saveCookie(usermail + "_" + mapId + "_extrapanel_autodraw_width", width.toString());
                    break;
                default:
                    break;
            }
        }
    }

    getSavedPanelWidthByExtraItem(): number {
        let SAVED_DIMENSIONS_PX: number = 0;
        const i = this.extraOpenDto.extraItem;

        const usermail = this.authService.getUserEmail();
        if (usermail === "") return SAVED_DIMENSIONS_PX;

        const mapId = this.mapStateService.id;
        const filter: Array<string> = [usermail, mapId];
        if (i) {
            switch (i) {
                case ExtraItem.OPEN_NOTEPAD:
                    SAVED_DIMENSIONS_PX = Number(this.userPreferencesService.getCookie("extrapanel_notepad_width", filter));
                    break;
                case ExtraItem.OPEN_OUTLINE:
                    SAVED_DIMENSIONS_PX = Number(this.userPreferencesService.getCookie("extrapanel_outline_width", filter));
                    break;
                case ExtraItem.OPEN_PDF:
                    SAVED_DIMENSIONS_PX = Number(this.userPreferencesService.getCookie("extrapanel_pdf_width", filter));
                    break;
                case ExtraItem.OPEN_BROWSER:
                    SAVED_DIMENSIONS_PX = Number(this.userPreferencesService.getCookie("extrapanel_web_width", filter));
                    break;
                case ExtraItem.OPEN_AUTODRAW:
                    SAVED_DIMENSIONS_PX = Number(this.userPreferencesService.getCookie("extrapanel_autodraw_width", filter));
                    break;
                default:
                    break;
            }
        }

        if (isNaN(SAVED_DIMENSIONS_PX))
            SAVED_DIMENSIONS_PX = 0;

        return SAVED_DIMENSIONS_PX;
    }


    getMinPanelWidthByExtraItem(): number {
        let MIN_DIMENSIONS_PX: number = 300;
        const i = this.extraOpenDto.extraItem;
        if (i) {
            switch (i) {
                case ExtraItem.OPEN_NOTEPAD:
                    MIN_DIMENSIONS_PX = 390;
                    break;
                case ExtraItem.OPEN_OUTLINE:
                    MIN_DIMENSIONS_PX = 400;
                    break;
                case ExtraItem.OPEN_BROWSER:
                    MIN_DIMENSIONS_PX = 400;
                    break;
                case ExtraItem.OPEN_AUTODRAW:
                    MIN_DIMENSIONS_PX = 400;
                    break;
                case ExtraItem.OPEN_PDF:
                    MIN_DIMENSIONS_PX = 530;
                    break;
                default:
                    break;
            }
        }

        return MIN_DIMENSIONS_PX;
    }

    getDefaultPanelWidthByExtraItem(): number {
        let DEF_DIMENSIONS_PX: number = 400;
        const i = this.extraOpenDto.extraItem;
        if (i) {
            switch (i) {
                case ExtraItem.OPEN_NOTEPAD:
                    DEF_DIMENSIONS_PX = 390;
                    break;
                case ExtraItem.OPEN_OUTLINE:
                    DEF_DIMENSIONS_PX = 460;
                    break;
                case ExtraItem.OPEN_BROWSER:
                    DEF_DIMENSIONS_PX = 460;
                    break;
                case ExtraItem.OPEN_AUTODRAW:
                    DEF_DIMENSIONS_PX = 460;
                    break;
                case ExtraItem.OPEN_PDF:
                    DEF_DIMENSIONS_PX = 530;
                    break;
                default:
                    break;
            }
        }

        return DEF_DIMENSIONS_PX;
    }

    validate = (event: ResizeEvent): boolean => {
        const minDim = this.getMinPanelWidthByExtraItem();

        if (
            event.rectangle.width &&
            event.rectangle.height &&
            (event.rectangle.width < minDim ||
                event.rectangle.height < minDim)
        ) {
            return false;
        }
        return true;
    }

    public onResizeStart(event: ResizeEvent) {
        //console.log("RESIZE START");
        this.resizing = true;
        //  this.mapinteraction = true;
        // this.editAppunti(false);
        this.mapStateService.setFocusOnMap(false);
        this.mapStateService.canEnableQuickEdit = false;
        this.smService.setEnableKeyPresses(false);
        this.quickEditService.onCanEdit.emit(false);
        // this.mapStateService.setEditingState(true);

        if (this.validate(event)) {
            //console.log("VALIDATE");
            this.styleover = {
                position: 'fixed',
                left: `${event.rectangle.left}px`,
                top: `${event.rectangle.top}px`,
                width: `${event.rectangle.width}px`,
                height: `${event.rectangle.height}px`
            };
        }
    }

    public onResizing(event: ResizeEvent) {
        //console.log("RESIZING");

        if (this.validate(event)) {
            // this.overPanel.nativeElement.width = event.rectangle.width;
            this.style = this.getStyleWidth(event.rectangle.width);

            this.styleover = {
                position: 'fixed',
                left: `${event.rectangle.left}px`,
                top: `${event.rectangle.top}px`,
                width: `${event.rectangle.width}px`,
                height: `${event.rectangle.height}px`
            };
        }
    }

    public onResizeEnd(event: any) {
        //console.log("RESIZE END");

        this.resizing = false;

        this.newWidth = event.rectangle.width;
        this.mapStateService.setFocusOnMap(true);
        this.mapStateService.canEnableQuickEdit = true;
        this.smService.setEnableKeyPresses(true);
        this.quickEditService.onCanEdit.emit(true);
        // this.mapStateService.setEditingState(false);


        if (this.validate(event)) {
            //cambio stile extra panel vero
            this.style = this.getStyleWidth(event.rectangle.width);

            //cambio stile overpanel
            this.styleover = {
                position: 'fixed',
                left: `${event.rectangle.left}px`,
                top: `${event.rectangle.top}px`,
                width: `${event.rectangle.width}px`,
                height: `${event.rectangle.height}px`
            };

            this.setSavedPanelWidthByExtraItem(event.rectangle.width);
        }

        this.extraService.updateExtraPanel(event.rectangle.width);
    }

    getStyleWidth(w: number | undefined): object {
        if (w)
            return {
                width: w + "px"
            };
        else {
            return {}
        }
    }

    closeExtra() {
        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
        }
        this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
    }

    ngOnDestroy(): void {
        if (this.fitTimeout !== undefined) {
            clearTimeout(this.fitTimeout);
            this.fitTimeout = undefined;
        }

        this.smService.setEnableKeyPresses(true);
        if (this.mapMoving) { this.mapMoving.unsubscribe(); }
        this.ttsService.stop();
        if (this.onResizeSubscription) { this.onResizeSubscription.unsubscribe(); }
        if (this.onExtraPanelContentChanged) { this.onExtraPanelContentChanged.unsubscribe(); }
        this.quickEditService.onCanEdit.emit(true);
        // this.mapStateService.setEditingState(false);
        this.extraService.isExtraPanelOpen = false;
    }
}
