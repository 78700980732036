import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateGoogleDocService } from '../../../../shared/commands/create-google-doc.service';
import { MapStateService } from '../../../../shared/map-state.service';

@Component({
  selector: 'app-google-doc-toolbar',
  templateUrl: './google-doc-toolbar.component.html',
  styleUrls: ['./google-doc-toolbar.component.scss']
})
export class GoogleDocToolbarComponent implements OnInit, OnDestroy {

  private onGoogleDocIdChangeSubscription: Subscription | undefined;
  private onMapIsMineSubscription: Subscription | undefined;
  public showGoogleDoc = false;
  public hasGoogleDoc = false;
  public isMine = false;

  constructor(private createGoogleDocService: CreateGoogleDocService, private mapStateService: MapStateService) { }

  ngOnInit() {
    this.isMine = false;
    this.onMapIsMineSubscription = this.mapStateService.onMapIsMine.subscribe((_isMine: boolean) => {
      this.isMine = _isMine;
    });
    this.showGoogleDoc = true;
    this.hasGoogleDoc = this.mapStateService.googleDocFileId !== '';
    if (this.onGoogleDocIdChangeSubscription) { this.onGoogleDocIdChangeSubscription.unsubscribe(); }
    this.onGoogleDocIdChangeSubscription = this.createGoogleDocService.onGoogleDocFileIdChange.subscribe((id: string) => {
      this.hasGoogleDoc = (id !== '');
      this.showGoogleDoc = true;
    });
  }

  ngOnDestroy(): void {
    if (this.onGoogleDocIdChangeSubscription) { this.onGoogleDocIdChangeSubscription.unsubscribe(); }
    if (this.onMapIsMineSubscription) { this.onMapIsMineSubscription.unsubscribe(); }
  }

  createOrUpdateGoogleDoc() {
    this.createGoogleDocService.createGoogleDoc();
  }

  openGoogleDoc() {
    this.createGoogleDocService.openGoogleDoc(this.mapStateService.googleDocFileId);
  }

}
