<div *ngIf="!viewToolbarCompressed" class="view-toolbar">
    <button mat-icon-button color="primary" [ngClass]="{'tts-mode': ttsEnable}" matTooltip="{{'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate}}" (click)="TTSRead()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate" svgIcon="message-processing"></mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_ZOOM-FIT' | translate}}" (click)="centerMap()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate">settings_overscan</mat-icon>
    </button>

    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_ZOOM_IN' | translate}}" (click)="zoom(+1)">
        <mat-icon aria-label="'MAIN_TOOLBAR_ZOOM-IN' | translate" svgIcon="magnify-plus"></mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_ZOOM_OUT' | translate}}" (click)="zoom(-1)">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-OUT' | translate" svgIcon="magnify-minus">
        </mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_EXPORT-TO-IMAGE' | translate}}" (click)="convertToImage(true)">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_EXPORT-TO-IMAGE' | translate">filter</mat-icon>
    </button>
    <button *ngIf="!isUserExpired" mat-icon-button color="primary" matTooltip="{{'COPY_MAP' | translate}}" (click)="copyMap()">
        <mat-icon color="primary" svgIcon="content-duplicate"></mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_MENU_FILE-PRINT' | translate}}" (click)="printMap(true)">
        <mat-icon matTootltip="'MAIN_MENU_FILE-PRINT' | translate">print</mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_MENU_FILE-SHARE' | translate}}" float="right" (click)="shareMap()">
        <mat-icon [attr.aria-label]="'MAIN_MENU_FILE-SHARE' | translate">share</mat-icon>
    </button>
    <button mat-icon-button color="primary" [ngClass]="{'active-find': mapSearchVisible}" matTooltip="{{'MAIN_TOOLBAR_FIND' | translate}}" (click)="toggleFindInMap()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_FIND' | translate" svgIcon="file-find"></mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'SHOWING' | translate}}" (click)="mapShowing()">
        <mat-icon color="primary" svgIcon="theater"></mat-icon>
    </button>
    <button *ngIf="canEdit" mat-button color="primary" matTooltip="{{'EDIT_MODE' | translate}}" (click)="navigateToEdit()">
        <span translate>EDIT_MODE</span>
    </button>
</div>
<div *ngIf="viewToolbarCompressed" class="view-toolbar">
    <app-view-menu [isLoading]="isLoading" (startLoading)="startLoading.emit()" (endLoading)="endLoading.emit()" [mapId]="mapId"></app-view-menu>
</div>
