import { inject, Injectable } from '@angular/core';
import { from, Observable, Subscriber } from 'rxjs';

import { ResourceUpload } from './resource-upload';
import { Logger } from '../logger.service';

import { FirebaseStorageUrlResolver } from './firebase-storage-url-resolver';

import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

import { ref, getStorage, uploadString, uploadBytes, uploadBytesResumable, getDownloadURL, UploadResult, deleteObject } from '@angular/fire/storage';
import { Firestore, getDoc, setDoc, collection, doc,query } from '@angular/fire/firestore';

const logger: Logger = new Logger('FirebaseService');



@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    public COLLECTION = {
        MAPS: 'maps',
        CHAT: 'chat',
        PDF: 'pdf',
        MESSAGES: 'messages'
    };
    public SUBCOLLECTION = {
        // Maps
        EVENTS: 'events',
        // Chat
        MESSAGES: 'messages',
        TIMESTAMPS: 'timestamps',
        RECENTS: 'recents',
        SHARED_STATE_CHANGE: 'shared_state_change',
        NOTIFICATION_CHANGE: 'notification_change'
    };

    firestore: Firestore = inject(Firestore);

    constructor(
        private http: HttpClient,
        /*private firestore: Firestore*/) { }
    // initializeApp() {
    //   // Initialize Firebase
    //   // firebase.initializeApp(environment.configFirebase);
    // }


    // deleteAtPath(path: any) {

    //     const functions = getFunctions();
    //     const deleteFn = httpsCallable(functions, '/api/recursiveDelete');
    //     deleteFn({ path: path })
    //         .then((result: any) => {
    //             console.log('Delete success: ' + JSON.stringify(result));
    //         })
    //         .catch((err: any) => {
    //             console.log('Delete failed, see console,');
    //             //  console.warn(err);
    //         });
    // }




    // deleteFromStorage(storageRef: string) {
    //     return new Observable((subscriber: Subscriber<any>) => {
    //         console.log(`Resource full path: ${storageRef}`);
    //         const storage = getStorage();
    //         const r = ref(storage, storageRef);
    //         deleteObject(r).then(() => {
    //         }).catch((error) => {
    //             logger.error('delete FAIL: ${error}');
    //             subscriber.next(error);
    //             subscriber.complete();
    //         });
    //     });
    // }


    getBucketName(): string {
        // return environment.configFirebase.storageBucket;
        const storageRef = ref(getStorage()).bucket;
        return storageRef;

    }

    resolveStorageUrl(storageRelativeUrl: string) {
        return new FirebaseStorageUrlResolver(this).resolveUrl(storageRelativeUrl);
    }

    downloadResource(resourceFullPath: string): Observable<any> {
        return new Observable((subscriber: Subscriber<Response>) => {
            new FirebaseStorageUrlResolver(this).resolveUrl(resourceFullPath).then((url: any) => {
                this.http.get(url).subscribe((json: any) => {
                    subscriber.next(json);
                    subscriber.complete();
                });
            }).catch((error) => {
                logger.error(`Download FAIL: ${error}`);
                subscriber.next(error);
                subscriber.complete();
            });
        });
    }

    downloadResourceAsBlob(resourceFullPath: string, typeBlob: string): Observable<any> {
        return new Observable((subscriber: Subscriber<any>) => {
            console.log(`Resource full path: ${resourceFullPath}`);
            new FirebaseStorageUrlResolver(this).resolveUrl(resourceFullPath).then((url: any) => {
                this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
                    const blob = new Blob([response], { type: typeBlob });
                    subscriber.next(blob);
                    subscriber.complete();
                });
            }).catch((error) => {
                logger.error('Download FAIL: ${error}');
                subscriber.next(error);
                subscriber.complete();
            });
        });
    }

    downloadResourceFromStorageAsBlob(url: string, typeBlob: string): Observable<any> {
        return new Observable((subscriber: Subscriber<any>) => {

            this.http.get(url, { responseType: 'blob' }).subscribe({
                next: (response: any) => {
                    const blob = new Blob([response], { type: typeBlob });
                    subscriber.next(blob);
                    subscriber.complete();
                },
                error: (error) => {
                    logger.error('Download FAIL: ${error}');
                    subscriber.next(error);
                    subscriber.complete();

                }
            });
        });
    }


    fsUploadString(upload: any, resourceFullPath: string): Observable<any> {
        // Get a reference to the storage service, which is used to create references in your storage bucket
        // Create a storage reference from our storage service
        const storageRef = ref(getStorage(), resourceFullPath);
        //const ref =  this.storage.ref(resourceFullPath);
        return new Observable((observer: any) => {
            uploadString(storageRef, upload)
                .then((snapshot: any) => {
                    // upload success
                    logger.info('Upload SUCCESS ' + resourceFullPath);
                    observer.next(snapshot.ref);
                    observer.complete('ok');
                }).catch((error: any) => {
                    logger.error(`Upload FAIL: ${error}`);
                    observer.next(error);
                    observer.complete();
                });
        });
    }


    uploadBase64Promise(upload: any, resourceFullPath: string, contentType: string): Promise<any> {
        const storageRef = ref(getStorage(), resourceFullPath);
        // const ref = this.storage.ref(resourceFullPath);
        const i = upload.indexOf(',');
        if (i >= 0) {
            upload = upload.slice(i + 1);
        }



        return uploadString(storageRef, upload, 'base64', { contentType: contentType });

    }

    uploadBase64(upload: any, resourceFullPath: string, contentType: string): Observable<any> {
        const storageRef = ref(getStorage(), resourceFullPath);
        // const ref = this.storage.ref(resourceFullPath);
        const i = upload.indexOf(',');
        if (i >= 0) {
            upload = upload.slice(i + 1);
        }
        return new Observable((observer: any) => {
            uploadString(storageRef, upload, 'base64', { contentType: contentType })
                .then((snapshot: any) => {
                    // upload success
                    logger.info('Upload SUCCESS ' + resourceFullPath);
                    observer.next(snapshot.ref);
                    observer.complete('ok');
                }).catch((error: any) => {
                    logger.error(`Upload FAIL: ${error}`);
                    observer.next(error);
                    observer.complete();
                });
        });
    }

    uploadBlob(blob: Blob, resourceFullPath: string): Observable<any> {
        return new Observable((observer: any) => {

            uploadBytes(ref(getStorage(), resourceFullPath), blob).then((snapshot: any) => {
                console.log('Uploaded a blob or file! ' + resourceFullPath);
                observer.next(snapshot.ref);
                observer.complete();
            }, (error: any) => {
                logger.error(`Upload FAIL: ${error}`);
                observer.next(error);
                observer.complete();
            });
        });
    }

    // uploadTask() {
    //   const storage = getStorage();
    //   const storageRef = ref(storage, 'images/rivers.jpg');

    //   const uploadTask = uploadBytesResumable(storageRef, file);

    //   Register three observers:
    //   1. 'state_changed' observer, called any time the state changes
    //   2. Error observer, called on failure
    //   3. Completion observer, called on successful completion
    //   uploadTask.on('state_changed',
    //     (snapshot) => {
    //       Observe state change events such as progress, pause, and resume
    //       Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       console.log('Upload is ' + progress + '% done');
    //       switch (snapshot.state) {
    //         case 'paused':
    //           console.log('Upload is paused');
    //           break;
    //         case 'running':
    //           console.log('Upload is running');
    //           break;
    //       }
    //     },
    //     (error) => {
    //       Handle unsuccessful uploads
    //     },
    //     () => {
    //       Handle successful uploads on complete
    //       For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //         console.log('File available at', downloadURL);
    //       });
    //     }
    //   );

    // }

    uploadFile(upload: ResourceUpload, resourceFullPath: string): Observable<any> {
        return new Observable((observer: any) => {
            // const filePath = `${resourceFullPath}/${upload.file.name}`;
            const storageRef = ref(getStorage(), resourceFullPath);
            const uploadTask = uploadBytesResumable(storageRef, upload.file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                    observer.next(progress);
                },
                (error) => {
                    // Handle unsuccessful uploads
                    observer.error(error);
                    observer.complete();
                },
                () => {
                    //  Handle successful uploads on complete
                    //  For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //   console.log('File available at', downloadURL);
                    observer.next(uploadTask.snapshot.ref);
                    observer.complete(uploadTask.snapshot.ref);
                    // });
                }
            );
        });
        //return uploadTask.snapshot.bytesTransferred;
    }


    // const uploadTask = this.storage.ref(resourceFullPath).put(upload.file);

    // return new Observable((observer: any) => {

    //   uploadTask.on(this.storage.TaskEvent.STATE_CHANGED, (snapshot: any) => {
    //     // upload in progress
    //     upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
    //   }, (error: any) => {
    //     // upload failed
    //     logger.error('Upload FAIL: ${error}');
    //     observer.next(error);
    //     observer.complete();
    //   }, () => {
    //     // upload success
    //     logger.info('Upload SUCCESS ' + resourceFullPath);
    //     // upload.url = uploadTask.snapshot.downloadURL;
    //     // upload.name = upload.file.name;
    //     observer.next(uploadTask.snapshot.ref);
    //     observer.complete('ok');
    //   });
    // });
    // }firestore


    getDownloadUriObs(path: string): Observable<string> {
        const result = from(this.getDownloadUri(path));
        return result;
    }

    getDownloadUri(path: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const storageRef = ref(getStorage(), path);
            getDownloadURL(storageRef)

                .then((url) => {
                    resolve(url);
                }).catch((error) => {
                    logger.error('FireBase Storage NOT FOUND: ' + error.message);
                    resolve('');
                });
        });
    }

    deleteResource(path: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const storageRef = ref(getStorage(), path);
            deleteObject(storageRef)
                .then(() => {
                    resolve(true);
                }).catch((error) => {
                    logger.error('FireBase Storage NOT FOUND: ' + error.message);
                    resolve(false);
                });
        });
    }


    getDownloadUriAndReparImage(path: string): Promise<string[]> {
        return new Promise((resolve) => {
            this.getDownloadUri(path)
                .then((url: string) => {

                    if (url != '') {
                        return Promise.resolve([url, '']);
                    } else {
                        return new FirebaseStorageUrlResolver(this).repairNoImage(path).then((url: string) => {
                            return Promise.resolve([url, '']);



                        }).catch(() => {
                            return Promise.resolve(['', ''])


                        });
                    }
                }).then((url: string[]) => {
                    return resolve(url);
                });
        })
    }

    uploadStringPromise(text: any, resourceFullPath: string, type: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const storageRef = ref(getStorage(), resourceFullPath);
            //const ref = this.storage.ref(resourceFullPath);
            uploadString(storageRef, text, 'raw', { contentType: type })
                .then((snapshot: any) => {
                    // upload success
                    logger.info('Promise Upload SUCCESS');
                    // resolve('ok');
                    resolve(storageRef);
                }).catch((error: any) => {
                    logger.error(`Promise Upload FAIL: ${error}`);
                    reject(error);
                });
        });
    }

    getFirestoreMapsListener(mapId: string) {
        // const listener =  this.firestore.collection(this.COLLECTION.MAPS).doc(mapId).collection(this.SUBCOLLECTION.EVENTS);
        const collMaps = collection(this.firestore, this.COLLECTION.MAPS);
        const docMaps = doc(collMaps, mapId);
        const listener = query(collection(docMaps, this.SUBCOLLECTION.EVENTS));
        return listener;
    }

    // this.dbListenerChatUnsubscribe = onSnapshot(doc(firestore, 'scientists', 'Charles Babbage'), (snapshot) => {
    //   console.log("> Updated received", doc.data())
    //   // TODO: Update data state
    // });

    getFirestoreChatListener(mapId: string) {
        const collChat = collection(this.firestore, this.COLLECTION.CHAT);
        const docChat = doc(collChat, mapId);
        const listener = query( collection(docChat, this.SUBCOLLECTION.MESSAGES));
        //const listener = this.firestore.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.MESSAGES);
        return listener;
    }

    // getFirestorePdfRecentsListener(userId: string) {
    //     const collPdf = collection(this.firestore, this.COLLECTION.PDF);
    //     const userPdf = doc(collPdf, userId);
    //     const listener = collection(userPdf, this.SUBCOLLECTION.RECENTS)
    //     //const listener = this.firestore.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.MESSAGES);
    //     return listener;
    // }

    getFirestoreRecentPdfListener(userId: string) {
        const collPdf = collection(this.firestore, this.COLLECTION.PDF);
        const userPdf = doc(collPdf, userId);
        const listener = query(collection(userPdf, this.SUBCOLLECTION.RECENTS));
        return listener;
    }


    // getFirestorePdfListener(userId: string, pdfId: string) {
    //     const collPdf = collection(this.firestore, this.COLLECTION.PDF);
    //     const userPdf = doc(collPdf, userId);
    //     const listener = collection(userPdf, this.SUBCOLLECTION.RECENTS);

    //     const pdfDoc = doc(listener, pdfId);
    //     const listenerPdf = collection(pdfDoc, this.SUBCOLLECTION.STATE);

    //     //const listener = this.firestore.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.MESSAGES);
    //     return listenerPdf;
    // }

    getFirestoreSharedStateChangeListener(email: string) {
        const collMessages = collection(this.firestore, this.COLLECTION.MESSAGES);
        const docEmail = doc(collMessages, email);
        const listener = query(collection(docEmail, this.SUBCOLLECTION.SHARED_STATE_CHANGE));
        return listener;
    }

    addSharedStateChangeListener(guid: string, mapId: string, email: string) {
        const data = {
            guid: guid,
            mapId: mapId,
            email: email,
            timestamp: new Date().toISOString(),
        };
        const collEv = collection(this.firestore, this.COLLECTION.MESSAGES);
        const docemail = doc(collEv, email);
        const subcoll = collection(docemail, this.SUBCOLLECTION.SHARED_STATE_CHANGE);
        return setDoc(doc(subcoll), data);
    }
    public getSharedStateChangeEvent(email: string, eventId: string): any {
        return new Promise((resolve, reject) => {
            const refEv = doc(collection(doc(collection(this.firestore, this.COLLECTION.MESSAGES), email), this.SUBCOLLECTION.SHARED_STATE_CHANGE), eventId);
            getDoc(refEv).then(doc => {
                if (!doc.exists) {
                    console.log('No such event!');
                    return resolve(null);
                } else {
                    console.log('Event data:', doc.data());
                    const json = doc.data();
                    return resolve(json);
                }
            })
        });
    }


    getFirestoreNotificationChangeListener(email: string) {
        const collMessages = collection(this.firestore, this.COLLECTION.MESSAGES);
        const docEmail = doc(collMessages, email);
        const listener = query(collection(docEmail, this.SUBCOLLECTION.NOTIFICATION_CHANGE));
        return listener;
    }

    getUserMessage(email: string, eventId: string, eventsArray: Array<any>): any {
        return this.getMessages(email, eventId, eventsArray);
    }

    addMapEvent(guid: string, type: string, userEmail: string, userName: string, userIcon: string, mapId: string, payload: string): Promise<any> {
        return this.addFirestoreEvent(guid, this.COLLECTION.MAPS, this.SUBCOLLECTION.EVENTS, type, userEmail, userName, userIcon, mapId, payload);
    }
    getMapsEvent(mapId: string, eventId: string, eventsArray: Array<any>): any {
        return this.getEvent(this.COLLECTION.MAPS, this.SUBCOLLECTION.EVENTS, mapId, eventId, eventsArray);
    }

    addChatMessage(guid: string, type: string, userEmail: string, userName: string, userIcon: string, mapId: string, payload: string): Promise<any> {
        return this.addFirestoreEvent(guid, this.COLLECTION.CHAT, this.SUBCOLLECTION.MESSAGES, type, userEmail, userName, userIcon, mapId, payload);
    }
    getChatMessage(mapId: string, eventId: string, eventsArray: Array<any>): any {
        return this.getEvent(this.COLLECTION.CHAT, this.SUBCOLLECTION.MESSAGES, mapId, eventId, eventsArray);
    }

    addPdfRecent(guid: string, userId: string, pdfId: string, event: string): Promise<any> {
        return this.addFirestorePdf(guid, this.COLLECTION.PDF, this.SUBCOLLECTION.RECENTS, userId, pdfId, event);
    }

    getPdfRecent(userId: string, eventId: string, eventsArray: Array<any>): any {
        return this.getEvent(this.COLLECTION.PDF, this.SUBCOLLECTION.RECENTS, userId, eventId, eventsArray);
    }
    // addPdfRecentState(userId: string, pdfId: string): Promise<any> {
    //     return this.addFirestorePdf(this.COLLECTION.PDF, this.SUBCOLLECTION.STATE, userId, pdfId);
    // }
    // getPdfRecentState(userId: string, pdfId: string, eventsArray: Array<any>): any {
    //     return this.getEvent(this.COLLECTION.PDF, this.SUBCOLLECTION.STATE, userId, pdfId, eventsArray);
    // }

    writeTimestamp(mapId: string, userId: string, timestamp: string) {
        //const timestampRef = db.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.TIMESTAMPS).doc(userId);
        const db = this.firestore;
        const collChat = collection(db, this.COLLECTION.CHAT);
        const docref = doc(collChat, mapId);
        const subcoll = collection(docref, this.SUBCOLLECTION.TIMESTAMPS);
        const timestampRef = doc(subcoll, userId);
        const data = { timestamp: timestamp };
        setDoc(timestampRef, data).then(() => {
        }).catch((error: any) => {
            console.log(error);
        });
    }
    readTimestamp(mapId: string, userId: string) {
        return new Promise((resolve, reject) => {
            //const timestampRef = db.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.TIMESTAMPS).doc(userId);
            const db = this.firestore;
            const collChat = collection(db, this.COLLECTION.CHAT);
            const docref = doc(collChat, mapId);
            const subcoll = collection(docref, this.SUBCOLLECTION.TIMESTAMPS);
            const timestampRef = doc(subcoll, userId);
            getDoc(timestampRef).then((docSnapshots: any) => {
                if (docSnapshots.exists() && docSnapshots && docSnapshots._document && docSnapshots._document.data &&
                    docSnapshots._document.data.value && docSnapshots._document.data.value.mapValue &&
                    docSnapshots._document.data.value.mapValue.fields && docSnapshots._document.data.value.mapValue.fields.timestamp &&
                    docSnapshots._document.data.value.mapValue.fields.timestamp.stringValue) {
                    return resolve(docSnapshots._document.data.value.mapValue.fields.timestamp.stringValue);
                } else {
                    return resolve('');
                }
            });
        });
    }

    getEventPayloadAsJson(event: any): string {
        let json: any = null;
        try {
            if (event.timestamp && event.payload) {
                const s: string = event.payload;
                json = JSON.parse(s);
            } else {
                console.log(`Error: payload is empty`);
            }
        } catch {
            console.log(`Error parsing payload:'${event.payload}'`);
        }
        return json;
    }

    // async deleteChat(mapId: string) {
    //     const db = this.firestore;
    //     const collChat = collection(db, this.COLLECTION.CHAT);
    //     const docref = doc(collChat, mapId);
    //     const subcoll = collection(docref, this.SUBCOLLECTION.TIMESTAMPS);
    //     this.deleteCollection(db, subcoll, 10);



    //     const submesscoll = collection(docref, this.SUBCOLLECTION.MESSAGES);
    //     this.deleteCollection(db, submesscoll, 10);



    //     // const collectionRefTimestamps = db.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.TIMESTAMPS);
    //     // this.deleteCollection(db, collectionRefTimestamps, 10);
    //     // const collectionRefMessages = db.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.MESSAGES);
    //     // this.deleteCollection(db, collectionRefMessages, 10);
    // }

    // deleteMapEvents(deletionType: string, mapId: string, toDateTime: Date | null) {
    //     // deletionType actions:
    //     // after_save: delete all command up to toDateTime + delete all save_start/save_end markers (unlock global saves) (the last save_end will be written after save)
    //     // reset_save: delete all save_start/save_end markers (unlock global saves) (the last save_end will be written after save)
    //     // -->everytime: check user_in / user_out...
    //     return new Promise((resolve, reject) => {
    //         const toDateTimeString = (toDateTime ? toDateTime.toISOString() : '');
    //         const refEvents = collection(doc(collection(this.firestore, this.COLLECTION.MAPS), mapId), this.SUBCOLLECTION.EVENTS);
    //         return getDocs(refEvents).then(
    //             (events: QuerySnapshot<DocumentData>) => {
    //                 if (!events.empty) {
    //                     let atLeastOne = false;
    //                     const batch = writeBatch(this.firestore)
    //                     //const batch = this.firestore.firestore.batch();
    //                     const userEvents = new Array<any>();

    //                     events.docs.forEach((ev: any) => {
    //                         let ok = false;
    //                         const eventData = ev.data();
    //                         // Delete all commands up to toDateTime
    //                         if (deletionType === 'after_save' && ev.type === 'command' && ev.timestamp <= toDateTimeString) {
    //                             ok = true;
    //                         }
    //                         // Delete all save markers (a save_end will be written after delete)
    //                         if ((deletionType === 'after_save' || deletionType === 'reset_save') && (eventData.type === 'save_start' ||
    //                             eventData.type === 'save_end')) {
    //                             ok = true;
    //                         }
    //                         // Manage user_in/user_out (always)
    //                         if (eventData.type === 'user_in' || eventData.type === 'user_out') {
    //                             const userEvent = {
    //                                 ref: ev.ref,
    //                                 data: eventData
    //                             };
    //                             userEvents.push(userEvent);
    //                         }
    //                         // Add events to delete
    //                         if (ok) {
    //                             batch.delete(ev.ref);

    //                             atLeastOne = true;
    //                         }
    //                     });
    //                     // Add user events to delete
    //                     // Sort by timestamp descending
    //                     userEvents.sort((a, b) => (a.data.timestamp > b.data.timestamp ? -1 : 1));
    //                     const userInEmails = new Array<string>();
    //                     const userOutEmails = new Array<string>();
    //                     for (let i = 0; i < userEvents.length; i++) {
    //                         const userEvent = userEvents[i];
    //                         if (userEvent.data.type === 'user_out') {
    //                             // Email must be deleted in next (previous) events
    //                             userOutEmails.push(userEvent.data.user.email);
    //                             batch.delete(userEvent.ref);
    //                             atLeastOne = true;
    //                         } else if (userEvent.data.type === 'user_in') {
    //                             if (userOutEmails.indexOf(userEvent.data.user.email) >= 0 || userInEmails.indexOf(userEvent.data.user.email) >= 0) {
    //                                 // user in already found, delete
    //                                 batch.delete(userEvent.ref);
    //                                 atLeastOne = true;
    //                             }
    //                             userInEmails.push(userEvent.data.user.email);
    //                         }
    //                     }
    //                     // Delete selected events
    //                     if (atLeastOne) {
    //                         batch.commit().then(() => {
    //                             return resolve(true);
    //                         });
    //                     } else {
    //                         return resolve(true);
    //                     }
    //                 } else {
    //                     console.log('No such event!');
    //                     return resolve(false);
    //                 }
    //             })


    //     });
    // }
    //     refEvents.get().pipe(
    //       map((events: any) => {
    //         if (!events.empty) {
    //           let atLeastOne = false;
    //           const batch = this.firestore.firestore.batch();
    //           const userEvents = new Array<any>();
    //           events.forEach((event: any) => {
    //             let ok = false;
    //             const eventData = event.data();
    //             // Delete all commands up to toDateTime
    //             if (deletionType === 'after_save' && eventData.type === 'command' && eventData.timestamp <= toDateTimeString) {
    //               ok = true;
    //             }
    //             // Delete all save markers (a save_end will be written after delete)
    //             if ((deletionType === 'after_save' || deletionType === 'reset_save') && (eventData.type === 'save_start' ||
    //               eventData.type === 'save_end')) {
    //               ok = true;
    //             }
    //             // Manage user_in/user_out (always)
    //             if (eventData.type === 'user_in' || eventData.type === 'user_out') {
    //               const userEvent = {
    //                 ref: event.ref,
    //                 data: eventData
    //               };
    //               userEvents.push(userEvent);
    //             }
    //             // Add events to delete
    //             if (ok) {
    //               batch.delete(event.ref);
    //               atLeastOne = true;
    //             }
    //           });
    //           // Add user events to delete
    //           // Sort by timestamp descending
    //           userEvents.sort((a, b) => (a.data.timestamp > b.data.timestamp ? -1 : 1));
    //           const userInEmails = new Array<string>();
    //           const userOutEmails = new Array<string>();
    //           for (let i = 0; i < userEvents.length; i++) {
    //             const userEvent = userEvents[i];
    //             if (userEvent.data.type === 'user_out') {
    //               // Email must be deleted in next (previous) events
    //               userOutEmails.push(userEvent.data.user.email);
    //               batch.delete(userEvent.ref);
    //               atLeastOne = true;
    //             } else if (userEvent.data.type === 'user_in') {
    //               if (userOutEmails.indexOf(userEvent.data.user.email) >= 0 || userInEmails.indexOf(userEvent.data.user.email) >= 0) {
    //                 // user in already found, delete
    //                 batch.delete(userEvent.ref);
    //                 atLeastOne = true;
    //               }
    //               userInEmails.push(userEvent.data.user.email);
    //             }
    //           }
    //           // Delete selected events
    //           if (atLeastOne) {
    //             batch.commit().then(() => {
    //               return resolve(true);
    //             });
    //           } else {
    //             return resolve(true);
    //           }
    //         } else {
    //           console.log('No such event!');
    //           return resolve(false);
    //         }
    //       })
    //     )
    //   });
    // }

    downloadChromeLink(so: string) {
        let fn = 'Google Chrome Touch';
        switch (so) {
            case 'windows':
                fn += '.lnk';
                break;
            case 'linux':
                fn += '.desktop';
                break;
            case 'mac':
                fn += '.sh';
                break;
        }
        this.downloadResourceAsBlob('/assets/links/' + fn, '').subscribe((blob) => {
            saveAs(blob, fn);
        });
    }

    getRef(url: string) {
        // return this.storage.ref(url);    
        return ref(getStorage(), url);
    }


    private addFirestorePdf(guid: string, coll: string, subCollection: string, userId: string, pdfId: string, event: string) {
        const data = {
            guid: guid,
            pdfId: pdfId,
            event: event,
            timestamp: new Date().toISOString()
        };

        const collEv = collection(this.firestore, coll);
        const docPdf = doc(collEv, userId);
        const subcoll = collection(docPdf, subCollection);
        return setDoc(doc(subcoll), data);
    }

    // private getPdf(coll: string, subCollection: string, userId: string, eventId: string, eventsArray: Array<any>): any {
    //     const refEv = doc(collection(doc(collection(this.firestore, coll), userId), subCollection), eventId);
    //     getDoc(refEv).then(doc => {
    //         if (!doc.exists) {
    //             console.log('No such event!');
    //             return null;
    //         } else {
    //             console.log('Event data:', doc.data());
    //             const json = doc.data();
    //             if (eventsArray) {
    //                 eventsArray.push(json);
    //             }
    //             return json;
    //         }
    //     })
    // }

    private addFirestoreEvent(guid: string, coll: string, subCollection: string, type: string, userEmail: string, userName: string, userIcon: string, mapId: string, payload: string): Promise<any> {
        // console.log(`Add map event guid${guid}, userName:${userName}, userIcon:${userIcon}, mapId:${mapId}, payload:${payload}`);
        const data = {
            type: type,
            guid: guid,
            user: {
                email: userEmail,
                name: userName,
                icon: userIcon
            },
            timestamp: new Date().toISOString(),
            payload: payload,
        };

        const collEv = collection(this.firestore, coll);
        const docMap = doc(collEv, mapId);
        const subcoll = collection(docMap, subCollection);
        return setDoc(doc(subcoll), data);
        // return setDoc(doc(collection(doc(collection(this.firestore, coll), mapId), subCollection)), data);
        // return this.firestore.collection(collection).doc(mapId).collection(subCollection).add(data);
    }

    private getEvent(coll: string, subCollection: string, mapId: string, eventId: string, eventsArray: Array<any>): any {
        return new Promise((resolve, reject) => {
            const refEv = doc(collection(doc(collection(this.firestore, coll), mapId), subCollection), eventId);
            getDoc(refEv).then(doc => {
                if (!doc.exists) {
                    console.log('No such event!');
                    return resolve(null);
                } else {
                    console.log('Event data:', doc.data());
                    const json = doc.data();
                    if (eventsArray) {
                        eventsArray.push(json);
                    }
                    return resolve(json);
                }
            })
        });
    }

    private getMessages(email: string, eventId: string, eventsArray: Array<any>): any {
        return new Promise((resolve, reject) => {
            const refEv = doc(collection(doc(collection(this.firestore, this.COLLECTION.MESSAGES), email), this.SUBCOLLECTION.EVENTS), eventId);
            getDoc(refEv).then(doc => {
                if (!doc.exists) {
                    console.log('No such event!');
                    return resolve(null);
                } else {
                    console.log('Event data:', doc.data());
                    const json = doc.data();
                    if (eventsArray) {
                        eventsArray.push(json);
                    }
                    return resolve(json);
                }
            })
        });
    }


}




