import { Component, OnInit } from '@angular/core';
import { ResourceUpload } from '../../../../core/firebase/resource-upload';
import { SmService } from 'supermappe-core';
import { ModalService } from '../../../../shared/links-menu/modal-service';
import { Subscription } from 'rxjs';
import { CustomizeToolbarService } from '../customize-toolbar.service';
import { CustomToolbarDto } from '../custom-toolbar-dto';

@Component({
  selector: 'app-image-toolbar',
  templateUrl: './image-toolbar.component.html',
  styleUrls: ['./image-toolbar.component.scss']
})
export class ImageToolbarComponent implements OnInit {

  currentImageUpload: ResourceUpload | undefined;
  onChangeToolbarVisibilitySubscription: Subscription | undefined;
  imageSelected = false;
  multiselection = false;
  public isFrameAvailable = false;
  public isFramed = false;

  error = '';
  constructor(private smService: SmService, private modalService: ModalService, private customizeToobarService: CustomizeToolbarService) { }

  ngOnInit() {
    this.onChangeToolbarVisibilitySubscription =
      this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
        this.changeSelection(_customData);
      });
    this.changeSelection(this.customizeToobarService.customToolbarDto);
  }

  changeSelection(_selectionData: any): any {
    //
    this.imageSelected = _selectionData.imageSelected;
    if (_selectionData.isFramed !== undefined) {
      this.isFramed = _selectionData.isFramed;
    }
    this.isFrameAvailable = (_selectionData.nNodesImage + _selectionData.nNodesImageFrame) > 0;

    this.multiselection = _selectionData != null && _selectionData.multiSelection;
    let totButtonVisible = 0;
    if (!this.multiselection) {
      totButtonVisible++;
    }
    if (_selectionData.imageSelected) {
      totButtonVisible += 3;
    }


    this.customizeToobarService.imageButtonVisible = totButtonVisible;
    this.customizeToobarService.updateTotButton();

  }

  toggleFrameImage() {
    //
    this.isFramed = !this.isFramed;
    this.smService.setSelectedNodesImageFrame(this.isFramed);
  }


  removeImage() {
    //
    this.smService.setSelectionNoImage();
  }

  cropImage() {
    this.modalService.showSelectedNodeImageDialogCropper();
  }

}
