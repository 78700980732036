import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { AccessConfigService } from '../access-config.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessDto } from '../common/access-dto';
import { AdminConfigComponent } from '../common/admin-config/admin-config.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OrderDto } from '../common/order-dto';
import { Logger } from '../../core/logger.service';
import { UserDto } from '../common/user-dto';
import { UsersListComponent } from '../common/users-list/users-list.component';
import { SubsConfigComponent } from '../common/subs-config/subs-config.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ConfirmationService } from '../../shared/dialog/confirmation.service';
import { extract } from '../../core/i18n.service';
import { Router } from '@angular/router';
import { OrderListComponent } from '../common/order-list/order-list.component';
import { DateConfigComponent } from '../common/date-config/date-config.component';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';


const logger: Logger = new Logger('ana-desktop');

@Component({
    selector: 'app-ana-desktop',
    templateUrl: './ana-desktop.component.html',
    styleUrls: ['./ana-desktop.component.scss']
})

export class AnaDesktopComponent implements OnInit, OnDestroy {
    public isLoading = false;
    public user: string | undefined;
    public role: string | undefined;
    private order: OrderDto | undefined;
    public slotCount = 10;
    public domainDisabled = false;
    public singleDisabled = false;
    public multyDisabled = false;
    public visible = false;
    orderId: string | undefined;
    randomId: string | undefined;
    private sendMail = false;
    public selectedIndex: number | undefined;
    public accessName: string | undefined;
    public panelOpenState: boolean | undefined;
    public users: UserDto[] | undefined;
    accessList: AccessDto | undefined;
    accessConfigForm: FormGroup = this.formBuilder.group({});
    singleConfigForm: FormGroup = this.formBuilder.group({});
    multyConfigForm: FormGroup = this.formBuilder.group({});

    startDate: string | undefined;
    expireDate: string | undefined;

    public logoUrlCompressed = '';
    logoUrlExtended = '';
    compressLogo: boolean = false;
    compressed: boolean = false;
    @ViewChild('multyconfig') private multyconfig: AdminConfigComponent | undefined;
    @ViewChild('usersconfig') private usersconfig: UsersListComponent | undefined;
    @ViewChild('domainconfig') private domainconfig: AdminConfigComponent | undefined;
    @ViewChild('singleconfig') private singleconfig: AdminConfigComponent | undefined;
    @ViewChild('domainName') private domainName: SubsConfigComponent | undefined;
    @ViewChild('multyName') private multyName: SubsConfigComponent | undefined;
    @ViewChild('singleName') private singleName: SubsConfigComponent | undefined;
    @ViewChild(OrderListComponent) orderlist: OrderListComponent | undefined;
    @ViewChild('multyDate') private multyDate: DateConfigComponent | undefined;
    @ViewChild('domainDate') private domainDate: DateConfigComponent | undefined;
    @ViewChild('singleDate') private singleDate: DateConfigComponent | undefined;

    createOrderSubscription: Subscription | undefined;
    saveAccesslistSubscription: any;




    @HostListener('window:beforeunload', ['$event'])
    onbeforeunload(event: any) {
        if (this.ctrlIfDirty(1) || this.ctrlIfDirty(2) || this.ctrlIfDirty(3)) {
            const confirmationMessage = '\o/';
            event.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
        } else
            return '';
    }

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.user !== this.firebaseAuthService.auth.currentUser?.email && this.firebaseAuthService.auth.currentUser !== null) {
            {
                this.authenticationService.reloadCredentials();
                this.user = this.authenticationService.getUserEmail();
                this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';
                this.accessConfig();
            }
        }

    }

    accessConfig() {

        let redirectUri;
        if (this.role === '') {
            redirectUri = '/user-desktop';
        } else
            if (this.role === 'spain') {
                redirectUri = /*window.location.origin + */ '/spain-desktop';
            } else if (this.role === 'aid') {
                redirectUri = /*window.location.origin + */ '/aid-desktop';
            } else if (this.role === 'anastasis') {
                redirectUri = /*window.location.origin + */ '/ana-desktop';
            } else if (this.role === 'admin') {
                redirectUri = /*window.location.origin + */ '/admin-page';
            }


        this.router.navigate([redirectUri]);
    }


    constructor(
        private authenticationService: AuthenticationService,
        private firebaseAuthService: FirebaseAuthService,
        private router: Router,
        private accessConfigService: AccessConfigService,
        private translateService: TranslateService,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.panelOpenState = false;
        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });
        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;
            });
        this.authenticationService.reloadCredentials();
        this.user = this.authenticationService.getUserEmail();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';


    }

    ngOnInit() {
        // this.isLoading = true;
        this.authenticationService.reloadCredentials();
        this.user = this.authenticationService.getUserEmail();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';



        const date = new Date();
        this.startDate = date.toISOString();
        date.setDate(date.getDate() + 365);
        this.expireDate = date.toISOString();

        if (this.role !== 'anastasis' && this.role !== 'admin') {
            this.router.navigate(['/home']);
        }

        this.users = [];
        this.selectedIndex = 0;
        this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');


        // this.accessConfigForm = this.formBuilder.group({
        //   //   accessName: ['', Validators.required],
        // });
        // this.multyConfigForm = this.formBuilder.group({
        //   //   accessName: ['', Validators.required],
        // });

        // this.singleConfigForm = this.formBuilder.group({
        //   //   accessName: ['', Validators.required],
        // });


    }

    ngOnDestroy() {
        if (this.createOrderSubscription) { this.createOrderSubscription.unsubscribe(); }
        if (this.saveAccesslistSubscription) { this.saveAccesslistSubscription.unsubscribe(); }
    }

    startLoadingList() {
        this.isLoading = true;
    }

    endLoadingList() {
        this.isLoading = false;
    }

    isDomainFormGroupDirty(): boolean {
        const dirty = (this.accessConfigForm && this.domainName && this.domainconfig) && (this.accessConfigForm.dirty || this.domainName.subsForm.dirty || this.domainconfig.parentForm.dirty) && (this.domainDate && this.domainDate.dateForm.dirty);

        if (dirty) {
            this.multyDisabled = this.singleDisabled = true;
            return true;
        } else {
            this.multyDisabled = this.singleDisabled = false;
            return false;
        }
    }

    isSingleFormGroupDirty(): boolean {
        const dirty = ((this.singleConfigForm && this.singleName && this.singleconfig) && (this.singleConfigForm.dirty || this.singleName.subsForm.dirty || this.singleconfig.parentForm.dirty) && (this.singleDate && this.singleDate.dateForm.dirty));
        if (dirty) {
            this.domainDisabled = this.multyDisabled = dirty;
            return true;
        } else {
            this.domainDisabled = this.multyDisabled = false;
            return false;
        }
    }

    isMultyFormGroupDirty(): boolean {
        const dirty = (this.multyConfigForm && this.multyName && this.usersconfig && this.multyconfig) && (this.multyConfigForm.dirty || this.multyconfig.parentForm.dirty ||
            this.multyName.subsForm.dirty || this.usersconfig.usersForm.dirty) && (this.multyDate && this.multyDate.dateForm.dirty);
        if (dirty) {
            this.domainDisabled = this.singleDisabled = dirty;
            return true;
        } else {
            this.domainDisabled = this.singleDisabled = false;
            return false;
        }
    }


    changeGroup(event: MatTabChangeEvent) {
        if (event.index !== 0) {
            this.panelOpenState = true;
            this.selectedIndex = event.index;
        }
    }


    ctrlIfDirty(index: number): boolean {
        const dirty = (this.selectedIndex === 1 && this.isDomainFormGroupDirty()) || (this.selectedIndex === 2 && this.isSingleFormGroupDirty()) || (this.selectedIndex === 3 && this.isMultyFormGroupDirty());
        if (dirty) {
            this.domainDisabled = true;
            this.singleDisabled = true;
            this.multyDisabled = true;

            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('EXIT_MESSAGE')),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('WAIT')))
                .subscribe({
                    next: result => {
                        if (result) {
                            // dirty = false;
                            this.domainDisabled = false;
                            this.singleDisabled = false;
                            this.multyDisabled = false;
                            this.panelOpenState = this.selectedIndex === index && !this.panelOpenState;
                        }
                    },
                    error: (error: any) => {
                        logger.error(error);
                    }
                });
        } else {
            this.panelOpenState = this.selectedIndex === index && !this.panelOpenState;
        }
        // if (!this.panelOpenState) {
        //   setTimeout(() => {
        //     this.selectedIndex = 0;
        //   }, 1);
        // }
        return dirty;
    }

    showHideDomain() {
        this.ctrlIfDirty(1);
    }

    showHideSingle() {
        this.ctrlIfDirty(2);
    }

    showHideGroup() {
        this.ctrlIfDirty(3);
    }

    saveData() {

        this.isLoading = true;
        this.accessList = new AccessDto();
        this.order = new OrderDto();
        this.order.orderId = new Date().toISOString().replace(/[^a-zA-Z0-9@|]/g, '-');
        this.order.origin = 'manual';
        this.order.userEmail = this.authenticationService.getUserEmail();
        this.order.domainLics = 0;
        this.order.fullName = this.authenticationService.getUserName();
        if (this.selectedIndex === 1) {
            this.order.licenseType = 'd';
            this.order.slotCount = 5;
            this.order.userType = this.domainDate?.dateForm.get('selectUserType')?.value;
            this.order.planId = this.domainDate?.dateForm.get('planId')?.value;
            // this.order.ocrMathScanQuota = this.domainDate?.dateForm.get('ocrMathScanQuota')?.value;
            // this.order.ocrMathScanPolicy = this.domainDate?.dateForm.get('ocrMathScanPolicy')?.value;
            this.order.startDate = this.domainDate?.dateForm.get('startDate')?.value.toISOString();
            this.order.expireDate = this.domainDate?.dateForm.get('expireDate')?.value.toISOString();
            this.order.origin = this.domainDate?.dateForm.get('origin')?.value;
            this.sendMail = this.domainDate?.dateForm.get('sendMail')?.value;
            this.order.domainLics = this.domainconfig?.parentForm.get('domainLics')?.value;
            const name = this.domainName?.subsForm.get('accessName')?.value ? this.domainName?.subsForm.get('accessName')?.value : '';
            if (name) this.accessList.name = name;
            this.accessList.admin.eMail = this.domainconfig?.parentForm.get('adminEmail')?.value;
            this.accessList.admin.name = this.domainconfig?.parentForm.get('adminName')?.value;
            this.accessList.admin.notes = this.domainconfig?.parentForm.get('adminNotes')?.value;
            this.accessList.domain = '@' + this.domainconfig?.parentForm.get('domain')?.value;
        }
        if (this.selectedIndex === 2) {
            this.order.licenseType = 's';
            this.order.slotCount = 1;
            this.order.userType = this.singleDate?.dateForm.get('selectUserType')?.value;
            this.order.planId = this.singleDate?.dateForm.get('planId')?.value;
            // this.order.ocrMathScanQuota = this.singleDate?.dateForm.get('ocrMathScanQuota')?.value;
            // this.order.ocrMathScanPolicy = this.singleDate?.dateForm.get('ocrMathScanPolicy')?.value;
            this.order.startDate = this.singleDate?.dateForm.get('startDate')?.value.toISOString();
            this.order.expireDate = this.singleDate?.dateForm.get('expireDate')?.value.toISOString();
            this.order.origin = this.singleDate?.dateForm.get('origin')?.value;
            this.sendMail = this.singleDate?.dateForm.get('sendMail')?.value;
            const name = this.singleName?.subsForm.get('accessName')?.value ? this.singleName?.subsForm.get('accessName')?.value : '';
            if (name) this.accessList.name = name;
            this.accessList.admin.eMail = this.singleconfig?.parentForm.get('adminEmail')?.value;
            this.accessList.admin.name = this.singleconfig?.parentForm.get('adminName')?.value;
            this.accessList.admin.notes = this.singleconfig?.parentForm.get('adminNotes')?.value;
        }

        if (this.selectedIndex === 3) {
            this.order.licenseType = 'm';
            this.order.userType = this.multyDate?.dateForm.get('selectUserType')?.value;
            this.order.planId = this.multyDate?.dateForm.get('planId')?.value;
            // this.order.ocrMathScanQuota = this.multyDate?.dateForm.get('ocrMathScanQuota')?.value;
            // this.order.ocrMathScanPolicy = this.multyDate?.dateForm.get('ocrMathScanPolicy')?.value;
            this.order.startDate = this.multyDate?.dateForm.get('startDate')?.value.toISOString();
            const dateField = this.multyDate?.dateForm.get('expireDate');
            this.order.expireDate = (dateField ? dateField.value.toISOString() : '');
            this.order.origin = this.multyDate?.dateForm.get('origin')?.value;
            this.sendMail = this.multyDate?.dateForm.get('sendMail')?.value;
            const name = this.multyName?.subsForm.get('accessName')?.value ? this.multyName?.subsForm.get('accessName')?.value : '';
            if (name) this.accessList.name = name;
            this.accessList.admin.eMail = this.multyconfig?.parentForm.get('adminEmail')?.value;
            this.accessList.admin.name = this.multyconfig?.parentForm.get('adminName')?.value;
            this.accessList.admin.notes = this.multyconfig?.parentForm.get('adminNotes')?.value;
            const val = this.usersconfig?.usersForm.get('slotCount')?.value ? this.usersconfig.usersForm.get('slotCount')?.value : 0;
            if (val) this.order.slotCount = val;
            this.accessList.users = (this.usersconfig) ? this.usersconfig.getUsers() : [];
        }

        this.order.networkUserId = 1;
        this.order.refOrderId = '';

        this.isLoading = true;

        // this.accessList.name = this.accessConfigForm.get('accessName').value;

        this.randomId = 'security-override';

        let days = 365;
        if (this.expireDate !== this.order.expireDate) {
            const date1 = new Date(new Date(this.order.startDate).toISOString().substr(0, 10) + 'T12:00:00Z');
            const date2 = new Date(new Date(this.order.expireDate).toISOString().substr(0, 10) + 'T12:00:00Z');
            const diff = Math.abs(date2.getTime() - date1.getTime());
            days = Math.ceil(diff / (1000 * 3600 * 24));
        }
        this.order.startDate = new Date(new Date(this.order.startDate).setHours(12)).toISOString().substr(0, 10);

        const operator = this.authenticationService.getUserEmail();
        this.createOrderSubscription = this.accessConfigService.createOrder(this.order, days, operator, this.sendMail)
            .subscribe((res: any) => {
                if (res.ok) {
                    const orderId = (this.order) ? this.order.orderId : '';
                    const randomId = (this.randomId) ? this.randomId : '';
                    this.saveAccesslistSubscription = this.accessConfigService.createAccess(orderId, randomId, this.accessList, operator, this.sendMail).subscribe((resp: any) => {
                        console.log(resp);
                        this.isLoading = false;
                        if (resp.ok === true) {
                            alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_SUCCESS'));
                            this.orderlist?.reloadData();
                        } else {
                            alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                        }
                    }, (error: any) => {
                        this.isLoading = false;
                        alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                        logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
                    });
                } else {
                    this.isLoading = false;
                    alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                    logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                }
            }, (error: any) => {
                this.isLoading = false;
                alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
            });
    }

    //  get f() { return this.accessConfigForm.controls; }

    enabledDomain() {
        let en = false;
        if (this.accessConfigForm && this.domainconfig && this.domainName && this.domainDate) {
            en = this.accessConfigForm.valid && this.domainconfig.parentForm.valid && this.domainName.subsForm.valid && this.domainDate.dateForm.valid;
            if (en) {
                en = en && (!this.accessConfigForm.pristine || !this.domainconfig.parentForm.pristine || !this.domainName.subsForm.valid || !this.domainDate.dateForm.pristine);
            }
        }
        return en;
    }

    enabledSingle() {
        let en = false;
        if (this.singleConfigForm && this.singleconfig && this.singleName && this.singleDate) {
            en = this.singleConfigForm.valid && this.singleconfig.parentForm.valid && this.singleName.subsForm.valid && this.singleDate.dateForm.valid;
            if (en) {
                en = en && (!this.singleConfigForm.pristine || !this.singleconfig.parentForm.pristine || !this.singleName.subsForm.pristine || !this.singleDate.dateForm.pristine);
            }
        }
        return en;
    }

    enabledGroup() {
        let en = false;
        if (this.multyConfigForm && this.usersconfig && this.multyName && this.multyDate) {
            en = this.multyConfigForm.valid && this.usersconfig.usersForm.valid && this.multyName.subsForm.valid && this.multyDate.dateForm.valid;
            if (en) {
                en = en && (!this.multyConfigForm.pristine || !this.usersconfig.usersForm.pristine || !this.multyName.subsForm.pristine || !this.multyDate.dateForm.pristine);
            }
        }
        return en;
    }

    enabledDetail() {
        const en = !this.isDomainFormGroupDirty() && !this.isMultyFormGroupDirty() && this.isSingleFormGroupDirty();
        return en;
    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role?.toLocaleUpperCase() || 'USER'));
    }


    onSubmit() {
        // if (this.accessConfigForm.invalid || this.adminconfig.parentForm.invalid) {
        //   return;
        // }

        this.panelOpenState = false;
        this.saveData();

    }

    protected resetForm(index: any) {
        setTimeout(() => {
            if (index === 1) {
                this.domainDate?.dateForm.get('startDate')?.setValue(this.startDate);
                this.domainDate?.dateForm.get('expireDate')?.setValue(this.expireDate);
                this.domainDate?.dateForm.markAsPristine();
            }
            if (index === 2) {
                this.singleDate?.dateForm.get('startDate')?.setValue(this.startDate);
                this.singleDate?.dateForm.get('expireDate')?.setValue(this.expireDate);
                this.singleDate?.dateForm.markAsPristine();
            }
            if (index === 3) {
                this.multyDate?.dateForm.get('startDate')?.setValue(this.startDate);
                this.multyDate?.dateForm.get('expireDate')?.setValue(this.expireDate);
                this.multyDate?.dateForm.markAsPristine();
            }
        });
    }

}
