<div class="copy-paste-popup">
  <div class="info">
    {{'COPY_PASTE_INFO1' | translate}}
  </div>
  <div class="info2">
    {{'COPY_PASTE_INFO2' | translate}}
  </div>
  <div class="info3">
    <div class="copy-info">
      {{ COPY_COMMAND }}
      <br />
      <span class="info4">{{'COPY_PASTE_INFO3_C' | translate}}</span>
    </div>
    <div class="cut-info">
      {{CUT_COMMAND}}
      <br />
      <span class="info4">{{'COPY_PASTE_INFO3_X' | translate}}</span>
    </div>
    <div class="paste-info">
      {{PASTE_COMMAND }}
      <br />
      <span class="info4">{{'COPY_PASTE_INFO3_V' | translate}}</span>
    </div>
  </div>
</div>
