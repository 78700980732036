import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    @Input() isLoading: boolean = false;
    @Input() message: string = "";
    @Input() versioned: boolean = false;

    public isLab: boolean = false;

    constructor(
        private authenticationService: AuthenticationService
    ) {
        this.isLab = this.authenticationService.isLab();
    }
}
