import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { MapEditComponent } from './map-edit.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';


const routes: Routes = Route.withShell([
  {
    path: 'map-edit/:mapId',
    component: MapEditComponent,
    data: { title: extract('MAPEDIT_TITLE') },
    canActivate: [AuthenticationGuard],
    canDeactivate: [CanDeactivateGuard]
  }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateGuard, AuthenticationGuard]
})
export class MapEditRoutingModule { }
