<h2 mat-dialog-title mdc-dialog-draggable-title class="title-text" translate> {{title}}</h2>
<div mat-dialog-content class="confirm-form">
    <img *ngIf="isImageSet" [src]="image" class="ocr-image" />
    <div *ngIf="!isImageSet" class="text-wait-container">
        <div class="text-wait" translate>OCRSCAN_WAIT</div>
    </div>
    <div class="text-sub">{{scansInfo}}</div>
</div>
<mat-dialog-actions>
    <button mat-stroked-button (click)="dialogRef.close(false)">
        <div>{{cancel}}</div>
    </button>
    <button mat-flat-button color="primary" *ngIf="!disabled" cdkFocusInitial (click)="dialogRef.close(ok)" translate>
        <span translate>{{insert}}</span>
    </button>
</mat-dialog-actions>
