<app-loader *ngIf="isLoading" [isLoading]="isLoading"></app-loader>

<h2 mat-dialog-title class="header">{{loginTitle.toUpperCase()}}</h2>
<button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close color="primary">
    <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
    <form [formGroup]="form" class="form-container">
        <div class="google-container">
            <script src="https://accounts.google.com/gsi/client" async defer></script>
            <div class="g-id-signin" id="google-button"></div>
            <!-- Bottone alternativo per sign in con Google che attiva le nuove regole di validazione URI (in vigore dal 6/1/2025) -->
            <!-- <div class="g_id_onload" id="google-button" data-enable_redirect_uri_validation="true"></div> -->
            <div *ngIf="!googleSignInAvailable" class="no-google-signin">
                <img class="google-small" src="../../assets/login/google_small.png" />
                <h1 translate>NO_GOOGLE_TITLE</h1>
                <span translate>NO_GOOGLE_MESSAGE_1</span>
                <br />
                <span translate>NO_GOOGLE_MESSAGE_2</span>
            </div>
        </div>
        <mat-label translate>YOUR_EMAIL</mat-label>
        <div class="email-input-container">
            <mat-form-field appearance="outline">
                <input #emailInput type="email" matInput placeholder="Email" formControlName="username" autocomplete="false" spellcheck="false" required />
            </mat-form-field>
            <div class="error-container">
                <div *ngIf="error" class="error-form">
                    {{ error }}
                </div>
                <div *ngIf="f['username'].errors && f['username'].dirty" class="error-form" translate>
                    NOT_VALID_EMAIL_MSG
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button [disabled]="!form.valid" mat-flat-button color="warn" type="button" id="login" (click)="submit()" translate>{{'GOON' | translate}}</button>
</mat-dialog-actions>
