import { Component, OnInit, NgZone, OnDestroy, Output, EventEmitter } from '@angular/core';
import { I18nService, extract } from '../core/i18n.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { GoogleService } from '../core/google/google.service';
import { SmeService } from '../core/sme/sme.service';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MapStateService } from '../shared/map-state.service';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';

declare let google: any;

const logger: Logger = new Logger('GdriveFolderPickerComponent');

@Component({
    selector: 'app-gdrive-folder-picker',
    templateUrl: './gdrive-folder-picker.component.html',
    styleUrls: ['./gdrive-folder-picker.component.scss']
})

export class GdriveFolderPickerComponent implements OnInit, OnDestroy {
    isLoading = false;
    error = '';
    picker: any;

    private mapId = '';

    private googlefolderId = '';


    createSubscription: Subscription | undefined;
    paramMapSubscription: Subscription | undefined;
    queryParamsSubscription: Subscription | undefined;
    accessTokenSubscription: Subscription | undefined;


    constructor(private router: Router, private route: ActivatedRoute,
        private zone: NgZone,
        private authenticationService: AuthenticationService,
        private i18nService: I18nService,
        private mapStateService: MapStateService,
        private location: Location,
        private translateService: TranslateService,
        private googleService: GoogleService,
        private smeService: SmeService) { }

    ngOnInit() {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            this.mapId = params['mapId'];

            this.initialize();
        });
    }

    initialize() {
        if (this.picker) {
            this.picker.setVisible();
        } else {
            let accessToken: string;
            this.accessTokenSubscription = this.smeService.getAccessToken().subscribe({
                next: (_accessToken: string) => {
                    accessToken = _accessToken;
                    this.googleService.loadPicker()
                        .then(() => {
                            this.createPicker(accessToken);
                            this.picker.setVisible(true);
                        }).catch((err: any) => {
                            logger.error('AUTH ERROR: ' + JSON.stringify(err));
                            this.error = extract('GDRIVE_PICKER_ERROR_LOADING_PICKER');
                            this.showError(this.error);
                        });
                },
                error: (error: any) => {
                    logger.error('AUTH ERROR: ' + JSON.stringify(error));
                    this.error = extract('GDRIVE_PICKER_ERROR_LOADING_PICKER');
                    this.showError(this.error);
                }
            });
            // , ((error: any) => {
            //   logger.error('AUTH ERROR: ' + JSON.stringify(error));
            //   this.error = extract('GDRIVE_PICKER_ERROR_LOADING_PICKER');
            //   this.showError(this.error);
            // });
        }
    }

    ngOnDestroy(): void {
        if (this.createSubscription) { this.createSubscription.unsubscribe(); }
        if (this.accessTokenSubscription) { this.accessTokenSubscription.unsubscribe(); }
        if (this.picker) {
            this.closePicker();
            this.picker.dispose();
            this.picker = null;
        }
    }

    private showError(errorCode: string) {
        this.error = this.translateService.instant(extract(errorCode));
    }


    closePicker() {
        if (this.picker) {
            this.picker.setVisible(false);
        }
    }

    createPicker(accessToken: string): void {

        if (!this.picker) {
            const language = this.i18nService.language;
            const titlePicker = this.translateService.instant('SELECT_FOLDER');

            const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
                .setParent('root')
                .setMimeTypes('application/vnd.google-apps.folder')
                .setSelectFolderEnabled(true);
            // const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS);

            // view.setMimeTypes('application/vnd.google-apps.folder');
            // view.setSelectFolderEnabled(true);
            // view.setQuery('Il Mio Drive');

            const APP_ID = environment.configFirebase.messagingSenderId;
            this.picker = new google.picker.PickerBuilder()
                .addView(view)
                .setAppId(APP_ID)
                .setOrigin(window.origin)
                .setOAuthToken(accessToken)
                .setTitle(titlePicker)
                .setLocale(language)
                .setCallback(this.onPickerChosen.bind(this))
                .build();
        }
    }

    backToHome() {
        this.router.navigate(['/home'], { replaceUrl: true });
    }

    onPickerChosen(data: any) {
        this.zone.run(() => {
            const action = data[google.picker.Response.ACTION];
            if (action === google.picker.Action.CANCEL) {
                this.closePicker();
                //  this.router.navigate(['/home'], { replaceUrl: true });
                this.location.back();
            } else if (action === google.picker.Action.PICKED) {
                this.closePicker();
                this.isLoading = true;
                const doc = data[google.picker.Response.DOCUMENTS][0];
                this.googlefolderId = doc['id'];

                //     if (this.mapId) {
                this.smeService.moveMap(this.googlefolderId, this.mapId)
                    .subscribe({
                        next: (res: any) => {
                            // this.router.navigate(['/map-edit', this.mapId]);
                            this.location.back();

                        },
                        error: (error: any) => {
                            this.isLoading = false;
                            this.location.back();
                            // this.router.navigate(['/map-edit', this.mapId]);
                            this.mapStateService.setStateError(error);
                            this.mapStateService.setBusyState(false);
                        }

                    });



            }
        });


    }

    // private loadMap(accessToken: string) {
    //   logger.info('Map uploaded');
    //   const fileRef = this.mapStateService.fileRef;
    //   if (this.createSubscription) { this.createSubscription.unsubscribe(); }
    //   this.createSubscription = this.smeService.createWorkMapFromStorage(fileRef, accessToken, this.googlefolderId)
    //     .subscribe((mapId: string) => {
    //       this.isLoading = false;
    //       logger.info(`Map ${mapId} created`);
    //       // window.location.href = 'map-edit/' + mapId;
    //       this.router.navigate(['map-edit/', mapId]);
    //     }, (error: any) => {
    //       logger.error('createWorkMapFromStorage().subscribe: ' + error);
    //       this.isLoading = false;
    //       let errorCode = '';
    //       if (error.status === 504) {
    //         errorCode = 'GATEWAY_TIMEOUT';
    //       } else {
    //         errorCode = (error.code) ? error.code : error.statusText;
    //       }
    //       this.showError(errorCode);
    //     });
    // }

    // private openNewMap(accessToken: string) {

    //   this.mapStateService.isNew = true;

    //   if (this.createSubscription) { this.createSubscription.unsubscribe(); }
    //   this.createSubscription = this.smeService.createNewMap(accessToken, this.googlefolderId)
    //     .subscribe((mapId: string) => {
    //       this.isLoading = false;
    //       logger.info(`new Map ${mapId} created`);
    //       this.router.navigate(['map-edit', mapId]);
    //       this.mapStateService.setBusyState(false);
    //     }, (error: any) => {
    //       this.isLoading = false;
    //       this.mapStateService.setBusyState(false);
    //       if (error.status === 504) {
    //         this.mapStateService.setStateError('GATEWAY_TIMEOUT');
    //       } else {
    //         const errorCode = (error.code) ? error.code : error.statusText;
    //         this.mapStateService.setStateError(errorCode);
    //       }

    //     });

    // }



}
