import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-forgot-password-sent',
    templateUrl: './forgot-password-sent.component.html',
    styleUrl: './forgot-password-sent.component.scss'
})
export class ForgotPasswordSentComponent {

    text: string = "";

    public compressLogo = false;
    ////////////

    public logoUrlExtended = '';
    public logoUrlCompressed = '';

    constructor(private translateService: TranslateService, private route: ActivatedRoute,
        private router: Router, private breakpointObserver: BreakpointObserver) {

        this.route.queryParams.subscribe(params => {
            const username: string = params['username'] ?? '';
            this.text = this.translateService.instant('RESPONSE_RP_2').replace('%email%', username);
        });


        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });



        // this.initialize();
        this.logoUrlExtended = ('../../assets/login/supermappex.png');
        this.logoUrlCompressed = ('../../assets/sm-logo-64.png');

    }

    gotoHome(): void {
        this.router.navigate(['home']);
    }

}
