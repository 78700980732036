<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'"></app-loader>

<h2 mat-dialog-title class="header">{{'PASSWORD' | translate | uppercase}}</h2>
<button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close color="primary">
    <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()" class="form-container">
        <div translate>INSERT_PASSWORD</div>
        <mat-form-field appearance="outline">
            <input #passwordInput [type]="hide ? 'password' :  'text'" matInput placeholder="Password" autocomplete="false" spellcheck="false" formControlName="password" mat-focuse />
            <mat-icon *ngIf="!isIEOrEdge()" matSuffix (click)="showHidePsw()">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>

        <a class="forgot-password" color="primary" (click)="openForgotPassword()">{{'FORGOT_PASSWORD' | translate}}</a>

    </form>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button [disabled]="!form.valid" mat-flat-button color="warn" type="submit" (click)="submit()" translate>{{'GOON' | translate}}</button>
</mat-dialog-actions>
