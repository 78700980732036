<h2 mat-dialog-title translate>APP_NAME</h2>
<div mat-dialog-content>
    <div class="device-info-content">
        {{deviceInfo}}
    </div>
</div>
<mat-dialog-actions [align]="'end'">
    <button mat-flat-button color="primary" type="button" [mat-dialog-close]="true" translate>
        <div translate>OK</div>
    </button>
</mat-dialog-actions>
