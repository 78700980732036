
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SmEventBroker } from '../sm-event-broker';
import { Observable } from 'rxjs';
import { MapStateService } from '../map-state.service';

@Injectable({
    providedIn: 'root'
})
export class MapLanguageService {
    public mapLanguageChange: Observable<string> | undefined;

    constructor(eventBroker: SmEventBroker, private mapStateService: MapStateService) {
        if (eventBroker.mapLanguage) {
            this.mapLanguageChange = eventBroker.mapLanguage.pipe(
                map((newLanguage: string) => {
                    this.mapStateService.mapLanguage = newLanguage;
                    // this.mapStateService.setSpellCheckerLanguages(newLanguage);
                    return newLanguage;
                }));
        }
    }

}
