import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SmeService } from '../core/sme/sme.service';
import { Logger } from '../core/logger.service';
import { AdminEditNotificationComponent } from '../admin-edit-notification/admin-edit-notification.component';
import { Subscription } from 'rxjs';
import { NotificationsDto } from '../shared/notifications-data-dto';


const logger: Logger = new Logger('admin-notifications');

@Component({
    selector: 'app-admin-users',
    templateUrl: './admin-users.component.html',
    styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
    public loaded = false;
    public isLoading = false;
    public showInsert = false;
    forceReload = false;
    user = '';
    role = '';
    insertNotificationSubscription: Subscription | undefined;
    insertNotificationForm: FormGroup = this.formBuilder.group({});

    @ViewChild('notificationForm') notificationForm: AdminEditNotificationComponent | undefined;

    constructor(private authenticationService: AuthenticationService, private smeService: SmeService, private translateService: TranslateService, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        // this.insertNotificationForm = this.formBuilder.group({});
        this.loaded = true;
        this.user = this.authenticationService.getUserName();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials.role : '';
    }

    showInsertNotification() {
        this.showInsert = !this.showInsert;
    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role.toLocaleUpperCase() || 'USER'));
    }

    updateForm() {
        if (this.notificationForm?.notificationForm.invalid || this.notificationForm?.notificationForm.pristine) { return; }

        if (this.notificationForm) {
            this.notificationForm.notificationData = new NotificationsDto('', null);
            this.saveData();
        }

    }

    canSave() {

        return (this.notificationForm && this.notificationForm.notificationForm && this.notificationForm.notificationForm.valid && !this.notificationForm.notificationForm.pristine);
    }

    resetForm() {
        this.showInsert = false;
    }

    saveData() {
        if (this.notificationForm && this.notificationForm.notificationData) {

            this.notificationForm.notificationData.active = this.notificationForm?.notificationForm?.get('active')?.value;

            const d = this.notificationForm.notificationForm.get('dateCreated')?.value;
            d.setHours(12); // evita il problema di mezzanotte che retrocede alle 23 del giorno prima!
            this.notificationForm.notificationData.dateCreated = d.toISOString(); // .substr(0, 10);
            if (this.notificationForm.selectedNotification) {
                this.notificationForm.notificationData.typeIcon = this.notificationForm.selectedNotification.value;

                this.notificationForm.notificationData.icon = this.notificationForm.selectedNotification.icon;
            }
            this.notificationForm.notificationData.link = this.notificationForm.notificationForm.get('link')?.value;
            this.notificationForm.notificationData.notes = this.notificationForm.notificationForm.get('notes')?.value;
            this.notificationForm.notificationData.title = this.notificationForm.notificationForm.get('title')?.value;

            this.insertNotificationSubscription = this.smeService.insertOrUpdateNotification(this.notificationForm.notificationData)
                .subscribe({
                    next: (id: any) => {
                        // this.router.navigate(['admin-page']);
                        alert('modifica effettuata');

                        if (this.notificationForm && this.notificationForm.notificationData) this.notificationForm.notificationData.edit = false;
                        this.forceReload = !(this.forceReload);

                        this.showInsert = false;

                    },
                    error: (error: any) => {
                        alert(error);
                        logger.error(error);
                    }
                });
        }

    }


}
