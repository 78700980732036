<div class="pdf-container">
    <!-- <div class="pdf-toolbar"> -->
    <!-- <span class="title-pdf" translate>IMPORTS_PDF</span> -->

    <div class="pdf-toolbar" draggable="true" ngClass="{{dragAreaClass}}">
        <mat-icon class="icon-drop" color="primary">system_update_alt</mat-icon>
        <div class="desc-drop">
            <span translate>DROP_PDF</span>
            <span translate>INSERT_LINK_PDF</span>
        </div>
        <!-- <input #linkpdf class="input-name" matInput contenteditable="true" (keyup.enter)="onChangeUrl($event)" />
            <mat-icon (click)="openPDFLink()">link</mat-icon> -->
        <div class="pdf-toolbar-top-right">
            <button *ngIf="googleUser" color="primary" mat-icon-button matTooltip="{{'IMPORTS_PDF' | translate}}" [matMenuTriggerFor]="menuopen">
                <mat-icon class="material-icons-sharp" fontSet="material-icons-filled" color="primary">upload</mat-icon>
            </button>
            <!-- <button mat-button-toggle *ngIf="googleUser" class="button-pdf" [matMenuTriggerFor]="menuopen" matTooltip="{{'IMPORTS_PDF' | translate}}"> -->
            <!-- <span class="span-pdf" translate>IMPORTS_PDF</span>
                <mat-icon class="icon-pdf" color="primary">
                    arrow_drop_down
                </mat-icon> -->
            <mat-menu #menuopen="matMenu" [overlapTrigger]="false">
                <div mat-menu-item class="custom-item" (click)="file.click()" matTooltip="{{'OPEN_PDF' | translate}}">
                    <input type="file" class="hidden" #file accept="application/pdf" (change)="openPDFFromLocal($event)" />
                    <mat-icon class="custom-icon" color="primary">computer</mat-icon>
                    <span class="custom-span" translate>OPEN_PDF</span>
                </div>

                <div mat-menu-item class="custom-item" (click)="openPDFFromDrive()" matTooltip="{{'FROM_GD' | translate}}">
                    <mat-icon color="primary" svgIcon="google-drive" class="custom-icon"></mat-icon>
                    <span translate class="custom-span">FROM_GD</span>
                </div>
            </mat-menu>
            <!-- </button> -->
            <input type="file" class="hidden" #file accept="application/pdf" (change)="openPDFFromLocal($event)" />
            <button *ngIf="!googleUser" mat-icon-button color="primary" matTooltip="{{'IMPORTS_PDF' | translate}}" (click)="file.click()">
                <mat-icon class="material-icons-sharp" fontSet="material-icons-filled" color="primary">upload</mat-icon>
            </button>


        </div>
        <input #linkpdf class="input-name" matInput spellcheck="false" placeholder="{{'PDF_PLACEORDER' | translate}}" (keyup.enter)="onChangeUrl($event)" (focus)="disableMap()" />
        <!-- <mat-icon (click)="openPDFLink()">link</mat-icon> -->
        <!-- </div> -->

    </div>
    <div class="pdf-recents">
        <span class="title-pdf" translate>MY_PDF</span>
        <mat-list class="pdf-recents-container">
            <div class="recent-pdf-list" *ngFor="let recentPdf of recentPdfsSortedFiltered">
                <app-pdf-recent [recentPdf]="recentPdf" (fromLocal)="fromLocal" (fromDrive)="fromDrive" (fromLink)="fromLink" (downloadItem)="downloadRecent($event)" (removeItem)="removePDF($event)" (renameItem)="renamePdf($event)" (fromRecent)="loadPdfFromRecent($event)"></app-pdf-recent>
            </div>
        </mat-list>
    </div>
</div>
