import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PasteTabComponent } from './paste-tab.component';

@Injectable({
    providedIn: 'root'
})
export class PasteTabService {

    public hasDeepContent: boolean = false;

    constructor(private dialog: MatDialog) {
        this.dialog = dialog;
    }

    public show(noTabRowsCols: boolean, hasDeepContent: boolean): Observable<boolean> {
        const dialogRef: MatDialogRef<PasteTabComponent> = this.dialog.open(PasteTabComponent);
        dialogRef.componentInstance.noTabRowsCols = noTabRowsCols;
        dialogRef.componentInstance.hasDeepContent = hasDeepContent;
        return dialogRef.afterClosed();
    }

}
