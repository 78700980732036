import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-message-sent',
    templateUrl: './message-sent.component.html',
    styleUrls: ['./message-sent.component.scss']
})
export class MessageSentComponent {
    text = "";
    public logoUrlExtended = '';
    public demo = false;

    public compressLogo = false;
    logoUrlCompressed: string;
    constructor(private translateService: TranslateService, private breakpointObserver: BreakpointObserver, private route: ActivatedRoute) {

        this.route.queryParams.subscribe(params => {
            const username: string = params['username'] ?? '';
            this.text = this.translateService.instant('RESPONSE_RS_2').replace('%email%', username);
           
            this.demo = params['demoFromStart'] === 'true';
        });

        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });

        // this.initialize();
        this.logoUrlExtended = ('../../assets/login/supermappex.png');
        this.logoUrlCompressed = ('../../assets/sm-logo-64.png');
    }

}
