import { Component, OnInit } from '@angular/core';
import { CustomSelectElement } from '../custom-select-element-dto';
import { SmService } from 'supermappe-core';
import { UiConstants } from '../../../../shared/ui-constants';


@Component({
  selector: 'app-align-toolbar',
  templateUrl: './align-toolbar.component.html',
  styleUrls: ['./align-toolbar.component.scss']
})
export class AlignToolbarComponent {

  public alignments: CustomSelectElement[];


  selectedAlignment = 'left';
  selectedAlignIcon = 'format-horizontal-align-left';

  constructor(private smService: SmService) {
    // this.onChangeToolbarVisibilitySubscription =
    //   this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
    //     this.changeSelection(_customData);
    //   });
    // this.imageIcon = '<mat-icon id="border-icon" class="under-select" mat-prefix [svgIcon]="'
    // + this.selectedBorderIcon
    //   + '"></mat-icon>';

    this.alignments = UiConstants.ALIGNMENTS;

  }

  changeAlign(event: any) {
    this.selectedAlignment = event.value;
    this.selectedAlignIcon = event.icon;
    this.smService.alignSelection(this.selectedAlignment);
  }
}
