import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MapOperationType } from '../shared/map-operations';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { extract } from '../core/i18n.service';
import { SmeService } from '../core/sme/sme.service';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { MapStateService } from '../shared/map-state.service';
import { ImportMapService } from '../shared/commands/import-map.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ResourceUpload } from '../core/firebase/resource-upload';
import { FirebaseService } from '../core/firebase/firebase.service';

@Component({
    selector: 'app-map-import',
    templateUrl: './map-import.component.html',
    styleUrl: './map-import.component.scss'
})
export class MapImportComponent {
    isLoading = false;
    public error = ''

    paramMapSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;

    mapId = '';
    compressHeader: boolean = false;
    compressLogo: boolean = false;
    logoUrlExtended: string;
    logoUrlCompressed: string;
    currentMapUpload: any;
    uploadMapSubscription: any;
    createWorkMapSubscription: any;

    disableOpen = true;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private messageBoxService: MessageBoxService,
        private smeService: SmeService,
        private mapStateService: MapStateService,
        private importMapService: ImportMapService,
        private firebaseService: FirebaseService,
        private breakpointObserver: BreakpointObserver

    ) {
        this.breakpointObserver
            .observe(['(max-width:725px)'])
            .subscribe((state: BreakpointState) => {
                this.compressHeader = state.matches;
            });
        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });



        // this.initialize();
        this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');

    }



    openMap() {

        const redirectUri = window.location.origin + '/map-open/' + this.mapId;
        window.open(redirectUri, '_blank');
    }

    openFile(file: any) {
        ////////////////////////////////////////////////
        // Uncomment to try error on local open
        // this.showError('HOME_ERROR_GENERIC_MAP_LOAD');const storageRef = firebase.storage().ref();
        // return;
        ////////////////////////////////////////////////
        if (!this.isLoading) {
            if (file.target.files[0].name.toLowerCase().endsWith('.sme')) {
                // SME: Load
                this.mapStateService.isSomeOperationStarted = true;
                this.isLoading = true;
                //  this.isLoading.emit(true);
                // this.isLoading = true;
                // this.error = '';
                //this.startLoading.emit();

                this.importMapService.onMapImported.subscribe((ok: boolean) => {
                    this.isLoading = false;
                });
                this.downloadFile(file);
            } else {
                // SMA/SMAM: Error
                // this.error = this.translateService.instant('ERROR_MAP_FORMAT_NOT_SUPPORTED');
                this.showError('ERROR_MAP_FORMAT_NOT_SUPPORTED');
            }

        }
    }

    downloadFile(file: any) {


        let currentMapFile = file;
        if (file.target !== undefined) {
            // apre da comando openfile
            currentMapFile = file.target.files[0];
        }


        const extension: string = currentMapFile.name.split('.').pop();
        if (extension === 'sme') {
            this.currentMapUpload = new ResourceUpload(currentMapFile);


            if (this.uploadMapSubscription) { this.uploadMapSubscription.unsubscribe(); }
            if (this.currentMapUpload) {

                let fileRef: any = '';


                this.uploadMapSubscription = this.smeService.uploadMap(this.currentMapUpload)
                    .subscribe({

                        next: (_fileRef) => {
                            fileRef = _fileRef;
                        },
                        complete: () => {



                            let mapName = currentMapFile.name;
                            if (mapName && mapName !== '' && mapName.split('.').length > 1) {
                                mapName = mapName.split('.').slice(0, -1).join('.');
                                console.log(mapName);

                            }
                            if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
                            this.createWorkMapSubscription = this.smeService.createWorkMapFromStorage(fileRef, mapName, '')
                                .subscribe({
                                    next: (mapId: string) => {
                                        this.mapId = mapId;
                                        this.disableOpen = false;
                                        this.isLoading = false;

                                        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
                                        this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });

                                        this.mapStateService.setBusyState(false);
                                        // if (this.currentMapUpload) this.smeService.uploadMap(this.currentMapUpload, mapId)

                                        // this.firebaseService.addSharedStateChangeListener(mapId, this.authenticationService.getUserEmail()).then(() => {

                                        // });


                                    },
                                    error: (error: any) => {


                                        let errorCode = '';
                                        if (error.status === 504) {
                                            errorCode = 'GATEWAY_TIMEOUT';
                                        } else {
                                            errorCode = (error.code) ? error.code : error.statusText;
                                        }
                                        this.mapStateService.setStateError(errorCode);
                                    }
                                });
                        },
                        error: (error: any) => {

                            let errorCode = '';
                            if (error.status === 504) {
                                errorCode = 'GATEWAY_TIMEOUT';
                            }
                        }

                    });
            }
        } else {
            this.mapStateService.setStateError('ERROR_WRONG_FILETYPE');
        }
    }

    // private prepareAndOpenMap(mapId: string) {
    //     this.isLoading = true;
    //     const email = this.authenticationService.credentials?.username + '';
    //     const userId = this.authenticationService.credentials?.firebaseUserId + '';
    //     // this.smeService.prepareMap(id).subscribe({
    //     this.smeService.getShareData(email, userId, mapId).subscribe({
    //         next: (_data: any) => {
    //             const data = _data;
    //             const viewMode = (data.shareData.isReadonly || this.authenticationService.isUserExpired() || !data.shareData.isMine);
    //             this.openMap(mapId, viewMode, email);
    //         },
    //         error: (error: any) => {
    //             if (error.status === 504) {
    //                 this.showError('GATEWAY_TIMEOUT');
    //             } else if (error.status === 500) {
    //                 if (error.error.message === 'Invalid Credentials') {
    //                     this.showError('ERROR_INVALID_CREDENTIALS');
    //                 } else if (error.error.message === 'GoogleId not found') {
    //                     this.showError('ERROR_GOOGLEID_NOT_FOUND');
    //                 } else {
    //                     this.showError('ERROR_OPEN_MAP');
    //                 }
    //             } else if (error && (error.error && error.error.code || error.status)) {
    //                 const errorCode = (error.error.code ? error.error.code : error.status);
    //                 this.showError(errorCode);
    //             } else {
    //                 this.showError('ERROR_OPEN_MAP');
    //             }
    //             this.isLoading = false;
    //         }
    //     });
    // }

    // private openMap(mapId: string, viewMode: boolean, email: string) {
    //     if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    //     this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, email)
    //         .subscribe({
    //             next: (_data: any) => {
    //                 if (viewMode) {
    //                     console.log('OPENING MAP IN VIEW MODE');
    //                     this.router.navigate(['map-view/', mapId]);
    //                     this.isLoading = false;
    //                 }
    //                 else {
    //                     console.log('OPENING MAP IN EDIT MODE');
    //                     this.router.navigate(['map-edit/', mapId]);
    //                     this.isLoading = false;
    //                 }
    //             },
    //             error: (err: any) => {
    //                 this.isLoading = false;
    //                 this.showError('ERROR_OPEN_MAP');
    //             }
    //         });
    // }

    private showError(errorCode: string) {
        try {
            // JHUBA ERROR DEBUG---------------------------------------------------
            // throw new Error('cippo');
            // JHUBA ERROR DEBUG---------------------------------------------------
            this.isLoading = false;
            this.error = this.translateService.instant(extract(errorCode));
            const title = this.translateService.instant('WARNING');
            const message = this.translateService.instant(errorCode);
            this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message).subscribe(() => {
                this.router.navigate(['/home']);
            });
        } catch (err) {
            // this.error = 'ERROR: ' + errorCode;
            this.messageBoxService.showGenericError(errorCode).subscribe(() => {
                this.router.navigate(['/home']);
            });
        }
    }


}



