import { Injectable, EventEmitter } from '@angular/core';
import { SmeService } from '../../core/sme/sme.service';
import { MapStateService } from '../map-state.service';
import { GoogleService } from '../../core/google/google.service';
import { Subscription } from 'rxjs';
import { MessageBoxService } from '../dialog/messagebox.service';
import { ResourceUpload } from 'src/app/core/firebase/resource-upload';
import { FirebaseService } from 'src/app/core/firebase/firebase.service';
import { Guid } from 'guid-typescript';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';
import { MapOperationType } from '../map-operations';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CopyMapService {

    public onMapCopied = new EventEmitter<any>();

    createWorkMapSubscription: Subscription | undefined;
    uploadMapSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;


    constructor(
        private smeService: SmeService,
        private firebaseService: FirebaseService,
        private mapStateService: MapStateService,
        private authenticationService: AuthenticationService,
        private firebaseAuthService: FirebaseAuthService,
        private messageBoxService: MessageBoxService,
        private router: Router
    ) {
    }
    // copyMap(mapId: string, mapName: string) {
    //     console.log('copy map: ' + mapId);
    //     this.mapStateService.setBusyState(true);


    //     const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;

    //     this.firebaseService.downloadResourceAsBlob(`/maps/${userId}/mymaps/${mapId}.sme`, 'application/supermappex').subscribe((blob: any) => {



    //         const newMapId = Guid.create();
    //         let resourceSmePath = `/maps/${userId}/${newMapId}.sme`;

    //         this.uploadMapSubscription = this.firebaseService.uploadBlob(blob, resourceSmePath)
    //             .subscribe({

    //                 next: (fileRef) => {

    //                     if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
    //                     this.createWorkMapSubscription = this.smeService.createWorkMapFromStorage(fileRef, mapName, '')
    //                         .subscribe({
    //                             next: (mapId: string) => {
    //                                 if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    //                                 this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
    //                                 this.mapStateService.setBusyState(false);
    //                                 resourceSmePath = `/maps/${userId}/mymaps/${mapId}.sme`;
    //                                 this.firebaseService.uploadBlob(blob, resourceSmePath).subscribe(() => { });
    //                                 const redirectUri = /*window.location.origin +*/ '/map-edit/' + mapId;
    //                                 this.router.navigate([redirectUri]);
    //                             },
    //                             error: (error: any) => {
    //                                 let errorCode = '';
    //                                 if (error.status === 504) {
    //                                     errorCode = 'GATEWAY_TIMEOUT';
    //                                 } else {
    //                                     errorCode = (error.code) ? error.code : error.statusText;
    //                                 }
    //                                 this.mapStateService.setStateError(errorCode);
    //                             }
    //                         });
    //                 },
    //                 error: (error: any) => {
    //                     let errorCode = '';
    //                     if (error.status === 504) {
    //                         errorCode = 'GATEWAY_TIMEOUT';
    //                     }
    //                 }

    //             });

    //     });

    // }

    copyMap(mapId: string, mapName: string) {
        console.log('copy map: ' + mapId);
        this.mapStateService.setBusyState(true);

        if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }

        this.createWorkMapSubscription = this.smeService.createCopyMap(mapId, mapName)
            .subscribe({
                next: (newMapId: string) => {
                    if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
                    this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
                    this.mapStateService.setBusyState(false);

                    const redirectUri = /*window.location.origin +*/ '/map-edit/' + newMapId;
                    this.router.navigate([redirectUri]);
                },
                error: (error: any) => {
                    let errorCode = '';
                    if (error.status === 504) {
                        errorCode = 'GATEWAY_TIMEOUT';
                    } else {
                        errorCode = (error.code) ? error.code : error.statusText;
                    }
                    this.mapStateService.setStateError(errorCode);
                }
            });

    }


    // copyMap(mapId: string, mapName: string, googleFolderId?: string) {
    //     console.log('copy map: ' + mapId);
    //     this.mapStateService.setBusyState(true);

    //     if (this.createWorkMapSubscription) { this.createWorkMapSubscription.unsubscribe(); }
    //     if (!googleFolderId) googleFolderId = '';
    //     this.googleService.getSMXFolderIds().then((folderIds: any) => {
    //         this.createCopyMapSubscription = this.smeService.createCopyMapInGoogleDrive(mapId, mapName, folderIds.smx)
    //             .subscribe({
    //                 next: (newMapId: string) => {
    //                     console.log('new map id: ' + newMapId);
    //                     this.onMapCopied.next(newMapId);
    //                 },
    //                 error: (error: any) => {
    //                     if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
    //                         this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
    //                         });
    //                     } else {
    //                         this.mapStateService.setBusyState(false);
    //                         const errorCode = (error.code) ? error.code : error.statusText;
    //                         this.mapStateService.setStateError(errorCode);
    //                     }
    //                 }
    //             });
    //     });
    // }

}
