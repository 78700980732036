import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, Observable, of, Subscriber } from 'rxjs';
import { FirebaseStorageUrlResolver } from 'src/app/core/firebase/firebase-storage-url-resolver';
import { FirebaseService } from 'src/app/core/firebase/firebase.service';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';


@Injectable({
    providedIn: 'root'
})
export class InputDialogService {



    constructor(private dialog: MatDialog, private httpClient: HttpClient, private firebaseService: FirebaseService, private translateService: TranslateService) {
        this.dialog = dialog;
    }

    public askName(title: string, message: string, name: string, mapId: string, urlToOpen: string, checkMsg?: string, preview?: string, tooltip?: string): Observable<any> {

        const dialogRef = this.dialog.open(InputDialogComponent, { width: '600px' });
        dialogRef.componentInstance.mapId = mapId;
        if (preview) dialogRef.componentInstance.preview = preview;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.question = message;
        dialogRef.componentInstance.name = name;
        if (tooltip) {
            dialogRef.componentInstance.tooltip = tooltip;
        } else {
            dialogRef.componentInstance.tooltip = name;
        }
        dialogRef.componentInstance.urlToOpen = urlToOpen;
        if (checkMsg) {
            dialogRef.componentInstance.checkMsg = checkMsg;
        }
        return dialogRef.afterClosed();
    }

    public askUrl(title: string, message: string, name: string, mapId: string, urlToOpen: string, checkMsg?: string, preview?: string, tooltip?: string): Observable<any> {
        return this.askName(title, message, name, mapId, urlToOpen, checkMsg, preview, tooltip);
    }

    public askRestore(title: string, message: string, name: string, mapId: string, urlToOpen: string, preview?: string, tooltip?: string): Observable<any> {
        return this.askName(title, message, name, mapId, urlToOpen, '', preview, tooltip);
    }

}
