import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmeService } from '../../core/sme/sme.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { FirebaseService } from '../../core/firebase/firebase.service';
import { UsersService } from '../commands/users.service';
import { MapStateService } from '../map-state.service';
import { ChatMessageModel } from './chat.message.model';
import { MessageBoxService } from '../dialog/messagebox.service';
import { TranslateService } from '@ngx-translate/core';
import { onSnapshot } from '@angular/fire/firestore';
import { clamp } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ChatService implements OnDestroy {

    public isMapOwner: boolean;
    dbListenerChatUnsubscribeRef: any;
    dbListenerChatUnsubscribe: any;
    private isServiceInitialized: boolean = false;
    isChatPanelOpen: boolean;
    messages: Array<any>;
    items: Array<any>;
    public onChatInitialized = new EventEmitter();
    public onToggleChatPanel = new EventEmitter<boolean>();
    public onChatMessageReceived = new EventEmitter();
    public onChatCleared = new EventEmitter();
    public onUserLoadedReceived: Subscription | undefined;
    public clearChatSubscription: Subscription | undefined;
    public readingTimestamp: string;
    public numUnread = 0;
    public hasContent = false;
    private todayLabel = this.translateService.instant('CHAT_TODAY');
    private yesterdayLabel = this.translateService.instant('CHAT_YESTERDAY');

    constructor(
        private mapStateService: MapStateService,
        private firebaseService: FirebaseService,
        private authenticationService: AuthenticationService,
        private usersService: UsersService,
        private smeService: SmeService,
        private translateService: TranslateService,
        private messageBoxService: MessageBoxService,
    ) {
        this.messages = new Array<any>();
        this.items = new Array<any>();
        this.isChatPanelOpen = false;
        this.isMapOwner = false;
        this.readingTimestamp = '';
    }

    async init(mapId: string) {
        // Firestore real time chat listener
        if (!this.isServiceInitialized) {
            try {
                // Read initial reading timestamp
                const fid = (this.authenticationService.credentials?.firebaseUserId) ? this.authenticationService.credentials.firebaseUserId : '';
                this.firebaseService.readTimestamp(this.mapStateService.id, fid).then((timestamp: any) => {
                    this.readingTimestamp = timestamp.toString();
                });
                // console.log('+++++READING TIMESTAMP: ' + readingTimestamp);
                this.smeService.findOwnerIdByMapId(mapId).subscribe((result: any) => {
                    const ownerId = result.ownerId;
                    if (ownerId === this.authenticationService.credentials?.firebaseUserId) {
                        this.isMapOwner = true;
                    }
                });
                this.items = [];
                this.messages = [];
                this.dbListenerChatUnsubscribeRef = this.firebaseService.getFirestoreChatListener(mapId);
                this.dbListenerChatUnsubscribe = onSnapshot(this.dbListenerChatUnsubscribeRef, (querySnapshot: any) => {
                    this.receiveChatEvents(querySnapshot);
                });
                this.isServiceInitialized = true;
            } catch (err) {
                console.log(`[MapEdit] FirestoreDB onSnapshot chat ERROR: ${err}`);
            }
            this.onUserLoadedReceived = this.usersService.onUsersLoaded.subscribe(() => {
                this.applyUsersColor();
                setTimeout(() => {
                    this.applyUsersColor();
                }, 1000);
            });
        }
    }

    ngOnDestroy(): void {
        this.items = [];
        this.messages = [];
        if (this.clearChatSubscription) { this.clearChatSubscription.unsubscribe(); }
    }

    clearChat() {
        if (this.hasContent) {
            const title = this.translateService.instant('WARNING');
            const message = this.translateService.instant('ASK_CLEAR_CHAT');
            if (this.clearChatSubscription) { this.clearChatSubscription.unsubscribe(); }
            this.clearChatSubscription = this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.YESNO, title, message).subscribe((result: any) => {
                if (result === 'yes') {
                    this.readingTimestamp = '';
                    this.messages = [];
                    this.items = [];
                    this.hasContent = false;
                    this.numUnread = 0;
                    this.onChatCleared.emit();
                    setTimeout(() => {
                        this.deleteChat(this.mapStateService.id);
                    }, 100);
                }
            });
        }
    }


    deleteChat(mapId: string) {
        // const db = this.firestore
        // const collChat = collection(db, this.firebaseService.COLLECTION.CHAT);
        // const docref = doc(collChat, mapId);
        // const subcoll = collection(docref, this.firebaseService.SUBCOLLECTION.TIMESTAMPS);
        // let collpath = this.firebaseService.COLLECTION.CHAT + '/' + this.firebaseService.SUBCOLLECTION.TIMESTAMPS;
        this.smeService.deleteChat(mapId, 10).subscribe((result: any) => {
            console.log('chat deleted!!');
        });
        // this.firebaseService.deleteAtPath(collpath);


        // collpath = this.firebaseService.COLLECTION.CHAT + '/' + this.firebaseService.SUBCOLLECTION.MESSAGES
        // const submesscoll = collection(docref, this.firebaseService.SUBCOLLECTION.MESSAGES);
        // // this.smeService.deleteCollection(db, collpath, 10);
        // this.firebaseService.deleteAtPath(collpath);



        // const collectionRefTimestamps = db.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.TIMESTAMPS);
        // this.deleteCollection(db, collectionRefTimestamps, 10);
        // const collectionRefMessages = db.collection(this.COLLECTION.CHAT).doc(mapId).collection(this.SUBCOLLECTION.MESSAGES);
        // this.deleteCollection(db, collectionRefMessages, 10);
    }


    applyUsersColor() {
        for (let i = 0; i < this.messages.length; i++) {
            const message = this.messages[i];
            const user = this.usersService.findUserByEmail(message.email);
            if (user && message.email === user.user.email) {
                message.color = user.user.color;
                message.loggedIn = true;
            } else {
                message.color = '#000000';
                message.loggedIn = false;
            }
        }
        this.buildItemsList();
    }

    buildItemsList() {
        const me = this.authenticationService.getUserEmail();
        this.items = [];
        let lastDate = '';
        let lastEmail = '';
        let unreadInserted = false;
        this.numUnread = 0;
        for (let i = 0; i < this.messages.length; i++) {
            const msg = this.messages[i];
            let type = '';
            let item;
            const date = msg.timestamp.substr(0, 10);
            let dateInserted = false;
            // Marcatore di messaggi non letti
            if (!unreadInserted && (msg.timestamp > this.readingTimestamp)) {
                item = {
                    type: 'unread',
                    loggedIn: true
                };
                unreadInserted = true;
                this.items.push(item);
            }
            // Data di separazione
            if (date > lastDate) {
                const shownDate = this.getShownDate(date);
                item = {
                    type: 'date',
                    date: shownDate,
                    loggedIn: true
                };
                lastDate = date;
                dateInserted = true;
                this.items.push(item);
            }
            // Message
            if (dateInserted || msg.email !== lastEmail) {
                type = (msg.email === me ? 'full-me' : 'full-other');
            } else {
                type = (msg.email === me ? 'short-me' : 'short-other');
            }
            lastEmail = msg.email;
            item = {
                type: type,
                guid: msg.guid,
                timestamp: msg.timestamp,
                email: msg.email,
                icon: msg.icon,
                color: msg.color,
                name: msg.name,
                loggedIn: (msg.loggedIn || type === 'short'),
                message: msg.message
            };
            this.items.push(item);
            if (unreadInserted) {
                this.numUnread++;
                this.numUnread = clamp(this.numUnread, 0, 99);
            }
        }
        this.hasContent = (this.items.length > 0);
    }

    receiveChatEvents(querySnapshot: any) {
        try {
            console.log('Listening to the projectTransactions chat collection');
            const promises: Array<any> = [];
            const events: Array<any> = [];
            querySnapshot.docChanges().forEach((change: any) => {
                // A new transaction has been added
                if (change.type === 'added') { // type can also be 'removed' or 'modified'
                    // console.log(`----->LISTENER: A new transaction has been ADDED with ID: ${change.doc.id}`);
                    const promise = this.firebaseService.getChatMessage(this.mapStateService.id, change.doc.id, events);
                    promises.push(promise);
                }
            });
            // Get all the events
            Promise.all(promises).then(async () => {
                // Sort events in timestamp ascending order
                events.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
                this.applyChatEvents(events);
                setTimeout(() => {
                    this.onChatInitialized.emit();
                    this.onChatMessageReceived.emit();
                }, 100);
            });
        } catch (err) {
            console.log(`[MapEdit] FirestoreDB receiveChatEvents ERROR: ${err}`);
        }
    }

    applyChatEvents(events: Array<any>) {
        if (events && events.length > 0) {
            for (let i = 0; i < events.length; i++) {
                // Get command from the event
                const event = events[i];
                if (event.type === 'chat') {
                    // console.log(`CHAT EVENT RECEIVED: ${JSON.stringify(event)}`);
                    // Trasforma messaggio Raw (compreso colore utente)
                    const payloadjs = (event.payload ? JSON.parse(event.payload) : {});
                    const user = this.usersService.findUserByEmail(event.user.email);
                    const loggedIn = (user ? true : false);
                    const color = (user ? user.user.color : '#000000');
                    const msg = {
                        guid: event.guid,
                        timestamp: payloadjs.timestamp,
                        color: color,
                        email: event.user.email,
                        icon: event.user.icon,
                        name: event.user.name,
                        message: payloadjs.message,
                        loggedIn: loggedIn
                    };
                    // Aggiungi messaggio in lista locale
                    this.messages.push(msg);
                    // Notifica messaggio (ricevuto se chat è aperta)
                }
            }
            this.buildItemsList();
            this.onChatMessageReceived.emit();
        }
    }

    sendMessage(message: ChatMessageModel) {
        if (message) {
            try {
                const userEmail = this.authenticationService.getUserEmail();
                const userName = this.authenticationService.getUserName();
                const userIcon = this.authenticationService.getUserImageUrl();
                const payloadjs = {
                    timestamp: message.timestamp,
                    message: message.text
                };
                const payload = JSON.stringify(payloadjs);
                return this.firebaseService.addChatMessage(this.mapStateService.guid, 'chat', userEmail, userName, userIcon, this.mapStateService.id, payload);
            } catch (err) {
                console.log(`[MapEdit] FirestoreDB addMapEvent (chat) ERROR: ${err}`);
                return null;
            }
        } else {
            return null;
        }
    }

    toggleChatPanel() {
        this.isChatPanelOpen = !this.isChatPanelOpen;
        console.log('Toggle Chat: ' + this.isChatPanelOpen);
        if (this.isChatPanelOpen) {
            this.buildItemsList();
            if (this.items.length > 0) {
                this.readingTimestamp = this.items[this.items.length - 1].timestamp;
            }
        }
        this.onToggleChatPanel.emit(this.isChatPanelOpen);
        this.numUnread = 0;
        return this.isChatPanelOpen;
    }

    public resizeChatPanel() {
        this.onToggleChatPanel.emit(this.isChatPanelOpen);
    }

    updateReadingTimestamp(timestamp: string) {
        if (timestamp) {
            this.readingTimestamp = timestamp;
            if (this.authenticationService.credentials?.firebaseUserId) {
                this.firebaseService.writeTimestamp(this.mapStateService.id, this.authenticationService.credentials.firebaseUserId, timestamp);
            }
        }
    }

    private padZero(s: string, len: number) {
        let res = '';
        res += s;
        while (res.length < len) {
            res = '0' + res;
        }
        return res;
    }

    private getShownDate(date: string) {
        let result = '';
        const today = new Date();
        const todayString = today.toISOString().substr(0, 10);
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const yesterdayString = yesterday.toISOString().substr(0, 10);
        const dateString = date.substr(0, 10);
        if (dateString === todayString) {
            result = this.todayLabel;
        } else if (dateString === yesterdayString) {
            result = this.yesterdayLabel;
        } else {
            const dt = new Date(date);
            const dd = dt.getDate().toString();
            const mm = (dt.getMonth() + 1).toString();
            const yyyy = dt.getFullYear().toString();
            result = this.padZero(dd, 2) + '/' + this.padZero(mm, 2) + '/' + this.padZero(yyyy, 4);
        }
        return result;

    }


}
