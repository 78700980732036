export class MathPrefsDto {
    public keyboardLayouts: string[];

    constructor(json: any = {}) {
        this.keyboardLayouts = (json && json.keyboardLayouts) ?
            json.keyboardLayouts :
            [];
        // ['BASIC', 'SETS', 'GEOMETRY', 'DISEQUATIONS', 'PHYSICS', 'ANALYSIS', 'TRIGONOMETRY', 'LOG_EXP', 'GREEK'];
    }
}
