import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { ShareMapService } from '../share-map/share-map.service';


declare let gapi: any;

@Component({
  selector: 'app-google-classroom',
  templateUrl: './google-classroom.component.html',
  styleUrls: ['./google-classroom.component.scss']
})
export class GoogleClassroomComponent implements OnInit, OnDestroy {

  private urlToShare = '';
  private callbackFunction: any;
  private onIdChangeSubscription: Subscription | undefined;

  constructor(private shareMapService: ShareMapService) { }

  renderWidget() {
    (<any>window).___gcfg = {
      parsetags: 'explicit'
    };
    gapi.sharetoclassroom.render('classroom-div',
      { 'url': this.urlToShare, 'onsharestart': () => { this.callbackFunction(); } });
    // { 'url': this.urlToShare, 'onsharestart': () => { this.shareMapService.callbackShareGC(); } });

    // { 'url': this.urlToShare, 'theme': 'light' });
  }

  init(googleUri: string, callback: any) {
    this.urlToShare = googleUri;
    this.callbackFunction = callback;
    this.renderWidget();
  }


  ngOnInit() {
    this.onIdChangeSubscription = this.shareMapService.onIdChange.subscribe(
      (data: any) => {
        this.init(data.uri, data.callback);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.onIdChangeSubscription) { this.onIdChangeSubscription.unsubscribe(); }
  }
}
