import { RecentMapDto } from "./recent-map-dto";

export enum RECENT_ACTION { NONE, TRASH, UNTRASH, REMOVE, DELETE }

export class RecentActionDto {

    public recentMapDto: RecentMapDto | null = null;
    public recentAction: RECENT_ACTION = RECENT_ACTION.NONE;

    constructor(recentMapDto: RecentMapDto, recentAction: RECENT_ACTION) {
        this.recentMapDto = recentMapDto;
        this.recentAction = recentAction;
    }

}
