import { VirtualKeyboardLayout } from 'mathlive';

export const IT_TRIGONOMETRY_KEYBOARD: VirtualKeyboardLayout = {
    id: "TRIGONOMETRY",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                latex: '\\sin(\\placeholder{})',
                tooltip: 'seno'
            },
            {
                latex: '\\mbox{sen}(\\placeholder{})',
                tooltip: 'seno'
            },
            {
                latex: '\\cos(\\placeholder{})',
                tooltip: 'coseno'
            },
            {
                latex: '\\tan(\\placeholder{})',
                tooltip: 'tangente'
            },
            {
                latex: '\\sec(\\placeholder{})',
                tooltip: 'secante'
            },
            {
                latex: '\\csc(\\placeholder{})',
                tooltip: 'cosecante'
            },
            {
                latex: '\\cot(\\placeholder{})',
                tooltip: 'cotangente'
            }
        ],
        // Second row
        [
            {
                class: 'small',
                latex: '\\arcsin(\\placeholder{})',
                tooltip: 'arcoseno'
            },
            {
                class: 'small',
                latex: '\\arccos(\\placeholder{})',
                tooltip: 'arcocoseno'
            },
            {
                class: 'small',
                latex: '\\arctan(\\placeholder{})',
                tooltip: 'arcotangente'
            },
            {
                class: 'small',
                latex: '\\mbox{arccot}(\\placeholder{})',
                tooltip: 'arcocotangente'
            }
        ],
        // Third row
        [
            {
                latex: '\\alpha',
                tooltip: '"alfa'
            },
            {
                latex: '\\beta',
                tooltip: 'beta'
            },
            {
                latex: '\\gamma',
                tooltip: 'gamma'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
