<div class="header-container">
    <div *ngIf="!compressLogo" class="smx-logo-extended" routerLink="/home" translate>
        <img class="smx-logo-image-extended" src="{{logoUrlExtended}}?v=1" />
    </div>
    <div *ngIf="compressLogo" class="smx-logo-compressed" routerLink="/home" translate>
        <img class="smx-logo-image-compressed" src="{{logoUrlCompressed}}?v=1" />
    </div>
    <div *ngIf="showMapComponents" class="search-bar-container">
        <div *ngIf="!compressHeader" [hidden]="noMap" class="search-bar search-bar-limited">
            <div class="search-button" matTooltip="{{'SEARCH_MAP_PLACEHOLDER' | translate}}" (click)="searchClick(textSearch);">
                <mat-icon color="primary">search</mat-icon>
            </div>
            <input class="search-input" type="text" placeholder="{{'SEARCH_MAP_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" (change)="searchClick($event)" (keyup)="searchClick($event);" value="{{textSearch}}" />
            <div *ngIf="textSearch.length!==0" class="search-clear-button" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="clearSearch()">
                <mat-icon color="primary" fontSet="material-icons-outlined">close</mat-icon>
            </div>
        </div>
    </div>
    <div *ngIf="showMapComponents" class="tools-container">
        <div *ngIf="adminUser">
            <button color="primary" mat-icon-button matTooltip="{{'HEADER_ADMIN' | translate}}" (click)="accessConfig()">
                <mat-icon class="material-icons-sharp" fontSet="material-icons-filled" color="primary">settings</mat-icon>
            </button>
        </div>
        <button *ngIf="isNotifLangSupported && numNewNotifications===0" mat-icon-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="notificationsMenu" matTooltip="{{'NOTIFICATIONS' | translate}}">
            <mat-icon fontSet="material-icons-outlined">notifications</mat-icon>
        </button>
        <button *ngIf="isNotifLangSupported && numNewNotifications>0" class="notification" (menuClosed)="menuNotifsClosed()" color="primary" mat-icon-button [matMenuTriggerFor]="notificationsMenu" matTooltip="{{'NOTIFICATIONS' | translate}}">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled" color="primary">notifications</mat-icon>
            <span class="badge"></span>
            <span class="badge-num">{{numNewNotifications}}</span>
        </button>
        <button *ngIf="isGoogleUser" [disabled]="!hasUserValidSubscription" color="primary" mat-icon-button matTooltip="{{'OPEN_FROM_LOCAL' | translate}}" [matMenuTriggerFor]="openMapMenu">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">upload</mat-icon>
        </button>
        <button *ngIf="!isGoogleUser" [disabled]="!hasUserValidSubscription" color="primary" mat-icon-button matTooltip="{{'OPEN_FROM_LOCAL' | translate}}" (click)="file.click()">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">upload</mat-icon>
        </button>
        <button *ngIf="compressHeader" color="warn" mat-icon-button [disabled]="!hasUserValidSubscription" (click)="newMap()" matTooltip="{{'NEW_MAP' | translate}}">
            <mat-icon fontSet="material-icons-outlined">add_circle</mat-icon>
        </button>
        <button *ngIf="!compressHeader" color="warn" mat-flat-button [disabled]="!hasUserValidSubscription" (click)="newMap()">
            <mat-icon fontSet="material-icons-outlined">add</mat-icon>
            {{'NEW_MAP' | translate}}</button>
        <button class="user-button" color="primary" mat-icon-button [matMenuTriggerFor]="userMenu" matTooltip="{{username}} ({{userEmail}})">
            <!-- <mat-icon *ngIf="!userImageUrl">account_circle</mat-icon> -->
            <img *ngIf="userImageUrl" class="img-user-small" [src]="userImageUrl" />
            <div class="user-initials-container" *ngIf="!userImageUrl">
                <div class="initials-text"> {{mapStateService.getInitials()}}</div>
            </div>
        </button>
        <mat-menu #notificationsMenu="matMenu" class="notifications-menu">
            <div *ngIf="!notifsEmpty" class="notifications-container">
                <div *ngFor="let notification of notifications">
                    <app-menu-notification [notification]="notification" [menuTrigger]="menuTrigger"></app-menu-notification>
                </div>
            </div>
            <div *ngIf="notifsEmpty" class="notifications-empty-container">
                <div class="notifications-empty-title" translate=>NOTIFICATIONS_EMPTY</div>
                <img class="notifications-empty-image" src="../../assets/notifications/nessunaNotifica.png" />
            </div>
            <div *ngIf="!isNotificationsExpanded && !notifsEmpty" class="notifications-footer" translate (click)="$event.stopPropagation(); onFooterNotificationClick();">
                NOTIFICATIONS_FOOTER_BUTTON
            </div>
        </mat-menu>
        <mat-menu #openMapMenu="matMenu">
            <div mat-menu-item (click)="(file.click())">
                <mat-icon color="primary"><span class="material-symbols-outlined">
                        computer
                    </span></mat-icon>
                <span class="custom-span" translate>{{'OPEN_PDF' | translate}}</span>
            </div>
            <div mat-menu-item *ngIf="isGoogleUser" color="primary" mat-icon-button matTooltip="{{'OPEN_FROM_DRIVE' | translate}}" (click)="openDrive()">
                <mat-icon color="primary" svgIcon="folder-google-drive"></mat-icon>
                <span class="custom-span" translate>{{'OPEN_FROM_DRIVE' | translate}}</span>
            </div>

        </mat-menu>
        <mat-menu #userMenu="matMenu">
            <div class="user-details-container">
                <div class="user-details">
                    <img class="user-image" *ngIf="userImageUrl" [src]="userImageUrl" (click)="goProfile()" />
                    <div class="container" *ngIf="!userImageUrl" (click)="goProfile()">
                        <div class="avatar"> {{mapStateService.getInitials()}}</div>


                    </div>
                    <div class="user-name-container">
                        <div class="user-detail"><b>{{username}}</b></div>
                        <div class="user-detail">({{userEmail}})</div>
                    </div>
                </div>
            </div>
            <hr />
            <button mat-menu-item color="primary" (click)="goProfile()">
                <mat-icon color="primary">settings</mat-icon>
                <span color="primary" translate>MAIN_MENU_FILE-PREFERENCES</span>
            </button>
            <!-- <div class="user-drive-container">
                <div class="user-drive-usage" translate>DRIVE_USAGE</div>
                <mat-progress-bar mode="determinate" value="{{driveUsageSpace}}" [color]="getDriveProgressBarColor(driveUsageSpace)"></mat-progress-bar>
            </div> -->

            <hr />
            <button mat-menu-item color="primary" (click)="manual()">
                <mat-icon color="primary">subject</mat-icon>
                <span color="primary" translate>HEADER_MANUAL</span>
            </button>
            <button mat-menu-item color="primary" (click)="accessStats()">
                <!--<mat-icon color="primary" svgIcon="chart-line"></mat-icon>-->
                <mat-icon color="primary" fontSet="material-icons-outlined">analytics</mat-icon>
                <span color="primary" translate>SHOW_STATS</span>
            </button>
            <!-- <button mat-menu-item color="primary" (click)="openIntroMap()">
                <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
                <span color="primary" translate>HEADER_MAPINTRO</span>
            </button> -->
            <button mat-menu-item color="primary" (click)="openFaq()">
                <mat-icon fontSet="material-icons-outlined" color="primary">help</mat-icon>
                <span translate>HEADER_FAQ</span>
            </button>
            <button mat-menu-item color="primary" (click)="about()">
                <mat-icon color="primary">info</mat-icon>
                <span color="primary" translate>HEADER_ABOUT</span>
            </button>
            <button mat-menu-item color="primary" (click)="aboutDevice()">
                <mat-icon color="primary" fontSet="material-icons-outlined">devices</mat-icon>
                <span color="primary" translate>HEADER_ABOUT_DEVICE</span>
            </button>
            <button mat-menu-item color="primary" (click)="privacy()">
                <mat-icon color="primary">announcement</mat-icon>
                <span color="primary" translate>HEADER_PRIVACY</span>
            </button>
            <hr />
            <div class="user-logout-container">
                <button mat-menu-item (click)="logout()">
                    <span color="primary" translate>HEADER_LOGOUT</span>
                </button>
                <div *ngIf="isGoogleUser" class="user-logout-info" disabled translate>HEADER_LOGOUT_SUB</div>
            </div>
        </mat-menu>
    </div>
    <input type="file" class="input-hidden" #file accept=".sme" (change)="openMap($event)" />
</div>
<div *ngIf="compressHeader && showMapComponents" [hidden]="noMap" class="header-under-container">
    <div class="search-bar-container">
        <div class="search-bar">
            <div class="search-button" matTooltip="{{'SEARCH_MAP_PLACEHOLDER' | translate}}" (click)="searchClick(textSearch);">
                <mat-icon color="primary">search</mat-icon>
            </div>
            <input class="search-input" type="text" placeholder="{{'SEARCH_MAP_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" (change)="searchClick($event)" (keyup)="searchClick($event);" value="{{textSearch}}" />
            <div *ngIf="textSearch.length!==0" class="search-clear-button" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="clearSearch()">
                <mat-icon color="primary" fontSet="material-icons-outlined">close</mat-icon>
            </div>
        </div>
    </div>
</div>
