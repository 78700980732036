import { Guid } from 'guid-typescript';

export class MapOperation {
  public guid = '';
  public date: Date | undefined;
  public email = '';
  public id = '';
  public num = 0;

  constructor() {
    this.guid = Guid.create().toString();
  }
}

export class MapOperationType {
  public static readonly STAT_NEWMAP = 'STAT_NEWMAP';
  public static readonly STAT_OPENMAP = 'STAT_OPENMAP';
  public static readonly STAT_SHOW = 'STAT_SHOW';
  public static readonly STAT_SHAREFB = 'STAT_SHAREFB';
  public static readonly STAT_SHARELINK = 'STAT_SHARELINK';
  public static readonly STAT_SHARECLASSROOM = 'STAT_SHARECLASSROOM';
  public static readonly STAT_GDOCCREATED = 'STAT_GDOCCREATED';
  public static readonly STAT_PRINT = 'STAT_PRINT';
  public static readonly STAT_ADDNODE = 'STAT_ADDNODE';
  public static readonly STAT_ADDEDGE = 'STAT_ADDEDGE';
  public static readonly STAT_ADDIMAGE = 'STAT_ADDIMAGE';
  public static readonly STAT_ADDLINK = 'STAT_ADDLINK';
  public static readonly STAT_ADDTABLE = 'STAT_ADDTABLE';
  public static readonly STAT_ADDVIDEO = 'STAT_ADDVIDEO';
  public static readonly STAT_WORDS = 'STAT_WORDS';
  public static readonly STAT_COPYSHARED = 'STAT_COPYSHARED';
  public static readonly STAT_VIEWSHARED = 'STAT_VIEWSHARED';
}

export class MapOperations {

  private _userEMail: string;
  private operations: Array<MapOperation> | undefined;
  private lastSaveRequestIndex = -1;

  constructor(userEMail: string) {
    this._userEMail = userEMail;
    this.clear();
  }

  public clear() {
    this.operations = new Array<MapOperation>();
    this.lastSaveRequestIndex = -1;
  }

  public add(operationId: string, opNum = 1, refOtherUserEMail = '') {
    const eMail = (refOtherUserEMail !== '' ? refOtherUserEMail : this._userEMail);
    const operation = new MapOperation();
    operation.date = new Date();
    operation.email = eMail;
    operation.id = operationId;
    operation.num = opNum;
    this.operations?.push(operation);
  }

  public getData() {
    return this.operations;
  }

}
