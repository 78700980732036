import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { MapEditComponent } from './map-edit.component';
import { ConfirmationService } from '../shared/dialog/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../core/i18n.service';
import { MapStateService } from '../shared/map-state.service';


@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard {

    mapEditComp: MapEditComponent | undefined;
    constructor(
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private mapStateService: MapStateService) {
    }

    canDeactivate(component: MapEditComponent) {
        if (this.mapStateService.isDeactivateDialogOpen) {
            console.warn('+++++canDeactivate: double EXIT_MESSAGE skipped');
        }
        // Allow navigation if the form is unchanged
        if (!component.mapStateService.isDirty || component.mapStateService.forceExit) {
            return true;
        } else if (this.mapStateService.isDeactivateDialogOpen) {
            return false;
        }
        this.mapEditComp = component;
        //  const confirmationMessage = '\o/';

        this.mapStateService.isDeactivateDialogOpen = true;
        return new Observable((observer: Observer<boolean>) => {
            // UnsavedChangesDialog defined somewhere else and imported above
            this.confirmationService.confirmExit(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('EXIT_MESSAGE')),
                this.translateService.instant(extract('EXIT')),
                this.translateService.instant(extract('WAIT')))
                .subscribe({
                    next: result => {
                        this.mapStateService.isDeactivateDialogOpen = false;
                        if (result === undefined) {
                            result = false;
                        } else {
                            if (!result) {
                                this.mapEditComp?.autosave();
                            }
                        }
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error) => {
                        this.mapStateService.isDeactivateDialogOpen = false;
                        observer.next(false);
                        observer.complete();
                    }
                });
        });
    }

}
