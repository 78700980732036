export class SmxFontStyle {
  public effect: string;
  public font: string;
  public size: string;
  public color: string;

  constructor(effect?: string, font?: string, size?: string, color?: string) {
    this.effect = effect || 'PREF_EFFECT_NONE';
    this.font = font || 'Arial';
    this.size = size || '14';
    this.color = color || '#000000';
  }
}
