import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, inject, HostListener } from '@angular/core';
import { RecentMapDto } from './recent-map-dto';
import { SmeService } from '../..//core/sme/sme.service';
import { Router } from '@angular/router';
import { Logger } from '../../core/logger.service';
import { Subscription, Observable, Observer } from 'rxjs';
import { FirebaseAuthService } from '../../core/firebase/firebase-auth.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { FirebaseService } from '../../core/firebase/firebase.service';
import { UserPreferenceService } from '../../shared/user-preference.service';
import { UserPrefsDto } from '../../shared/users/user-prefs-dto';
import { UiConstants } from '../../shared/ui-constants';
import { ConfirmationService } from '../../shared/dialog/confirmation.service';
import { InputDialogService } from '../../shared/dialog/input-dialog.service';
import { CopyAndOpenMapService } from '../../shared/commands/copy-and-open-map.service';
import { TranslateService } from '@ngx-translate/core';
import { ShareMapService } from '../../shared/share-map/share-map.service';
import { MapStateService } from '../../shared/map-state.service';
import { MessageBoxService } from '../../shared/dialog/messagebox.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { onSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { RECENT_ACTION, RecentActionDto } from './recent-action-dto';

import * as FileSaver from 'file-saver';

const logger: Logger = new Logger('RecentComponent');

export function extract(s: string) {
    return s;
}

@Component({
    selector: 'app-recent',
    templateUrl: './recent.component.html',
    styleUrls: ['./recent.component.scss']
})

export class RecentComponent implements OnInit, OnDestroy {

    //DEBUG variables
    private FAKE_TRASH: boolean = false;
    ////////////

    //LAYOUT variables
    public isHeaderCompressed = false;
    public isListCompressed = false;
    ////////////

    private t1: Date | undefined;
    private t1Desc = '';
    private t2: Date | undefined;

    @Input() loadingMap = false;
    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();
    @Output() errorRecent = new EventEmitter<any>();

    private Guid: string;
    public recentPreview = true;
    private allMaps: RecentMapDto[] = new Array<RecentMapDto>();
    public recentMaps: RecentMapDto[] = new Array<RecentMapDto>();
    private noImageUrl = '';
    public headerBackgroundColor: string;
    // private endLoadRecent = new EventEmitter<RecentMapDto>();

    noMapText = this.translateService.instant("NO_MAPS_ALL");
    recentMap: RecentMapDto | undefined;

    public isUserExpired = false;
    currentUser = '';
    public recentMapsSorted: RecentMapDto[] = new Array<RecentMapDto>();
    public recentMapsSortedFiltered: RecentMapDto[] = new Array<RecentMapDto>();
    public isRecentsLoading = false;
    public isRecentsDataLoading = false;
    public defaultSortRecentsBy = '';
    private searchFilter = '';
    public numMap = '';
    public noMap = false;
    public currentRecentsStyle: string = 'preview';
    private itemMenuClicked = false;
    private matSnackbarRef: MatSnackBarRef<any> | undefined;
    private snackBarActionPressed = false;
    private pendingActions: Array<RecentActionDto> = [];
    private isSnackbarEnabled = true;
    private isSnackbarOpen = false;
    public expirationDate?: Date;

    getUserPreferencesSubscription: Subscription | undefined;
    getRecentMapsSubscription: Subscription | undefined;
    getRecentDataSubscription: Subscription | undefined;
    getRecentsDataSubscription: Subscription | undefined;
    updateUserPreferenceSubscription: Subscription | undefined;
    removeRecentSubscription: Subscription | undefined;
    trashRecentSubscription: Subscription | undefined;
    recentSearchSubscription: Subscription | undefined;
    stopSharingSubscription: Subscription | undefined;
    receivedStopSharingSubscription: Subscription | undefined;
    startSharingSubscription: Subscription | undefined;
    renameMapSubscription: Subscription | undefined;
    smeServiceRenameMapSubscription: Subscription | undefined;
    askNameSubscription: Subscription | undefined;
    downloadJsonSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;
    refreshRecentSharingSubscription: Subscription | undefined;
    getShareDataSubscription: Subscription | undefined;
    dbListenerSharedStateChange: any;
    dbListenerSharedStateChangeSnap: any;

    firstTimer: any;
    isFirstFirestoreEvent = true;
    currentRecentGroup = '';
    mapTab: any;
    emptyTrashEnabled = false;

    constructor(
        private router: Router,
        private smeService: SmeService,
        private firebaseService: FirebaseService,
        private userPreferenceService: UserPreferenceService,
        private authenticationService: AuthenticationService,
        private copyAndOpenMapService: CopyAndOpenMapService,
        private shareMapService: ShareMapService,
        private mapStateService: MapStateService,
        private confirmationService: ConfirmationService,
        private messageBoxService: MessageBoxService,
        private inputDialogService: InputDialogService,
        private translateService: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private firebaseAuthService: FirebaseAuthService,
        private _snackBar: MatSnackBar
    ) {
        this.Guid = crypto.randomUUID();
        (window as any).recents = this;
        this.headerBackgroundColor = (this.authenticationService.isLab() ? '#F9C091' : '#96C9CE');

        this.mapStateService.currentRecentGroup = this.currentRecentGroup;
        this.firebaseAuthService.auth.onAuthStateChanged((user) => {
            if (user != null && this.authenticationService.getUserEmail() !== user.email) {
                this.getRecentsFromServer();
            }
        });
        this.breakpointObserver
            .observe(['(max-width:800px)'])
            .subscribe((state: BreakpointState) => {
                this.isHeaderCompressed = state.matches;
            })

        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.isListCompressed = state.matches;
            })
    }

    // private timeStart(desc: string) {
    //     this.t1Desc = desc;
    //     this.t1 = new Date();
    //     console.log(`+++++++++++++++Timer started: ${this.t1Desc}`);
    // }
    // private timeStop() {
    //     this.t2 = new Date();
    //     if (this.t1) {
    //         const secs = (this.t2.getTime() - this.t1.getTime()) / 1000;
    //         console.log(`+++++++++++++++Timer stopped:${this.t1Desc} Elapsed time: ${secs} sec.`);
    //     }
    // }

    ngOnInit() {
        this.initialize();


    }

    initialize() {
        // JHUBA ERROR DEBUG---------------------------------------------------
        // const error = { code: 'cippo', status: 503 };
        // logger.error('getUserPreference().subscribe: ' + error);
        // this.loadingMap = false;
        // this.errorRecent.emit((error.code) ? error.code : error.status);
        // JHUBA ERROR DEBUG---------------------------------------------------
        this.isUserExpired = this.authenticationService.isUserExpired();
        this.currentUser = this.authenticationService.getUserEmail();
        this.expirationDate = new Date(this.authenticationService.getExpiryDate());

        this.loadingMap = false;
        this.searchFilter = '';
        this.defaultSortRecentsBy = 'date';
        // this.userPreferenceService.getUserPreference();
        if (this.getUserPreferencesSubscription) this.getUserPreferencesSubscription.unsubscribe();
        this.getUserPreferencesSubscription = this.smeService.getUserPreferences().subscribe((resp: any) => {
            let res = '';
            if (resp.result) {
                res = resp.result;
            }
            const userPrefs: UserPrefsDto = new UserPrefsDto(res);
            if (this.userPreferenceService) {
                this.userPreferenceService.userPrefs = userPrefs;
            }
            this.currentRecentGroup = userPrefs.groupId;
            this.mapStateService.currentRecentGroup = userPrefs.groupId;
            this.recentPreview = (userPrefs.recentsViewMode === 'preview');
            this.currentRecentsStyle = userPrefs.recentsViewMode;

            this.defaultSortRecentsBy = userPrefs.sortRecentsBy;

            // set default font styles
            UiConstants.nodeFontStyle = userPrefs.nodeTextStyle;
            UiConstants.edgeFontStyle = userPrefs.edgeTextStyle;
            UiConstants.deepFontStyle = userPrefs.deepTextStyle;
            UiConstants.noteFontStyle = userPrefs.noteTextStyle;
            UiConstants.nodeAspect = userPrefs.nodeAspect;
            UiConstants.edgeAspect = userPrefs.edgeAspect;

            this.getRecents(this.currentRecentGroup, true);
        }, (error: any) => {
            logger.error('getUserPreference().subscribe: ' + error);
            this.loadingMap = false;
            this.errorRecent.emit((error.code) ? error.code : error.status);
        });

        // this.endLoadRecentSubscription = this.endLoadRecent.subscribe((recent: RecentMapDto) => {
        //   // JHUBA: DA RIMETTERE PER SHARED DATA!!! ERRORE QUOTE GOOGLE DRIVE!!!
        //   // this.getRecentData(recent);
        // });

        if (this.recentSearchSubscription) this.recentSearchSubscription.unsubscribe();
        this.recentSearchSubscription = this.userPreferenceService.onRecentSearch.subscribe((searchText: string) => {
            this.searchFilter = searchText.toLowerCase();
            this.applyFilter();
        });

        if (this.receivedStopSharingSubscription) this.receivedStopSharingSubscription.unsubscribe();
        this.receivedStopSharingSubscription = this.mapStateService.stopSharing.subscribe((mapId: string) => {
            this.mapStateService.refreshRecentSharing.emit(mapId);
        });

        if (this.refreshRecentSharingSubscription) this.refreshRecentSharingSubscription.unsubscribe();
        this.refreshRecentSharingSubscription = this.mapStateService.refreshRecentSharing.subscribe(async (mapId: string) => {
            for (const recent of this.allMaps) {
                if (recent.id === mapId) {
                    this.smeService.getShareData(
                        this.authenticationService.credentials?.username + '',
                        this.authenticationService.credentials?.firebaseUserId + '',
                        recent.id
                    ).subscribe({
                        next: (res: any) => {
                            if (res && res.shareData) {
                                recent.isMine = res.shareData.isMine;
                                recent.isShared = res.shareData.isShared;
                                recent.readonly = res.shareData.isReadonly;
                                recent.isPubliclyShared = res.shareData.isPubliclyShared;
                                recent.shareType = res.shareData.shareType;
                            }
                        },
                        error: (err: any) => {
                            console.error('shareData error: ' + JSON.stringify(err));
                        }
                    });
                }
            }
        });

        // Firestore real time events listener
        try {
            const email = this.authenticationService.credentials?.username + '';
            this.dbListenerSharedStateChange = this.firebaseService.getFirestoreSharedStateChangeListener(email);
            this.dbListenerSharedStateChangeSnap = onSnapshot(this.dbListenerSharedStateChange, (querySnapshot: QuerySnapshot) => {
                this.receiveMessages(querySnapshot);
            });
        } catch (err) {
            console.log(`[Recent] FirestoreDB onSnapshot maps ERROR: ${err}`);
        }

    }

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.currentUser !== this.firebaseAuthService.auth.currentUser?.email) {
            {
                this.initialize();

                this.getRecentsFromServer();
            }
        }

    }

    // getRecentData(recent: RecentMapDto) {
    //     // this.timeStart('getRecentData');
    //     // const startDelay = recent.sequence * 50;

    //     //setTimeout(() => {
    //     if (this.authenticationService.credentials) {

    //         this.smeService.getRecentData(this.authenticationService.credentials.googleUserEmail, recent).subscribe({
    //             next: (recentData: any) => {
    //                 this.updateRecentData(recent, recentData);
    //                 // this.timeStop();
    //             },
    //             error: (error => {
    //                 console.log(`+++++GETRECENTDATA ERROR: NAME: ${recent.name}, ERROR: ${error.message}`);
    //                 // this.timeStop();
    //                 // Backoff gestito lato server, no richiamata!
    //                 // setTimeout(() => { this.endLoadRecent.emit(recent); }, 1000);

    //             })
    //         });
    //     }
    //     //}, 0);

    // }

    applyFilter() {
        if (this.searchFilter === '') {
            this.recentMapsSortedFiltered = this.recentMapsSorted;
        } else {
            console.log('Apply filter: ' + this.searchFilter);
            this.recentMapsSortedFiltered = [];
            this.recentMapsSorted.forEach((map: RecentMapDto) => {
                const name = map.name.toLowerCase();
                if (name.includes(this.searchFilter)) {
                    this.recentMapsSortedFiltered.push(map);
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.getUserPreferencesSubscription) { this.getUserPreferencesSubscription.unsubscribe(); }
        if (this.getRecentMapsSubscription) { this.getRecentMapsSubscription.unsubscribe(); }
        if (this.getRecentDataSubscription) { this.getRecentDataSubscription.unsubscribe(); }
        if (this.getRecentsDataSubscription) { this.getRecentsDataSubscription.unsubscribe(); }
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        if (this.removeRecentSubscription) { this.removeRecentSubscription.unsubscribe(); }
        if (this.trashRecentSubscription) { this.trashRecentSubscription.unsubscribe(); }
        if (this.recentSearchSubscription) { this.recentSearchSubscription.unsubscribe(); }
        if (this.receivedStopSharingSubscription) { this.receivedStopSharingSubscription.unsubscribe(); }
        if (this.stopSharingSubscription) { this.stopSharingSubscription.unsubscribe(); }
        // if (this.endLoadRecentSubscription) { this.endLoadRecentSubscription.unsubscribe(); }
        if (this.startSharingSubscription) { this.startSharingSubscription.unsubscribe(); }
        if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
        if (this.refreshRecentSharingSubscription) { this.refreshRecentSharingSubscription.unsubscribe(); }
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
        if (this.dbListenerSharedStateChangeSnap) { this.dbListenerSharedStateChangeSnap(); }
    }

    public onRequestedRecentsValueChange(requestedRecents: any) {
        this.currentRecentGroup = requestedRecents;
        this.mapStateService.currentRecentGroup = this.currentRecentGroup;
        if (this.userPreferenceService && this.userPreferenceService.userPrefs) {
            this.userPreferenceService.userPrefs.groupId = this.currentRecentGroup;
        }
        this.getRecents(this.currentRecentGroup, false);
    }

    getRecents(filterGroup: string, forceReload: boolean) {
        this.currentRecentGroup = filterGroup;
        if (filterGroup === 'all') {
            this.noMapText = this.translateService.instant('NO_MAPS_ALL');
        } else if (filterGroup === 'mine') {
            this.noMapText = this.translateService.instant('NO_MAPS_MINE');
        } else if (filterGroup === 'shared') {
            this.noMapText = this.translateService.instant('NO_MAPS_OTHERS');
        } else if (filterGroup === 'trash') {
            this.noMapText = this.translateService.instant('NO_MAPS_TRASH');
        }
        this.startLoading.emit();
        this.isRecentsLoading = true;
        this.isRecentsDataLoading = true;
        if (forceReload || this.allMaps.length === 0) {
            this.getRecentsFromServer();
        } else {
            this.filterRecents(filterGroup);
        }
    }

    private filterRecents(filterGroup: string) {
        this.recentMaps = [];
        this.allMaps.forEach((recent: RecentMapDto) => {
            const isDeleted = (recent.deleteDate !== null && recent.deleteDate !== undefined && recent.deleteDate !== null);
            let addMap = false;
            switch (filterGroup) {
                case 'all':
                    addMap = !isDeleted;
                    break;
                case 'mine':
                    addMap = !isDeleted && recent.isMine;
                    break;
                case 'shared':
                    addMap = !isDeleted && recent.isShared && !recent.isMine;
                    break;
                case 'trash':
                    addMap = isDeleted;
                    break;
            }
            if (addMap) {
                this.recentMaps.push(recent);
            }
        });
        this.emptyTrashEnabled = (filterGroup === 'trash' && this.recentMaps.length > 0);
        this.noMap = this.recentMaps.length === 0;
        this.sortRecentMaps(this.defaultSortRecentsBy);
        this.applyFilter();
        this.isRecentsLoading = false;
        this.endLoading.emit(this.noMap);
    }

    public getRecentsFromServer() {
        // this.timeStart('getRecents');
        if (!navigator.onLine) {
            logger.error('getRecentMap() no connection');
            this.loadingMap = false;
            this.errorRecent.emit('GATEWAY_TIMEOUT');
        }
        if (this.authenticationService.isAuthenticated()) {
            // this.startLoading.emit();
            // this.isRecentsLoading = true;
            // this.isRecentsDataLoading = true;
            this.allMaps = [];
            // DEBUG: EMPTY DESKTOPS
            // this.filterRecents(this.currentRecentGroup);
            // this.endLoading.emit(true);
            // return;
            ////////////////////////
            this.recentMaps = [];
            let rm: RecentMapDto;
            this.firebaseService.getDownloadUri('assets/noimage.png').then((url) => {
                this.noImageUrl = url;
                if (this.getRecentMapsSubscription) { this.getRecentMapsSubscription.unsubscribe(); }
                this.getRecentMapsSubscription = this.smeService.getRecentMaps().subscribe((resp: any) => {
                    // const promises = new Array<Promise<any>>();
                    const viewStringSing = this.translateService.instant('RECENT_VIEW_SING');
                    const copyStringSing = this.translateService.instant('RECENT_COPY_SING');
                    const viewStringPlur = this.translateService.instant('RECENT_VIEW_PLUR');
                    const copyStringPlur = this.translateService.instant('RECENT_COPY_PLUR');
                    let num = 0;
                    //this.numMap = '(' + resp.length + ' ' + this.translateService.instant('REPORT_CREATEDMAPS2') + ')';
                    this.numMap = ': ' + resp.length;

                    resp.forEach((recent: any) => {
                        //  if (recent.shareData.isMine || recent.shareData.isShared) {
                        num++;
                        rm = new RecentMapDto(num, recent, viewStringSing, copyStringSing, viewStringPlur, copyStringPlur);
                        this.updateRecentData(rm, recent.shareData);
                        const path = 'maps/' + recent.ownerId + '/work/' + recent.id + '/preview.jpg';
                        this.getRecentPreview(rm, path);
                        //    if (options === 'all' || (options === 'mine' && rm.isMine) || (options === 'shared' && rm.isShared && !rm.isMine)) {
                        this.allMaps.push(rm);
                        //  }
                        //  }
                    });
                    this.filterRecents(this.currentRecentGroup);
                    // this.noMap = this.recentMaps.length === 0;
                    // this.sortRecentMaps(this.defaultSortRecentsBy);
                    // this.applyFilter();
                    // this.isRecentsLoading = false;
                    // this.endLoading.emit(this.noMap);
                    // setTimeout(() => {
                    //     this.getRecentsDataInSequence();
                    // }, 10);
                }, (error: any) => {
                    logger.error('getRecentMap().subscribe: ' + error);
                    this.loadingMap = false;
                    this.errorRecent.emit((error.code) ? error.code : error.status);
                    //   this.timeStop();
                });
            });
        } else {
            this.router.navigate(['login', '/home']);
        }
    }


    getRecentPreview(recent: RecentMapDto, path: string) {
        this.firebaseService.getDownloadUri(path).then((url) => {
            recent.previewPublicUrl = url;
        });
    }
    // private sleeper(ms: number) {
    //     return new Promise(resolve => setTimeout(() => resolve(true), ms));
    // }

    // private async getRecentsDataInSequence() {
    //     // this.timeStart('getRecentsDataInSequence');
    //     console.log('getRecentsDataInSequence: start');

    //     const email = this.authenticationService.getUserEmail();
    //     let i = 0;
    //     while (!this.loadingMap && !this.mapStateService.isSomeOperationStarted && i < this.recentMaps.length) {
    //         const recent = this.recentMaps[i];
    //         console.log(`Updating map id:${recent.id}, name:${recent.name}`);
    //         const recentData = await this.smeService.awaitableGetRecentData(email, recent);
    //         //await this.sleeper(100);
    //         this.updateRecentData(recent, recentData);
    //         console.log(`Map updated`);
    //         i++;
    //     }
    //     console.log('getRecentsDataInSequence: end');
    //     //    this.timeStop();

    // }

    // private getRecentsData() {
    //   this.timeStart('getRecentsData');
    //   this.googleService.getAccessToken().then((token: string) => {
    //     if (this.getRecentDataSubscription) { this.getRecentDataSubscription.unsubscribe(); }
    //     const mapList: Array<String> = [];
    //     for (let i = 0; i < this.recentMaps.length; i++) {
    //       const recentMap = this.recentMaps[i];
    //       mapList.push(recentMap.id)
    //     }
    //     this.smeService.getRecentsData(this.authenticationService.credentials.googleUserEmail, token, mapList).subscribe((data: any) => {
    //       // console.log('getRecentsData----------------------------------------------------');
    //       // console.log(JSON.stringify(data));
    //       // console.log('-----------------------------------------------------------------');
    //       if (data && data.mapInfoList && data.mapInfoList.length > 0) {
    //         for (let i = 0; i < data.mapInfoList.length; i++) {
    //           const recent = this.findRecentById(data.mapInfoList[i].id);
    //           if (recent) {
    //             const recentData = data.mapInfoList[i];
    //             this.updateRecentData(recent, recentData);
    //           }
    //         }
    //       }
    //       this.timeStop();
    //     });
    //   });
    // }

    private findRecentById(mapId: string) {
        let res = null;
        let i = 0;
        let found = false;
        while (!found && i < this.recentMapsSortedFiltered.length) {
            const recent = this.recentMapsSortedFiltered[i];
            if (recent.id === mapId) {
                res = recent;
                found = true;
            } else {
                i++;
            }
        }
        return res;
    }

    private updateRecentData(recent: RecentMapDto, recentData: any) {
        // console.log(`updateRecentData: name:${recent.name}, id:${recent.id}, googleId:${recent.googleId}`); // , data:${JSON.stringify(data)}`);
        if (recentData) {
            const _recentData = (recentData.mapInfo ? recentData.mapInfo : recentData);
            if (_recentData) {
                recent.isShared = (_recentData.isShared ? true : false);
                recent.isPubliclyShared = (_recentData.isPubliclyShared ? true : false);
                recent.isMine = (_recentData.isMine ? true : false);
                const fId = (this.authenticationService && this.authenticationService.credentials ? this.authenticationService.credentials.firebaseUserId : 'xxx');
                const ownerId = (_recentData.ownerId ? _recentData.ownerId : '');
                //recent.isMine = (fId === ownerId);
                recent.readonly = (_recentData.isReadonly ? true : false);
                recent.shareType = (_recentData.shareType ? _recentData.shareType : '');
                recent.stat_copyShared = (_recentData.stat_copyShared ? _recentData.stat_copyShared : 0);
                recent.stat_viewShared = (_recentData.stat_viewShared ? _recentData.stat_viewShared : 0);
                recent.updateStrings();
                // // Aggiorna se sono arrivati dati
                // if (_recentData.isMine !== undefined) {
                //   recent.dataLoaded = true;
                // }
            } else {
                console.log(`No recent data available (1)`);
            }
        } else {
            console.log(`No recent data available (2)`);
        }
        // this.timeStop();
    }

    public replaceDefaultUrl(event: any) {
        event.target.src = this.noImageUrl;

    }

    public sortRecentMaps(type: string) {
        if (type === 'alphabetical') {
            this.recentMapsSorted = this.recentMaps.sort((n1, n2) => {
                const nameA: string = n1.name.toLowerCase();
                const nameB: string = n2.name.toLowerCase();
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }
                return 0;
            });
        } else {
            this.recentMapsSorted = this.recentMaps.sort((n1, n2) => {
                const n1Time = (n1.lastOpened ? n1.lastOpened.getTime() : new Date().getTime());
                const n2Time = (n2.lastOpened ? n2.lastOpened.getTime() : new Date().getTime());
                return (n2Time - n1Time);
            });
        }
        if (this.userPreferenceService.userPrefs) {
            this.userPreferenceService.userPrefs.sortRecentsBy = type;
        }
        // this.smeService.updateSortingType(type).subscribe((resp: any) => { }, (error: any) => {
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        this.updateUserPreferenceSubscription = this.smeService.updateUserPreference(this.userPreferenceService.userPrefs).subscribe({
            next: (resp: any) => { },
            error: (error: any) => {
                logger.error('updateSortingType().subscribe: ' + error);
                this.loadingMap = false;
                this.errorRecent.emit((error.code) ? error.code : error.status);
            }
        });
    }


    // public moveMap(mapId: string) {
    //     this.startLoading.emit();
    //     if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
    //     this.downloadJsonSubscription = this.smeService.downloadJsonToEdit(mapId).subscribe((_mapData: any) => {
    //         this.endLoading.emit();
    //         this.router.navigate(['gdrive-folder-picker', mapId]);
    //     }, (error) => {
    //         this.endLoading.emit();
    //         this.messageBoxService.showTextMessage(
    //             this.messageBoxService.MODE_TYPE.OK,
    //             this.translateService.instant('ERROR_MAP_NOT_FOUND'),
    //             this.translateService.instant('ERROR_GOOGLEID_NOT_FOUND')
    //         ).subscribe(() => {
    //             console.log(`ERROR in shareMap: ${error.message}`);
    //         });
    //     });
    // }

    private _renameMap(_mapData: any, recent: RecentMapDto) {
        let result: any;
        this.firebaseService.getDownloadUriAndReparImage(recent.previewPublicUrl)
            .then((url) => {
                if (this.renameMapSubscription) { this.renameMapSubscription.unsubscribe(); }
                this.renameMapSubscription = this.openDialog(recent.name, recent.id, url[0], url[1])
                    .subscribe({
                        next: (res: any) => {
                            result = res;
                            // if (result && result.name.trim() && result.name !== recent.name) {
                            //     const name = result.name.trim();
                            //     recent.name = name;
                            //     if (this.smeServiceRenameMapSubscription) { this.smeServiceRenameMapSubscription.unsubscribe(); }
                            //     this.smeServiceRenameMapSubscription = this.smeService.renameMap(recent.id, recent.name + '.sme').
                            //         subscribe(() => {
                            //         });
                            // }
                        },
                        complete: () => {
                            this.endLoading.emit();
                            if (result && result.name.trim() && result.name !== recent.name) {
                                const name = result.name.trim();
                                recent.name = name;
                                if (this.smeServiceRenameMapSubscription) { this.smeServiceRenameMapSubscription.unsubscribe(); }
                                this.smeServiceRenameMapSubscription = this.smeService.renameMap(recent.id, recent.name + '.sme').
                                    subscribe(() => {
                                    });
                            }

                        },
                        error: (error) => {
                            this.endLoading.emit();
                            this.messageBoxService.showTextMessage(
                                this.messageBoxService.MODE_TYPE.OK,
                                this.translateService.instant('ERROR_MAP_NOT_FOUND'),
                                this.translateService.instant('ERROR_GOOGLEID_NOT_FOUND')
                            ).subscribe(() => {
                                console.log(`ERROR in shareMap: ${error.message}`);
                            });
                        }
                    });
            });

    }

    public renameMap(recent: RecentMapDto) {
        if (!this.authenticationService.isAuthenticated()) {
            this.router.navigate(['login']);
        }
        this.startLoading.emit();
        if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
        this.downloadJsonSubscription = this.smeService.downloadJsonToEdit(recent.id)
            .subscribe({
                next: (_mapData: any) => {
                    this.endLoading.emit();
                    this._renameMap(_mapData, recent);

                }, error: (error) => {
                    this.endLoading.emit();
                }
            });

    }

    openDialog(name: string, id: string, preview: string, tooltip: string) {
        return new Observable((observer: Observer<boolean>) => {
            if (this.askNameSubscription) { this.askNameSubscription.unsubscribe(); }
            this.askNameSubscription = this.inputDialogService.askName(this.translateService.instant(extract('TITLE_RENAME_MAP')),
                this.translateService.instant(extract('INPUT_MAPNAME')), name, id, '', '', preview, tooltip)
                .subscribe({
                    next: (result) => {
                        observer.next(result);
                        // observer.complete();

                    },
                    complete: () => {
                        observer.complete();
                    },
                    error: (error) => {
                        observer.next(false);
                        observer.complete();

                    }
                });
        });
    }

    public copyMap(recent: RecentMapDto) {
        if (!this.authenticationService.isAuthenticated()) {
            this.router.navigate(['login']);
        }
        if (!this.isUserExpired) {
            this.startLoading.emit();
            this.copyAndOpenMapService.copyAndOpenMap(recent.id, recent.name, recent.previewPublicUrl, () => { this.endLoading.emit(); })
                .then(() => {
                    this.endLoading.emit();
                }).catch((error) => {
                    this.endLoading.emit();
                    this.messageBoxService.showTextMessage(
                        this.messageBoxService.MODE_TYPE.OK,
                        this.translateService.instant('ERROR_MAP_NOT_FOUND'),
                        this.translateService.instant('ERROR_GOOGLEID_NOT_FOUND')
                    ).subscribe(() => {
                        this.endLoading.emit();
                        console.log(`ERROR in copyMap: ${error.message}`);
                    });
                });
        }
    }

    public downloadMap(recent: RecentMapDto) {
        if (!this.authenticationService.isAuthenticated()) {
            this.router.navigate(['login']);
        }
        this.startLoading.emit();
        this.mapStateService.id = recent.id;
        this.mapStateService.name = recent.name;
        this.mapStateService.author = recent.authDesc;
        const userId = this.authenticationService.credentials?.firebaseUserId;
        const map = `/maps/${userId}/mymaps/${recent.id}.sme`
        this.smeService.exportSmeRequest(recent.id)
            // this.firebaseService.downloadResourceAsBlob(map, 'application/supermappex')
            .subscribe({
                next: (sme: any) => {
                    this.endLoading.emit();
                    const smeBlob = new Blob([sme], { type: 'application/supermappe' });
                    FileSaver.saveAs(smeBlob, recent.name + '.sme');
                },
                error: (error) => {
                    this.endLoading.emit();
                    this.messageBoxService.showTextMessage(
                        this.messageBoxService.MODE_TYPE.OK,
                        this.translateService.instant('ERROR_MAP_NOT_FOUND'),
                        this.translateService.instant('ERROR_GOOGLEID_NOT_FOUND')
                    ).subscribe(() => {
                        console.log(`ERROR in downloadMap: ${error.message}`);
                    });
                }
            });
    }

    public shareMap(recent: RecentMapDto) {
        if (!this.authenticationService.isAuthenticated()) {
            this.router.navigate(['login']);
        }
        this.mapStateService.id = recent.id;
        this.mapStateService.name = recent.name;
        this.mapStateService.author = recent.authDesc;
        this.shareMapService.showShareMapDialog(recent.id, recent.name, true, false, recent.isShared, false);
    }

    //#region  Trash
    public async trashOp(dto: RecentActionDto) {
        if (this.authenticationService.isAuthenticated()) {
            if (dto && dto.recentMapDto && dto.recentAction !== RECENT_ACTION.NONE) {
                let res;
                switch (dto.recentAction) {
                    case RECENT_ACTION.TRASH:
                        console.log(`***Trash map: ${dto.recentMapDto.name} - ${dto.recentMapDto.id}`);
                        this.trashRecentGUI(dto.recentMapDto.id);
                        setTimeout(() => { this.pendingActions.push(dto); }, 500);
                        break;
                    case RECENT_ACTION.UNTRASH:
                        console.log(`***Untrash map: ${dto.recentMapDto.name} - ${dto.recentMapDto.id}`);
                        this.untrashRecentGUI(dto.recentMapDto.id);
                        setTimeout(() => { this.pendingActions.push(dto); }, 500);
                        break;
                    case RECENT_ACTION.REMOVE:
                        console.log(`***Remove map: ${dto.recentMapDto.name} - ${dto.recentMapDto.id}`);
                        res = await this.removeRecentGUI(dto.recentMapDto.id);
                        if (res) {
                            setTimeout(() => { this.pendingActions.push(dto); }, 500);
                        }
                        break;
                    case RECENT_ACTION.DELETE:
                        console.log(`***Delete map: ${dto.recentMapDto.name} - ${dto.recentMapDto.id}`);
                        res = await this.deleteRecentGUI(dto.recentMapDto.id);
                        if (res) {
                            setTimeout(() => { this.pendingActions.push(dto); }, 500);
                        }
                        break;
                }
            }
        } else {
            this.router.navigate(['login']);
        }
    }

    openSnackBar(message: string, action: string) {
        if (this.isSnackbarEnabled) {
            this.isSnackbarOpen = true;
            this.snackBarActionPressed = false;
            const snackbarConfig = new MatSnackBarConfig();
            snackbarConfig.duration = 5000;

            this.matSnackbarRef = this._snackBar.open(message, action, snackbarConfig);
            this.matSnackbarRef.onAction().subscribe(() => {
                this.isSnackbarOpen = false;
                // console.log('***HAI PREMUTO ACTION***');
                this.snackBarActionPressed = true;
                this.invertPendingAction();
            });
            this.matSnackbarRef.afterDismissed().subscribe(() => {
                this.isSnackbarOpen = false;
                if (!this.snackBarActionPressed) {
                    // console.log('***SNACKBAR CHIUSO SENZA AZIONE***');
                    this.executePendingActions();
                }
            });
        }
    }

    async executePendingActions(notifyClient = true) {
        // Finalizza l'operazione del cestino su DB
        if (this.pendingActions) {
            while (this.pendingActions.length > 0) {
                const action = { ...this.pendingActions[0] };
                this.pendingActions.splice(0, 1);
                console.log(`***Execute pending action: ${action.recentMapDto?.name}, action: ${action.recentAction}`);
                if (action && action.recentMapDto && action.recentAction !== RECENT_ACTION.NONE) {
                    let res;
                    switch (action.recentAction) {
                        case RECENT_ACTION.TRASH:
                            if (this.FAKE_TRASH) {
                                console.log('***FAKE TRASH***');
                            } else {
                                res = await this.trashOrRestoreRecentOnDB(action.recentMapDto.id, 'trash')
                            }
                            break;
                        case RECENT_ACTION.UNTRASH:
                            if (this.FAKE_TRASH) {
                                console.log('***FAKE UNTRASH***');
                            } else {
                                res = await this.trashOrRestoreRecentOnDB(action.recentMapDto.id, 'restore')
                            }
                            break;
                        case RECENT_ACTION.REMOVE:
                            // Elimina la mappa dalla lista dell'utente e se è proprietario cancella anche lo storage (in questo caso no)
                            if (this.FAKE_TRASH) {
                                console.log('***FAKE REMOVE***');
                            } else {
                                res = await this.removeOrDeleteRecentOnDB(action.recentMapDto.id);
                            }
                            break;
                        case RECENT_ACTION.DELETE:
                            // Elimina la mappa dalla lista dell'utente e se è proprietario cancella anche lo storage (in questo caso sì)
                            if (this.FAKE_TRASH) {
                                console.log('***FAKE DELETE***');
                            } else {
                                res = await this.removeOrDeleteRecentOnDB(action.recentMapDto.id);
                            }
                            break;
                    }
                }
                if (notifyClient) {
                    this.firebaseService.addSharedStateChangeListener(this.Guid, this.mapStateService.id, this.authenticationService.getUserEmail()).then(() => {
                        console.log('refresh home');
                    });
                }
            }
        }
    }

    invertPendingAction() {
        // Ripristina la GUI dopo annulla
        if (this.pendingActions) {
            while (this.pendingActions.length > 0) {
                const action = { ...this.pendingActions[0] };
                this.pendingActions.splice(0, 1);
                console.log(`***Invert pending action: ${action.recentMapDto?.name}, action: ${action.recentAction}`);
                if (action && action.recentMapDto && action.recentAction !== RECENT_ACTION.NONE) {
                    this.isSnackbarEnabled = false;
                    switch (action.recentAction) {
                        case RECENT_ACTION.TRASH:
                            this.setDeleteDate(action.recentMapDto.id, false);
                            break;
                        case RECENT_ACTION.UNTRASH:
                            this.setDeleteDate(action.recentMapDto.id, true);
                            break;
                        case RECENT_ACTION.REMOVE:
                        case RECENT_ACTION.DELETE:
                            this.allMaps.push(action.recentMapDto);
                            this.filterRecents(this.currentRecentGroup);
                            break;
                    }
                    this.isSnackbarEnabled = true;
                }
            }
        }
    }

    public trashRecentGUI(mapId: string) {
        this.setDeleteDate(mapId, true);
    }

    public untrashRecentGUI(mapId: string) {
        this.setDeleteDate(mapId, false);
    }

    public removeRecentGUI(mapId: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('TRASH_REMOVE_CONFIRM')),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('CANCEL'))
            ).subscribe({
                next: (result) => {
                    if (result) {
                        this.discardRecentGUI(mapId);
                    }
                    return resolve(result);
                }
            });
        });
    }

    public deleteRecentGUI(mapId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('TRASH_DELETE_CONFIRM')),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('CANCEL'))
            ).subscribe({
                next: (result) => {
                    if (result) {
                        this.discardRecentGUI(mapId);
                    }
                    return resolve(result);
                }
            });
        });
    }

    private setDeleteDate(mapId: string, isDeleted: boolean) {
        const recent = this.getRecentItem(mapId);
        if (recent) {
            recent.lastOpened = new Date();
            recent.deleteDate = (isDeleted ? new Date() : null);
            this.filterRecents(this.currentRecentGroup);
        }
        const message = this.translateService.instant(isDeleted ? 'MSG_TRASHED_MAP' : 'MSG_UNTRASHED_MAP');
        const action = this.translateService.instant('CANCEL');
        this.openSnackBar(message, action);
    }

    private discardRecentGUI(mapId: string) {
        const recent = this.getRecentItem(mapId);
        this.allMaps.forEach(map => {
            if (map.id === mapId) {
                const index = this.allMaps.indexOf(map, 0);
                if (index > -1) {
                    this.allMaps.splice(index, 1);
                }
            }
        });
        this.filterRecents(this.currentRecentGroup);
        const msgId = (recent && recent.isMine ? 'MSG_DELETED_MAP' : 'MSG_REMOVED_MAP');
        const message = this.translateService.instant(msgId);
        const action = this.translateService.instant('CANCEL');
        this.openSnackBar(message, action);
    }


    private trashOrRestoreRecentOnDB(mapId: string, operation: 'trash' | 'restore') {
        return new Promise((resolve, reject) => {
            if (this.trashRecentSubscription) { this.trashRecentSubscription.unsubscribe(); }
            this.removeRecentSubscription = this.smeService.trashRecent(mapId, operation).subscribe((res: any) => {
                if (res) {
                    this.noMap = this.recentMaps.length === 0;
                }
                console.log('trashOrRestoreRecentOnDB OK')
                return resolve(true);
            }, (error: any) => {
                logger.error('trashOrRestoreRecentOnDB() ERROR: ' + error);
                return resolve(false);
            });

        });
    }

    private removeOrDeleteRecentOnDB(mapId: string) {
        return new Promise((resolve, reject) => {
            if (this.removeRecentSubscription) { this.removeRecentSubscription.unsubscribe(); }
            this.removeRecentSubscription = this.smeService.removeRecent(mapId).subscribe((res: any) => {
                if (res) {
                    this.noMap = this.recentMaps.length === 0;
                }
                logger.info('removeOrDeleteRecentOnDB OK')
                return resolve(true);
            }, (error: any) => {
                logger.error('removeOrDeleteRecentOnDB() ERROR: ' + error);
                return resolve(false);
            });
        });
    }

    private getRecentItem(mapId: string): RecentMapDto | null {
        let res = null;
        for (const recent of this.allMaps) {
            if (recent.id === mapId) {
                res = recent;
            }
        }
        return res;
    }
    // #endregion

    public stopSharing(mapId: string, removeFromRecent?: boolean) {
        // this.startLoading.emit();
        // logger.info('Stop sharing map:' + mapId);

        // // console.log(`token:${_accessToken}`);
        // if (this.stopSharingSubscription) { this.stopSharingSubscription.unsubscribe(); }
        // this.stopSharingSubscription = this.smeService.stopSharing(mapId).subscribe((res: any) => {
        //     if (res) {
        //         this.mapStateService.refreshRecentSharing.emit(mapId);
        //         if (removeFromRecent) {
        //             this._removeRecent(mapId);
        //         }
        //         this.endLoading.emit();
        //         this.confirmationService.confirm(
        //             this.translateService.instant(extract('MAIN_MENU_FILE-SHARE')),
        //             this.translateService.instant(extract('SHARING_STOPPED')),
        //             this.translateService.instant(extract('BUTTON_OK')),
        //             '');
        //     }
        // }, (error: any) => {
        //     logger.error('stopSharing().subscribe: ' + error);
        //     this.endLoading.emit();
        //     this.errorRecent.emit((error.code) ? error.code : error.status);
        // });
    }

    public recentShowPreviews() {
        logger.debug('Show preview');
        if (this.userPreferenceService.userPrefs) this.userPreferenceService.userPrefs.recentsViewMode = 'preview';
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        this.updateUserPreferenceSubscription = this.smeService.updateUserPreference(this.userPreferenceService.userPrefs).subscribe({
            next: (resp: any) => { },
            error: (error: any) => {
                // this.smeService.updateRecentsViewMode('preview').subscribe((resp: any) => { }, (error: any) => {
                logger.error('updateRecentsViewMode().subscribe: ' + error);
                this.loadingMap = false;
                this.errorRecent.emit((error.code) ? error.code : error.status);
            }
        });
        this.currentRecentsStyle = 'preview';
        this.recentPreview = true;
    }

    public recentShowList() {
        logger.debug('Show list');
        if (this.userPreferenceService.userPrefs) this.userPreferenceService.userPrefs.recentsViewMode = 'list';
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        this.updateUserPreferenceSubscription = this.smeService.updateUserPreference(this.userPreferenceService.userPrefs).subscribe({
            next: (resp: any) => { },
            error: (error: any) => {
                // this.smeService.updateRecentsViewMode('list').subscribe((resp: any) => {

                logger.error('updataRecentsViewMode().subscribe: ' + error);
                this.loadingMap = false;
                this.errorRecent.emit((error.code) ? error.code : error.status);
            }
        });
        this.currentRecentsStyle = 'list';
        this.recentPreview = false;
    }

    public itemMenuClick(rm: RecentMapDto, event: Event | undefined) {
        event?.stopPropagation();
        this.itemMenuClicked = true;
        this.recentMap = rm;
    }

    public loadMapFromRecent(mapId: string) {
        if (this.currentRecentGroup === 'trash') {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('TRASH_RESTORE_OPEN_CONFIRM')),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('CANCEL'))
            ).subscribe({
                next: (result) => {
                    if (result) {
                        const recent = this.getRecentItem(mapId);
                        if (recent) {
                            const operation = new RecentActionDto(recent, RECENT_ACTION.UNTRASH);
                            this.trashOp(operation);
                        }
                        this.openWindow(mapId);
                    }
                }
            });
        } else {
            this.openWindow(mapId);
        }
    }

    openWindow(mapId: string) {
        if (this.authenticationService.isAuthenticated()) {


            const url = window.location.origin + '/map-open/' + mapId;
            //DEBUG: RIPRISTINARE window.open!!!
            // alert('DEBUG: open map in same tab');
            // this.router.navigate(['map-open', mapId]);
            //----------------------------------
            window.open(url, '_blank');
        } else {
            this.router.navigate(['login']);
        }
    }

    openIntroMap() {
        this.userPreferenceService.openIntroMap();
    }

    receiveMessages(querySnapshot: QuerySnapshot) {
        try {
            console.log('Listening to the projectTransactions events collection');
            const promises: Array<any> = [];
            const events: Array<any> = [];
            querySnapshot.docChanges().forEach(async (change: any) => {
                if (!this.isFirstFirestoreEvent) {
                    if (this.isSnackbarOpen) {
                        this.executePendingActions();
                    }
                    const event = await this.firebaseService.getSharedStateChangeEvent(this.authenticationService.getUserEmail(), change.doc.id);
                    if (event.guid !== this.Guid) {
                        this.getRecents(this.currentRecentGroup, true);
                    }
                }
                if (!this.firstTimer) {
                    this.firstTimer = setTimeout(() => {
                        this.isFirstFirestoreEvent = false;
                    }, 2000);
                }
            });
        } catch (err) {
            console.log(`[Recent] FirestoreDB receiveMessages ERROR: ${err}`);
        }
    }

    public getShareIconTooltip(recent: RecentMapDto): string {
        let t = '';
        if (recent && recent.isShared) {
            if (recent.isMine) {
                if (recent.shareType === 'none' || recent.shareType === 'view') {
                    t = this.translateService.instant("SHARED_VIEW_MINE");
                } else if (recent.shareType === 'edit') {
                    t = this.translateService.instant("SHARED_EDIT_MINE");
                }
            } else {
                if (recent.author) {
                    if (recent.shareType === 'view') {
                        t = this.translateService.instant("SHARED_VIEW_OTHER_AUTHOR") + ' ' + recent.author;
                    } else if (recent.shareType === 'edit') {
                        t = this.translateService.instant("SHARED_EDIT_OTHER_AUTHOR") + ' ' + recent.author;
                    }
                } else {
                    if (recent.shareType === 'view') {
                        t = this.translateService.instant("SHARED_VIEW");
                    } else if (recent.shareType === 'edit') {
                        t = this.translateService.instant("SHARED_EDIT");
                    }
                }
            }
        }
        return t;
    }

    public renewSubscription() {
        window.open(window.location.origin + '/user-desktop', '_blank');
    }

    public emptyTrash() {
        this.confirmationService.confirm(
            this.translateService.instant(extract('WARNING')),
            this.translateService.instant(extract('TRASH_EMPTY_CONFIRM')),
            this.translateService.instant(extract('BUTTON_OK')),
            this.translateService.instant(extract('CANCEL'))
        ).subscribe({
            next: (result) => {
                if (result) {
                    for (let i = this.allMaps.length - 1; i >= 0; i--) {
                        const map = this.allMaps[i];
                        if (map.deleteDate) {
                            const index = this.allMaps.indexOf(map, 0);
                            if (index > -1) {
                                this.allMaps.splice(index, 1);
                            }
                        }
                    }
                    this.filterRecents(this.currentRecentGroup);
                    const message = this.translateService.instant('TRASH_EMPTIED');
                    const action = 'Chiudi';
                    this.openSnackBar(message, action);
                    this.executePendingActions(false);
                    setTimeout(() => {
                        this.smeService.emptyTrash().subscribe({
                            next: () => {
                                console.log('emptyTrash finished OK');
                            },
                            error: (error: any) => {
                                logger.error('emptyTrash ERROR: ' + error);
                            }
                        });
                    }, 1000);
                }
            }
        });
    }

}
