<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''" [versioned]="true"></app-loader>

<div class="main-content">
    <div [ngClass]="(compressed ? 'access-title-compressed' : 'access-title')">
        <!-- <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="{{logoUrl}}?v=1" />
        </a> -->
        <div *ngIf="!compressLogo" class="smx-logo-extended" routerLink="/home" translate>
            <img class="smx-logo-image-extended" src="{{logoUrlExtended}}?v=1" />
        </div>
        <div *ngIf="compressLogo" class="smx-logo-compressed" routerLink="/home" translate>
            <img class="smx-logo-image-compressed" src="{{logoUrlCompressed}}?v=1" />
        </div>
        <div class="spacer"></div>


        <h1 class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </h1>
        <div class="spacer"></div>
        <!-- <span class="access-subtitle">{{getRoleType()}}</span> -->
    </div>

    <div class="order-list">
        <app-order-list [openFirstElement]="true" [isLoading]="isLoading" (startLoading)="startLoadingList()" (endLoading)="endLoadingList()" [enabledDetail]="true"></app-order-list>
    </div>
</div>
