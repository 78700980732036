import { Injectable } from '@angular/core';
import { SmeService } from '../../core/sme/sme.service';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../../../app/shared/map-state.service';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GenerateSmeService {

  imgPreview: any;
  imgMap: any;

  exportSmeSubscription: Subscription | undefined;

  constructor(
    private smeService: SmeService,
    private smService: SmService,
    private mapStateService: MapStateService) {
  }

  generate(mapId: string, mapWorkjson: any): Promise<any> {
    // salvo le immagini da inserire nello sme
    return new Promise((resolve, reject) => {
      const image = this.smService.convertToJpegImage(false, null, this.mapStateService.limitPrintSize, false);
      this.imgPreview = image.thumbnail;
      this.imgMap = image.image;
      if (this.exportSmeSubscription) { this.exportSmeSubscription.unsubscribe(); }
      this.exportSmeSubscription = this.smeService.exportSme(mapId, this.imgPreview, this.imgMap, mapWorkjson)
        .subscribe({
          next: (data: any) => {
            return resolve(data);
          },
          error: (error) => {
            return reject(error);
          }
        });
    });
  }

}
