<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'"></app-loader>

<div class="user-profile">
    <mat-card class="user-container">
        <div class="header">
            <h2 translate>
                EDIT_PROFILE
            </h2>
            <button mat-icon-button color="primary" aria-label="Torna alla home" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>


        <!-- FOTO PROFILO -->
        <h3>{{'AVATAR' | translate}}</h3>
        <div class="params">
            <input hidden type="file" #file name="fileInput" accept="image/*" multiple="false" (change)="selectFiles($event)" />
            <img mat-card-avatar src="{{imageUrl}}" class="img-user-small" *ngIf="imageUrl" />
            <div class="container" *ngIf="!imageUrl">
                <div class="avatar"> {{mapStateService.getInitials()}}</div>

            </div>
            <div class="bottoni" *ngIf="!isNativeGoogleUser">
                <button color="primary" *ngIf="imageUrl" [disabled]="editing" mat-flat-button class="reset-password" type="button" (click)="removeAvatar()">{{'REMOVE_AVATAR' | translate}}</button>
                <button color="primary" [disabled]="editing" mat-flat-button class="reset-password" type="button" (click)="file.click()">{{'CHOOSE_AVATAR' | translate}}</button>
            </div>
        </div>


        <!-- NOME COMPLETO -->
        <hr />
        <h3>{{'FULLNAME' | translate}}</h3>
        <ng-container *ngIf="editFullName ; else viewingFullname">
            <app-set-fullname [fullname]="fullName" (savedFullName)="onSavedFullName($event)"></app-set-fullname>
        </ng-container>
        <ng-template #viewingFullname>
            <div class="params">
                <span type="text" class="input-param">{{fullName}}</span>
                <button (click)="showEditFullname()" *ngIf="!isNativeGoogleUser" [disabled]="editing" mat-flat-button color="primary" class="reset-password">{{'EDIT' | translate}}</button>
            </div>
        </ng-template>


        <!-- EMAIL -->
        <hr />
        <h3>{{'USERNAME' | translate}}</h3>
        <div class="params">
            <span class="input-param" matTooltip="{{'USERNAME' | translate}}">{{userName}}</span>
        </div>


        <!-- PASSWORD -->
        <hr />
        <h3>{{'PASSWORD' | translate}}</h3>
        <ng-container *ngIf="editPassword ; else viewingPassword">
            <app-set-password [erroreSalvataggio]="errorOnSavePassword" [erroreOldPassword]="errorOnPassword" [email]="userName" (onSubmit)="onSavedPassword($event)" (onCancel)="onSavedPassword()"></app-set-password>
        </ng-container>
        <ng-template #viewingPassword>
            <div class="params">

                <span>*******</span>
                <!-- <button color="primary" mat-button class="reset-password" type="button" (click)="resetPassword()">{{'RESET_PASSWORD' | translate}}</button> -->
                <button *ngIf="!isNativeGoogleUser" [disabled]="editing" (click)="showEditPassword()" type="button" color="primary" class="edit-password" mat-flat-button>{{'EDIT' | translate}}</button>
            </div>
        </ng-template>

        <!-- LINGUA -->
        <hr />

        <div class="params">
            <div class="language-container">
                <mat-label>{{'HEADER_LANGUAGE' | translate}}</mat-label>
                <mat-select [(value)]="currLanguage" (selectionChange)="setLanguage($event)" [disabled]="editing">
                    <mat-option *ngFor="let language of languages" [value]="language">
                        <div class="option">
                            <img src="assets/flags/{{language}}.png" />
                            <div>{{languageName(language)}}</div>
                        </div>
                    </mat-option>
                </mat-select>
            </div>

            <img class="flag-lang" src="assets/flags/{{currLanguage}}.png" />
        </div>

        <!-- CONNETTI a GOOGLE -->
        <hr />

        <div class="params"  [ngClass]="{ highlighted: highlightGoogleButton}">
            
            <div class="horizontal">
                <img width="60px" height="60px" src="../../assets/google-logo.png" />
                <div *ngIf="linkedWithGoogle() && !isNativeGoogleUser">Account Google connesso: {{userGoogleEmail}}</div>
            </div>
            <ng-container *ngIf=" !isNativeGoogleUser">
                <button *ngIf="!linkedWithGoogle()" [disabled]="editing" color="primary" mat-flat-button class="reset-password" type="button" (click)="linkWithGoogle()">{{'LINK_WITH_GOOGLE' | translate}}</button>
                <button *ngIf="linkedWithGoogle()  && !isNativeGoogleUser" [disabled]="editing" color="primary" mat-flat-button class="reset-password" type="button" (click)="unlinkGoogle()">{{'DISCONNECT' | translate}}</button>
            </ng-container>
            <ng-container *ngIf="isNativeGoogleUser">
                <div>{{'MESS_GOOGLE_ACCOUNT_1' |translate}} {{userName}},<br />{{'MESS_GOOGLE_ACCOUNT_2' |translate}}
                    <br />
                    {{'MESS_GOOGLE_ACCOUNT_3' |translate}}
                </div>
            </ng-container>
        </div>
        <!-- <button color="primary" mat-button class="reset-password" type="button" (click)="goOn()">{{'GOON' | translate}}</button> -->


        <div class="login-error" [hidden]="!showingError" translate>
            <div>
                <br />
                <span *ngIf="!clickableMsg" translate>{{ loginErrorMessage }}</span>
                <a *ngIf="clickableMsg" translate href="{{loginErrorMessage}}">LOGIN_COOKIES_INFO</a>
            </div>
        </div>
    </mat-card>
</div>
