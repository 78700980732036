import { DesmosConfigDto } from 'src/app/map-edit/desmos-config/desmosConfig-dto';
import { SmxFontStyle } from '../../map-edit/dialog-preferences/smx-font-style';
import { SmxNodeAspect } from '../../map-edit/dialog-preferences/smx-node-aspect';
import { SmxEdgeAspect } from '../../map-edit/dialog-preferences/smx-edge-aspect';
import { PdfToolsPrefsDto } from 'src/app/map-edit/pdf-editor/pdf-master/pdf-tools-prefs-dto';
import { MathPrefsDto } from 'src/app/map-edit/math-editor/math-prefs-dto';

export class UserPrefsDto {
    public groupId: string;
    public recentsViewMode: string;
    public sortRecentsBy: string;
    public show_ttsEnabled: boolean;
    public nodeTextStyle: { [index: string]: any };
    public edgeTextStyle: { [index: string]: any };
    public deepTextStyle: { [index: string]: any };
    public noteTextStyle: { [index: string]: any };
    public nodeAspect: { [index: string]: any };
    public edgeAspect: { [index: string]: any };
    public usePasteWithouKeyboard: boolean;
    public desmosConfig: DesmosConfigDto;
    public sideBarOpen: boolean;
    public framedNodeImage: boolean;
    public pdf: PdfToolsPrefsDto;
    public math: MathPrefsDto;

    constructor(json: any = {}) {
        this.groupId = (json.groupId) ? json.groupId : 'all';
        this.recentsViewMode = (json.recentsViewMode) ? json.recentsViewMode : 'preview';
        this.sortRecentsBy = (json.sortRecentsBy) ? json.sortRecentsBy : 'date';
        this.show_ttsEnabled = (json.show_ttsEnabled !== undefined) ? json.show_ttsEnabled : true;
        this.usePasteWithouKeyboard = (json.usePasteWithouKeyboard !== undefined) ? json.usePasteWithouKeyboard : false;

        this.nodeTextStyle = (json.nodeTextStyle) ? json.nodeTextStyle : new SmxFontStyle();
        this.edgeTextStyle = (json.edgeTextStyle) ? json.edgeTextStyle : new SmxFontStyle();
        this.deepTextStyle = (json.deepTextStyle) ? json.deepTextStyle : new SmxFontStyle();
        this.noteTextStyle = (json.noteTextStyle) ? json.noteTextStyle : new SmxFontStyle();
        this.nodeAspect = (json.nodeAspect) ? json.nodeAspect : new SmxNodeAspect();
        this.edgeAspect = (json.edgeAspect) ? json.edgeAspect : new SmxEdgeAspect();
        this.desmosConfig = new DesmosConfigDto(json.desmosConfig);
        this.sideBarOpen = (json.sideBarOpen === true || json.sideBarOpen === false) ? json.sideBarOpen : true;
        this.framedNodeImage = (json.framedNodeImage === true || json.framedNodeImage === false) ? json.framedNodeImage : false;
        this.pdf = new PdfToolsPrefsDto(json.pdf);
        this.math = new MathPrefsDto(json.math);
    }
}
