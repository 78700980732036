<canvas #canvas [hidden]="true" width="96" height="96"></canvas>
<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'"></app-loader>
<div class="login-box">
    <div class="login-box-container">
        <div class="smx-logo-container" routerLink="/home" translate>
            <img class="smx-logo" src="{{logoUrlExtended}}?v=1" />
        </div>

        <div class="login-buttons-container">
            <button mat-flat-button color="warn" (click)="openLogin()">{{'LOGIN' | translate}}</button>
            <button mat-flat-button class="primary" color="primary" (click)="requestDemo()">{{'DEMO_REQUEST_BUTTON' | translate}}</button>
        </div>

        <div class="subscription-container">
            <span class="subscription-message" translate>LOGIN_SUBSCRIPTION</span>
            <a class="subscription-discover-more" translate href="https://www.supermappex.it" target="_blank">LOGIN_DISCOVER_MORE</a>
        </div>

        <div class="features-container">
            <div class="features-header">
                <div class="features-header-text" translate>LOGIN_MAPS_AND_MORE</div>
                <img class="features-header-icon" src="assets/login/lab_extra.png?v=1" alt="logo" />
            </div>
            <div class="features-content">
                <div class="features-item">
                    <img class="features-item-image" src="assets/login/SMX_strumenti1.png?v=1" alt="logo" />
                    <div class="features-item-text" translate>LOGIN_UNDER_MAPS</div>
                </div>
                <div class="features-item">
                    <img class="features-item-image" src="assets/login/SMX_strumenti2.png?v=1" alt="logo" />
                    <div class="features-item-text" translate>LOGIN_UNDER_NOTEPAD</div>
                </div>
                <div class="features-item">
                    <img class="features-item-image" src="assets/login/SMX_strumenti3.png?v=1" alt="logo" />
                    <div class="features-item-text" translate>LOGIN_UNDER_DRAW</div>
                </div>
                <div class="features-item">
                    <img class="features-item-image" src="assets/login/SMX_strumenti4.png?v=1" alt="logo" />
                    <div class="features-item-text" translate>LOGIN_UNDER_OUTLINE</div>
                </div>
                <div class="features-item">
                    <img class="features-item-image" src="assets/login/SMX_strumenti5.png?v=1" alt="logo" />
                    <div class="features-item-text" translate>LOGIN_UNDER_PDF</div>
                </div>
                <div class="features-item">
                    <img class="features-item-image" src="assets/login/SMX_strumenti6.png?v=1" alt="logo" />
                    <div class="features-item-text" translate>LOGIN_UNDER_MATH</div>
                </div>
            </div>
        </div>

        <!--<div class="modules-container">
            <div class="modules-text-top-container">
                <span class="modules-text-top" translate>LOGIN_MAPS_AND_MORE</span>
                <img class="modules-icon-top" src="assets/login/lab_extra.png?v=1" alt="logo" />
            </div>
            <img class="modules-image" src="assets/login/SMX_strumenti.png?v=1" alt="logo" />
            <div class="modules-text-bottom-container">
                <span class="modules-text-bottom-maps" translate>LOGIN_UNDER_MAPS</span>
                <span class="modules-text-bottom-notepad" translate>LOGIN_UNDER_NOTEPAD</span>
                <span class="modules-text-bottom-draw" translate>LOGIN_UNDER_DRAW</span>
                <span class="modules-text-bottom-outline" translate>LOGIN_UNDER_OUTLINE</span>
                <span class="modules-text-bottom-pdf" translate>LOGIN_UNDER_PDF</span>
                <span class="modules-text-bottom-math" translate>LOGIN_UNDER_MATH</span>
            </div>
        </div>-->

        <div class="privacy-container">
            <span class="privacy-text" translate>LOGIN_FIRST_2</span>
            <a class="privacy-link" href="https://www.anastasis.it/supermappex-privacy/" target="_blank"><span class="privacy-link" translate>LOGIN_LINK_PRIVACY</span></a>
        </div>

        <!--<div class="login-error" [hidden]="!showingError" translate>
            <div>
                <br />
                <span *ngIf="!clickableMsg" translate>{{ loginErrorMessage }}</span>
                <a *ngIf="clickableMsg" translate href="{{loginErrorMessage}}">LOGIN_COOKIES_INFO</a>
            </div>
        </div>-->
    </div>
</div>
