<div *ngIf="!compressed" class="toolbar">
    <button mat-icon-button color="primary" [ngClass]="{'tts-mode': ttsEnabled}" matTooltip="{{'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate}}" (click)="TTSRead()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate" svgIcon="message-processing"></mat-icon>
    </button>
    <mat-divider vertical style="height: 30px;"></mat-divider>
    <button mat-icon-button color="accent" [disabled]="!isPrevEnabled" matTooltip="{{'SHOW_TOOLBAR_PREV' | translate}}" (click)="prevShowStep()">
        <mat-icon [attr.aria-label]="'SHOW_TOOLBAR_PREV' | translate" fontSet="material-icons-outlined">chevron_left</mat-icon>
    </button>
    <button mat-icon-button color="accent" [disabled]="!isNextEnabled" matTooltip="{{'SHOW_TOOLBAR_NEXT' | translate}}" (click)="nextShowStep()">
        <mat-icon [attr.aria-label]="'SHOW_TOOLBAR_NEXT' | translate" fontSet="material-icons-outlined">chevron_right</mat-icon>
    </button>
    <mat-divider vertical style="height: 30px;"></mat-divider>
    <button mat-icon-button color="primary" [ngClass]="{'zoom-selection-mode': zoomOnSelectionEnabled}" matTooltip="{{'MAIN_TOOLBAR_ZOOM_SELECTION' | translate}} " (click)="zoomOnSelection()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM_SELECTION' | translate" svgIcon="selection-search">
        </mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_ZOOM-FIT' | translate}}" (click)="centerMap()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate">settings_overscan</mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_ZOOM_IN' | translate}}" (click)="zoom(+1)">
        <mat-icon aria-label="'MAIN_TOOLBAR_ZOOM-IN' | translate" fontSet="material-icons-outlined">zoom_in</mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_ZOOM_OUT' | translate}}" (click)="zoom(-1)">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-OUT' | translate" fontSet="material-icons-outlined">zoom_out</mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_QRCODE' | translate}}" (click)="showQRCode()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_QRCODE' | translate" fontSet="material-icons-outlined">qr_code</mat-icon>
    </button>
    <mat-divider vertical style="height: 30px;"></mat-divider>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_CLOSE_NODES' | translate}}" (click)="closeAllNodes()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_CLOSE_NODES' | translate" svgIcon="hide_node">
        </mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{'MAIN_TOOLBAR_OPEN_NODES' | translate}}" (click)="openAllNodes()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_OPEN_NODES' | translate" svgIcon="open_node">
        </mat-icon>
    </button>
    <mat-divider vertical style="height: 30px;"></mat-divider>
    <button *ngIf="!isFullscreen" mat-icon-button color="primary" [disabled]="!isLoaded" matTooltip="{{'FULLSCREEN' | translate}}" (click)="toggleFullscreen(true,false)">
        <mat-icon [attr.aria-label]="'FULLSCREEN' | translate" fontSet="material-icons-outlined">fullscreen</mat-icon>
    </button>
    <button *ngIf="isFullscreen" mat-icon-button color="primary" [disabled]="!isLoaded" matTooltip="{{'FULLSCREEN-EXIT' | translate}}" (click)="toggleFullscreen(false,false)">
        <mat-icon [attr.aria-label]="'FULLSCREEN-EXIT' | translate" fontSet="material-icons-outlined">fullscreen_exit</mat-icon>
    </button>
</div>

<div *ngIf="compressed" class="toolbar">
    <button mat-icon-button color="primary" [ngClass]="{'tts-mode': ttsEnabled}" matTooltip="{{'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate}}" (click)="TTSRead()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate" svgIcon="message-processing"></mat-icon>
    </button>
    <mat-divider vertical style="height: 30px;"></mat-divider>
    <button mat-icon-button color="accent" [disabled]="!isPrevEnabled" matTooltip="{{'SHOW_TOOLBAR_PREV' | translate}}" (click)="prevShowStep()">
        <mat-icon [attr.aria-label]="'SHOW_TOOLBAR_PREV' | translate" fontSet="material-icons-outlined">chevron_left</mat-icon>
    </button>
    <button mat-icon-button color="accent" [disabled]="!isNextEnabled" matTooltip="{{'SHOW_TOOLBAR_NEXT' | translate}}" (click)="nextShowStep()">
        <mat-icon [attr.aria-label]="'SHOW_TOOLBAR_NEXT' | translate" fontSet="material-icons-outlined">chevron_right</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="compressedToolbar">
        <mat-icon color="primary" fontSet="material-icons-outlined">more_vert</mat-icon>
    </button>
</div>

<mat-menu #compressedToolbar="matMenu">
    <button mat-menu-item color="primary" [ngClass]="{'zoom-selection-mode': zoomOnSelectionEnabled}" (click)="zoomOnSelection()">
        <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_ZOOM_SELECTION' | translate" svgIcon="selection-search"></mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM_SELECTION</span>
    </button>
    <button mat-menu-item color="primary" (click)="centerMap()">
        <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate">settings_overscan</mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM-FIT</span>
    </button>
    <button mat-menu-item color="primary" (click)="zoom(+1)">
        <mat-icon color="primary" aria-label="'MAIN_TOOLBAR_ZOOM-IN' | translate" fontSet="material-icons-outlined">zoom_in</mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM_IN</span>
    </button>
    <button mat-menu-item color="primary" (click)="zoom(-1)">
        <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-OUT' | translate" fontSet="material-icons-outlined">zoom_out</mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM_OUT</span>
    </button>
    <button mat-menu-item color="primary" (click)="showQRCode()">
        <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_QRCODE' | translate" fontSet="material-icons-outlined">qr_code</mat-icon>
        <span translate>MAIN_TOOLBAR_QRCODE</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item color="primary" [attr.aria-label]="'MAIN_TOOLBAR_CLOSE_NODES' | translate" (click)="closeAllNodes()">
        <mat-icon color="primary" svgIcon="hide_node"></mat-icon>
        <span translate>MAIN_TOOLBAR_CLOSE_NODES</span>
    </button>
    <button mat-menu-item color="primary" [attr.aria-label]="'MAIN_TOOLBAR_OPEN_NODES' | translate" (click)="openAllNodes()">
        <mat-icon color="primary" svgIcon="open_node"></mat-icon>
        <span translate>MAIN_TOOLBAR_OPEN_NODES</span>
    </button>
    <mat-divider></mat-divider>
    <button *ngIf="!isFullscreen" mat-menu-item color="primary" [attr.aria-label]="'FULLSCREEN' | translate" [disabled]="!isLoaded" (click)="toggleFullscreen(true,false)">
        <mat-icon color="primary" fontSet="material-icons-outlined">fullscreen</mat-icon>
        <span translate>FULLSCREEN</span>
    </button>
    <button *ngIf="isFullscreen" mat-menu-item color="primary" [disabled]="!isLoaded" [attr.aria-label]="'FULLSCREEN-EXIT' | translate" (click)="toggleFullscreen(false,false)">
        <mat-icon color="primary" fontSet="material-icons-outlined">fullscreen_exit</mat-icon>
        <span translate>FULLSCREEN-EXIT</span>
    </button>
</mat-menu>
