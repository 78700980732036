<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'PLEASE_WAIT'" [versioned]="true"></app-loader>

<div id="div-stamp-image" class="stamp-image"></div>
<div id="div-map-qrcode" class="stamp-image"></div>
<div id="div-map-qrcode-symbol" class="stamp-image"></div>
<div id="div-gdoc-qrcode-symbol" class="stamp-image"></div>
<div id="div-gdoc-qrcode" class="stamp-image"></div>

<div class="dim-screen">
    <img [src]="imageBase64" class="print-map" (load)="printImage()" />
</div>

<div *ngIf="!error" class="main-content">
    <div class="container-map">
        <div class="sm" [ngClass]="{'sm-loading':isLoading}">
            <canvas id="sm-canvas-grid" class="sm-canvas sm-canvas-grid direct"></canvas>
            <div #mapcontainer class="sm-canvas-base-container">
                <canvas id="sm-canvas-base" class="sm-canvas-base direct" [ngClass]="{'interaction-mode-readonly': isReadonlyMode}"></canvas>
            </div>
            <canvas id="sm-canvas-over" class="sm-canvas sm-canvas-over direct"></canvas>
            <canvas id="sm-canvas-save" class="sm-canvas"></canvas>
            <div id="sm-tmp" class="hidden"></div>
        </div>
    </div>
    <!-- </div> -->
    <div class="navbar">
        <div class="smx-logo-container">
            <img class="smx-logo-image" src="{{logoUrl}}?v=1" alt="logo" />
        </div>
        <app-show-toolbar [mapId]="mapStateService.id" [compressed]="compressedToolbar"></app-show-toolbar>
        <div class="spacer"></div>
        <div class="right-toolbar">
            <!-- RECORD VIDEO -->
            <div *ngIf="isVideoRecSupported" class="start-screen-recording recording-style-blue rec-button-container">
                <div class="rec-dot"></div>
                <span translate class="rec-button-text" color="primary">RECVIDEO_BUTTON</span>
            </div>
            <button *ngIf="isVideoRecSupported" mat-icon-button (click)="showRecordInfo()">
                <mat-icon color="primary" fontSet="material-icons-outlined">info</mat-icon>
            </button>
            <!--<button *ngIf="!isVideoRecSupported" class="fakerec-button" mat-stroked-button color="primary" (click)="showRecordNotSupported()">
                <mat-icon color="primary" fontSet="material-icons-outlined">radio_button_checked</mat-icon>
                <span translate>RECVIDEO_BUTTON</span>
            </button>-->

        </div>

    </div>
    <app-links-menu *ngIf="contextItems.length>0" [items]="contextItems"></app-links-menu>
</div>
<div *ngIf="error" class="container mat-typography">
    <mat-card appearance="outlined" class="message-container">
        <div class="message-title" translate>WARNING</div>
        <div class="message-text">{{error}}</div>
        <div class="ok-button" (click)="hideErrorGlobal()" translate>BUTTON_OK</div>
    </mat-card>
</div>
