<div class="custom-toolbar">
    <app-format-table-toolbar *ngIf="tableVisible"></app-format-table-toolbar>
    <mat-divider *ngIf="tableVisible && textVisible" [vertical]="true" style="height: 35px;"></mat-divider>
    <app-format-text-toolbar *ngIf="textVisible"></app-format-text-toolbar>
    <mat-divider *ngIf="formatVisible" [vertical]="true" style="height: 35px;"></mat-divider>
    <app-format-element-toolbar *ngIf="formatVisible"></app-format-element-toolbar>
    <mat-divider [vertical]="true" style="height: 35px;"></mat-divider>
    <app-image-toolbar></app-image-toolbar>
    <mat-divider [vertical]="true" style="height: 35px;"></mat-divider>
    <app-language-toolbar></app-language-toolbar>
</div>
