import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Logger } from '../../../core/logger.service';
import { SubscriptionDto } from '../subscription-dto';
import { AccessConfigService } from '../../access-config.service';
import { SmeService } from '../../../core/sme/sme.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ConfirmationService } from '../../../shared/dialog/confirmation.service';
import { extract } from '../../../core/i18n.service';
import { OrderConfigComponent } from './order-config/order-config.component';
import { MessageBoxService } from '../../../shared/dialog/messagebox.service';
import { MapStateService } from '../../../shared/map-state.service';
import { OrderDto } from '../order-dto';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

const logger: Logger = new Logger('order-list');

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
    @Input() openFirstElement = false;
    @Input() isLoading = false;
    @Input() enabledDetail = false;
    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();
    public isOperator: boolean = false;
    public noList = false;
    public searchedText: string = '';

    public searchForm: FormGroup = this.formBuilder.group({
        searchText: ['']
    });


    public editOrderForm: FormGroup = this.formBuilder.group({});

    @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
    @ViewChild('orderForm') orderForm: OrderConfigComponent | undefined;
    @ViewChild('searchtext') searchText: ElementRef | undefined;



    public length = 1;
    private pageIndex = 1;
    public pageSize = 10;
    // public pageSizeOptions: number[] = [5, 10, 25, 100];
    public pageEvent: PageEvent | undefined;
    public isLoaded = false;

    public subscriptions: SubscriptionDto[] | undefined;

    compressed: boolean = false;
    medium: boolean = false;
    minimumState: boolean = false;
    expanded: boolean = true;
    constructor(
        private formBuilder: FormBuilder,
        private mapStateService: MapStateService,
        private accessConfigService: AccessConfigService,
        private smeService: SmeService,
        private breakpointObserver: BreakpointObserver,
        // private googleService: GoogleService,
        // private router: Router,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private confirmationService: ConfirmationService,
        private messageBoxService: MessageBoxService) {
    }

    ngOnInit() {
        this.isLoaded = false;
        this.pageSize = 10;
        // this.searchForm = this.formBuilder.group({
        //   searchText: ['']
        // });

        // this.editOrderForm = this.formBuilder.group({});


        this.breakpointObserver
            .observe(['(max-width:400px)'])
            .subscribe((state: BreakpointState) => {
                this.minimumState = state.matches;
            })

        this.breakpointObserver
            .observe(['(max-width:950px)'])
            .subscribe((state: BreakpointState) => {
                this.medium = state.matches;
            })



        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;

            });

        if (this.accessConfigService.pageEvent) {
            this.pageEvent = this.accessConfigService.pageEvent;
            this.pageIndex = this.accessConfigService.pageEvent.pageIndex;
        } else {
            this.pageIndex = 0;
        }
        const role = this.authenticationService.credentials?.role;
        this.isOperator = (role === 'admin' || role === 'anastasis' || role === 'aid' || role === 'spain');
        if (!this.isOperator) {
            setTimeout(() => {
                this.loadData('');
            });
        } else {
            this.isLoading = false;
            this.endLoading.emit();
        }
        setTimeout(() => {
            this.searchText?.nativeElement.focus();
        }, 0);
    }

    loadData(search: string) {
        this.isLoading = true;
        this.startLoading.emit();

        this.accessConfigService.searchSubscriptions(search, true, this.pageIndex + 1, this.pageSize, 'expiryDate', false)
            .subscribe({
                next: (subs: any) => {
                    // console.log(subs);
                    this.length = (subs.params.numPages * this.pageSize) || 0;
                    if (this.accessConfigService.pageEvent) {
                        this.pageIndex = this.accessConfigService.pageEvent.pageIndex;
                    }
                    if (this.paginator) {
                        this.paginator.pageIndex = this.pageIndex;
                    }
                    this.subscriptions = [];
                    if (subs && subs.subs) {
                        for (let i = 0; i < subs.subs.length; i++) {
                            const subscription = new SubscriptionDto(subs.subs[i]);
                            subscription.findsDescription = this.getFindsDescription(subs.subs[i].finds);
                            // if (subscription.order.orderId === this.accessConfigService.orderId) {
                            //   subscription.panelOpenState = true;
                            // } else {
                            //   subscription.panelOpenState = false;
                            // }
                            this.subscriptions.push(subscription);
                            this.getOrderStatus(subscription);
                        }
                        this.noList = this.subscriptions.length === 0;
                        this.isLoading = false;
                        this.endLoading.emit();
                        this.searchedText = search;
                        this.isLoaded = true;
                    }
                },

                error: (error: any) => {
                    logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
                }
            });

    }

    public panelOpenState(subscription: any, open: boolean) {
        // console.log(`---------------subscription id=${subscription.order.orderId} - open=${open}`);
        subscription.panelOpenState = open;
        if (open && !subscription.order.ocrMathScansLeft) {
            this.smeService.getOcrMathPixScansLeft(subscription.order.orderId).subscribe({
                next: (_result: any) => {
                    subscription.order.ocrMathScansLeft = _result.result.scansLeft;
                },
                error: (error: any) => {
                }
            });
        }
    }

    public changePage(event: PageEvent) {
        this.pageIndex = event.pageIndex;
        this.pageEvent = event;
        this.accessConfigService.pageEvent = event;
        const textSearch = this.searchForm?.get('searchText')?.value;
        this.loadData(textSearch);
    }

    public reloadData() {
        const textSearch = this.searchForm?.get('searchText')?.value;
        this.loadData(textSearch);
    }

    public getLicenseType(licenseType: string, domainLics: number): string {
        switch (licenseType) {
            case 's':
                return this.translateService.instant('ACCESSCONFIG_LICENSE_SINGLE');
            case 'm':
                return this.translateService.instant('ACCESSCONFIG_LICENSE_MULTI');
            case 'd':
                const descNumLicenses = (domainLics <= 0 ?
                    this.translateService.instant('ACCESSCONFIG_UNLIMITED_LICENSES') :
                    this.translateService.instant('ACCESSCONFIG_LIMITED_LICENSES').replace('%s', domainLics)
                );
                return this.translateService.instant('ACCESSCONFIG_LICENSE_DOMAIN') + ' ' + descNumLicenses;
            default:
                return 'undefined';
        }
    }

    public getOriginType(origin: string) {
        return this.translateService.instant('ORIGIN_' + origin.toLocaleUpperCase());
    }

    public getRefOrderId(refId: string, lastAction: string) {
        let s = refId;
        if (lastAction) {
            s += this.translateService.instant('REFORDERID_' + lastAction.toUpperCase());
        }
        return s;
    }

    public getOrderStatus(subscription: any) {
        let status = '?';
        if (subscription) {
            subscription.isSuspended = false;
            subscription.isExpired = false;
            if (subscription.order.suspended === true) {
                status = this.translateService.instant('ACCESSCONFIG_ORDER_SUSPENDED');
                subscription.isSuspended = true;
            } else {
                const now = new Date().toISOString().slice(0, 10);
                if (now > subscription.order.dbExpireDate) {
                    status = this.translateService.instant('ACCESSCONFIG_ORDER_EXPIRED');
                    subscription.isExpired = true;
                } else {
                    status = this.translateService.instant('ACCESSCONFIG_ORDER_ACTIVE');
                }
            }
        }
        return status;
    }

    configAccess(sub: SubscriptionDto) {
        // const redirectUri = window.location.origin + '/access-config/';
        // window.open(redirectUri + order.order.orderId + '/' + order.order.randomId, '_blank');
        const role = this.authenticationService.credentials?.role;
        this.isOperator = (role === 'admin' || role === 'anastasis' || role === 'aid' || role === 'spain');
        if (this.enabledDetail && (!sub.order.suspended && !sub.isExpired) || this.isOperator) {
            this.accessConfigService.orderId = sub.order.orderId;
            let url = window.location.origin;
            if (sub.order.licenseType === 's') {
                url += `/single-user-config/${sub.order.orderId}/${sub.order.randomId}?operator=${this.isOperator}`;
                // this.router.navigate(['/single-user-config/', sub.order.orderId, sub.order.randomId], { queryParams: { operator: this.isOperator } });
            } else {
                url += `/access-config/${sub.order.orderId}/${sub.order.randomId}?operator=${this.isOperator}`;
                // this.router.navigate(['/access-config/', sub.order.orderId, sub.order.randomId], { queryParams: { operator: this.isOperator } });
            }
            window.open(url, '_blank');
        } else if (sub.order.suspended) {
            const msg = this.translateService.instant('ORDER-SUSPENDED');
            alert(msg);
        } else if (sub.isExpired) {
            const msg = this.translateService.instant('ORDER-EXPIRED');
            alert(msg);
        }
    }

    openStats(sub: SubscriptionDto) {
        if (sub && sub.order) {
            let url = window.location.origin;
            switch (sub.order.licenseType) {
                case 's':
                    url += '/user-stats/' + sub.access.admin.eMail;
                    break;
                case 'm':
                case 'd':
                    url += '/multi-stats/' + sub.order.orderId;
                    break;
            }
            window.open(url, '_blank');
        }
    }

    isAdmin() {
        const role = this.authenticationService.credentials?.role;
        const is = (role === 'admin');
        return is;
    }

    // isOperator() {
    //     const role = this.authenticationService.credentials?.role;
    //     const is = (role === 'anastasis' || role === 'admin' || role === 'aid' || role === 'spain');
    //     return is;
    // }

    isAnastasisOperator() {
        const role = this.authenticationService.credentials?.role;
        const is = (role === 'anastasis' || role === 'admin');
        return is;
    }

    canDeleteSubscription() {
        return this.isAdmin();
    }

    canSendAdminMail(sub: SubscriptionDto) {
        const can = (this.isOperator && sub.access && sub.access.admin && sub.access.admin.eMail);
        return can;
    }

    canSendMail() {
        return this.isAnastasisOperator();
    }

    canEditOrder() {
        return this.isAnastasisOperator();
    }

    resendEmail(subscription: SubscriptionDto, toAdmin: boolean) {
        let mailTo = subscription.order.userEmail;
        let name = subscription.order.fullName;
        if (toAdmin) {
            if (subscription.access && subscription.access.admin && subscription.access.admin.eMail) {
                mailTo = subscription.access.admin.eMail;
                name = subscription.access.admin.name;
            }
        }
        this.accessConfigService.sendMail(subscription.order.orderId, subscription.order.randomId, mailTo, name)
            .subscribe((resp: any) => {
                if (resp.ok) {
                    alert(this.translateService.instant('MAIL_SENT'));
                }
            });
    }

    isDirty(subscription: SubscriptionDto) {
        const dirty = (this.orderForm && this.orderForm.orderForm.dirty) ? this.orderForm.orderForm.dirty : false;
        if (dirty) {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('EXIT_MESSAGE')),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('WAIT')))
                .subscribe({
                    next: result => {
                        if (result) {
                            // dirty = false;
                            subscription.showOrder = !subscription.showOrder;
                        }
                    },
                    error: (error: any) => {
                        logger.error(error);
                    }
                });
        } else {
            subscription.showOrder = !subscription.showOrder;
        }
    }

    showUpdateOrder(subscription: SubscriptionDto) {
        if (subscription.showOrder) {
            this.isDirty(subscription);
        } else {
            subscription.showOrder = !subscription.showOrder;
        }
    }

    isEnableUpdateOrder(): boolean {
        let result = false;
        if (this.orderForm && this.orderForm.orderForm && this.orderForm.orderForm.valid && !this.orderForm.orderForm.pristine) result = true;
        return result;
    }

    updateOrder(subscription: SubscriptionDto) {
        // updateOrder
        if (this.orderForm?.orderForm.valid) {
            subscription.order.userType = this.orderForm.orderForm.get('selectUserType')?.value;
            subscription.order.planId = this.orderForm.orderForm.get('planId')?.value;
            subscription.order.userEmail = this.orderForm.orderForm.get('userEmail')?.value;
            subscription.order.fullName = this.orderForm.orderForm.get('fullName')?.value;
            subscription.order.slotCount = this.orderForm.orderForm.get('slotCount')?.value;
            subscription.order.domainLics = this.orderForm.orderForm.get('domainLics')?.value | 0;
            //
            const orderForm = this.orderForm.orderForm;
            if (orderForm) {
                const date = orderForm.get('startDate');
                if (date) {
                    const sd = date.value as Date;
                    if (sd) {
                        sd.setHours(12); // evita il problema di mezzanotte che retrocede alle 23 del giorno prima!
                        subscription.order.dbStartDate = sd.toISOString().substr(0, 10);
                        const [syear, smonth, sday]: string[] = subscription.order.dbStartDate.split('-');
                        subscription.order.startDate = `${sday}/${smonth}/${syear}`;
                    }
                }
                const d = orderForm.get('expireDate');
                if (d) {

                    //
                    const ed = d.value as Date;
                    ed.setHours(12); // evita il problema di mezzanotte che retrocede alle 23 del giorno prima!
                    subscription.order.dbExpireDate = ed.toISOString().substr(0, 10);
                    const [eyear, emonth, eday]: string[] = subscription.order.dbExpireDate.split('-');
                    subscription.order.expireDate = `${eday}/${emonth}/${eyear}`;
                }
                //
                subscription.order.origin = this.orderForm.orderForm.get('origin')?.value;
                subscription.order.suspended = this.orderForm.orderForm.get('suspended')?.value;
                subscription.order.notes = this.orderForm.orderForm.get('notes')?.value;
                subscription.order.ocrMathScanQuota = this.orderForm.orderForm.get('ocrMathScanQuota')?.value;
                subscription.order.ocrMathScanPolicy = this.orderForm.orderForm.get('ocrMathScanPolicy')?.value;
                const operator = this.authenticationService.getUserEmail();
                this.accessConfigService.updateOrder(subscription.order.orderId, subscription.order.randomId, subscription.order.userEmail, subscription.order.fullName, subscription.order.dbStartDate, subscription.order.dbExpireDate, subscription.order.origin, subscription.order.suspended, subscription.order.notes, operator, subscription.order.slotCount, subscription.order.domainLics, subscription.order.planId, subscription.order.ocrMathScanQuota, subscription.order.ocrMathScanPolicy, subscription.order.userType)
                    .subscribe((resp: any) => {
                        alert(this.translateService.instant('ORDER_MODIFIED'));
                        // subscription.order = resp.order;
                        subscription.order.lastModificationDate = resp.order.lastModificationDate;
                        subscription.order.lastModificationBy = resp.order.lastModificationBy;
                        subscription.order.z_history = resp.order.z_history;
                        subscription.showOrder = false;
                    });
            }
        }
    }

    cancelOrderUpdate(subscription: SubscriptionDto) {
        subscription.showOrder = false;

    }

    historyExists(order: OrderDto) {
        let exists = false;
        if (order && order.z_history) {
            const orderHistoryKeys = Object.keys(order.z_history);
            exists = (orderHistoryKeys.length > 0);
        }
        return exists;
    }

    showHistory(order: OrderDto) {
        this.messageBoxService.showOrderHistory(order);
    }

    resetSearch() {
        this.pageIndex = 0;
        this.pageEvent = undefined;
        this.accessConfigService.pageEvent = undefined;
        this.searchForm?.get('searchText')?.setValue('');
        this.loadData('');
    }

    onSubmit() {
        this.pageIndex = 0;
        this.pageEvent = undefined;
        this.accessConfigService.pageEvent = undefined;
        this.search();
    }

    search() {
        const textSearch = this.searchForm?.get('searchText')?.value;
        this.loadData(textSearch);
    }

    getFindsDescription(finds: any[]) {
        let s = '';
        if (finds && finds.length > 0) {
            for (let i = 0; i < finds.length; i++) {
                const find = finds[i];
                if (s !== '') {
                    s += ', ';
                }
                s += this.translateService.instant(find.where);
                if (find.pos >= 0) {
                    s += (find.pos + 1);
                }
            }
        }
        return this.translateService.instant('FOUND_IN') + ' ' + s;
    }

    goToWebsiteClick(from: string, type: string) {
        this.mapStateService.goToWebsiteClick(from, type);
    }

    renewSubscription(subscription: SubscriptionDto) {
        let ok = false;
        if (subscription && subscription.order && subscription.order.orderId && subscription.order.licenseType) {
            // if (subscription.order.origin === 'demo') {
            //   // Demo: manda alla pagina di SuperMappeX con le offerte commerciali
            //   window.open('https://www.supermappex.it', '_blank');
            //   ok = true;
            // } else
            if (subscription.order.origin === 'doposcuola') {
                // Doposcuola: rinnovare convenzione o tramite servizio clienti
                ok = true;
                this.messageBoxService.showRenewAfterSchoolContactUs();
            } else if (
                (subscription.order.origin !== 'manual' && subscription.order.origin !== 'buy' && subscription.order.origin !== 'demo') ||
                (subscription.order.licenseType === 's' && subscription.order.planId === 'edu') ||
                subscription.order.licenseType !== 's') {
                // Utente che non ha acquistato (es: licenza omaggio dal webinar o scuola attivata da AID)
                // Oppure singolo utente con piano EDU
                // Oppure scuola o classe
                // -> messaggio con link per andare a contatti anastasis
                ok = true;
                this.messageBoxService.showRenewContactUs();
            } else {
                // Utente che ha acquistato
                // -> sito anastasis con carrello precaricato
                const productId = this.getSMXProductId(subscription.order.origin, subscription.order.licenseType, subscription.order.domainLics);
                if (productId) {
                    ok = true;
                    // TODO: aggiungere parametro edu/lab
                    const url = `https://www.anastasis.it/carrello/?add-to-cart=${productId}&ana_supermappe_ref_order_id=${subscription.order.orderId}`;
                    window.open(url, '_blank');
                }
            }
        }
        if (!ok) {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('ACCESSCONFIG_RENEW_INVALID_ORDER')),
                this.translateService.instant(extract('BUTTON_OK')),
                '');
        }
    }

    deleteSubscription(sub: SubscriptionDto) {
        this.confirmationService.confirm(
            this.translateService.instant(extract('WARNING')),
            this.translateService.instant(extract('DELETE_CONFIRM')).replace(/§orderId§/g, sub.order.orderId),
            this.translateService.instant(extract('BUTTON_OK')),
            this.translateService.instant(extract('CANCEL')))
            .subscribe({
                next: result => {
                    if (result) {
                        this.isLoading = true;
                        this.startLoading.emit();
                        this.smeService.deleteSubscription(sub.order.orderId).subscribe({
                            next: (_result: any) => {
                                // console.log(subs);
                                if (_result.result) {
                                    this.isLoading = false;
                                    this.endLoading.emit();
                                    this.confirmationService.confirm(
                                        '',
                                        this.translateService.instant(extract('ACCESSCONFIG_MSG_DELETE_OK')),
                                        this.translateService.instant(extract('BUTTON_OK')),
                                        '').subscribe(() => {
                                            this.reloadData();
                                        });
                                } else {
                                    this.isLoading = false;
                                    this.endLoading.emit();
                                    this.confirmationService.confirm(
                                        '',
                                        this.translateService.instant(extract('ACCESSCONFIG_MSG_DELETE_FAIL')),
                                        this.translateService.instant(extract('BUTTON_OK')),
                                        '').subscribe(() => { });
                                }
                            },
                            error: (error: any) => {
                                this.isLoading = false;
                                this.endLoading.emit();
                                logger.error(this.translateService.instant('ACCESSCONFIG_MSG_DELETE_FAIL') + '\n' + error.message);
                                this.confirmationService.confirm(
                                    '',
                                    this.translateService.instant(extract('ACCESSCONFIG_MSG_DELETE_FAIL')),
                                    this.translateService.instant(extract('BUTTON_OK')),
                                    '').subscribe(() => { });
                            }
                        });
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.endLoading.emit();
                    logger.error(error);
                }
            });
    }

    /**
     * URL base: https://www.anastasis.it/carrello/?add-to-cart=<product id>&ana_supermappe_ref_order_id=<id-ordine-vecchio>
     * product Id:
     * Licenza singola (s): 64386
     * Licenza multipla (m): 64821
     * Licenza dominio (d) account illimitati: 64818
     * Licenza dominio (d) max 300 account: 64820
     * Licenza dominio (d) max 600 account: 64819
     */
    private getSMXProductId(origin: string, licenseType: string, domainLics: number): string {
        let id = '';
        if (licenseType === 's') {
            id = '152210';
            // // Licenza singola
            // if (origin === 'demo') {
            //     // Primo acquisto da demo
            //     id = '152210';
            // } else {
            //     // Rinnovo
            //     id = '64386';
            // }
        } else if (licenseType === 'm') {
            // Licenza multipla (30 utenti)
            id = '64821';
        } else if (licenseType === 'd') {
            if (domainLics <= 0) {
                // Licenza dominio con account illimitati
                id = '64818';
            } else if (domainLics <= 300) {
                // Licenza dominio con 300 account (1-300)
                id = '64820';
            } else if (domainLics <= 600) {
                // Licenza dominio con 600 account (301-600)
                id = '64819';
            } else {
                // 601 account e oltre
                // Non era 0 o negativo, ma neanche una taglia riconosciuta
                // Licenza dominio con account illimitati
                id = '64818';
            }
        }
        return id;
    }

    private lead0(n: number, len: number) {
        let s1 = String(n);
        while (s1.length < len) {
            s1 = '0' + s1;
        }
        return s1;
    }

    public toLocalDateTime(dbDate: string) {
        // In formato yyyy-MM-ddThh:mm.xxx:ssZ
        let s = '';
        if (dbDate) {
            const d = new Date(dbDate);
            s =
                this.lead0(d.getDate(), 2) + '/' +
                this.lead0(d.getMonth() + 1, 2) + '/' +
                this.lead0(d.getFullYear(), 4) + ' ' +
                this.lead0(d.getHours(), 2) + ':' +
                this.lead0(d.getMinutes(), 2) + ':' +
                this.lead0(d.getSeconds(), 2);
        }
        return s;
    }

    public toLocalLocked(locked: boolean) {
        let s = '';
        if (locked === true) {
            s = this.translateService.instant('ACCESSCONFIG_LOCKED_TRUE');
        } else {
            s = this.translateService.instant('ACCESSCONFIG_LOCKED_FALSE');
        }
        return s;
    }

}

