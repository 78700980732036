import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../../../core/route.service';
import { extract } from '../../../core/i18n.service';
import { PdfPrintComponent } from './pdf-print.component';
import { AuthenticationGuard } from '../../../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
    { path: 'pdfprint/:filename', component: PdfPrintComponent, data: { title: extract('SMX: Print PDF') }, canActivate: [AuthenticationGuard] }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})
export class PdfPrintRoutingModule { }
