import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import {  CoreModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LinksMenuComponent } from './links-menu/links-menu.component';
import { DialogDeepComponent } from './links-menu/dialog-deep/dialog-deep.component';
import { DialogImageCropperComponent } from '../map-edit/dialog-image-cropper/dialog-image-cropper.component';
import { ModalService } from './links-menu/modal-service';
import { MapEditorComponent } from './map-editor/map-editor.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmationService } from './dialog/confirmation.service';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { InputDialogService } from './dialog/input-dialog.service';
import { MccColorPickerModule } from 'material-community-components/color-picker';
import { SpeechRecognitionService } from './speech/speechrecognition.service';
import { MapClipboardService } from '../map-edit/commands/map-clipboard.service';
import { GoogleImagesService } from './commands/google.image.service';
import { CopyPastePopupComponent } from './copy-paste-popup/copy-paste-popup.component';
import { ModalPositionCache } from './links-menu/modal-position.cache';
import { DialogDraggableTitleDirective } from './links-menu/dialog-draggable-title.directive';
import { ShareMapComponent } from './share-map/share-map.component';
import { ShareMapService } from './share-map/share-map.service';
import { GoogleClassroomComponent } from './google-classroom/google-classroom.component';
import { ShareLinkService } from './commands/share-link.service';
import { WebSearchService } from './commands/web-search.service';
import { MapFindService } from './map-find/map-find.service';
import { MapFindComponent } from './map-find/map-find.component';
import { CreateGoogleDocService } from './commands/create-google-doc.service';
import { CreateMapDocumentService } from './commands/create-map-document.service';
import { DialogPreferencesComponent } from '../map-edit/dialog-preferences/dialog-preferences.component';
import { DialogQRCodeComponent } from '../map-edit/dialog-qrcode/dialog-qrcode.component';
import { TokenInterceptor } from '../core/http/token.interceptor';
import { ImportMapService } from './commands/import-map.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DialogImageComponent } from './links-menu/dialog-image/dialog-image.component';
import { MenuNotificationComponent } from './menu-notification/menu-notification.component';
import { MessageBoxDialogComponent } from './messagebox-dialog/messagebox-dialog.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { MessageBoxService } from './dialog/messagebox.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersToolbarComponent } from './users-toolbar/users-toolbar.component';
import { UsersService } from './commands/users.service';
import { RealTimeMarkersComponent } from './real-time-markers/real-time-markers.component';
import { LinksService } from './links.service';
import { ChatService } from './chat/chat.service';
import { MatChipsModule } from '@angular/material/chips';
import { PasteTabComponent } from './paste-tab/paste-tab.component';
import { PasteBulletsComponent } from './paste-bullets/paste-bullets.component';
import { PasteStructureComponent } from './paste-structure/paste-structure.component';
import { ConfirmMathOCRDialogComponent } from './confirm-math-ocrdialog/confirm-math-ocrdialog.component';
import { ConfirmExitDialogComponent } from './confirm-exit-dialog/confirm-exit-dialog.component';

@NgModule({
    imports: [
        MaterialModule,
        MatChipsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MccColorPickerModule.forRoot({
            empty_color: 'transparent',
            selected_icon: '',
            disable_selected_icon: true,
            enable_alpha_selector: false
        }),
        ImageCropperModule,
        TranslateModule.forRoot(),
        RouterModule
    ],
    declarations: [
        LoaderComponent,
        HeaderComponent,
        LinksMenuComponent,
        MapEditorComponent,
        ConfirmDialogComponent,
        InputDialogComponent,
        DialogImageComponent,
        DialogDeepComponent,
        DialogImageCropperComponent,
        GoogleClassroomComponent,
        CopyPastePopupComponent,
        DialogPreferencesComponent,
        DialogQRCodeComponent,
        DialogDraggableTitleDirective,
        ShareMapComponent,
        MapFindComponent,
        MenuNotificationComponent,
        MessageBoxDialogComponent,
        OrderHistoryComponent,
        UsersToolbarComponent,
        RealTimeMarkersComponent,
        PasteTabComponent,
        PasteBulletsComponent,
        PasteStructureComponent,
        ConfirmMathOCRDialogComponent,
        ConfirmExitDialogComponent
    ],
    // entryComponents: [
    //     DialogDeepComponent,
    //     DialogImageCropperComponent,
    //     DialogImageComponent,
    //     ConfirmDialogComponent,
    //     CopyPastePopupComponent,
    //     DialogPreferencesComponent,
    //     DialogQRCodeComponent,
    //     InputDialogComponent,
    //     ShareMapComponent,
    //     MessageBoxDialogComponent,
    //     OrderHistoryComponent,
    // ],
    providers: [
        MatChipsModule,
        TokenInterceptor,
        ModalService,
        ConfirmationService,
        InputDialogService,
        SpeechRecognitionService,
        MapClipboardService,
        GoogleImagesService,
        ModalPositionCache,
        ShareMapService,
        ShareLinkService,
        WebSearchService,
        MapFindService,
        CreateGoogleDocService,
        CreateMapDocumentService,
        ImportMapService,
        MessageBoxService,
        UsersService,
        LinksService,
        ChatService
    ],
    exports: [
        LoaderComponent,
        HeaderComponent,
        MenuNotificationComponent,
        LinksMenuComponent,
        MapEditorComponent,
        MapFindComponent,
        UsersToolbarComponent,
        RealTimeMarkersComponent
    ]
})
export class SharedModule { }
