import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MapNewRoutingModule } from './map-new-routing.module';
import { MapNewComponent } from './map-new.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        CoreModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatTooltipModule,
        MatInputModule,
        MapNewRoutingModule,
    ],
    declarations: [
        MapNewComponent
    ],
    providers: [
    ]
})
export class MapNewModule { }
