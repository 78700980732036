<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a><br />
        <span class="access-subtitle">
            {{'REPORT_ADMIN_TITLE' | translate}}
        </span>
    </div>

    <div *ngIf="role==='admin'" class="stats-admin">
        <hr />
        <div class="access-section">Congelamento statistiche</div>
        <span>anno:</span><input [(ngModel)]="freeze_year" />
        <br />
        <br />
        <span>mese:</span><input [(ngModel)]="freeze_month" />
        <br />
        <br />
        <button mat-button class="stat-button" (click)="freezeSubs(freeze_year, freeze_month)">
            <span>Congela statistiche ordini per anno/mese</span>
        </button>
    </div>

    <div *ngIf="role==='admin'" class="stats-admin">
        <hr />
        <div class="access-section">Estrazione CSV statistiche</div>
        <label>Seleziona un'origine:</label>
        <select [(ngModel)]="stats_origin">
            <option *ngFor="let o of origins" [ngValue]="o.id">{{o.name | translate}}</option>
        </select>
        <br />
        <br />
        <span>anno:</span><input type="number" [(ngModel)]="stats_year" />
        <br />
        <button mat-button class="stat-button" (click)="getStatsByOriginAndYear(stats_origin, stats_year)">
            <span>Scarica statistiche per origine e anno</span>
        </button>
        <br />
        <mat-progress-bar class="stat-progress" color="primary" mode="determinate" [value]="stat_progress">
        </mat-progress-bar>
    </div>

    <div *ngIf="role==='admin'" class="stats-admin">
        <hr />
        <div class="access-section">Estrazione CSV Domini per l'anno</div>
        <span>anno:</span><input type="number" [(ngModel)]="domains_year" />
        <br />
        <br />
        <button mat-button class="stat-button" (click)="getDomainsStatsByYear(domains_year)">
            <span>Scarica statistiche Domini per anno</span>
        </button>
    </div>

</div>
