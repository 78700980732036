import { Component, OnInit, ViewChild, Input, OnChanges, ElementRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
// import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationsDto } from '../shared/notifications-data-dto';
import { SmeService } from '../core/sme/sme.service';
import { Subscription } from 'rxjs';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminEditNotificationComponent } from '../admin-edit-notification/admin-edit-notification.component';

const logger: Logger = new Logger('admin-list-notifications');

@Component({
    selector: 'app-admin-list-notifications',
    templateUrl: './admin-list-notifications.component.html',
    styleUrls: ['./admin-list-notifications.component.scss']

})
export class AdminListNotificationsComponent implements OnInit, OnChanges {

    // @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() isLoading = false;
    @Input() forceReload = false;

    editNotificationForm: FormGroup = this.formBuilder.group({

    });


    @ViewChild('notificationForm') notificationForm: AdminEditNotificationComponent | undefined;
    @ViewChild('searchtext') searchText: ElementRef | undefined;

    public noList = false;
    public searchedText: string | undefined;
    public searchForm: FormGroup = this.formBuilder.group({

        searchText: ['']

    });

    public length = 1;
    private pageIndex = 1;
    public pageSize = 10;
    public pageSizeOptions: number[] = [5, 10, 25, 100];
    // public pageEvent: PageEvent;
    public isLoaded = false;
    // notificationEdit = false;
    notificationData: NotificationsDto | undefined;

    public notifications: NotificationsDto[] | undefined;
    listNotificationsSubs: Subscription | undefined;

    editNotication = false;
    private initialized = false;

    insertNotificationSubscription: Subscription | undefined;

    ngOnChanges() {
        // create header using child_id
        // console.log(this.forceReload);
        if (this.initialized) {
            this.reloadData();
        }
    }

    constructor(private formBuilder: FormBuilder, private smeService: SmeService, private translateService: TranslateService) { }


    ngOnInit() {
        this.isLoaded = false;
        this.pageSize = 10; // JHUBA: 3 = valore per test con pochi ordini

        // this.searchForm = this.formBuilder.group({
        //   searchText: ['']
        // });
        // // this.editNotificationForm = this.formBuilder.group({});


        // if (this.accessConfigService.pageEvent) {
        //   this.pageEvent = this.accessConfigService.pageEvent;
        //   this.pageIndex = this.accessConfigService.pageEvent.pageIndex;
        // } else {
        this.pageIndex = 0;
        // }
        setTimeout(() => {
            this.loadData('');
            this.initialized = true;
        });
    }

    loadData(search: string) {
        this.isLoading = true;

        this.listNotificationsSubs = this.smeService.adminListNotifications()
            .subscribe({
                next: (subs: any) => {
                    // console.log(subs);

                    // this.paginator.pageIndex = this.pageIndex;
                    this.notifications = [];
                    if (subs && subs.notifications) {

                        // tslint:disable-next-line:forin
                        for (const n in subs.notifications) {

                            const el = new NotificationsDto(subs.notifications[n], null);
                            el.id = n;
                            let add = true;
                            if (search !== '') {
                                if (!el.notes.includes(search) && !el.title.includes(search) && !el.link.includes(search) && !el.dateCreated.toISOString().includes(search)) {
                                    add = false;
                                }
                            }
                            if (add) {
                                this.notifications.push(el);
                            }
                        }
                    }

                    this.noList = this.notifications.length === 0;
                    this.length = (this.notifications.length);
                    this.isLoading = false;
                    this.searchedText = search;
                    this.isLoaded = true;

                },
                error: (error: any) => {
                    logger.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
                }
            });

    }

    // public changePage(event: PageEvent) {
    //   this.pageIndex = event.pageIndex;
    //   this.pageEvent = event;
    //   const textSearch = this.searchForm.get('searchText').value;
    //   this.loadData(textSearch);
    // }

    public reloadData() {
        const textSearch = this.searchForm?.get('searchText')?.value;
        this.loadData(textSearch);
    }


    resetSearch() {
        this.pageIndex = 0;
        //  this.pageEvent = undefined;

        this.searchForm?.get('searchText')?.setValue('');
        this.loadData('');
    }

    onSubmit() {
        this.pageIndex = 0;
        // this.pageEvent = undefined;
        this.search();
    }

    search() {
        const textSearch = this.searchForm?.get('searchText')?.value;
        this.loadData(textSearch);
    }

    editNotification(event: NotificationsDto) {
        //
        event.edit = !event.edit;
    }

    updateForm(notification: NotificationsDto) {
        if (this.notificationForm?.notificationForm.invalid || this.notificationForm?.notificationForm.pristine) { return; }

        //  this.notificationData = new NotificationsDto('');
        this.saveData();

    }

    canSave() {

        return (this.notificationForm && this.notificationForm.notificationForm && this.notificationForm.notificationForm.valid && !this.notificationForm.notificationForm.pristine);
    }

    saveData() {
        if (this.notificationForm?.notificationData) {

            this.notificationForm.notificationData.active = this.notificationForm?.notificationForm.get('active')?.value;

            const d = this.notificationForm.notificationForm.get('dateCreated')?.value;
            d.setHours(12); // evita il problema di mezzanotte che retrocede alle 23 del giorno prima!
            this.notificationForm.notificationData.dateCreated = d.toISOString().substr(0, 10);
            if (this.notificationForm.selectedNotification) {
                this.notificationForm.notificationData.typeIcon = this.notificationForm.selectedNotification.value;

                this.notificationForm.notificationData.icon = this.notificationForm.selectedNotification.icon;
            }
            this.notificationForm.notificationData.link = this.notificationForm.notificationForm.get('link')?.value;
            this.notificationForm.notificationData.notes = this.notificationForm.notificationForm.get('notes')?.value;
            this.notificationForm.notificationData.title = this.notificationForm.notificationForm.get('title')?.value;

            this.insertNotificationSubscription = this.smeService.insertOrUpdateNotification(this.notificationForm.notificationData)
                .subscribe({
                    next: (id: any) => {
                        // this.router.navigate(['admin-page']);
                        alert('modifica effettuata');
                        if (this.notificationForm && this.notificationForm.notificationData) {
                            this.notificationForm.notificationData.edit = false;
                        }

                    },
                    error: (error: any) => {
                        alert(error);
                        logger.error(error);
                    }
                });

        }
    }

    // insertData() {
    //   this.notificationData = new NotificationsDto('');
    //   this.notificationData.active = this.notificationForm.notificationForm.get('active').value;
    //   this.notificationData.dateCreated = this.notificationForm.notificationForm.get('dateCreated').value.toISOString();
    //   this.notificationData.icon = this.notificationForm.selectedNotification.icon;
    //   this.notificationData.link = this.notificationForm.notificationForm.get('link').value;
    //   this.notificationData.notes = this.notificationForm.notificationForm.get('notes').value;
    //   this.notificationData.title = this.notificationForm.notificationForm.get('title').value;
    //   this.insertNotificationSubscription = this.smeService.insertOrUpdateNotification(this.notificationData)
    //     .subscribe((id: any) => {
    //       // this.router.navigate(['admin-page']);
    //       this.notificationData.id = id;
    //       this.notificationForm.notificationForm.get('notificationId').setValue(id);

    //     }, (error: any) => {
    //       logger.error(error);
    //     });
    // }

    enabledGroup() {
        return true;
    }

    resetForm() {
        setTimeout(() => {

            this.notificationForm?.notificationForm.markAsPristine();
            //
            // this.router.navigate(['admin-page']);
        });
    }



}
