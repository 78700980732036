import { Component, OnInit, HostListener } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title, DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import { environment } from "../environments/environment";
import { Logger } from "./core/logger.service";
import { I18nService } from "./core/i18n.service";

import { MatIconRegistry } from "@angular/material/icon";

const logger = new Logger("App");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // @HostListener('document:contextmenu', ['$event'])
  // hideDefaultCtxMenu() {
  //   return false;
  // }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  ngOnInit() {
    // Setup logger
    if (environment.production || environment.test) {
      Logger.enableProductionMode();
    }
    logger.debug("init");

    if (!environment.test && !environment.production) {
      // Uglify UI in dev environment
      console.warn("Setting .env-dev CSS class on body!");
      console.log(JSON.stringify(document.body.classList));
      document.body.classList.add("env-dev");
      console.log(JSON.stringify(document.body.classList));
    } else if (environment.test) {
      // Uglify UI in test environment
      console.warn("Setting .env-test CSS class on body!");
      console.log(JSON.stringify(document.body.classList));
      document.body.classList.add("env-test");
      console.log(JSON.stringify(document.body.classList));
    }

    // // Init Firebase --> spostata nel modulo
    // this.firebaseService.initializeApp();

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    environment.supportedLanguages.forEach((element) => {
      this.translateService.getTranslation(element);
    });

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event["title"];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  private registerIcons() {
    this.matIconRegistry.addSvgIcon(
      "open_node",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/apri_nodi.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "hide_node",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/nascondi_nodi.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "recent_list_item",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/home/recent_list_item.svg")
    );
    //
    this.matIconRegistry.addSvgIcon(
      "border-1",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/spessore1.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "border-2",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/spessore2.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "border-3",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/spessore3.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "border-4",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/spessore4.svg")
    );
    //sostituita con material ma in un componente c'e' ancora perche' usata come icona runtime
    this.matIconRegistry.addSvgIcon(
      "pencil01",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/pencil-01.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "marker01",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/marker-01.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "inside",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/testo dentro.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "over",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/testo sopra.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "under",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/testo sotto.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "none",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/testo nascosto.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "remove-column",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/elimina colonna-01.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "insert-column-dx",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/inserisci colonna Dx-01.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "insert-column-sx",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/inserisci colonna Sx-01.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "roundrect",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/arrotondato.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "ellipse",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/ovale.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "rectangle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/rettangolo.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "text",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/solo testo.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "image-edit-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/image-edit-outline.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "flipvertical",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/flip-vertical.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "node-shape",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/forma.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "text-position",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/posizione testo.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "ins-map",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/imm mappa.svg")
    );

    //c'e' anche la versione material ma e' da colorare. la versione in svg e' colorata di blu/azzurro ggole
    this.matIconRegistry.addSvgIcon(
      "doc-google",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/doc-google.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "link-box-variant",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/link-box-variant.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "qrcode-24",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/qrcode-24.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "printer-settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/printer-settings.svg")
    );

    //c'e' anche la versione material ma ha lettere maiuscole
    this.matIconRegistry.addSvgIcon(
      "alphabetical",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/alphabetical.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "selection-search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/selection-search.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "add-deep",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/inserisci approf.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "account-details",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/account-details.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "arrow-down-circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/arrow-down-circle.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "account-check-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/account-check-outline.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "account-cancel-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/account-cancel-outline.svg")
    );

    //esiste anche material ma non filled come la nostra...c'e' solo outlined
    this.matIconRegistry.addSvgIcon(
      "lock-open",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/lock_open.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "add-node",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/inserisci_nodo.svg")
    );

    //sostituita con material ma ancora necessaria per un'icona runtime
    this.matIconRegistry.addSvgIcon(
      "tavolozza",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/tavolozza.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "arrange-map",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/arrange.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "outline-crea-figlio",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/outline_crea_figlio.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "outline-crea-fratello",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/outline_crea_fratello.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "latex",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/toolbar/latex.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "facebookIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/facebook.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "classroomIcon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/classroom.svg")
    );
  }
}
