import { Injectable, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SmService } from 'supermappe-core';

export enum ExtraItem {
    CLOSE = 'close',
    OPEN_NOTEPAD = 'open_notepad',
    OPEN_OUTLINE = 'open_outline',
    OPEN_BROWSER = 'open_browser',
    OPEN_PDF = 'open_pdf',
    OPEN_AUTODRAW = 'open_autodraw'
}

@Injectable({
    providedIn: 'root'
})
export class ExtraService {

    public onToggleExtraPanel = new EventEmitter<ExtraOpenDto>();
    public onExtraPanelContentChanged = new EventEmitter<ExtraOpenDto>();
    public onExtraPanelResized = new EventEmitter<number>();
    public isExtraPanelOpen = false;
    public lastOpenDto = new ExtraOpenDto(ExtraItem.CLOSE, '');

    constructor(
        private smService: SmService,
        private domSanitizer: DomSanitizer
    ) {
        this.lastOpenDto = new ExtraOpenDto(ExtraItem.CLOSE, this.domSanitizer.bypassSecurityTrustResourceUrl(''))
    }

    public hasNotes(): boolean {
        const notesData = this.smService.getNotes();
        const has = (notesData && (notesData.title !== '' || notesData.textHtml !== ''));
        return has;
    }

    public toggleExtraPanel(extraItem: ExtraItem, url: string = '', forced: boolean = false): boolean {
        if (extraItem === ExtraItem.OPEN_AUTODRAW) {
            url = 'https://www.autodraw.com/';
        }
        console.log('Toggle Extra Panel: ' + extraItem + ' url: ' + url);

        const newExtraDto = new ExtraOpenDto(extraItem, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
        const prevExtraDto = new ExtraOpenDto(this.lastOpenDto.extraItem, this.lastOpenDto.safeUrl);

        this.lastOpenDto = newExtraDto;

        this.isExtraPanelOpen = (extraItem !== ExtraItem.CLOSE);

        this.onToggleExtraPanel.emit(this.lastOpenDto);

        if (prevExtraDto.extraItem !== extraItem || forced) {
            this.onExtraPanelContentChanged.emit(this.lastOpenDto);
        }

        return this.isExtraPanelOpen;
    }

    public updateExtraPanel(width: number = -1) {
        this.onToggleExtraPanel.emit(this.lastOpenDto);
        if (width != -1)
            this.onExtraPanelResized.emit(width);
    }
}

export class ExtraOpenDto {
    public extraItem: ExtraItem = ExtraItem.CLOSE;
    public safeUrl: SafeResourceUrl;

    constructor(extraItem: ExtraItem, safeUrl: SafeResourceUrl = '') {
        this.extraItem = extraItem;
        this.safeUrl = safeUrl;

    }
}

