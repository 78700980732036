import { Component } from '@angular/core';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialogRef } from '@angular/material/dialog';
import { UiConstants } from 'src/app/shared/ui-constants';
import { UserPreferenceService } from 'src/app/shared/user-preference.service';

@Component({
    selector: 'app-math-keyboard-choice',
    templateUrl: './math-keyboard-choice.component.html',
    styleUrls: ['./math-keyboard-choice.component.scss']
})
export class MathKeyboardChoiceComponent {

    public baseKeyboardType: string = 'BASIC';

    constructor(
        public dialogRef: MatDialogRef<MathKeyboardChoiceComponent>,
        private userPreferenceService: UserPreferenceService
    ) {
        this.updateKeyboards();
    }

    onKeyboardLayoutToggleGroupChanged($event: MatButtonToggleChange) {
        const e = $event as MatButtonToggleChange
        const toggle = e.source as MatButtonToggle;
        const value = toggle.buttonToggleGroup.value;
        this.baseKeyboardType = value;
        this.updateKeyboards();
    }

    updateKeyboards() {
        if (this.userPreferenceService.userPrefs && this.userPreferenceService.userPrefs.math) {
            if (this.baseKeyboardType === 'BASIC') {
                this.userPreferenceService.userPrefs.math.keyboardLayouts = UiConstants.MATH_KEYBOARDS.BASIC;
            } else {
                this.userPreferenceService.userPrefs.math.keyboardLayouts = UiConstants.MATH_KEYBOARDS.PRIMARY;
            }
            if (this.userPreferenceService.userPrefs) {
                this.userPreferenceService.setUserPreference(this.userPreferenceService.userPrefs);
            }
        }
    }

    close() {
        this.dialogRef.close();
    }

}
