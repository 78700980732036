import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { DemoRoutingModule } from './demo-routing.module';
import { DemoComponent } from './demo.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EmailDemoRequestComponent } from './email-demo-request/email-demo-request.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        FormsModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        DemoRoutingModule
    ],
    declarations: [
        DemoComponent,
        EmailDemoRequestComponent
    ]
})
export class DemoModule { }
