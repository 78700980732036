import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RecentMapDto } from '../recent-map-dto';
import { AuthenticationService } from '../../../../app/core/authentication/authentication.service';
import { MapStateService } from '../../../../app/shared/map-state.service';
import { RECENT_ACTION as RECENT_ACTION, RecentActionDto } from '../recent-action-dto';

@Component({
    selector: 'app-recent-menu',
    templateUrl: './recent-menu.component.html',
    styleUrls: ['./recent-menu.component.scss']
})

export class RecentMenuComponent implements OnInit {

    @Input() recentMap: RecentMapDto = new RecentMapDto(0, {}, '', '', '', '');
    @Output() trashOp = new EventEmitter<RecentActionDto>();
    @Output() openMap = new EventEmitter<string>();
    @Output() stopSharing = new EventEmitter<string>();
    @Output() moveMap = new EventEmitter<string>();
    @Output() copyMap = new EventEmitter<RecentMapDto>();
    @Output() renameMap = new EventEmitter<RecentMapDto>();
    @Output() downloadRecent = new EventEmitter<string>();
    @Output() shareRecent = new EventEmitter<RecentMapDto>();

    public isUserExpired = false;

    constructor(
        private authenticationService: AuthenticationService,
        public mapStateService: MapStateService
    ) { }

    ngOnInit() {
        this.isUserExpired = this.authenticationService.isUserExpired();
    }

    open() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.openMap.emit(this.recentMap.id);
    }

    renameRecent() {
        this.mapStateService.isSomeOperationStarted = true;
        this.renameMap.emit(this.recentMap);
    }
    createCopy() {
        this.mapStateService.isSomeOperationStarted = true;
        this.copyMap.emit(this.recentMap);
    }

    downloadMap() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.downloadRecent.emit(this.recentMap.id);
    }

    shareMap() {
        this.mapStateService.isSomeOperationStarted = true;
        this.shareRecent.emit(this.recentMap);
    }

    // TRASH 
    deleteRecent() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.trashOp.emit(new RecentActionDto(this.recentMap, RECENT_ACTION.DELETE));
    }

    removeRecent() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.trashOp.emit(new RecentActionDto(this.recentMap, RECENT_ACTION.REMOVE));
    }

    trashRecent() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.trashOp.emit(new RecentActionDto(this.recentMap, RECENT_ACTION.TRASH));
    }

    untrashRecent() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.trashOp.emit(new RecentActionDto(this.recentMap, RECENT_ACTION.UNTRASH));
    }


    moveRecent() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.moveMap.emit(this.recentMap.id);
    }

    exportImage() {
    }

    deleteSharing() {
        this.mapStateService.isSomeOperationStarted = true;
        if (this.recentMap) this.stopSharing.emit(this.recentMap.id);
    }
}
