import { Injectable, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SmeService } from '../core/sme/sme.service';
import { UserPrefsDto } from './users/user-prefs-dto';
import { Logger } from '../core/logger.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DesmosConfigDto } from '../map-edit/desmos-config/desmosConfig-dto';
import { CookieService } from 'ngx-cookie-service';

const logger: Logger = new Logger('MapShowComponent');
@Injectable({
    providedIn: 'root'
})

export class UserPreferenceService implements OnDestroy {
    public userPrefs: UserPrefsDto | undefined;
    public onUserPrefsChange = new EventEmitter<UserPrefsDto>();
    public onUserPrefsGets = new EventEmitter<UserPrefsDto>();
    public onRecentSearch = new EventEmitter<string>();
    public onChangeDesmosConfig = new EventEmitter<DesmosConfigDto>();

    getUserPreferencesSubscription: Subscription | undefined;
    updateUserPreferenceSubscription: Subscription | undefined;
    getIntroMapsSubscription: Subscription | undefined;

    constructor(
        private smeService: SmeService,
        private router: Router,
        private cookieService: CookieService) {
        this.getUserPreference();
    }



    ngOnDestroy() {
        if (this.getUserPreferencesSubscription) { this.getUserPreferencesSubscription.unsubscribe(); }
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        if (this.getIntroMapsSubscription) { this.getIntroMapsSubscription.unsubscribe(); }
    }

    getUserPreference() {
        if (this.getUserPreferencesSubscription) { this.getUserPreferencesSubscription.unsubscribe(); }
        this.getUserPreferencesSubscription = this.smeService.getUserPreferences().subscribe((resp: any) => {
            if (resp.result) {
                this.userPrefs = new UserPrefsDto(resp.result);
            } else {
                this.userPrefs = new UserPrefsDto();
            }
            this.onUserPrefsGets.emit(this.userPrefs);
        });
    }

    setUserPreference(_prefs: UserPrefsDto) {
        const prefs = {};
        if (this.updateUserPreferenceSubscription) { this.updateUserPreferenceSubscription.unsubscribe(); }
        this.updateUserPreferenceSubscription = this.smeService.updateUserPreference(_prefs).subscribe({
            next: (resp: any) => {
                if (resp.ok) {
                    this.userPrefs = new UserPrefsDto(resp.result);
                    this.onUserPrefsChange.emit(this.userPrefs);
                }
            }, error: (error) => {
                this.onUserPrefsChange.emit(this.userPrefs);
                logger.error('set userPrefence()' + error);
            }
        });
    }

    public saveDesmosPreference(userDesmosConfig: DesmosConfigDto) {
        return this.smeService.setDesmosConfig(userDesmosConfig)
            .subscribe({
                next: () => {
                    console.log('dati salvati con successo!');
                    if (this.userPrefs) {
                        this.userPrefs.desmosConfig = userDesmosConfig;
                        this.onChangeDesmosConfig.emit(userDesmosConfig);
                        this.onUserPrefsChange.emit(this.userPrefs);
                    }
                },
                error: (error: any) => {
                    console.error('error ' + error);
                }
            });
    }

    public updateSideBar(open: boolean) {
        if (this.userPrefs) {
            this.userPrefs.sideBarOpen = open;
        }
        return this.smeService.updateSideBar(open)
            .subscribe({
                next: () => {
                    if (this.userPrefs) {
                        this.onUserPrefsChange.emit(this.userPrefs);
                    }
                },
                error: (error: any) => {
                    console.error('error ' + error);
                }
            })
    }

    openIntroMap() {
        if (this.getIntroMapsSubscription) { this.getIntroMapsSubscription.unsubscribe(); }
        this.getIntroMapsSubscription = this.smeService.getIntroMaps().subscribe((introMaps: any) => {
            if (introMaps) {
                if (introMaps.length > 0) {
                    this.router.navigate(['map-view', introMaps[0]]);
                }
            }
        });
    }

    //COOKIES REGION
    public saveCookie(key: string, value: string) {
        //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! SAVING COOKIE " + key + " " + value);

        //if (this.cookieService.check(key))
        //this.cookieService.delete(key, '/', undefined, undefined, 'Lax');
        this.cookieService.set(key, value, undefined, '/', undefined, undefined, 'Lax');
    }

    public getAllCookies(): {} {
        return this.cookieService.getAll();
    }

    public deleteAllCookies() {
        this.cookieService.deleteAll('/');
    }

    public getCookie(cookie: string, filters: string[] | null): string {
        if (filters) {
            const filtered = this.getCookiesWithFilter(filters);
            //console.log("!!!!!!!!!!!!!!!! FILTERED VALUES " + JSON.stringify(filtered));
            for (const chiave in filtered) {
                if (chiave.includes(cookie))
                    return filtered[chiave];
            }
            return "";
        } else {
            return this.cookieService.check(cookie) ? this.cookieService.get(cookie) : "";
        }
    }

    private filterCookies(json: { [key: string]: any }, filters: string[]) {
        const res: { [key: string]: any } = {};

        for (const chiave in json) {
            if (filters.every((str) => str != '' && chiave.includes(str))) {
                res[chiave] = json[chiave];
            }
        }

        return res;
    }

    private getCookiesWithFilter(filters: string[]) {
        const all = this.cookieService.getAll();
        //console.log("!!!!!!!!!!!!!!!! ALL Cookies " + JSON.stringify(all));
        return this.filterCookies(all, filters);
    }

    //READ SPEED COOKIES
    public saveReadSpeedCookie(usermail: string, lang: string, speed: number) {
        if (usermail != '' && lang != '') {
            this.saveCookie(usermail + "_read_speed" + "_" + lang, speed.toString());
            //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!! SAVED RATE SPEED " + lang + " " + speed);
        }
    }

    public getReadSpeedCookie(filter: string[]): number {
        const savedSpeed = Number(this.getCookie("read_speed", filter));
        if (!isNaN(savedSpeed) && savedSpeed > 0) {
            return savedSpeed;
        } else {
            return -1;
        }
    }
    //
}
