import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { ImageMapsService } from '../commands/image-maps.service';
import { SmService } from 'supermappe-core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogImageComponent } from '../../shared/links-menu/dialog-image/dialog-image.component';
import { TranslateService } from '@ngx-translate/core';

interface SizedEvent {
    width: number;
    height: number;
}



@Component({
    selector: 'app-dialog-image-cropper',
    templateUrl: './dialog-image-cropper.component.html',
    styleUrls: ['./dialog-image-cropper.component.scss']
})

export class DialogImageCropperComponent implements OnInit, OnDestroy {
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent | undefined;


    @Input() refNodeId = '';
    @Input() imageBase64 = '';
    croppedImageBase64 = '';
    _imageLoaded = false;
    cropperHeight = 300;
    cropperWidth = 300;
    canvasRotation = 0;
    rotation = 0;
    transform: ImageTransform = {};

    constructor(
        private imageMapsService: ImageMapsService,
        private dialogRef: MatDialogRef<DialogImageComponent>,
        private translateService: TranslateService,
        private smService: SmService
    ) { }

    ngOnInit() {
        setTimeout(() => {
            this.smService.setEnableKeyPresses(false);
        }, 100);
        if (!this.imageBase64 || !this.refNodeId) {
            this.smService.getSelectedSingleNodeImageDataURL((dataUrl: any) => {
                if (dataUrl) {
                    const nodeId = this.smService.getSelectedNode().id;
                    this.setNodeId(nodeId);
                    this.setImage(dataUrl);
                    this.imageLoaded();
                } else {
                    // Messaggino di errore? (ma non dovrebbe mai arrivare qui!)
                    console.log('ATTENZIONE!!! Cropper declinato (serve una selezione di un singolo nodo con immagine)');
                }
            });
        } else {
            console.log('CROPPER: argomenti dati in input');
            this.imageLoaded();
        }
    }

    ngOnDestroy() {
        this.smService.setEnableKeyPresses(true);
    }

    imageLoaded() {
        const img = new Image();
        img.onload = (event) => {
            this.canvasRotation = 0;
            this.cropperHeight = ((img.naturalHeight + 10) < window.innerHeight) ? (img.naturalHeight + 10) : window.innerHeight - 200;

            this.cropperWidth = ((img.naturalWidth + 10) < window.innerWidth) ? (img.naturalWidth + 10) : window.innerWidth - 10;
            this._imageLoaded = true;
        };

        img.src = this.imageBase64;

    }

    imageCropped(event: ImageCroppedEvent) {
        if (event.base64) {
            this.croppedImageBase64 = event.base64;
            // console.log(this.croppedImageBase64);
            this.imageMapsService.insertImageFromUri(this.croppedImageBase64, '', this.refNodeId, true);
        }
    }

    setImage(dataUri: string) {
        console.log('image-cropper setImage');
        this.imageBase64 = dataUri;
    }

    setNodeId(id: string) {
        this.refNodeId = id;
    }


    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }
    // rotateLeft() {
    //   this.imageCropper.rotateLeft();
    // }

    // rotateRight() {
    //   this.imageCropper?.rotateRight();
    // }

    // flipHorizontal() {
    //   this.imageCropper?.flipHorizontal();
    // }

    // flipVertical() {
    //   this.imageCropper.flipVertical();
    // }

    crop() {
        this.imageCropper?.crop();
        if (this.dialogRef) { this.dialogRef.close(); }
    }

}
