import { VirtualKeyboardLayout } from 'mathlive';

export const EN_BASIC_KEYBOARD: VirtualKeyboardLayout = {
    id: "BASIC",
    labelClass: 'MLK__tex',
    rows: [
        // First row
        [
            {
                key: '(',
                label: '(',
                shift: { latex: 'x', tooltip: 'x' },
                tooltip: 'open round bracket'
            },
            {
                key: ')',
                label: ')',
                shift: { latex: 'n', tooltip: 'n' },
                tooltip: 'closed round bracket'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '7',
                label: '7',
                shift: { latex: '#@^7', tooltip: 'elevated to the power of seven' },
                tooltip: '7'
            },
            {
                class: 'hide-shift',
                key: '8',
                label: '8',
                shift: { latex: '#@^8', tooltip: 'elevated to the power of eight' },
                tooltip: '8'
            },
            {
                class: 'hide-shift',
                key: '9',
                label: '9',
                shift: { latex: '#@^9', tooltip: 'elevated to the power of nine' },
                tooltip: '9'
            },
            {
                class: 'hide-shift',
                latex: ':',
                label: ':',
                shift: { label: '/', latex: '/', tooltip: 'divided by' },
                tooltip: 'divided by'
            },
            '[separator-5]',
            {
                latex: '\\pi',
                shift: { latex: '\\overline{\\placeholder{}}', tooltip: 'overbar' },
                tooltip: 'pi'
            },
            {
                latex: '\\degree',
                shift: { latex: '\\left\\vert\\placeholder{}\\right\\vert', tooltip: 'absolute value' },
                tooltip: 'degrees'
            },
            {
                latex: '\\%',
                shift: { latex: '\\overrightarrow{\\placeholder{}}', tooltip: 'vector' },
                tooltip: 'percent'
            },
        ],
        // Second row
        [
            {
                key: '[',
                label: '[',
                shift: { label: 'y', tooltip: 'y' },
                tooltip: 'open square bracket'
            },
            {
                key: ']',
                label: ']',
                shift: { label: 'a', tooltip: 'a' },
                tooltip: 'closed square bracket'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '4',
                label: '4',
                shift: { latex: '#@^4', tooltip: 'elevated to the power of four' },
                tooltip: '4'
            },
            {
                class: 'hide-shift',
                key: '5',
                label: '5',
                shift: { latex: '#@^5', tooltip: 'elevated to the power of five' },
                tooltip: '5'
            },
            {
                class: 'hide-shift',
                key: '6',
                label: '6',
                shift: { latex: '#@^6', tooltip: 'elevated to the power of six' },
                tooltip: '6'
            },
            {
                class: '',
                latex: '\\cdot',
                shift: { latex: '\\times', tooltip: 'multiplied by' },
                tooltip: 'multiplied by'
            },
            '[separator-5]',
            {
                class: 'hide-shift small',
                latex: '\\frac{\\placeholder{}}{\\placeholder{}}',
                shift: { latex: '\\frac{1}{\\placeholder{}}', tooltip: 'one divided by' },
                tooltip: 'fraction'
            },
            {
                latex: '#@^{#0}}',
                class: 'hide-shift',
                shift: { latex: '#@_{#?}', tooltip: 'subscript' },
                tooltip: 'superscript'
            },
            {
                class: 'hide-shift',
                latex: '\\sqrt{#0}',
                shift: { latex: '\\sqrt[#0]{#?}}', tooltip: 'n-th root' },
                tooltip: 'square root'
            },
        ],
        // Third row
        [
            {
                key: '{',
                label: '{',
                shift: { label: 'z', tooltip: 'z' },
                tooltip: 'open curly bracket'
            },
            {
                key: '}',
                label: '}',
                shift: { label: 'b', tooltip: 'b' },
                tooltip: 'closed curly bracket'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '1',
                label: '1',
                shift: { latex: '#@^{-1}', tooltip: 'elevated to minus one' },
                tooltip: '1'
            },
            {
                class: 'hide-shift',
                key: '2',
                label: '2',
                shift: { latex: '#@^2', tooltip: 'squared' },
                tooltip: '2'
            },
            {
                class: 'hide-shift',
                key: '3',
                label: '3',
                shift: { latex: '#@^3', tooltip: 'cubed' },
                tooltip: '3'
            },
            {
                class: 'hide-shift',
                key: '-',
                label: '-',
                shift: { latex: '\\pm', tooltip: 'plus or minus' },
                tooltip: 'minus'
            },
            '[separator-5]',
            {
                label: '<',
                latex: '<',
                class: 'hide-shift',
                shift: { latex: '\\le', label: '≤', tooltip: 'less or equal than' },
                tooltip: 'less than'
            },
            {
                label: '>',
                latex: '>',
                class: 'hide-shift',
                shift: { latex: '\\ge', label: '≥', tooltip: 'greater or equal than' },
                tooltip: 'greater than'
            },
            {
                label: '[backspace]',
                width: 1.0,
                tooltip: 'delete'
            },
        ],
        // Fourth row
        [
            {
                label: '[shift]',
                width: 2.0,
                tooltip: 'shift'
            },
            '[separator-5]',
            {
                class: 'hide-shift',
                key: '0',
                label: '0',
                shift: { latex: '\\infty', tooltip: 'infinity' },
                tooltip: '0'
            },
            {
                class: 'hide-shift',
                key: ',',
                label: ',',
                shift: { latex: '.', tooltip: 'point' },
                tooltip: 'comma'
            },
            {
                class: 'hide-shift',
                key: '=',
                label: '=',
                shift: { latex: '≠', tooltip: 'not equal' },
                tooltip: 'equals'
            },
            {
                class: 'hide-shift',
                key: '+',
                label: '+',
                shift: { latex: '\\sum', tooltip: 'summation' },
                tooltip: 'plus'
            },
            '[separator-5]',
            {
                label: '[left]',
                tooltip: 'left'
            },
            {
                label: '[right]',
                tooltip: 'right'
            },
            {
                class: "svg-glyph",
                label: "<svg class='svg-glyph'><use xlink:href='#svg-commit' /></svg>",
                command: ["performWithFeedback", "commit"],
                tooltip: 'confirm'
            }
        ]
    ]
};
