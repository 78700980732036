import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogDeepComponent } from './dialog-deep/dialog-deep.component';
import { DialogImageCropperComponent } from '../../map-edit/dialog-image-cropper/dialog-image-cropper.component';
import { CopyPastePopupComponent } from '../copy-paste-popup/copy-paste-popup.component';
import { DialogPreferencesComponent } from '../../map-edit/dialog-preferences/dialog-preferences.component';
import { DialogQRCodeComponent } from '../../map-edit/dialog-qrcode/dialog-qrcode.component';
import { MathKeyboardChoiceComponent } from '../../map-edit/math-keyboard-choice/math-keyboard-choice.component';

import { SmService } from 'supermappe-core';
import { MapShowService } from '../../map-show/map-show.service';
import { QRCodesService } from '../commands/qrcodes.service';
import { DialogImageComponent } from './dialog-image/dialog-image.component';
import { DesmosConfigComponent } from 'src/app/map-edit/desmos-config/desmos-config.component';
import { DesmosConfigDto } from 'src/app/map-edit/desmos-config/desmosConfig-dto';
import { PdfConfigToolsComponent } from 'src/app/map-edit/pdf-editor/pdf-config-tools/pdf-config-tools.component';
import { PdfToolsPrefsDto } from 'src/app/map-edit/pdf-editor/pdf-master/pdf-tools-prefs-dto';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(
        private dialog: MatDialog,
        private smService: SmService,
        private mapShowService: MapShowService,
        private qrCodesService: QRCodesService
    ) { }

    public showExtraMenuConfig(desmosConfigDto: DesmosConfigDto) {
        const desmosConfig = this.dialog.open(DesmosConfigComponent, { maxWidth: '700px' });
        desmosConfig.componentInstance.loadDesmosPreference(desmosConfigDto);
    }

    public showDeepContent(nodeId: string, htmlContent: string, plainContent: string, currLanguage: string) {
        if (!this.mapShowService.isEditShowMode) {
            this.smService.setEnableKeyPresses(false);
            const dialogDeep = this.dialog.open(DialogDeepComponent, { maxHeight: '80vh', maxWidth: '90vw', width: '720px', disableClose: true });
            dialogDeep.componentInstance.setImageNode(nodeId);
            if (htmlContent === undefined) htmlContent = '';
            dialogDeep.componentInstance.setHtmlContent(htmlContent);
            if (plainContent === undefined) plainContent = '';
            dialogDeep.componentInstance.setPlainContent(plainContent, currLanguage);
        }
    }

    public showImageContent(editMode: boolean, nodeId: string) {
        if (!this.mapShowService.isEditShowMode) {
            const dialogImage = this.dialog.open(DialogImageComponent, { panelClass: 'resizable-container' });
            dialogImage.componentInstance.editMode = editMode;
            dialogImage.componentInstance.setImageNode(nodeId);
        }
    }

    public showCopyPastePopup() {
        this.dialog.open(CopyPastePopupComponent);
    }

    public showDialogPreferences() {
        this.dialog.open(DialogPreferencesComponent);
    }

    public showKeyboardChoice() {
        this.dialog.open(MathKeyboardChoiceComponent)
    }

    public showQRCode(type: string, link: string, size?: number, message?: string) {
        const dialogQRCode = this.dialog.open(DialogQRCodeComponent);
        this.qrCodesService.createQRCode(link, size).then((dataUrl) => {
            dialogQRCode.componentInstance.setTitle(type);
            dialogQRCode.componentInstance.setQRCode(dataUrl);
            dialogQRCode.componentInstance.setMessage(message + '');
        });
    }

    public showSelectedNodeImageDialogCropper() {
        const dialogCropper = this.dialog.open(DialogImageCropperComponent, { width: '600px', maxHeight: '90vh' });
    }

    public showPdfPreferences(pdfToolsPrefsDto: PdfToolsPrefsDto): any {
        const pdfConfig = this.dialog.open(PdfConfigToolsComponent, { disableClose: true });

        pdfConfig.componentInstance.initParam(pdfToolsPrefsDto);
        pdfConfig.backdropClick().subscribe(() => {
            // Close the dialog
            pdfConfig.componentInstance.closeWin();
            pdfConfig.close();
        });

        return pdfConfig;

    }
}
