<h1 mat-dialog-title class="title" translate>EDIT_IMAGE</h1>
<div class="content">
    <div class="tools-container">
        <button mat-icon-button color="primary" matTooltip="{{'ROTATE_RIGHT' | translate}}" (click)="rotateRight()">
            <mat-icon [attr.aria-label]="'ROTATE_RIGHT' | translate" svgIcon="rotate-right-variant"></mat-icon>
        </button>
        <button mat-icon-button color="primary" matTooltip="{{'ROTATE_LEFT' | translate}}" (click)="rotateLeft()">
            <mat-icon [attr.aria-label]="'ROTATE_LEFT' | translate" svgIcon="rotate-left-variant"></mat-icon>
        </button>
        <button mat-icon-button color="primary" matTooltip="{{'FLIP_VERT' | translate}}" (click)="flipVertical()">
            <mat-icon [attr.aria-label]="'FLIP_VERT' | translate" svgIcon="flipvertical"></mat-icon>
        </button>
        <button mat-icon-button color="primary" matTooltip="{{'FLIP_HOR' | translate}}" (click)="flipHorizontal()">
            <mat-icon [attr.aria-label]="'FLIP_HOR' | translate" fontSet="material-icons-outlined">flip</mat-icon>
        </button>
    </div>
    <div class="image-container">
        <image-cropper [imageBase64]="imageBase64" [canvasRotation]="canvasRotation" [transform]="transform" [maintainAspectRatio]="false" [resizeToWidth]="0" format="png" [roundCropper]="false" [cropperMinWidth]="50" [cropperMinHeight]="50" [onlyScaleDown]="true" [autoCrop]="false" (imageCropped)="imageCropped($event)">
        </image-cropper>
    </div>
</div>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="true" cdkFocusInitial>
        <span translate>CANCEL</span>
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="crop()" translate>
        <span translate>OK</span>
    </button>
</mat-dialog-actions>
