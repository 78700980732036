import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SmeService } from '../../core/sme/sme.service';
import { Subscription } from 'rxjs';
import { GoogleService } from '../../core/google/google.service';
import { AccessConfigService } from '../../access-config/access-config.service';
import { Logger } from '../../core/logger.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MapOperationType } from '../../shared/map-operations';
import { SubscriptionDto } from '../../access-config/common/subscription-dto';
import { YearUserStatsDto } from './year-user-stats-dto';
import { UserStatsDto } from './user-stats-dto';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';

const logger: Logger = new Logger('order-list');

@Component({
    selector: 'app-user-stats',
    templateUrl: './user-stats.component.html',
    styleUrls: ['./user-stats.component.scss']
})
export class UserStatsComponent implements OnInit, OnDestroy {

    public isLoading = false;
    public user = '';
    public userEmail = '';
    public role = '';

    public allData: UserStatsDto = new UserStatsDto;
    public yearData: YearUserStatsDto = new YearUserStatsDto;

    public monthStats: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    public shareStats: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    public tickShareMultiplier = 1;
    public tickDetailMultiplier = 1;

    public subscriptions: SubscriptionDto[] | undefined;
    public pageIndex = 0;
    public pageSize = 100;

    public currentYear = 0;
    private currentMonth = 0;
    public selectedMapStat = MapOperationType.STAT_NEWMAP;
    public selectedShareStat = MapOperationType.STAT_SHARELINK;

    private paramSubscription: Subscription | undefined;
    private getSingleUserStatsSubscription: Subscription | undefined;
    private searchSubscriptionsSubscription: Subscription | undefined;

    public years: Array<any> | undefined;
    public selectedYear: any;

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.authenticationService.getUserEmail() !== this.firebaseAuthService.auth.currentUser?.email) {
            {

                this.router.navigate(['/home']);
            }
        }

    }

    constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private firebaseAuthService: FirebaseAuthService,
        private translateService: TranslateService, private smeService: SmeService, private googleService: GoogleService, private accessConfigService: AccessConfigService) { }

    ngOnInit() {
        if (this.authenticationService.credentials) {

            this.user = this.authenticationService.getUserName();

            this.role = this.authenticationService.credentials.role;
            this.userEmail = this.authenticationService.getUserEmail();

            this.selectedMapStat = MapOperationType.STAT_NEWMAP;
            this.selectedShareStat = MapOperationType.STAT_SHARELINK;

            const currentDate = new Date();
            this.currentYear = currentDate.getFullYear();
            this.currentMonth = currentDate.getMonth();
            if (this.currentMonth < 8) {
                this.currentYear--;
            }
            this.years = new Array<any>();
            for (let i = this.currentYear; i >= 2019; i--) {
                this.years.push({ num: i, name: i + '/' + (i + 1) });
            }
            this.selectedYear = this.years[0];

            // console.log(`++++++++++++++++++++++++++++REQUEST paramSubscription`);
            this.paramSubscription = this.paramSubscription = this.route.params.subscribe((params: Params) => {
                const email = params['userEmail'];
                // console.log(`++++++++++++++++++++++++++++RESPONSE paramSubscription email:${email}`);
                if (email.includes('@')) {
                    this.userEmail = email;
                    this.user = email;
                    this.loadDataSet();
                }
            });
            this.getSubscriptions();
        }
    }

    ngOnDestroy() {
        if (this.getSingleUserStatsSubscription) { this.getSingleUserStatsSubscription.unsubscribe(); }
        if (this.paramSubscription) { this.paramSubscription.unsubscribe(); }
        if (this.searchSubscriptionsSubscription) { this.searchSubscriptionsSubscription.unsubscribe(); }
    }

    loadDataSet() {
        this.isLoading = true;
        this.allData = new UserStatsDto();
        this.yearData = new YearUserStatsDto();
        this.yearData.init(this.currentYear);
        // console.log(`++++++++++++++++++++++++++++REQUEST single user stats email:${email}, year:${this.schoolYear}`);
        this.getSingleUserStatsSubscription = this.getSingleUserStatsSubscription = this.smeService.readSingleUserStats(this.userEmail, this.currentYear + '-09-01', (this.currentYear + 1) + '-08-31').subscribe((res: any) => {
            // console.log(`++++++++++++++++++++++++++++RESPONSE single user stats email:${email}, year:${this.schoolYear}`);
            const dataStats = res.res;
            console.log(JSON.stringify(dataStats));
            for (let i = 0; i < dataStats.length; i++) {
                this.allData?.addStat(dataStats[i].id, dataStats[i].tot);
                this.yearData?.addStat(dataStats[i].year, dataStats[i].month, dataStats[i].id, dataStats[i].tot);
            }
            this.getGraphStats(this.selectedMapStat, false);
            this.getGraphStats(this.selectedShareStat, true);
            this.isLoading = false;
        });
    }

    selectedYearChanged(event: any) {
        if (event) {
            // JHUBA: CHECK
            if (this.currentYear !== this.selectedYear.num) {
                this.currentYear = this.selectedYear.num;
                this.loadDataSet();
            }
        }
    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role?.toLocaleUpperCase() || 'USER'));
    }

    getSubscriptions() {
        // console.log(`++++++++++++++++++++++++++++REQUEST getSubscriptions`);
        this.isLoading = true;
        if (this.searchSubscriptionsSubscription) { this.searchSubscriptionsSubscription.unsubscribe(); }
        this.searchSubscriptionsSubscription = this.accessConfigService.searchSubscriptions('', true, this.pageIndex + 1, this.pageSize, 'expiryDate', false)
            .subscribe({
                next: (subs: any) => {
                    // console.log(`++++++++++++++++++++++++++++RESPONSE getSubscriptions length:${subs.subs.length}`);
                    this.subscriptions = [];
                    if (subs && subs.subs) {
                        for (let i = 0; i < subs.subs.length; i++) {
                            const element = new SubscriptionDto(subs.subs[i]);
                            this.subscriptions.push(element);
                        }
                        this.isLoading = false;
                    }
                },
                error: (error: any) => {
                    logger.error(this.translateService.instant('GETSUBSCRIPTIONS_FAIL') + '\n' + error.message);
                }
            });

    }

    showMultiStats(orderId: string) { //, randomId: string) {
        // this.router.navigate(['multi-stats/', orderId]);
        window.open('multi-stats/' + orderId, '_blank');
    }

    showAdminStats() {
        // this.router.navigate(['admin-stats/']);
        window.open('admin-stats', '_blank');
    }

    showDetailChart(idOperation: string) {
        if (this.selectedMapStat === idOperation) {
            this.selectedMapStat = '';
        } else {
            this.selectedMapStat = idOperation;
            this.getGraphStats(idOperation, false);
        }
    }

    showShareChart(idOperation: string) {
        if (this.selectedShareStat === idOperation) {
            this.selectedShareStat = '';
        } else {
            this.selectedShareStat = idOperation;
            this.getGraphStats(idOperation, true);
        }
    }

    getGraphStats(idOperation: string, shareInfo: boolean) {
        if (this.yearData) {
            const detailInfos = this.yearData.getMonthsData(idOperation);
            let maxValue = 0;
            for (const val of detailInfos) {
                if (maxValue < val) {
                    maxValue = val;
                }
            }
            let multiplier = 1;
            if (maxValue >= 6) {
                const tick = maxValue / 5;
                multiplier = Math.ceil(tick / 5) * 5;
            }
            if (shareInfo) {
                this.shareStats = detailInfos;
                this.tickShareMultiplier = multiplier;
            } else {
                this.monthStats = detailInfos;
                this.tickDetailMultiplier = multiplier;
            }
        }
    }

}
