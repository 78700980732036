import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements AfterViewInit {

    @Input() username?: string;
    @Output() goBackClicked = new EventEmitter<void>();
    isLoading = false;
    error = false;
    requestSent = false;
    text = "";
    form: FormGroup = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),

    });

    constructor(private firebaseAuthService: FirebaseAuthService, private dialog: MatDialog, private translateService: TranslateService, private router: Router) {

    }
    get f() { return this.form?.controls; }

    ngAfterViewInit() {
        this.form.get('username')?.setValue(this.username);
    }

    submit() {
        this.resetPassword();
    }

    resetPassword() {
        this.firebaseAuthService.auth.languageCode = this.translateService.currentLang;
        this.firebaseAuthService.resetPassword(this.form.get("username")?.value)
            .then(() => {
                // Password reset email sent!

                this.dialog.closeAll();
                this.router.navigate(['forgot-password-sent'], { queryParams: { username: this.form.get("username")?.value } });
                // this.dialog.closeAll();

            })
            .catch((error) => {
                this.error = error;
            });
    }

    goBack() {
        this.goBackClicked.emit();
    }

}
