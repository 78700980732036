import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { UserStatsComponent } from './user-stats.component';
import { UserStatsRoutingModule } from './user-stats.routing.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        CoreModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        FormsModule,
        UserStatsRoutingModule
    ],
    declarations: [
        UserStatsComponent
    ],
    providers: [
    ]
})
export class UserStatsModule { }
