import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { GdrivePickerComponent } from './gdrive-picker.component';
import { AuthenticationGuard } from '../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
  { path: 'gdrive-picker', component: GdrivePickerComponent, data: { title: extract('GDRIVE_PICKER_TITLE') }, canActivate: [AuthenticationGuard] },
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class GdrivePickerRoutingModule { }
