import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../../core/route.service';
import { extract } from '../../core/i18n.service';
import { ShareClassroomComponent } from './share-classroom.component';
import { AuthenticationGuard } from '../../core/authentication/authentication.guard';

const routes: Routes = Route.withShell([
    { path: 'share-classroom/:mapId', component: ShareClassroomComponent, data: { title: extract('PREPARING_MAP') } }
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard]
})
export class ShareClassroomRoutingModule { }
