import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../../core/i18n.service';
import { SingleUserConfigComponent } from './single-user-config.component';

const routes: Routes = [
  { path: 'single-user-config/:orderId/:randomId', component: SingleUserConfigComponent, data: { title: extract('ACCESS_CONFIG') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SingleUserConfigRoutingModule { }
