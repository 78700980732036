import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomSelectElement } from '../toolbar/custom-toolbar/custom-select-element-dto';
import { UiConstants } from 'src/app/shared/ui-constants';
import { Subject, Subscription, take, tap } from 'rxjs';
import { WebSearchService } from 'src/app/shared/commands/web-search.service';
import { MapStateService } from 'src/app/shared/map-state.service';
import { QuickEditService } from 'src/app/shared/commands/quick-edit.service';
import { ExtraItem, ExtraService } from '../commands/extra.service';
import { Point } from 'jspdf';
import { CropDimensions, NgxCaptureService } from 'ngx-capture';
import { ImageMapsService } from '../commands/image-maps.service';

@Component({
    selector: 'app-autodraw',
    templateUrl: './autodraw.component.html',
    styleUrls: ['./autodraw.component.scss']
})
export class AutodrawComponent implements OnInit {
    @ViewChild('iframe') iframe: ElementRef | undefined;
    @ViewChild('screen', { static: true }) screen: any;
    @ViewChild('rect', { static: true }) rectangle?: ElementRef;
    @ViewChild('over', { static: true }) overlay?: ElementRef;


    @Input() url: SafeResourceUrl = 'https://www.autodraw.com/';

    public style: object = {};
    public styleover: object = {};
    public mapinteraction = false;

    private newTop = 0;
    @Input() offsetLeft: number = 75;
    @Input() offsetTop: number = 100;
    @Output() resultImage = new EventEmitter<string>();



    rect?: HTMLElement;
    captureZone?: HTMLElement;

    isDrawing = false;

    mouseStart: Point = { x: 0, y: 0 };

    cropDimensions: CropDimensions = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };
    destroy$ = new Subject<void>();

    public capturingScreen: boolean = false;
    private captureTogglePressed: boolean = false;
    public percCropX: number = 0;
    public percCropY: number = 0;
    public percCropX2: number = 100;
    public percCropY2: number = 100;

    public ttsEnable = false;
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    selectedLangIcon = UiConstants.LANGUAGES[0].icon;
    onGetVoicesSubscription: Subscription | undefined;
    onToggleWebSearchSubscription: Subscription | undefined;
    onResizeSubscription: Subscription | undefined;
    // onToggleDesmosSubscrition: Subscription | undefined;
    rateValue = 1;
    public speechEditEnabled = false;
    onSpeechEditEnableSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    mapMoving: Subscription | undefined;
    geometry = false;
    fourfunction = false;
    calculator = false;
    scientific = false;
    resizing = false;



    constructor(
        // private webService: WebService, 
        private captureService: NgxCaptureService,
        private imageMapsService: ImageMapsService,
        private webSearchService: WebSearchService,
        private mapStateService: MapStateService,
        private quickEditService: QuickEditService,
        private extraService: ExtraService,
        private sanitizer: DomSanitizer) {

    }

    ngOnInit() {


        //  const extraContainer: any = document.getElementsByClassName('extra-container')[0];

        // Cattura schermo
        setTimeout(() => {
            this.rect = this.rectangle?.nativeElement;
            this.captureZone = this.overlay?.nativeElement;

            if (!this.captureZone) {
                console.warn('"captureZone" is not set');
                return;
            }

            this.captureZone.onmousedown = (e) => this.startCapture(e);
            this.captureZone.onmousemove = (e) => this.drawRect(e);
            this.captureZone.onmouseup = () => this.endCapture();
            document.body.onmouseup = () => this.stopCapture();

        }, 2000);


    }
    isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        input !== null && input.tagName === 'IFRAME';



    closeExtra() {
        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
        }
        // this.webService.toggleWebPanel();
        this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
    }

    public Capture() {

        if (this.capturingScreen) {
            this.stopCapture();
        } else {
            console.log("START CAPUTURE");
            this.capturingScreen = true;
            //   this.SetReadonly(this.capturingScreen);
            this.percCropX = 0;
            this.percCropY = 0;
            this.percCropX2 = 0;
            this.percCropY2 = 0;

            this.captureTogglePressed = true;
            setTimeout(() => this.captureTogglePressed = false, 20);
        }
    }
    private startCapture(e: any) {
        if (!this.capturingScreen) return;
        const mouse = this.setMousePosition(e, true);

        this.isDrawing = true;

        this.cropDimensions = {
            x: mouse.x,
            y: mouse.y,
            width: 0,
            height: 0,
        };

        if (this.captureZone) {
            this.captureZone.style.cursor = 'crosshair';
        }
    }

    private drawRect(e: any) {
        if (this.isDrawing) {
            const mouse = this.setMousePosition(e, false);

            this.cropDimensions = {
                x: mouse.x - this.mouseStart.x < 0 ? mouse.x - this.offsetLeft : this.mouseStart.x - this.offsetLeft,
                y: mouse.y - this.mouseStart.y < 0 ? mouse.y - this.offsetTop : this.mouseStart.y - this.offsetTop,
                width: Math.abs(mouse.x - this.mouseStart.x),
                height: Math.abs(mouse.y - this.mouseStart.y),
            };
            this.setRectangle();
        }
    }

    private setMousePosition(e: any, isStart = false): Point {
        const ev = e || window.event; // Moz || IE
        const mouse: Point = { x: 0, y: 0 };

        if (ev.pageX) {
            // Moz
            mouse.x = ev.clientX;
            mouse.y = ev.clientY;
        } else if (ev.clientX) {
            // IE
            mouse.x = ev.clientX + document.body.scrollLeft;
            mouse.y = ev.clientY + document.body.scrollTop;
        }

        if (isStart) {
            this.mouseStart.x = mouse.x;
            this.mouseStart.y = mouse.y;
        }

        return mouse;
    }

    private stopCapture() {
        setTimeout(() => {
            if (!this.captureTogglePressed) {
                if (this.capturingScreen) {
                    console.log("STOP CAPUTURE");
                    this.capturingScreen = false;
                    //  this.SetReadonly(this.capturingScreen);
                    if (this.captureZone) { this.captureZone.style.cursor = 'default'; }
                    this.isDrawing = false;
                    this.cropDimensions = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    };
                    this.setRectangle();
                }
            }

        }, 10);

    }

    private endCapture() {

        const iframe = document.getElementById('autodraw') as HTMLIFrameElement;
        // const mainCanvas = document.getElementById('main-canvas');
        // const canvas = iframe.getElementById("main-canvas");
        // const childDocument = iframe?.contentWindow?.document;
        // const canvas = childDocument?.getElementById("main-canvas");
        // const mainCanvas = this.iframe?.nativeElement.getElementsByClassName('main-canvas');

        // const innerDoc = (iframe.contentDocument) ? iframe.contentDocument : iframe?.contentWindow?.document;

        // const mainCanvas = innerDoc?.getElementById("main-canvas");
        if (this.capturingScreen) {
            console.log('STOP CAPTURE');
            this.capturingScreen = false;


            if (this.captureZone && this.isDrawing) {

                this.captureZone.style.cursor = 'default';
                this.isDrawing = false;
                setTimeout(() => {
                    this.captureService
                        .getImage(this.screen, false, {
                            ...this.cropDimensions,
                            x: this.cropDimensions.x + 1 + window.scrollX,
                            y: this.cropDimensions.y + 1 + window.scrollY,
                        })
                        .pipe(
                            take(1),
                            tap((img: string | undefined) => {
                                console.log(img);

                                const result: any = this.dataUrlToBlob(img);
                                if (result != null) {
                                    const now = new Date();
                                    result.lastModifiedDate = now;
                                    result.name = `camera-${now}.png`;
                                    this.imageMapsService.insertImageFromFile(result, null);
                                }
                            })
                        )
                        .subscribe();

                    this.cropDimensions = {
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0,
                    };
                    this.setRectangle();
                })
            }
        }
    }

    private setRectangle() {
        if (this.rect) {
            this.rect.style.left = this.cropDimensions.x + 'px';
            this.rect.style.top = this.cropDimensions.y + 'px';
            this.rect.style.width = this.cropDimensions.width + 'px';
            this.rect.style.height = this.cropDimensions.height + 'px';
            this.setMask(this.cropDimensions.x, this.cropDimensions.y, this.cropDimensions.width, this.cropDimensions.height);
        }
    }

    private setMask(x: number, y: number, width: number, height: number) {
        const w = Number(this.captureZone?.offsetWidth);
        const h = Number(this.captureZone?.offsetHeight);

        const pW = 100 / w;
        const pH = 100 / h;
        this.percCropX = pW * x;
        this.percCropY = pH * y;
        this.percCropX2 = pW * (x + width);
        this.percCropY2 = pH * (y + height);
    }
    /**
     * Convert base64/URLEncoded data component to raw binary data held in a string
     */
    private dataUrlToBlob = function (dataUrl: any) {
        let byteString;
        if (dataUrl != null) {
            if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataUrl.split(',')[1]);
            } else {
                byteString = decodeURI(dataUrl.split(',')[1]);
            }
            const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
            const array = [];
            for (let i = 0; i < byteString.length; i++) {
                array.push(byteString.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], { type: mimeString });
        }
        return null;
    };


}
