<!-- <div class="loader-container open-page" [hidden]="!isLoading"> -->
<!-- <app-loader *ngIf="isLoading" class="loader" [isLoading]="isLoading" size="5"></app-loader> -->
<!-- </div> -->
<form *ngIf="isOperator" class="search" [formGroup]="searchForm" novalidate (ngSubmit)="onSubmit()">
    <span class="section-title" [ngClass]="(minimumState ? 'hidden' : 'section-title')">{{'SUBSCRIPTIONS_LIST' | translate}}</span>
    <button mat-icon class="button-search" type="submit" ngDefaultControl matTooltip="{{'SEARCH' | translate}}">
        <mat-icon>search</mat-icon>
    </button>
    <input #searchtext type="text" placeholder="{{'SEARCH_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" class="input-search" formControlName="searchText" />
    <button mat-icon type="button" class="button-clear" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="resetSearch()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</form>
<div *ngIf="noList" class="center-text">
    <span class="title-span">{{'NO_SUBSCRIPTIONS' | translate}}</span>
</div>
<div *ngIf="!noList">
    <div *ngIf="isOperator" [hidden]="!isLoaded">
        <mat-paginator class="paginator" #paginator [showFirstLastButtons]="true" [length]="length" [pageSize]="pageSize" (page)="pageEvent = $event; changePage($event)">
        </mat-paginator>
    </div>
    <mat-accordion multi="true" class="order-panel">
        <mat-expansion-panel *ngFor="let subscription of subscriptions; let i = index; first as isFirst" [expanded]="isFirst && openFirstElement" (afterCollapse)="panelOpenState(subscription,false)" (afterExpand)="panelOpenState(subscription,true)">
            <mat-expansion-panel-header class="expansion-header">
                <mat-panel-title>
                    <div class="order-item">
                        <div class="item-1">
                            <mat-icon *ngIf="subscription.isSuspended" class="order-status-indicator order-suspended" svgIcon="checkbox-blank-circle"></mat-icon>
                            <mat-icon *ngIf="subscription.isExpired" class="order-status-indicator order-expired" svgIcon="checkbox-blank-circle"></mat-icon>
                        </div>
                        <div [ngClass]="((minimumState) ? 'hidden' :  (compressed||medium ?  'item-compressed' : 'item-expanded'))">
                            <span class="header-title-span bold">{{'ACCESSCONFIG_ADMIN' | translate}}</span>
                            <span class="header-text-span">{{subscription.access.admin.name}} ({{subscription.access.admin.eMail}})</span>
                        </div>
                        <div [ngClass]="(compressed || medium ? 'item-compressed' : 'item-expanded')">
                            <span class="header-title-span bold">{{'ACCESSCONFIG_EXPIREDATE' | translate}}</span>
                            <span class="header-text-span">{{subscription.order.expireDate}}</span>
                        </div>
                        <div [ngClass]="((minimumState) ? 'hidden' :  (compressed || medium ?  'item-compressed' : 'item-expanded'))">
                            <span class="header-title-span bold">{{'ACCESSCONFIG_NAME' | translate}}</span>
                            <span class="header-text-span">{{subscription.access.name}}</span>
                        </div>
                        <div [ngClass]="((minimumState) ? 'hidden' :  (compressed ||medium?  'item-compressed' : 'item-expanded'))" *ngIf="searchedText">
                            <mat-icon svgIcon="file-find" matTooltip="{{subscription.findsDescription}}"></mat-icon>
                        </div>


                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div [ngClass]="compressed ? 'order-body-compressed'  : 'order-body'">
                <!-- ABBONAMENTO -->
                <mat-card appearance="outlined" class="item-1 card subscription">
                    <div class="card-header card-row1 uppercase big">
                        <span class="card-header-col1" translate>SUBSCRIPTION</span>
                        <span class="card-header-col2 bold uppercase smx-blue little link" (click)="configAccess(subscription)" translate>ACCESSCONFIG_DETAILS</span>
                    </div>
                    <div class="card-info card-row2">
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'IC_USER_TYPE_TITLE' | translate}}</span>
                            <span class="item-span">{{'IC_USER_TYPE_' + subscription.order.userType | translate}}</span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_PLAN' | translate}}</span>
                            <span class="item-span">{{subscription.order.planId}}</span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_LICENSETYPE' | translate}}</span>
                            <span class="item-span">{{getLicenseType(subscription.order.licenseType, subscription.order.domainLics)}}</span>
                            <span *ngIf="subscription.order.licenseType==='d'" class="title-span">{{'ACCESSCONFIG_DOMAIN' | translate}}</span>
                            <span *ngIf="subscription.order.licenseType==='d'" class="item-span">{{subscription.access.domain}}</span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_USERS' | translate}}: </span>
                            <span class="item-span">{{subscription.order.slotCount}}</span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_EXPIREDATE' | translate}}</span>
                            <span class="item-span">{{subscription.order.expireDate}}</span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_LOCKED' | translate}}: </span>
                            <span class="item-span">{{toLocalLocked(subscription.order.locked)}} </span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_LOCKED_ACCEPTED' | translate}}: </span>
                            <span class="item-span">{{toLocalDateTime(subscription.order.acceptedDate)}} </span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_ORDER_STATUS' | translate}}</span>
                            <span class="item-span">{{getOrderStatus(subscription)}}</span>

                            <span *ngIf="subscription.isSuspended || subscription.isExpired">
                                <mat-icon *ngIf="subscription.isSuspended" class="item-span order-status-indicator order-suspended" svgIcon="checkbox-blank-circle"></mat-icon>
                                <mat-icon *ngIf="subscription.isExpired" class="item-span order-status-indicator order-expired" svgIcon="checkbox-blank-circle"></mat-icon>
                            </span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span *ngIf="subscription.order.planId==='lab'" class="title-span">{{'ACCESSCONFIG_OCR_QUOTA' | translate}} </span>
                            <span *ngIf="subscription.order.planId==='lab'" class="item-span">{{subscription.order.ocrMathScanQuota}} </span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span *ngIf="subscription.order.planId==='lab'" class="title-span">{{'ACCESSCONFIG_OCR_POLICY' | translate}} </span>
                            <span *ngIf="subscription.order.planId==='lab'" class="item-span">{{'OCRSCAN_POLICY_' + subscription.order.ocrMathScanPolicy | translate}} </span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span *ngIf="subscription.order.planId==='lab'" class="title-span" translate>OCRSCAN_LEFT</span>
                            <span *ngIf="subscription.order.planId==='lab'" class="item-span">{{subscription.order.ocrMathScansLeft}} </span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_ADMIN' | translate}}: </span>
                            <span class="item-span">{{subscription.access.admin.name}}</span>
                            <span class="item-span">({{subscription.access.admin.eMail}})</span>
                        </div>
                        <div [ngClass]="(compressed ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_USER_NAME' | translate}}</span>
                            <span class="item-span">{{subscription.order.fullName}}</span>
                            <span class="item-span">({{subscription.order.userEmail}}) </span>
                        </div>
                    </div>
                    <div class="card-footer card-row4">
                        <span *ngIf="enabledDetail && !compressed" class="bold uppercase smx-blue little link end spaced" (click)="openStats(subscription)" translate>ACCESSCONFIG_OPENSTATS</span>
                    </div>
                </mat-card>
                <!-- ACQUISTO -->
                <mat-card appearance="outlined" class="item-2 card buy">
                    <div class="card-header card-row1 uppercase big">
                        <span class="card-header-col1" translate>ACCESSCONFIG_BUY</span>
                        <span class="card-header-col2">
                            <mat-icon class="smx-blue" svgIcon="cart"></mat-icon>
                        </span>
                    </div>
                    <div class="card-buttons card-row2">
                        <button color="primary" *ngIf="subscription.order.origin !== 'demo'" mdc-button class="title-button" (click)="renewSubscription(subscription)">{{'ACCESSCONFIG_RENEW_SUBSCRIPTION' | translate}}</button>
                        <button color="primary" *ngIf="subscription.order.origin === 'demo'" mdc-button class="title-button" (click)="goToWebsiteClick('order-list','private')">{{'ACCESSCONFIG_BUY_SUBSCRIPTION' | translate}}</button>
                        <button color="primary" *ngIf="subscription.order.origin === 'demo'" mdc-button class="title-button" (click)="goToWebsiteClick('order-list','school')">{{'ACCESSCONFIG_DISCOVER' | translate}}</button>
                    </div>
                    <div class="card-footer card-row4">
                        <span *ngIf="historyExists(subscription.order)" class="bold uppercase smx-blue little link end spaced" (click)="showHistory(subscription.order)" translate>ACCESSCONFIG_HISTORY</span>
                    </div>
                </mat-card>
                <!-- OPERATORI -->
                <mat-card appearance="outlined" *ngIf="isOperator && !subscription.showOrder" class="item-3 card operator">
                    <div class="card-header card-row1 uppercase big">
                        <span class="card-header-col1" translate>ACCESSCONFIG_OPERATORS</span>
                    </div>
                    <div class="card-buttons card-row2">
                        <button color="primary" [disabled]="!enabledDetail" *ngIf="canEditOrder()" mdc-button class="title-button" (click)="showUpdateOrder(subscription)">{{'ACCESSCONFIG_MODIFY-ORDER' | translate}}</button>
                        <button color="primary" *ngIf="canSendMail()" mdc-button class="detail-button" (click)="resendEmail(subscription,false)">{{'ACCESSCONFIG_SENDEMAIL' | translate}}</button>
                        <button color="primary" *ngIf="canSendAdminMail(subscription)" mdc-button class="detail-button" (click)="resendEmail(subscription,true)">{{'ACCESSCONFIG_SENDADMINEMAIL' | translate}}</button>
                    </div>
                    <div class="card-info card-row3">
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_ORIGIN' | translate}} </span>

                            <span class="item-span">{{getOriginType(subscription.order.origin)}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_ORDERID' | translate}}</span>
                            <span class="item-span">{{subscription.order.orderId}}</span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_REFORDERID' | translate}}</span>
                            <span class="item-span">{{getRefOrderId(subscription.order.refOrderId,subscription.order.lastAction)}}</span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_MECHCODE' | translate}}: </span>
                            <span class="item-span">{{subscription.order.mechCode}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'START_DATE' | translate}}: </span>
                            <span class="item-span">{{subscription.order.startDate}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <!-- <span *ngIf="subscription.order.planId==='lab'" class="title-span">{{'ACCESSCONFIG_OCR_QUOTA' | translate}} </span>
                        <span *ngIf="subscription.order.planId==='lab'" class="item-span">{{subscription.order.ocrMathScanQuota}} </span>
                        <span *ngIf="subscription.order.planId==='lab'" class="title-span">{{'ACCESSCONFIG_OCR_POLICY' | translate}} </span>
                        <span *ngIf="subscription.order.planId==='lab'" class="item-span">{{'OCRSCAN_POLICY_' + subscription.order.ocrMathScanPolicy | translate}} </span> -->
                            <span class="title-span">{{'ACCESSCONFIG_CREATIONDATE' | translate}}: </span>
                            <span class="item-span">{{toLocalDateTime(subscription.order.creationDate)}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <!-- <span class="title-span">{{'ACCESSCONFIG_LOCKED' | translate}}: </span>
                        <span class="item-span">{{toLocalLocked(subscription.order.locked)}} </span> -->
                            <span class="title-span">{{'ACCESSCONFIG_CREATEDBY' | translate}}: </span>
                            <span class="item-span">{{subscription.order.createdBy}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_LASTMODIFICATIONDATE' | translate}}: </span>
                            <span class="item-span">{{toLocalDateTime(subscription.order.lastModificationDate)}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span class="title-span">{{'ACCESSCONFIG_LASTMODIFICATIONBY' | translate}}: </span>
                            <span class="item-span">{{subscription.order.lastModificationBy}} </span>
                        </div>
                        <div [ngClass]="(medium ? 'param-minimun' : 'param')">
                            <span *ngIf="canEditOrder()" class="title-span item-sub1" translate>SUBSCRIPTION_NOTES</span>
                            <span *ngIf="canEditOrder()" class="item-span item-sub2 wrap fit-height">{{subscription.order.notes}}</span>
                        </div>
                    </div>
                    <div class="card-footer card-row4">
                        <button color="warn" *ngIf="canDeleteSubscription()" mat-flat-button class="delete-button" (click)="deleteSubscription(subscription)">{{'ACCESSCONFIG_DELETE_SUBSCRIPTION' | translate}}</button>
                    </div>
                </mat-card>
                <!-- UPDATE ORDINE -->
                <mat-card appearance="outlined" *ngIf="isOperator && subscription.showOrder" class="item-3 card update-order">
                    <div class="card-header card-row1 uppercase big">
                        <span class="card-header-col1" translate>ACCESSCONFIG_UPDATE_ORDER</span>
                    </div>
                    <form [formGroup]="editOrderForm" novalidate (ngSubmit)="updateOrder(subscription)">
                        <app-order-config #orderForm [orderForm]="editOrderForm" [userType]="subscription.order.userType" [planId]="subscription.order.planId" [fullName]="subscription.order.fullName" [userEmail]="subscription.order.userEmail" [startDate]="subscription.order.dbStartDate" [expireDate]="subscription.order.dbExpireDate" [origin]="subscription.order.origin" [suspended]="subscription.order.suspended" [notes]="subscription.order.notes" [slotCount]="subscription.order.slotCount" [showSlot]="subscription.order.licenseType!=='s'" [showDomainLics]="subscription.order.licenseType==='d'" [domainLics]="subscription.order.domainLics" [minSlot]="subscription.access.allocatedSlots" [ocrMathScanQuota]="subscription.order.ocrMathScanQuota" [ocrMathScanPolicy]="subscription.order.ocrMathScanPolicy"></app-order-config>
                        <div class="update-button-panel">
                            <button color="warn" [disabled]="!isEnableUpdateOrder()" mat-flat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>
                            <button mat-flat-button type="button" (click)="cancelOrderUpdate(subscription)">{{'ACCESSCONFIG_CANCEL' | translate}}</button>
                        </div>
                    </form>
                </mat-card>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
