import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MessageBoxDialogComponent } from '../messagebox-dialog/messagebox-dialog.component';
import { OrderHistoryComponent } from '../order-history/order-history.component';
import { TranslateService } from '@ngx-translate/core';

import * as adminDisabled3rdPartyApps from './messagebox/admin-disabled-3rd-party-apps';
import * as genericError from './messagebox/generic-error';
import * as googleShareNotFirstUser from './messagebox/google-share-not-first-user';
import * as recvideoError from './messagebox/recvideo-error';
import * as recvideoInfo from './messagebox/recvideo-info';
import * as renewAfterschoolContactus from './messagebox/renew-afterschool-contactus';
import * as renewContactus from './messagebox/renew-contactus';
import * as userCantShare from './messagebox/user-cant-share';

@Injectable({
    providedIn: 'root'
})
export class MessageBoxService {

    MODE_TYPE = {
        OK: 'OK',
        YESNO: 'YESNO'
    };

    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService) {
        this.dialog = dialog;
    }

    public showVideoRecError(): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = recvideoError.recvideoError;
        const placeHolders = [
            { ph: 'OOPS', txt: this.translateService.instant('OOPS') },
            { ph: 'MSG_NO_VIDEOREC_SUPPORTED', txt: this.translateService.instant('MSG_NO_VIDEOREC_SUPPORTED') }
        ];
        dialogRef.componentInstance.title = this.translateService.instant('WARNING');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showUserCantShare(): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = userCantShare.userCantShare
        const placeHolders = [
            { ph: 'OOPS', txt: this.translateService.instant('OOPS') },
            { ph: 'MSG_USER_CANT_SHARE', txt: this.translateService.instant('MSG_USER_CANT_SHARE') }
        ];
        dialogRef.componentInstance.title = this.translateService.instant('WARNING');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showVideoRecInfo(): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = recvideoInfo.recvideoInfo;
        const placeHolders = [
            { ph: 'MSG_VIDEOREC_INFO_01', txt: this.translateService.instant('MSG_VIDEOREC_INFO_01') },
            { ph: 'MSG_VIDEOREC_INFO_02', txt: this.translateService.instant('MSG_VIDEOREC_INFO_02') },
            { ph: 'MSG_VIDEOREC_INFO_03', txt: this.translateService.instant('MSG_VIDEOREC_INFO_03') },
            { ph: 'MSG_VIDEOREC_INFO_04', txt: this.translateService.instant('MSG_VIDEOREC_INFO_04') },
            { ph: 'MSG_VIDEOREC_INFO_05', txt: this.translateService.instant('MSG_VIDEOREC_INFO_05') },
            { ph: 'MSG_VIDEOREC_INFO_06', txt: this.translateService.instant('MSG_VIDEOREC_INFO_06') },
            { ph: 'MSG_VIDEOREC_INFO_07', txt: this.translateService.instant('MSG_VIDEOREC_INFO_07') },
            { ph: 'MSG_VIDEOREC_INFO_08', txt: this.translateService.instant('MSG_VIDEOREC_INFO_08') },
            { ph: 'MSG_VIDEOREC_SUB_01', txt: this.translateService.instant('MSG_VIDEOREC_SUB_01') },
            { ph: 'MSG_VIDEOREC_SUB_02', txt: this.translateService.instant('MSG_VIDEOREC_SUB_02') },
            { ph: 'MSG_VIDEOREC_SUB_03', txt: this.translateService.instant('MSG_VIDEOREC_SUB_03') },
            { ph: 'MSG_VIDEOREC_SUB_04', txt: this.translateService.instant('MSG_VIDEOREC_SUB_04') }
        ];
        dialogRef.componentInstance.title = this.translateService.instant('INFORMATION');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showRenewContactUs(): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = renewContactus.renewContactUs;
        const placeHolders = [
            { ph: 'RCU_TITLE', txt: this.translateService.instant('RCU_TITLE') },
            { ph: 'RCU_TEXT_01', txt: this.translateService.instant('RCU_TEXT_01') },
            { ph: 'RCU_TEXT_02', txt: this.translateService.instant('RCU_TEXT_02') },
            { ph: 'RCU_LINK_TEXT', txt: this.translateService.instant('RCU_LINK_TEXT') },
            { ph: 'RCU_LINK', txt: this.translateService.instant('RCU_LINK') },
        ];
        dialogRef.componentInstance.title = this.translateService.instant('INFORMATION');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showRenewAfterSchoolContactUs() {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = renewAfterschoolContactus.renewAfterschoolContactus;
        const placeHolders = [
            { ph: 'RACU_TITLE', txt: this.translateService.instant('RACU_TITLE') },
            { ph: 'RACU_TEXT_01', txt: this.translateService.instant('RACU_TEXT_01') },
            { ph: 'RACU_TEXT_02', txt: this.translateService.instant('RACU_TEXT_02') },
            { ph: 'RACU_TEXT_03', txt: this.translateService.instant('RACU_TEXT_03') },
            { ph: 'RACU_LINK_TEXT', txt: this.translateService.instant('RACU_LINK_TEXT') },
            { ph: 'RACU_LINK', txt: this.translateService.instant('RACU_LINK') },
        ];
        dialogRef.componentInstance.title = this.translateService.instant('INFORMATION');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showAdminDisabled3rdPartyApps(): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = adminDisabled3rdPartyApps.adminDisabled3rdPartyApps;
        const placeHolders = [
            { ph: 'GD3P_TITLE', txt: this.translateService.instant('GD3P_TITLE') },
            { ph: 'GD3P_TEXT_01', txt: this.translateService.instant('GD3P_TEXT_01') },
            { ph: 'GD3P_TEXT_02', txt: this.translateService.instant('GD3P_TEXT_02') },
            { ph: 'GD3P_TEXT_03', txt: this.translateService.instant('GD3P_TEXT_03') },
            { ph: 'GD3P_TEXT_04', txt: this.translateService.instant('GD3P_TEXT_04') },
            { ph: 'GD3P_TEXT_05', txt: this.translateService.instant('GD3P_TEXT_05') },
        ];
        dialogRef.componentInstance.title = this.translateService.instant('WARNING');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public googleShareNotFirstUserWarning(userMail: string): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = googleShareNotFirstUser.googleShareNotFirstUser;
        const placeHolders = [
            { ph: 'OOPS', txt: this.translateService.instant('OOPS') },
            { ph: 'GDNFU_TEXT_01', txt: this.translateService.instant('GDNFU_TEXT_01') },
            { ph: 'GDNFU_TEXT_02', txt: this.translateService.instant('GDNFU_TEXT_02') },
            { ph: 'GDNFU_TEXT_03', txt: this.translateService.instant('GDNFU_TEXT_03') },
            { ph: 'GDNFU_TEXT_04', txt: this.translateService.instant('GDNFU_TEXT_04') },
            { ph: 'GDNFU_TEXT_05', txt: this.translateService.instant('GDNFU_TEXT_05') },
            { ph: 'GDNFU_TEXT_06', txt: this.translateService.instant('GDNFU_TEXT_06') },
            { ph: 'USERMAIL', txt: userMail }
        ];
        dialogRef.componentInstance.title = this.translateService.instant('WARNING');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showGenericError(errorCode: string): Observable<boolean> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = this.MODE_TYPE.OK;
        const html = genericError.genericError;
        const placeHolders = [
            { ph: 'OOPS', txt: this.translateService.instant('OOPS') },
            { ph: 'GE_CODE', txt: this.translateService.instant('GE_CODE') },
            { ph: 'GE_ERROR_CODE', txt: errorCode },
            { ph: 'GE_TEXT_01', txt: this.translateService.instant('GE_TEXT_01') },
            { ph: 'GE_TEXT_02', txt: this.translateService.instant('GE_TEXT_02') },
            { ph: 'GE_TEXT_03', txt: this.translateService.instant('GE_TEXT_03') },
            { ph: 'GE_TEXT_04', txt: this.translateService.instant('GE_TEXT_04') },
            { ph: 'GE_TEXT_05', txt: this.translateService.instant('GE_TEXT_05') },
            { ph: 'GE_TEXT_06', txt: this.translateService.instant('GE_TEXT_06') }
        ];
        dialogRef.componentInstance.title = this.translateService.instant('WARNING');
        dialogRef.componentInstance.setHtml(html, placeHolders);
        //
        return dialogRef.afterClosed();
    }

    public showTextMessage(mode: string, title: string, message: string): Observable<any> {
        const dialogRef: MatDialogRef<MessageBoxDialogComponent> = this.dialog.open(MessageBoxDialogComponent);
        dialogRef.componentInstance.mode = mode;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.setHtml(message);
        //
        return dialogRef.afterClosed();
    }

    public showOrderHistory(order: any): Observable<boolean> {
        const dialogRef: MatDialogRef<OrderHistoryComponent> = this.dialog.open(OrderHistoryComponent, { width: '80vw', height: '80vh', maxWidth: '1000px' });
        dialogRef.componentInstance.title = this.translateService.instant('WARNING');
        dialogRef.componentInstance.setData(order.z_history);
        //
        return dialogRef.afterClosed();
    }

}
