import { Injectable } from '@angular/core';
import { VirtualKeyboardLayout, VirtualKeyboardName } from 'mathlive';

// Italian
import { IT_PRIMARY_KEYBOARD } from './keyboards/it/primary-kbd';
import { IT_BASIC_KEYBOARD } from './keyboards/it/basic-kbd';
import { IT_SETS_KEYBOARD } from './keyboards/it/sets-kbd';
import { IT_GEOMETRY_KEYBOARD } from './keyboards/it/geometry-kbd';
import { IT_DISEQ_KEYBOARD } from './keyboards/it/disequations-kbd';
import { IT_PHYSICS_KEYBOARD } from './keyboards/it/physics-kbd';
import { IT_ANALYSIS_KEYBOARD } from './keyboards/it/analysis-kbd';
import { IT_TRIGONOMETRY_KEYBOARD } from './keyboards/it/trigonometry-kbd';
import { IT_LOG_EXP_KEYBOARD } from './keyboards/it/log-exp-kbd';
import { IT_GREEK_KEYBOARD } from './keyboards/it/greek-kbd';

// English
import { EN_PRIMARY_KEYBOARD } from './keyboards/en/primary-kbd';
import { EN_BASIC_KEYBOARD } from './keyboards/en/basic-kbd';
import { EN_SETS_KEYBOARD } from './keyboards/en/sets-kbd';
import { EN_GEOMETRY_KEYBOARD } from './keyboards/en/geometry-kbd';
import { EN_DISEQ_KEYBOARD } from './keyboards/en/disequations-kbd';
import { EN_PHYSICS_KEYBOARD } from './keyboards/en/physics-kbd';
import { EN_ANALYSIS_KEYBOARD } from './keyboards/en/analysis-kbd';
import { EN_TRIGONOMETRY_KEYBOARD } from './keyboards/en/trigonometry-kbd';
import { EN_LOG_EXP_KEYBOARD } from './keyboards/en/log-exp-kbd';
import { EN_GREEK_KEYBOARD } from './keyboards/en/greek-kbd';

import { TranslateService } from '@ngx-translate/core';
import { UserPreferenceService } from '../user-preference.service';
import { ModalService } from '../links-menu/modal-service';

@Injectable({
    providedIn: 'root'
})
export class MathKeyboardService {

    private tooltipRegistry: { [index: string]: any } = {}
    private keyboardList: any = null;

    constructor(
        private translateService: TranslateService,
        private userPreferenceService: UserPreferenceService,
        private modalService: ModalService
    ) {
        this.initKeyboardService();
    }

    public getKeyboardLayouts(language: string): Array<VirtualKeyboardName | VirtualKeyboardLayout> {
        this.keyboardList = (language === 'it' ? [
            IT_PRIMARY_KEYBOARD, IT_BASIC_KEYBOARD, IT_SETS_KEYBOARD, IT_GEOMETRY_KEYBOARD, IT_DISEQ_KEYBOARD,
            IT_PHYSICS_KEYBOARD, IT_ANALYSIS_KEYBOARD, IT_TRIGONOMETRY_KEYBOARD, IT_LOG_EXP_KEYBOARD, IT_GREEK_KEYBOARD
        ] : [
            EN_PRIMARY_KEYBOARD, EN_BASIC_KEYBOARD, EN_SETS_KEYBOARD, EN_GEOMETRY_KEYBOARD, EN_DISEQ_KEYBOARD,
            EN_PHYSICS_KEYBOARD, EN_ANALYSIS_KEYBOARD, EN_TRIGONOMETRY_KEYBOARD, EN_LOG_EXP_KEYBOARD, EN_GREEK_KEYBOARD
        ]
        );
        this.initKeyboardService();
        const layouts = new Array<VirtualKeyboardName | VirtualKeyboardLayout>();
        //
        // Custom keyboards
        //
        if (this.userPreferenceService.userPrefs &&
            this.userPreferenceService.userPrefs.math.keyboardLayouts.length === 0 ||
            (window as any).forceKeyboardChoice === true) {
            (window as any).forceKeyboardChoice = false;
            this.modalService.showKeyboardChoice();
        } else {
            this.keyboardList.forEach((kb: any) => {
                if (!this.userPreferenceService.userPrefs ||
                    this.userPreferenceService.userPrefs.math.keyboardLayouts.length === 0) {
                    // this.addKeyboard(layouts, kb);
                } else {
                    if (typeof kb === 'string' && this.userPreferenceService.userPrefs.math.keyboardLayouts.includes(kb.toLocaleUpperCase())) {
                        this.addKeyboard(layouts, kb);
                    } else if (this.userPreferenceService.userPrefs.math.keyboardLayouts.includes(kb.id)) {
                        this.addKeyboard(layouts, kb);
                    }
                }
            });
        }
        //
        // Standard keyboards
        //
        // layouts.push('compact');
        // layouts.push('minimalist');
        // layouts.push('numeric-only');
        // layouts.push('numeric');
        // layouts.push('symbols');
        // layouts.push('alphabetic'); // --togliere numero 1a RIGA
        // layouts.push('greek');
        return layouts;
    }

    private addKeyboard(layouts: any, kb: any) {
        layouts.push(this.trans(kb, this.translateService.currentLang));
    }

    private initKeyboardService() {
        this.tooltipRegistry = {};
    }

    private trans(keyboard: VirtualKeyboardLayout, lang: string): VirtualKeyboardLayout {
        if (keyboard) {
            const id = (keyboard.id ? keyboard.id : keyboard);
            try {
                const kbName = this.translateService.instant("MATH_" + id + "_KEYBOARD");
                if (keyboard.id) keyboard.label = kbName;
            } catch (err) {
                console.error("Error during translating keyboard name");
            }
        }
        return keyboard;
    }

    private getLangShortCode(lang: string): string {
        let sc = 'en-GB';
        switch (lang) {
            case 'it-IT':
                sc = 'it';
                break;
            default:
                sc = 'en';
                break;
        }
        return sc
    }

    public checkEqualLayouts(layouts1: string[], layouts2: string[]): boolean {
        if (layouts1.length !== layouts2.length) {
            return false;
        }

        const sortedArr1 = layouts1.slice().sort();
        const sortedArr2 = layouts2.slice().sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }
}
