import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MapStateService } from '../map-state.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-paste-tab',
    templateUrl: './paste-tab.component.html',
    styleUrls: ['./paste-tab.component.scss']
})
export class PasteTabComponent {

    constructor(
        public dialogRef: MatDialogRef<PasteTabComponent>,
        private mapStateService: MapStateService
    ) {
    }

    public noTabRowsCols: boolean = false;
    public hasDeepContent: boolean = false;
    public isPasteAsDeepChecked: boolean = true;

    clicked(pasteMode: string) {
        console.log('CLICKED: ' + pasteMode);
        this.mapStateService.pasteMode = pasteMode;
        this.mapStateService.pasteWithDeepContent = (this.hasDeepContent ? this.isPasteAsDeepChecked : false);
        this.dialogRef.close(true);
    }

    public OnDeepToggleChange(event: any) {
        const e = event as MatSlideToggleChange
        this.isPasteAsDeepChecked = e.checked;
    }

}
