import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import * as contentEnGb from '../translations/en-GB.json';
import * as contentItIt from '../translations/it-IT.json';
// import * as contentCaEs from '../translations/ca-ES.json';
// import * as contentEsEs from '../translations/es-ES.json';

const TRANSLATIONS: any = {
  'it-IT': contentItIt,
  'en-GB': contentEnGb // ,
  // 'ca-ES': contentCaEs,
  // 'es-ES': contentEsEs,
};

export class TranslateUniversalLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    console.log('LOAD LANG:' + lang);
    return of(TRANSLATIONS[lang].default);
  }
}
