import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../core/i18n.service';
import { DemoComponent } from './demo.component';

const routes: Routes = [
  { path: 'demo', component: DemoComponent, data: { title: extract('LOGIN_TITLE_DEMO') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DemoRoutingModule { }
