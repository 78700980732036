import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-dialog-qrcode',
    templateUrl: './dialog-qrcode.component.html',
    styleUrls: ['./dialog-qrcode.component.scss']
})
export class DialogQRCodeComponent {

    public title: string;
    public qrcode: string;
    public safeMessage: SafeHtml;
    public isMapLink: boolean;
    public isGDocLink: boolean;

    constructor(
        private translateService: TranslateService,
        private domSanitizer: DomSanitizer
    ) {
        this.title = '';
        this.qrcode = '';
        this.safeMessage = '';
        this.isMapLink = false;
        this.isGDocLink = false;
    }

    setTitle(type: string) {
        this.title = this.translateService.instant('QRCODE_TITLE_' + type);
        if (type.toLocaleLowerCase() === 'map') {
            this.isMapLink = true;
        } else {
            this.isGDocLink = true;
        }
    }

    setQRCode(dataUrl: string) {
        this.qrcode = dataUrl;
    }

    setMessage(message: string) {
        this.safeMessage = this.domSanitizer.bypassSecurityTrustHtml(message.replace(/\n/g, '<br/>'));
    }

}
