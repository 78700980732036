import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { MapStateService } from '../../shared/map-state.service';
import { WebSearchService } from '../../shared/commands/web-search.service';
import { UiConstants } from '../../shared/ui-constants';
import { CustomSelectElement } from '../toolbar/custom-toolbar/custom-select-element-dto';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { WebService } from 'src/app/shared/commands/web.service';
import { ExtraItem, ExtraService } from '../commands/extra.service';

@Component({
    selector: 'app-web-panel',
    templateUrl: './web-panel.component.html',
    styleUrls: ['./web-panel.component.scss']
})
export class WebPanelComponent implements OnInit {

    @ViewChild('iframe') iframe: ElementRef | undefined;

    @Input() editUrl: boolean = true;
    @Input() url: SafeResourceUrl = '';

    public style: object = {};
    public styleover: object = {};
    public mapinteraction = false;
    private newTop = 0;

    public ttsEnable = false;
    public languages: CustomSelectElement[] = new Array<CustomSelectElement>();
    selectedLangIcon = UiConstants.LANGUAGES[0].icon;
    onGetVoicesSubscription: Subscription | undefined;
    onToggleWebSearchSubscription: Subscription | undefined;
    onResizeSubscription: Subscription | undefined;
    // onToggleDesmosSubscrition: Subscription | undefined;
    rateValue = 1;
    public speechEditEnabled = false;
    onSpeechEditEnableSubscription: Subscription | undefined;
    speechRecordSubscription: Subscription | undefined;
    mapMoving: Subscription | undefined;
    geometry = false;
    fourfunction = false;
    calculator = false;
    scientific = false;
    resizing = false;

    public urlInput: string = '';

    constructor(
        // private webService: WebService, 
        private webSearchService: WebSearchService,
        private mapStateService: MapStateService,
        private quickEditService: QuickEditService,
        private extraService: ExtraService,
        private sanitizer: DomSanitizer) {

    }

    ngOnInit() {
        this.urlInput = (this.url as any)['changingThisBreaksApplicationSecurity'];
        const extraContainer: any = document.getElementsByClassName('extra-container')[0];
        // Modifica altezza in base alla visibilità del pannello search
        // this.onToggleDesmosSubscrition = this.webService.onToggleWebPanel.subscribe((opened: boolean) => {
        //     if (opened) {
        //         this.urlInput = this.webService.url;
        //         setTimeout(() => {
        //             this.changeUrl();
        //         }, 0);
        //     }
        // });

        /*this.onToggleWebSearchSubscription = this.webSearchService.onToggleWebSearchPanel.subscribe((opened: boolean) => {
            if (opened) {
                extraContainer.style.top = this.newTop + 45 + 'px'; //  '40px';
                this.newTop = this.newTop + 45;
            } else {
                const top = this.newTop - 45;
                this.newTop = (top >= 0) ? top : 0;
                extraContainer.style.top = this.newTop + 'px';
            }
            // this.resizeEditor();
        });*/

    }
    isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        input !== null && input.tagName === 'IFRAME';

    changeUrl() {
        //
        //this.webService.url = value;
        if (this.urlInput) {
            if (this.urlInput.toLowerCase().indexOf('https://') === -1) {
                if (this.urlInput.toLowerCase().indexOf('http://') >= 0) {
                    this.urlInput = this.urlInput.toLowerCase().replace('http://', 'https://');
                } else {
                    this.urlInput = 'https://' + this.urlInput;
                }
            }
            if (this.urlInput.toLowerCase().indexOf('wikipedia') >= 0) {
                this.urlInput = 'https://it.wikipedia.org/'
            }
            // const frame = document.getElementById('iframe-id');
            // if (frame && this.isIFrame(frame) && frame.contentWindow) {
            //    frame.contentWindow.location.href = this.urlInput;
            // }
            // if (this.iframe) {
            //     this.iframe.nativeElement.src = this.urlInput;
            // }
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlInput);
        }
    }

    enterEdit() {
        // this.resizing = false;
        this.mapStateService.setFocusOnMap(false);
        this.mapStateService.canEnableQuickEdit = false;
    }

    exitEdit() {
        // this.resizing = true;
        this.mapStateService.setFocusOnMap(true);
        this.mapStateService.canEnableQuickEdit = true;
    }

    closeExtra() {
        if (this.quickEditService.isQuickEditEnabled()) {
            this.quickEditService.toggleQuickEdit();
        }
        if (this.quickEditService.isSpeechEditEnabled()) {
            this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
        }
        // this.webService.toggleWebPanel();
        this.extraService.toggleExtraPanel(ExtraItem.CLOSE);
    }

}
