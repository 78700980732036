import { Injectable } from '@angular/core';
import { SmService } from 'supermappe-core';



@Injectable({
  providedIn: 'root'
})
export class MapFoldingService {
  // mapStateOpen: boolean;
  // public OnMapStateChanged = new EventEmitter<boolean>();
  constructor(private smService: SmService) {
    //  this.mapStateOpen = true;
  }

  // true: apre tutti i nodi (shift pressed)
  // false: chiude tutti i nodi
  public setFoldingState(open: boolean) {
    this.smService.setMapState(open);
    this.smService.updateCache(false);
    this.smService.askOutline();
  }

  public startShow() {
    this.smService.startShow();
  }

  public nextShowStep() {
    this.smService.nextShowStep();
  }

  public prevShowStep() {
    this.smService.prevShowStep();
  }

  public goToStartingShowStep() {
    this.smService.goToStartingShowStep();
  }

  public goToFinalShowStep() {
    this.smService.goToFinalShowStep();
  }

}
