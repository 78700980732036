<div class="custom-toolbar">
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked?'button-disabled':''" (click)="removeTable()" color="primary" matTooltip="{{'TABLE_TOOLBAR-REMOVE-TABLE' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-REMOVE-TABLE' | translate" svgIcon="grid-off" color="primary"></mat-icon>
    </button>
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked?'button-disabled':''" (click)="removeRow()" color="primary" matTooltip="{{'TABLE_TOOLBAR-REMOVE-ROW' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-REMOVE-ROW' | translate" svgIcon="table-row-remove" color="primary"></mat-icon>
    </button>
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked?'button-disabled':''" (click)="removeCol()" color="primary" matTooltip="{{'TABLE_TOOLBAR-REMOVE-COL' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-REMOVE-COL' | translate" svgIcon="remove-column" color="primary"></mat-icon>
    </button>
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked || !canAddRow?'button-disabled':''" (click)="addTableRowUp()" color="primary" matTooltip="{{'TABLE_TOOLBAR-ADD-ROW-ABOVE' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-ADD-ROW-ABOVE' | translate" svgIcon="table-row-plus-before" color="primary"></mat-icon>
    </button>
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked || !canAddRow?'button-disabled':''" (click)="addTableRowDown()" color="primary" matTooltip="{{'TABLE_TOOLBAR-ADD-ROW-BELOW' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-ADD-ROW-BELOW' | translate" svgIcon="table-row-plus-after" color="primary"></mat-icon>
    </button>
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked || !canAddCol?'button-disabled':''" (click)="addTableColumnLeft()" color="primary" matTooltip="{{'TABLE_TOOLBAR-ADD-COL-BEFORE' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-ADD-COL-BEFORE' | translate" svgIcon="insert-column-sx" color="primary"></mat-icon>
    </button>
    <button mat-icon-button class="mat-tooltip-trigger" [ngClass]="isTableLocked || !canAddCol?'button-disabled':''" (click)="addTableColumnRight()" color="primary" matTooltip="{{'TABLE_TOOLBAR-ADD-COL-AFTER' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-ADD-COL-AFTER' | translate" svgIcon="insert-column-dx" color="primary"></mat-icon>
    </button>
    <button *ngIf="!isTableLocked" class="mat-tooltip-trigger" mat-icon-button color="primary" (click)="lockTable(true)" matTooltip="{{'TABLE_TOOLBAR-LOCK' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-LOCK' | translate" color="primary" fontSet="material-icons-outlined">lock_open</mat-icon>
    </button>
    <button *ngIf="isTableLocked" class="mat-tooltip-trigger" mat-icon-button color="primary" (click)="lockTable(false)" matTooltip="{{'TABLE_TOOLBAR-UNLOCK' | translate}}">
        <mat-icon [attr.aria-label]="'TABLE_TOOLBAR-UNLOCK' | translate" class="material-icons-sharp red-icon" fontSet="material-icons-filled">lock</mat-icon>
    </button>
</div>
