<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </span>
        <!-- <span class="access-subtitle">{{getRoleType()}}</span> -->
    </div>
    <div class="panel-button">
        <button mat-button class="title-button" (click)="showInsertNotification()">
            {{'NOTIFICATION_INSERT' | translate}}
        </button>
        <!-- <div class="button-panel">
      <button color="primary" mat-button class="title-button" (click)="editNotification(notification)">{{'EDIT_NOTIFICATION' | translate}}</button>
    </div> -->
        <div class="item-2" *ngIf="showInsert">
            <form [formGroup]="insertNotificationForm" novalidate (ngSubmit)="updateForm()">
                <app-admin-edit-notification #notificationForm [notificationForm]="insertNotificationForm"></app-admin-edit-notification>
                <div class="form-button">
                    <button class="ok-button" [disabled]="!canSave()" mat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}
                    </button>
                    <button color="primary" class="cancel-button" type="reset" (click)="resetForm()" mat-button>{{'ACCESSCONFIG_RESET' | translate}}</button>
                </div>

            </form>
        </div>
    </div>


    <div class="list-notification">
        <app-admin-list-notifications [isLoading]="isLoading" [forceReload]="forceReload"></app-admin-list-notifications>
    </div>



</div>
