import { VirtualKeyboardLayout } from 'mathlive';

export const IT_ANALYSIS_KEYBOARD: VirtualKeyboardLayout = {
    id: "ANALYSIS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                class: 'small',
                latex: '\\sum_{\\placeholder{}}^\\placeholder{}',
                tooltip: 'sommatoria'
            },
            {
                class: 'small',
                latex: '\\prod_{\\placeholder{}}^\\placeholder{}',
                tooltip: 'produttoria'
            },
            {
                class: 'small',
                latex: '\\int_\\!\\placeholder{}\\,\\mathrm{d}x',
                tooltip: 'primitiva'
            },
            {
                class: 'small',
                latex: ' \\int_\\placeholder{}\\!\\placeholder{}\\,\\mathrm{d}x',
                tooltip: 'integrale'
            },
            {
                class: 'small',
                latex: '\\int_{\\placeholder{}}^{\\placeholder{}}\\!\\placeholder{}\\,\\mathrm{d}x',
                tooltip: 'integrale'
            }
        ],
        // Second row
        [
            {
                latex: '\\lim_{x\\to0^+}',
                tooltip: 'limite per x che tende a zero da destra'
            },
            {
                latex: '\\lim_{x\\to0^-}',
                tooltip: 'limite per x che tende a zero da sinistra'
            },
            {
                latex: '\\lim_{x\\to0}',
                tooltip: 'limite per x che tende a zero'
            },
            {
                latex: '\\lim_{x\\to-\\infty}',
                tooltip: 'limite per x che tende a meno infinito'
            },
            {
                latex: ' \\lim_{x\\to+\\infty}',
                tooltip: 'limite per x che tende a più infinito'
            }
        ],
        // Third row
        [
            {
                latex: '\\infty',
                tooltip: 'infinito", "en": "infinity'
            },
            {
                latex: '\\epsilon',
                tooltip: 'epsilon'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            },
            {
                latex: '\\theta',
                tooltip: 'theta'
            },
            {
                class: 'small',
                latex: '\\frac{\\mathrm{d}}{\\mathrm{d}x}',
                tooltip: 'derivata rispetto a x'
            }
        ],
        // Fourth row
        [
            {
                latex: '\\to',
                tooltip: 'che tende a'
            }
        ]
    ]
};
