import { Component, OnInit, EventEmitter, Output, Input, ContentChild, Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';


@Component({
    selector: 'app-button-color-picker',
    templateUrl: './button-color-picker.component.html',
    styleUrls: ['./button-color-picker.component.scss']
})


export class ButtonColorPickerComponent {
    // public colors: string[] = [
    //   '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    //   '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    //   '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    //   '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    //   '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    // ];

    // public items: MccColorPickerItem[] = [
    //   { text: 'Black', value: '#000000' },
    //   { text: 'White', value: '#FFFFFF' },
    //   { text: 'Gray', value: '#CCCCCC' },
    // ];

    @Input() colors: string[] = [];
    @Input() selectedColor: any;
    @Input() toolTip: string;
    @Input() pickerIcon: any;
    @Input() usedSizeColors: number;
    @Input() usedColorLabel: string;
    @Input() hideButtons: boolean;
    @Input() hideUsedColors: boolean;
    @Input() showTransparent: boolean;
    @Input() disabled = false;
    @Input() hideHexForms: boolean;
    @Input() btnCancel: string;
    @Input() btnConfirm: string;
    @Input() nocolor: string;
    @Input() colorLabel: string;

    public isOpen = false;
    public hiddenPicker = false;

    @Output()
    onChangeColor = new EventEmitter<string>();

    constructor(private translateService: TranslateService) {

        this.btnCancel = this.translateService.instant(extract('CANCEL'));
        this.btnConfirm = this.translateService.instant(extract('OK'));
        this.usedSizeColors = 0;
        this.hideUsedColors = true;
        this.hideButtons = true;
        this.selectedColor = '#ff0000';
        this.toolTip = '';
        this.usedSizeColors = 0;
        this.colorLabel = '';
        this.nocolor = '';//this.translateService.instant(extract('NOCOLOR'));

        this.usedColorLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));
        this.showTransparent = true;
        this.hideHexForms = false;

    }

    // this.hiddenPicker = this.pickerIcon === undefined;

    toggleColor() {
        this.isOpen = true;
        this.hiddenPicker = this.isOpen;
    }

    selected(e: any) {
        if (e !== '' && !this.hideButtons) {
            this.selectedColor = e;
            this.onChangeColor.emit(this.selectedColor);
        }
        this.isOpen = false;
        this.hiddenPicker = this.isOpen;
    }

    cancelSelection() {
        this.isOpen = false;
        this.hiddenPicker = this.isOpen;
    }

    changedColor(e: any) {
        if (e !== '' && this.hideButtons) {
            this.selectedColor = e;
            this.onChangeColor.emit(this.selectedColor);
            this.isOpen = false;
            this.hiddenPicker = false;
        }
    }

}
