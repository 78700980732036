import { Injectable, EventEmitter } from '@angular/core';
import { EditData } from 'supermappe-core';
import { Observable } from 'rxjs';
import { SmEventBroker } from '../sm-event-broker';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditDataService {
  public editDataChange: Observable<any> | undefined
  public onOpenEditor = new EventEmitter<boolean>();
  constructor(eventBroker: SmEventBroker) {
    if (eventBroker.editData) {
    this.editDataChange = eventBroker.editData
      .pipe(map((editData: EditData) => {
        return editData;
      }));
    }
  }

  public closeEditor() {
    this.onOpenEditor.emit(false);
  }

  public openEditor() {
    this.onOpenEditor.emit(true);
  }
}
