<h2 mat-dialog-title translate>APP_NAME</h2>
<div mat-dialog-content>
    <span translate>HEADER_INFO_COPYRIGHT</span><br />
    <br />
    <mat-icon>code</mat-icon>
    <span translate>ABOUT_VERSION</span> {{version}}<br />
    <span translate>ABOUT_BUILD</span> {{build}}<br />
    <br />
    <b><span translate>ABOUT_EXPIRY</span> {{expiry}}</b><br />
    <span translate>ABOUT_PLAN</span><b> {{planId}}</b><br />
    <br />
</div>
<mat-dialog-actions [align]="'end'">
    <button mat-flat-button color="primary" type="button" [mat-dialog-close]="true" translate>
        <div translate>OK</div>
    </button>
</mat-dialog-actions>
