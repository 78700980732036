import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { SmeService } from '../../core/sme/sme.service';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import { UiConstants } from '../../shared/ui-constants';

@Component({
    selector: 'app-admin-stats',
    templateUrl: './admin-stats.component.html',
    styleUrls: ['../admin-stats/admin-stats.component.scss']
})

export class AdminStatsComponent implements OnInit, OnDestroy {

    public isLoading = false;
    public user = '';
    public role = '';
    private freezeSubsSubscription: Subscription | undefined;
    private GetStatsSubscription: Subscription | undefined;

    public freeze_year = 0;
    public freeze_month = 0;
    public stats_origin = '';
    public stats_year = 0;
    public stats_subs: any;
    public stats_totUsers: any;
    public stats_activeUsers: any;
    public stats_openedMaps: any;
    public stat_progress = 0;
    public domains_year = 0;

    public origins = UiConstants.ORIGINS_ALL;

    constructor(
        private authenticationService: AuthenticationService,
        private smeService: SmeService,
    ) { }

    ngOnInit() {
        if (this.authenticationService) {
            this.user = this.authenticationService.getUserName();
            this.role = (this.authenticationService.credentials ? this.authenticationService.credentials.role : '');
            this.stat_progress = 0;
        }
    }

    ngOnDestroy() {
        if (this.freezeSubsSubscription != null) { this.freezeSubsSubscription.unsubscribe(); }
    }

    freezeSubs(year: number, month: number) {
        this.isLoading = true;
        if (year >= 2018 && month >= 1 && month <= 12) {
            console.log(`Freezing year:${year}, month:${month}`);
            if (this.freezeSubsSubscription) { this.freezeSubsSubscription.unsubscribe(); }
            this.freezeSubsSubscription = this.smeService.freezeSubscription(year, month).subscribe((id: any) => {
                this.isLoading = false;
                alert('modifica effettuata');
            }, (smxError: any) => {
                this.isLoading = false;
                alert(`ERROR: ${JSON.stringify(smxError)}`);
            });
        } else {
            this.isLoading = false;
            alert('Inserire anno 2018 o superiore e mese da 1 a 12');
        }
    }

    // getStatsByOriginAndYear(origin: string, year: number) {
    //   this.isLoading = true;
    //   if (year >= 2018) {
    //     console.log(`Getting stats for origin:${origin}, year:${year}`);
    //     if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
    //     this.GetStatsSubscription = this.smeService.getStatsByOriginAndYear(origin, year).subscribe((data: any) => {
    //       if (data && data.data) {
    //         const dataBlob = new Blob([data.data], { type: 'text/csv' });
    //         this.isLoading = false;
    //         FileSaver.saveAs(dataBlob, 'stats_' + origin + '_' + year + '.csv');
    //       } else {
    //         this.isLoading = false;
    //         alert('ERRORE: la funzione non ha restituito dati (timeout?)');
    //       }
    //     }, (error: any) => {
    //       this.isLoading = false;
    //       alert(`ERRORE: ${JSON.stringify(error)}`);
    //     });
    //   } else {
    //     this.isLoading = false;
    //     alert('Inserire anno 2018 o superiore');
    //   }
    // }

    getStatsByOriginAndYear(origin: string, year: number) {
        this.isLoading = true;
        if (year >= 2018 && origin) {
            console.log(`Getting stats for origin:${origin}, year:${year}`);
            Promise.resolve().then(() => {
                this.stat_progress = 0;
                return this.getStatsByOriginAndYearStep1(origin, year);
            }).then(() => {
                this.stat_progress = 10;
                return this.getStatsByOriginAndYearStep2(year);
            }).then(() => {
                this.stat_progress = 30;
                return this.getStatsByOriginAndYearStep3(year);
            }).then(() => {
                this.stat_progress = 60;
                return this.getStatsByOriginAndYearStep4(year);
            }).then(() => {
                this.stat_progress = 90;
                return this.getStatsByOriginAndYearStep5(origin, year, this.stats_subs, this.stats_totUsers, this.stats_activeUsers, this.stats_openedMaps);
            }).then(() => {
                this.stat_progress = 100;
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                console.error('FAILED!!! ERROR:' + error);
            });
        } else {
            this.isLoading = false;
            alert('Selezionare anno >= 2018 e una delle origini disponibili');
        }
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------

    getStatsByOriginAndYearStep1(origin: string, year: number) {
        return new Promise((resolve, reject) => {
            console.log(`getStatsByOriginAndYearStep1(origin:${origin}, year:${year})`);
            if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
            this.GetStatsSubscription = this.smeService.getStatsByOriginAndYearStep1(origin, year).subscribe({
                next: (data: any) => {
                    if (data && data.data) {
                        this.isLoading = false;
                        this.stats_subs = data.data;
                        console.log(`getStatsByOriginAndYearStep1 RESOLVED!`);
                        return resolve('');
                    } else {
                        this.isLoading = false;
                        alert('ERRORE: la funzione non ha restituito dati (timeout?)');
                        return reject('ERROR');
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    alert(`ERRORE: ${JSON.stringify(error)}`);
                    return reject('ERROR');
                }
            });
        });
    }

    getStatsByOriginAndYearStep2(year: number) {
        return new Promise((resolve, reject) => {
            console.log(`getStatsByOriginAndYearStep2(year:${year})`);
            this.isLoading = true;
            if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
            this.GetStatsSubscription = this.smeService.getStatsByOriginAndYearStep2(year).subscribe({
                next: (data: any) => {
                    if (data && data.data) {
                        this.isLoading = false;
                        this.stats_totUsers = data.data;
                        console.log(`getStatsByOriginAndYearStep2 RESOLVED!`);
                        return resolve('');
                    } else {
                        this.isLoading = false;
                        alert('ERRORE: la funzione non ha restituito dati (timeout?)');
                        return reject('ERROR');
                    }
                }, error: (error: any) => {
                    this.isLoading = false;
                    alert(`ERRORE: ${JSON.stringify(error)}`);
                    return reject('ERROR');
                }
            });
        });
    }

    getStatsByOriginAndYearStep3(year: number) {
        return new Promise((resolve, reject) => {
            console.log(`getStatsByOriginAndYearStep3(year:${year})`);
            this.isLoading = true;
            if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
            this.GetStatsSubscription = this.smeService.getStatsByOriginAndYearStep3(year).subscribe({
                next: (data: any) => {
                    if (data && data.data) {
                        this.isLoading = false;
                        this.stats_activeUsers = data.data;
                        console.log(`getStatsByOriginAndYearStep3 RESOLVED!`);
                        return resolve('');
                    } else {
                        this.isLoading = false;
                        alert('ERRORE: la funzione non ha restituito dati (timeout?)');
                        return reject('ERROR');
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    alert(`ERRORE: ${JSON.stringify(error)}`);
                    return reject('ERROR');
                }
            });
        });
    }

    getStatsByOriginAndYearStep4(year: number) {
        return new Promise((resolve, reject) => {
            console.log(`getStatsByOriginAndYearStep4(year:${year})`);
            this.isLoading = true;
            if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
            this.GetStatsSubscription = this.smeService.getStatsByOriginAndYearStep4(year).subscribe({
                next: (data: any) => {
                    if (data && data.data) {
                        this.isLoading = false;
                        this.stats_openedMaps = data.data;
                        console.log(`getStatsByOriginAndYearStep4 RESOLVED!`);
                        return resolve('');
                    } else {
                        this.isLoading = false;
                        alert('ERRORE: la funzione non ha restituito dati (timeout?)');
                        return reject('ERROR');
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    alert(`ERRORE: ${JSON.stringify(error)}`);
                    return reject('ERROR');
                }
            });
        });
    }

    getStatsByOriginAndYearStep5(origin: string, year: number, subs: any, totUsers: any, activeUsers: any, openedMaps: any) {
        return new Promise((resolve, reject) => {
            console.log(`getStatsByOriginAndYearStep5(origin:${origin}, year:${year},...)`);
            this.isLoading = true;
            if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
            this.GetStatsSubscription = this.smeService.getStatsByOriginAndYearStep5(subs, totUsers, activeUsers, openedMaps).subscribe({
                next: (data: any) => {
                    if (data && data.data) {
                        const dataBlob = new Blob([data.data], { type: 'text/csv' });
                        this.isLoading = false;
                        if (origin === '*') {
                            origin = 'all';
                        }
                        FileSaver.saveAs(dataBlob, 'stats_' + origin + '_' + year + '.csv');
                        console.log(`getStatsByOriginAndYearStep5 RESOLVED!`);
                        return resolve('');
                    } else {
                        this.isLoading = false;
                        alert('ERRORE: la funzione non ha restituito dati (timeout?)');
                        return reject('ERROR');
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    alert(`ERRORE: ${JSON.stringify(error)}`);
                    return reject('ERROR');
                }
            });
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------

    getDomainsStatsByYear(year: number) {
        this.isLoading = true;
        if (year >= 2018) {
            console.log(`Getting stats for domains year:${year}`);
            if (this.GetStatsSubscription) { this.GetStatsSubscription.unsubscribe(); }
            this.GetStatsSubscription = this.smeService.getDomainsStatsByYear(year).subscribe({
                next: (data: any) => {
                    if (data && data.data) {
                        const dataBlob = new Blob([data.data], { type: 'text/csv' });
                        this.isLoading = false;
                        FileSaver.saveAs(dataBlob, 'domain_stats_' + year + '.csv');
                    } else {
                        this.isLoading = false;
                        alert('ERRORE: la funzione non ha restituito dati (timeout?)');
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    alert(`ERRORE: ${JSON.stringify(error)}`);
                }
            });
        } else {
            this.isLoading = false;
            alert('Inserire anno 2018 o superiore');
        }
    }

}
