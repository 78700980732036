import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmMathOCRDialogComponent } from '../confirm-math-ocrdialog/confirm-math-ocrdialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class MathocrConfirmationService {

    public ref: any;
    public isCopyLatex: boolean = false;
    constructor(private dialog: MatDialog, private translateService: TranslateService) {
        this.dialog = dialog;
    }

    public confirm(title: string, email: string): Observable<boolean> {
        const dialogRef: MatDialogRef<ConfirmMathOCRDialogComponent> = this.dialog.open(ConfirmMathOCRDialogComponent, { disableClose: true, hasBackdrop: false });
        this.isCopyLatex = false;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.email = email;
        dialogRef.componentInstance.message = '';
        dialogRef.componentInstance.image = '';
        dialogRef.componentInstance.serviceRef = this;
        dialogRef.componentInstance.cancel = this.translateService.instant('GENERAL_CANCEL');
        dialogRef.componentInstance.insert = this.translateService.instant('INSERT')
        dialogRef.componentInstance.copyLatex = this.translateService.instant('COPY_LATEX')
        this.ref = dialogRef;
        return dialogRef.afterClosed();
    }

    public setImage(image: string) {
        this.ref.componentInstance.image = image;
        this.ref.componentInstance.isImageSet = true;
    }
}
