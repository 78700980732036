import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserDto } from '../user-dto';
import { AccessDto } from '../access-dto';
import { MatFormField } from '@angular/material/form-field';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-admin-config',
    templateUrl: './admin-config.component.html',
    styleUrls: ['./admin-config.component.scss']
})
export class AdminConfigComponent implements OnInit {

    @Input() parentForm = new FormGroup({
        domain: new FormControl(),
        domainLics: new FormControl(),
        adminEmail: new FormControl(),
        adminName: new FormControl(),
        adminNotes: new FormControl()
    });
    @Input() orderId: string | undefined;
    @Input() randomId: string | undefined;
    @Input() canEditDomain = false;
    @Input() requiredDomain = false;
    @Input() domainLics: number | undefined;
    @Input() showDomainLics = false;
    @Input() isSingleUser = false;


    @Input() accessList: AccessDto | undefined;


    compressed = false;
    public domain: string | undefined;
    public admin: UserDto | undefined;


    constructor(private formBuilder: FormBuilder, private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;
            });
    }

    ngOnInit() {
        if (!this.domainLics) {
            this.domainLics = 0;
        }
        this.createForm();
        this.parentForm?.get('domainLics')?.setValue(this.domainLics);
        if (this.requiredDomain) {
            this.parentForm?.get('domain')?.setValidators([Validators.required, this.domainValidator]);
            this.parentForm?.get('domainLics')?.setValidators([Validators.required, Validators.min(0)]);
        } else {
            if (this.canEditDomain) {
                this.parentForm?.get('domain')?.setValidators([Validators.required, this.domainValidator]);
                this.parentForm?.get('domainLics')?.setValidators([Validators.required, Validators.min(0)]);
            }
        }
        if (this.accessList) {
            this.initializeForm();
        }
    }

    createForm() {

        this.parentForm = this.formBuilder.group({
            domain: [''],
            domainLics: 0,
            adminEmail: ['', [Validators.required, Validators.email]],
            adminName: ['', Validators.required],
            adminNotes: ['']
        });
    }

    initializeForm() {
        this.parentForm?.get('domain')?.setValue(this.accessList?.domain.replace('@', ''));
        if (this.accessList?.admin) {
            this.parentForm.get('adminEmail')?.setValue(this.accessList.admin.eMail);
            this.parentForm.get('adminName')?.setValue(this.accessList.admin.name);
            this.parentForm.get('adminNotes')?.setValue(this.accessList.admin.notes);
        }
        // this.parentForm.get('adminEmail').setValidators(Validators.email);
    }

    domainValidator(domain: FormControl): { [s: string]: boolean } | null {
        console.log('editDomain domain: ' + domain);
        if (domain && domain.value) {
            const value = domain.value;
            if (value !== '') {
                if (value.length > 2 && value.includes('.') && (value.toLowerCase() !== 'gmail.com')) {
                } else {
                    return { invalidEmail: true };
                }
            }
        }
        return null;
    }

    get f() { return this.parentForm.controls; }

}
