import { Injectable } from '@angular/core';

/**
 * Service che gestisce il caso in cui il login avviene da dentro un iframe:
 * in questo caso Google dà errore e quindi dobbiamo
 * 1. Aprire un'altra finestra.
 * 2. Aspettare che l'utente faccia login.
 * 3. Chiudere la finestra.
 * @see LoginComponent
 * @see LoggedInComponent
 */
@Injectable({
  providedIn: 'root'
})
export class IframeLoginService {

  constructor() { }

  /**
   * Apre un'altra finestra e aspetta che l'utente chiuda la finestra.
   * return Promise che viene risolta quando l'utente chiude la finestra.
   */
  public loginOnNewTab() {
    return new Promise<any>((resolve, reject) => {
      localStorage.setItem('iframeLogging', 'true');
      const win = window.open(window.location.href, 'SuperMappeX - Login');
      const loginTimer = setInterval(() => {
        if (win?.closed) {
          clearInterval(loginTimer);
          localStorage.removeItem('iframeLogging');
          resolve('');
        }
      }, 500); // <- E' volutamente diverso da zero. Deve davvero controllare ogni mezzo secondo.
    });
  }

  /**
   * Controlla se siamo durante una fase di login
   * che è partita da un iframe.
   */
  public isIframeLogging(): boolean {
    return ('true' === localStorage.getItem('iframeLogging'));
  }
}
