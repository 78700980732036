import { Component } from '@angular/core';
import { DesmosConfigDto } from './desmosConfig-dto';
import { MatDialogRef } from '@angular/material/dialog';
import { UserPreferenceService } from 'src/app/shared/user-preference.service';

@Component({
    selector: 'app-desmos-config',
    templateUrl: './desmos-config.component.html',
    styleUrls: ['./desmos-config.component.scss']
})
export class DesmosConfigComponent {

    private initialConfig: string = '';
    public isLoading = false;
    private isClosing = false;
    private userDesmosConfig: DesmosConfigDto;
    public desmosCalculator: boolean = false;
    public desmosScientific: boolean = false;
    public desmosGeometry: boolean = false;
    public desmosFourFunction: boolean = false;
    public autoDraw: boolean = false;

    onDesmosLoadPreferenceSubscription: any;

    constructor(private dialogRef: MatDialogRef<DesmosConfigComponent>, private userPrefsService: UserPreferenceService) {
        this.userDesmosConfig = new DesmosConfigDto('');
        this.onDesmosLoadPreferenceSubscription = this.userPrefsService.onChangeDesmosConfig.
            subscribe({
                next: (desmosConfig: DesmosConfigDto) => {
                    this.loadDesmosPreference(desmosConfig);
                    this.isLoading = false;
                    if (this.isClosing) {
                        this.closeDialog();
                    }
                    this.onDesmosLoadPreferenceSubscription.unsubscribe();
                },
                error: (error: any) => {
                    console.error(error);
                }

            });

    }

    public loadDesmosPreference(desmosValue: DesmosConfigDto) {
        if (!this.isClosing) {
            this.initialConfig = JSON.stringify(desmosValue);
            this.userDesmosConfig = { ...desmosValue };
            this.desmosCalculator = desmosValue.calculator;
            this.desmosFourFunction = desmosValue.function;
            this.desmosGeometry = desmosValue.geometry;
            this.desmosScientific = desmosValue.scientific;
            this.autoDraw = desmosValue.autoDraw;
        }
    }

    closeDialog() {
        this.dialogRef.close(); // <- Closes the dialog
    }

    savePreference() {
        if (this.initialConfig !== JSON.stringify(this.userDesmosConfig)) {
            this.isLoading = true;
            this.isClosing = true;
            this.userPrefsService.saveDesmosPreference(this.userDesmosConfig);
        } else {
            this.closeDialog();
        }
    }

    change(name: number) {
        if (name === 0) this.userDesmosConfig.calculator = this.desmosCalculator;
        if (name === 2) this.userDesmosConfig.function = this.desmosFourFunction;
        if (name === 1) this.userDesmosConfig.scientific = this.desmosScientific;
        if (name === 3) this.userDesmosConfig.geometry = this.desmosGeometry;
        if (name === 4) this.userDesmosConfig.autoDraw = this.autoDraw;
    }

}
