import { Component, OnInit, HostListener, ViewChild, Compiler, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { SmeService } from '../core/sme/sme.service';
import { SmService } from 'supermappe-core';
import { Logger } from '../core/logger.service';
import { AuthenticationService } from '../core/authentication/authentication.service';

import { extract } from '../core/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { SmEventBroker } from '../shared/sm-event-broker';
import { PrintMapService } from '../shared/commands/print-map.service';
import { ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MapStateService } from '../shared/map-state.service';
import { Subscription } from 'rxjs';
import { YoutubeService } from '../shared/commands/youtube.service';
import { ModalService } from '../shared/links-menu/modal-service';
import { CreateGoogleDocService } from '../shared/commands/create-google-doc.service';
import { MapOperationType } from '../shared/map-operations';
import { FirebaseService } from '../core/firebase/firebase.service';
import { UsersService } from '../shared/commands/users.service';
import { LinkData } from '../shared/links-menu-data';
import { onSnapshot } from '@angular/fire/firestore';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MapFindService } from '../shared/map-find/map-find.service';
import { DeviceService } from '../core/device.service';

const logger: Logger = new Logger('MapViewComponent');

const SMX_CONFIG = {
    'READ_ONLY': true,
    'SHOW_MODE': false,
    'SELECTION_MODE': 'SINGLE',
    'CONTINUOUS_IMAGE_UPDATE': false,
    'EDIT_ON_TEXT': false,
    'FIT_WHOLE_PAGE': false,
    'STAMP_IMAGE_NAME': 'stamp-image',
    'MAP_QRCODE_SYMBOL_NAME': 'map-qrcode-symbol',
    'MAP_QRCODE_NAME': 'map-qrcode',
    'GDOC_QRCODE_SYMBOL_NAME': 'gdoc-qrcode-symbol',
    'GDOC_QRCODE_NAME': 'gdoc-qrcode',
    'INTERNAL_MOUSEWHEEL': true,
    'SHOW_DEEP_STARS': false,
    'SHOW_NODE_MATH': false,
    'SHOW_NODE_REC': false,
    'URL': {
        'ROOT': '',
        'MAPLIST': 'resources',
        'BASE': '', // JHUBA TOLTO PER NUOVE FUNCTION IL CORE NON DEVE METTERLO!!!!!
        'ASSETS': './assets'
    },
    'isMobile': false
};

@Component({
    selector: 'app-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.scss']
})

export class MapViewComponent implements OnInit, OnDestroy {

    //LAYOUT
    compressLogo = false;
    compressHeaderMenu = false;

    public logoUrlCompressed: string;
    public logoUrlExtended: string;
    isLoading = false;
    _mapName = '';
    isLogged = false;
    readyToPrint = false;
    mapId = '';
    canEdit = false;
    error = '';
    imageBase64: any;
    isRenewVisible = false;
    // JHUBA CHECK
    contextItems: Array<LinkData> = new Array<LinkData>();
    isReadonlyMode = false;
    imageUrl = '';
    mapSearchVisible: boolean;
    mapSearchDefaultPosition: any;
    onErrorSubscription: Subscription | undefined;
    downloadJsonSubscription: Subscription | undefined;
    paramSubscription: Subscription | undefined;
    onImageUpdatedSubscription: Subscription | undefined;
    interactionModeSubscription: Subscription | undefined;
    onDeepEditSubscription: Subscription | undefined;
    onShowImageSubscription: Subscription | undefined;
    changedBackgroundcolorSubscription: Subscription | undefined;
    statShareSubscription: Subscription | undefined;
    getShareDataSubscription: Subscription | undefined;
    onToggleMapSearchSubscription: Subscription | undefined;
    dbListenerUnsubscribeRef: any;
    dbListenerUnsubscribe: any;
    currentUser = '';

    @ViewChild('mapcontainer') el: ElementRef | undefined;

    @HostListener('document:contextmenu', ['$event'])
    hideDefaultCtxMenu() {
        return false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.smService.resizeCanvas();
        this.youtubeService.videoPictureInPicture();
    }

    @HostListener('window:afterprint', ['$event'])
    onafterprint(event: any) {
        this.readyToPrint = false;
        this.imageBase64 = null;
        this.removeImgQrCode();
    }

    @HostListener('window:beforeprint', ['$event'])
    onbeforeprint(event: any) {
        if (!this.readyToPrint) {
            if (!this.readyToPrint) {
                this.printService.printMap(this.mapStateService.limitPrintSize, true);
            }
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back button pressed');
        // this.youtubeService.clearAllVideos();
        this.goHome();
    }

    private removeImgQrCode() {
        let oldel = document.getElementById('map-qrcode');
        if (oldel) { oldel.remove(); }
        oldel = document.getElementById('gdoc-qrcode');
        if (oldel) { oldel.remove(); }
    }

    constructor(private route: ActivatedRoute,
        private router: Router,
        private compiler: Compiler,
        private translateService: TranslateService,
        private smeService: SmeService,
        private authenticationService: AuthenticationService,
        private smService: SmService,
        private printService: PrintMapService,
        private smEventBroker: SmEventBroker,
        private titleService: Title,
        private meta: Meta,
        public mapStateService: MapStateService,
        private youtubeService: YoutubeService,
        private modalService: ModalService,
        private createGoogleDocService: CreateGoogleDocService,
        private firebaseService: FirebaseService,
        private firebaseAuthService: FirebaseAuthService,
        private usersService: UsersService,
        private breakpointObserver: BreakpointObserver,
        private mapSearchService: MapFindService,
        private platform: DeviceService
    ) {
        // this.logoUrl = (this.authenticationService.isLab() ? 'assets/sm-logo-32-lab.png' : 'assets/sm-logo-32.png');
        this.logoUrlExtended = (this.authenticationService.isLab() ? 'assets/home/SMX_logo_lab.png' : 'assets/home/SMX_logo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? 'assets/home/logo_lab.png' : 'assets/home/logo_edu.png');
        this.updateMetaTags();
        let username = '';
        this.mapSearchVisible = false;
        this.mapSearchDefaultPosition = { x: 0, y: 0 };
        if (this.authenticationService.isAuthenticated()) {
            username = this.authenticationService.getUserEmail();
            if (this.authenticationService.isUserExpired()) {
                this.isRenewVisible = true;
            }
        }
        this.usersService.init(username, false);

        this.breakpointObserver
            .observe(['(max-width:1000px)'])
            .subscribe((state: BreakpointState) => {
                this.compressHeaderMenu = state.matches;
            });

        this.breakpointObserver
            .observe(['(max-width:550px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });
        this.authenticationService.setIcon();
    }


    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.currentUser !== this.firebaseAuthService.auth.currentUser?.email) {
            {
                const oldUserEmail = this.firebaseAuthService.auth.currentUser?.email;
                this.authenticationService.reloadCredentials();
                this.currentUser = this.authenticationService.getUserEmail();
                if (this.currentUser !== oldUserEmail) {
                    this.usersService.init(this.currentUser, false);
                    this.openMap();
                    // location.reload();
                }
            }
        }

    }

    ngOnInit() {
        logger.debug('map-view ngOnInit()');
        this.isLogged = this.authenticationService.isAuthenticated();
        this.compiler.clearCache();
        this.canEdit = false;
        this.youtubeService.videoPosition = this.youtubeService.VIDEO_POSITION.CENTER_SCREEN;
        this.paramSubscription = this.route.params.subscribe((params: Params) => {
            this.isLoading = true;
            this.mapId = params['mapId'];
            if (this.isLogged) {
                this.smeService.resolveWorkUrl(this.mapId, 'map.jpg')
                    .then((imageUrl: any) => {
                        this.imageUrl = imageUrl;
                        logger.debug(`Facebook image URL: ${imageUrl}`);
                        const img = (<any>window).location.origin + this.imageUrl;
                        this.meta.addTags([{ name: 'image', content: img }]);
                        this.meta.addTags([{ name: 'og:image', content: img }]);
                        this.meta.addTags([{ name: 'author', content: this.authenticationService.getUserEmail() }]);
                    }).catch(() => {
                        logger.info('Non riesco a caricare l\'immagine della mappa. Vado comunque avanti');
                    });
            }
            this.mapStateService.id = this.mapId;
            this.mapStateService.loadFonts().then(() => {
                if (this.authenticationService.isAuthenticated()) {
                    this.openMap();
                } else {
                    this.openMap();
                }
            });
            this.onErrorSubscription = this.mapStateService.onError.subscribe((error: any) => {
                this.error = error;
                if (error !== '') {
                    this.showError(error);
                } else {
                    this.hideError();
                }
            });
        });
    }

    startLoading() {
        this.isLoading = true;
    }
    endLoading() {
        this.isLoading = false;
    }

    openMap() {

        if (this.authenticationService.isAuthenticated()) {

            if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
            const email = (this.isLogged && this.authenticationService.credentials?.username ? this.authenticationService.credentials.username : 'anonymous');
            const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
            this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, this.mapId).subscribe(
                (data: any) => {
                    this.mapStateService.initialize();
                    this.mapStateService.isMine = (data && data.shareData ? data.shareData.isMine : false);
                    this.mapStateService.readOnly = (data && data.shareData ? data.shareData.isReadonly : false);

                    if (this.mapStateService.readOnly !== undefined) {
                        this.canEdit = this.isLogged && !this.mapStateService.readOnly && !this.authenticationService.isUserExpired();
                        //this.canCopy = this.isLogged && this.mapStateService.readOnly && !this.isUserExpired;
                    }

                    if (data.shareData.isMine || data.shareData.isShared) {
                        this.downloadMap();
                    } else {
                        this.showError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                        this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                    }
                },
                (error: any) => {
                    this.isLoading = false;
                    if (error.status === 404) {
                        this.showError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                        this.mapStateService.setStateError(this.translateService.instant(extract('ERROR_MAP_NOT_SHARED')));
                    } else {
                        this.showError('MAPEDIT_ERROR_GENERIC_MAP_LOAD');
                        this.mapStateService.setStateError(this.translateService.instant(extract('MAPEDIT_ERROR_GENERIC_MAP_LOAD')));
                    }
                }
            );
        } else {
            this.downloadMap();
            //this.router.navigate(['login', '/map-view/' + this.mapStateService.id]);
        }
    }

    openMapUnlogged() {
        console.log('aaa');
    }


    initListener() {
        // this.smEventBroker.initialize();

        this.onToggleMapSearchSubscription = this.mapSearchService.openFindInMap.subscribe((opened: boolean) => {
            this.mapSearchVisible = opened;
            if (opened) {
                setTimeout(() => {
                    this.setMapSearchPosition();
                }, 0);
            }
        });

        if (this.onImageUpdatedSubscription) { this.onImageUpdatedSubscription.unsubscribe(); }
        this.onImageUpdatedSubscription = this.printService.onImageUpdated.subscribe((imageBase64: any) => {
            if (!this.readyToPrint) {
                this.readyToPrint = true;
                this.imageBase64 = imageBase64.image;
            }
        });
        if (this.smEventBroker.editDeepData)
            this.onDeepEditSubscription = this.smEventBroker.editDeepData.subscribe((node: any) => {
                if (node && node.deepHtml && node.deepText && node.nodeData && node.nodeData.language) {
                    this.modalService.showDeepContent(node.id, node.deepHtml, node.deepText, node.nodeData.language);
                }
            });
        if (this.smEventBroker.showImageData)
            this.onShowImageSubscription = this.smEventBroker.showImageData.subscribe((node: any) => {
                if (this.smService.sm) {
                    if (node && node.image && node.image.name) {
                        this.modalService.showImageContent(false, node.id);
                    }
                }
            });
        // Firestore real time events listener
        if (this.isLogged) {
            try {
                this.dbListenerUnsubscribeRef = this.firebaseService.getFirestoreMapsListener(this.mapStateService.id);
                this.dbListenerUnsubscribe = onSnapshot(this.dbListenerUnsubscribeRef, (querySnapshot: any) => {
                    this.receiveMapsEvents(querySnapshot);
                });
            } catch (error) {
                console.log(`FirestoreDB listener Error: ${error}`);
            }
        } else {
            console.log('FirestoreDB listener not supported for anonymous user!');
        }
    }


    ngOnDestroy(): void {
        logger.debug('map-view ngOnDestroy()');
        if (this.onErrorSubscription) { this.onErrorSubscription.unsubscribe(); }
        if (this.paramSubscription) { this.paramSubscription.unsubscribe(); }
        if (this.downloadJsonSubscription) { this.downloadJsonSubscription.unsubscribe(); }
        if (this.onImageUpdatedSubscription) { this.onImageUpdatedSubscription.unsubscribe(); }
        if (this.interactionModeSubscription) { this.interactionModeSubscription.unsubscribe(); }
        if (this.onDeepEditSubscription) { this.onDeepEditSubscription.unsubscribe(); }
        if (this.onShowImageSubscription) { this.onShowImageSubscription.unsubscribe(); }
        if (this.changedBackgroundcolorSubscription) { this.changedBackgroundcolorSubscription.unsubscribe(); }
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
        if (this.dbListenerUnsubscribe) { this.dbListenerUnsubscribe(); }
        if (this.onToggleMapSearchSubscription) { this.onToggleMapSearchSubscription.unsubscribe(); }
        this.smService.destroy();
        this.smEventBroker.clearData();
    }

    receiveMapsEvents(querySnapshot: any) {
        if (this.isLogged) {
            console.log('Listening to the projectTransactions collection');
            const promises: Array<any> = [];
            const events: Array<any> = [];
            querySnapshot.docChanges().forEach((change: any) => {
                // A new transaction has been added
                if (change.type === 'added') { // type can also be 'removed' or 'modified'
                    // console.log(`----->LISTENER: A new transaction has been ADDED with ID: ${change.doc.id}`);
                    const promise = this.firebaseService.getMapsEvent(this.mapStateService.id, change.doc.id, events);
                    promises.push(promise);
                }
            });
            // Get all the events
            Promise.all(promises).then(() => {
                // Sort events in timestamp ascending order
                events.sort((a, b) => (a.timestamp <= b.timestamp ? -1 : 1));
                // Apply events
                this.applyMapEvents(events);
            });
        } else {
            console.log('Receive FirestoreDB events declined for anonymous user');
        }
    }

    applyMapEvents(events: Array<any>) {
        if (events && events.length > 0) {
            if (this.smService && this.smService.sm) {
                for (let i = 0; i < events.length; i++) {
                    // Get command from the event
                    const event = events[i];
                    if (event.type === 'command' && event.guid !== this.mapStateService.guid) {
                        const command = this.firebaseService.getEventPayloadAsJson(event);
                        // Pass command to core to be applied
                        this.smService.applyCommand(command, event.user.email);
                        // Never emit command: cannot be generated by map-view
                    } else if (event.type === 'save_start' || event.type === 'save_end') {
                        // Ignore save events
                    } else if (event.type === 'user_in' || event.type === 'user_out') {
                        this.mapStateService.emitUserEvent(event);
                    }
                }
            } else {
                console.log('CANNOT APPLY MAP EVENTS: NO smService or smService.sm!');
            }
        }
    }

    printImage() {
        if (this.imageBase64 != null) {
            setTimeout(() => {
                (<any>window).print();
            }, 1000);
        }
    }

    downloadMap() {
        this.downloadJsonSubscription = this.smeService.downloadJsonToView(this.mapId, this.authenticationService.isAuthenticated())
            .subscribe({
                next: (data: any) => {
                    //this.smService.destroy();
                    //  this.mapStateService.initialize();
                    this.loadMap(data.mapJson);
                    // this.mapStateService.readOnly = data.readonly;
                    this.mapStateService.viewMode = true;
                    this.mapStateService.name = data.mapJson.mapProps.mapName;
                    this.mapStateService.googleFileId = data.googleFileId;
                    this.mapStateService.googleUri = data.googleUri;

                    this.mapStateService.setLoaded(true);
                    this.initListener();
                    this.addMapEvent('user_in', '');
                    this.isLoading = false;
                    // Richiede il google Id dell'eventuale file google doc creato. I componenti si sottoscrivono all'evento onchange
                    this.createGoogleDocService.findGoogleDocFileIdbyMapId(this.mapStateService.id).subscribe(() => {
                    });
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.mapStateService.setBusyState(false);
                    const errorCode = (error.code) ? error.code : 'MAPEDIT_ERROR_GENERIC_MAP_LOAD';
                    this.mapStateService.setStateError(this.translateService.instant(extract(errorCode)));
                }
            });
    }

    loadMap(mapJson: any) {
        logger.debug(`Loading map ${this.mapId}...`);
        const urlResolver = this.smeService.getUrlResolver();
        const eventBroker = this.smEventBroker;
        if (this.interactionModeSubscription) { this.interactionModeSubscription.unsubscribe(); }
        if (this.smEventBroker.interactionMode)
            this.interactionModeSubscription = this.smEventBroker.interactionMode.subscribe((_isReadonlyMode) => {
                this.isReadonlyMode = _isReadonlyMode;
            });
        SMX_CONFIG.isMobile = this.platform.isMobileOrTabletDevice();
        this.smService.init(SMX_CONFIG, eventBroker, urlResolver);
        this.smService.show(this.mapId, mapJson, false);

        // Add stat
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapId, MapOperationType.STAT_VIEWSHARED, this.authenticationService.getUserEmail()).subscribe((data: any) => { });

        if (this.smEventBroker.backgroundChangedData)
            this.changedBackgroundcolorSubscription = this.smEventBroker.backgroundChangedData.subscribe((color: any) => {
                const cb = document.getElementById('sm-canvas-base');
                if (cb) {
                    cb.style.backgroundColor = color;
                }
            });

        if (mapJson && mapJson && mapJson.mapProps) { this.mapName = mapJson.mapProps.mapName.replace('.sme', ''); }
        // if (!environment.production) {
        (<any>window).sm = this.smService.sm;
        // }

        setTimeout(() => {
            this.firebaseService.addSharedStateChangeListener('', this.mapStateService.id, this.authenticationService.getUserEmail()).then(() => {
                console.log('refresh home');
            });
        }, 5000);

    }

    addMapEvent(type: string, payload: string) {
        return new Promise((resolve, reject) => {
            if (this.isLogged) {
                Promise.resolve().then((doc) => {
                    const userEmail = this.authenticationService.getUserEmail();
                    const userName = this.authenticationService.getUserName();
                    const userIcon = this.authenticationService.getUserImageUrl();
                    return this.firebaseService.addMapEvent(this.mapStateService.guid, type, userEmail, userName, userIcon, this.mapStateService.id, payload);
                }).then(() => {
                    return resolve(null);
                }).catch((error: any) => {
                    console.log(`FireStore DB  Error: ${error}`);
                    return resolve(null);
                });
            } else {
                console.log('FirestoreDB Event declined for anonymous user');
                return resolve(null);
            }
        });
    }

    goHome() {
        this.youtubeService.clearAllVideos();
        this.addMapEvent('user_out', '').then(() => {
            //  window.location.href = 'home';
            this.router.navigate(['home']);
        });
    }

    private showError(errorCode: string) {
        this.isLoading = false;
        this.error = this.translateService.instant(errorCode);
    }

    public reloadPage() {
        this.mapStateService.setStateError('');
        window.location.reload();
    }

    public hideError() {
        this.error = '';
        this.isLoading = false;
    }

    public hideErrorGlobal() {
        this.mapStateService.setStateError('');
        this.goHome();
    }

    private updateMetaTags() {
        const description: string = this.translateService.instant(extract('DESC_APP'));
        const title: string = this._mapName + ' - ' + this.translateService.instant(extract('MAPVIEW_TITLE'));
        const siteName: string = this.translateService.instant(extract('APP_NAME'));
        this.titleService.setTitle(title);
        this.meta.addTags([
            { name: 'description', content: description },
            { name: 'og:title', content: title },
            { name: 'og:site_name', content: siteName },
            { name: 'og:description', content: description }
        ]);
    }

    get mapName(): string {
        return this._mapName;
    }

    set mapName(newMapName: string) {
        this._mapName = newMapName;
        this.updateMetaTags();
    }

    public renewSubscription() {
        window.open(window.location.origin + '/user-desktop', '_blank');
    }

    setMapSearchPosition() {
        const webSearchPanel: HTMLElement = document.getElementById("mapSearchPanel") as HTMLElement;
        const mapArea: HTMLElement = document.getElementById("container-map") as HTMLElement;

        if (mapArea && webSearchPanel) {
            const leftOffset = mapArea.getBoundingClientRect().left + (mapArea.clientWidth * 0.5) - (webSearchPanel.clientWidth * 0.5);
            const topOffset = mapArea.offsetTop;

            const newTop = topOffset + 20;
            const newLeft = leftOffset;

            this.mapSearchDefaultPosition = { x: newLeft, y: newTop };
        }
    }

}
