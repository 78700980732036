import { VirtualKeyboardLayout } from 'mathlive';

export const EN_SETS_KEYBOARD: VirtualKeyboardLayout = {
    id: "SETS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                latex: '\\left\\vert\\placeholder{}\\right',
                tooltip: 'such that'
            },
            {
                latex: '\\left.\\placeholder{}\\right\\vert',
                tooltip: 'such that'

            },
            {
                latex: '\\cup',
                tooltip: 'union'
            },
            {
                latex: '\\cap',
                tooltip: 'intersection'
            },
            {
                latex: '\\in',
                tooltip: 'in'
            },
            {
                latex: '\\not\\in',
                tooltip: 'not in'
            },
            {
                latex: '\\lnot',
                tooltip: 'negation'
            }
        ],
        // Second row
        [
            {
                latex: '\\subset',
                tooltip: 'includes'
            },
            {
                latex: '\\subseteq',
                tooltip: 'includes or is coincident'
            },
            {
                latex: '\\supset',
                tooltip: 'is included'
            },
            {
                latex: '\\supseteq',
                tooltip: 'is included or coincident'
            },
            {
                latex: '\\forall',
                tooltip: 'for each'
            },
            {
                latex: '\\exists',
                tooltip: 'exists'
            },
            {
                latex: '\\nexists',
                tooltip: 'does not exist'
            }
        ],
        // Third row
        [
            {
                latex: '\\vee',
                tooltip: 'or'
            },
            {
                latex: '\\wedge',
                tooltip: 'and'
            },
            {
                latex: '\\veebar',
                tooltip: 'exclusive or'
            },
            {
                latex: '\\implies',
                tooltip: 'implies'
            },
            {
                latex: '\\impliedby',
                tooltip: 'is implied'
            },
            {
                latex: '↔',
                tooltip: 'if and only if'
            },
            {
                latex: ' \\overline{\\placeholder{}}',
                tooltip: 'complementary set'
            }
        ],
        // Fourth row
        [
            {
                latex: '∅',
                tooltip: 'empty set'
            },
            {
                latex: '\\mathbb{N} ',
                tooltip: 'natural numbers set'
            },
            {
                latex: '\\mathbb{Z}',
                tooltip: 'integer numbers set'
            },
            {
                latex: '\\mathbb{Q}',
                tooltip: 'rational numbers set'
            },
            {
                latex: '\\mathbb{R}',
                tooltip: 'real numbers set'
            },
            {
                latex: '\\mathbb{C}',
                tooltip: 'complex numbers set'
            },
            {
                latex: 'ℜ',
                tooltip: 'relation'
            },
        ]
    ]
};
