import { UiConstants } from './ui-constants';

export class NotificationsDto {
    public id = '';
    public source: string;
    public dateCreated: Date;
    public active: boolean;
    public title: string;
    public typeIcon = '';
    public icon: any;
    public link: string;
    public notes: string;
    public shown: boolean;
    public edit: boolean;
    public panelOpenState: boolean;
    public data: any;
    public notificationsMenu: any;

    constructor(notification: any, menuTrigger: any = null) {
        this.source = '';
        this.title = '';
        this.link = '';
        this.notes = '';
        this.dateCreated = new Date();
        this.active = false;
        this.shown = false;
        this.edit = false;
        this.panelOpenState = false;
        this.data = null;
        this.notificationsMenu = menuTrigger;

        if (notification) {
            if (notification.id) {
                this.id = notification.id;
            }
            if (notification.source) {
                this.source = notification.source;
            }
            if (notification.dateCreated) {
                this.dateCreated = notification.dateCreated;
            }
            if (notification.active) {
                this.active = notification.active;
            }
            if (notification.title) {
                this.title = notification.title;
            }
            if (notification.typeIcon) {
                this.typeIcon = notification.typeIcon;
                this.icon = UiConstants.getNotificationIcon(this.typeIcon);
            }
            if (notification.link) {
                this.link = notification.link;
            }
            if (notification.notes) {
                this.notes = notification.notes;
            }
            if (notification.shown) {
                this.shown = notification.shown;
            }
            if (notification.edit) {
                this.edit = notification.edit;
            }
            if (notification.panelOpenState) {
                this.panelOpenState = notification.panelOpenState;
            }
            if (notification.data) {
                this.data = JSON.parse(notification.data);
            }
        }

    }
}
