import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service'

export interface ModificaNomeCognomeDto {
    //firstName: string;
    displayName: string;
}

@Component({
    selector: 'app-set-fullname',
    templateUrl: './set-fullname.component.html',
    styleUrls: ['./set-fullname.component.scss']
})

export class SetFullnameComponent implements OnInit {
    formName: FormGroup = new FormGroup({});
    erroreSalvataggio: boolean = false;
    saving: boolean = false;

    @Output() savedFullName: EventEmitter<string> = new EventEmitter<string>();
    @Input() fullname: string = "";

    constructor(private firebaseAuthService: FirebaseAuthService) {
    }
    ngOnInit(): void {
        this.formName = new FormGroup({
            nome: new FormControl(this.firebaseAuthService.auth.currentUser?.displayName, Validators.required)
        });
    }

    submit(): void {
        const dto: ModificaNomeCognomeDto = {
            // firstName: this.form?.get('nome')?.value,
            displayName: this.formName?.get('nome')?.value
        }
        if (!this.saving && (dto.displayName != this.firebaseAuthService.auth.currentUser?.displayName)) {
            this.saving = true;
            this.erroreSalvataggio = false;
            this.firebaseAuthService.updateUserFullName(dto.displayName)
                .then(
                    () => {
                        this.saving = false;
                        this.savedFullName.emit(dto.displayName);
                    }).catch((error) => {
                        this.saving = false;
                        this.erroreSalvataggio = true
                    });
        } else {
            this.savedFullName.emit(dto.displayName);
        }
    }

    cancel(): void {
        this.savedFullName.emit(this.fullname);
    }

}



