<div class="dialog-capture-camera">
    <div matDialogContent>
        <video #video width="640" height="480" autoplay></video>
    </div>
    <div matDialogActions>
        <button class="dialog-button" mat-button (click)="capture()" color="primary">
            <span translate>CAPTURE_CAMERA</span>
        </button>
        <button class="dialog-button" mat-button mat-dialog-close>
            <span translate>BUTTON_CLOSE</span>
        </button>
    </div>
    <canvas #canvas width="640" height="480" [hidden]="true"></canvas>
</div>
