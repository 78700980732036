import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UiConstants } from '../../../../shared/ui-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-order-config',
    templateUrl: './order-config.component.html',
    styleUrls: ['./order-config.component.scss']
})
export class OrderConfigComponent implements OnInit {

    @Input() orderForm = new FormGroup({
        selectUserType: new FormControl(),
        planId: new FormControl(),
        fullName: new FormControl(),
        userEmail: new FormControl(),
        startDate: new FormControl(),
        expireDate: new FormControl(),
        origin: new FormControl(),
        suspended: new FormControl(),
        notes: new FormControl(),
        slotCount: new FormControl(),
        domainLics: new FormControl(),
        ocrMathScanQuota: new FormControl(),
        ocrMathScanPolicy: new FormControl(),
    });

    @Input() userType: string | undefined;
    @Input() planId: string | undefined;
    @Input() fullName: string | undefined;
    @Input() userEmail: string | undefined;
    @Input() startDate: string | undefined;
    @Input() expireDate: string | undefined;
    @Input() origin: string | undefined;
    @Input() suspended = false;
    @Input() notes: string | undefined;
    @Input() slotCount: number | undefined;
    @Input() domainLics: number | undefined;
    @Input() showSlot = false;
    @Input() minSlot = 0;
    @Input() showDomainLics = false;
    @Input() ocrMathScanQuota: number | undefined;
    @Input() ocrMathScanPolicy: string | undefined;

    public loaded = false;

    public origins = UiConstants.ORIGINS;
    public planIds = UiConstants.PLAN_IDS;
    public userTypes = UiConstants.USER_TYPES;
    public scanPolicies = UiConstants.OCRMATH_POLICIES;

    constructor(
        private formBuilder: FormBuilder,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.populateUserTypes();
        if (this.fullName) {
            this.orderForm.get('fullName')?.setValue(this.fullName);
        }
        if (this.userEmail) {
            this.orderForm.get('userEmail')?.setValue(this.userEmail);
        }
        if (this.startDate) {
            const startDate = new Date(this.startDate);
            this.orderForm.get('startDate')?.setValue(startDate);
        }
        if (this.expireDate) {
            const expireDate = new Date(this.expireDate);
            this.orderForm.get('expireDate')?.setValue(expireDate);
        }
        if (this.origin !== null) {
            this.orderForm.get('origin')?.setValue(this.origin);
        }
        if (this.suspended !== null) {
            this.orderForm.get('suspended')?.setValue(this.suspended);
        }
        if (this.notes !== null) {
            this.orderForm.get('notes')?.setValue(this.notes);
        }
        if (this.showSlot) {
            this.orderForm.get('slotCount')?.setValue(this.slotCount);
        } else {
            this.orderForm.get('slotCount')?.setValue(0);
        }
        if (this.showDomainLics) {
            this.orderForm.get('domainLics')?.setValue(this.domainLics);
        } else {
            this.orderForm.get('domainLics')?.setValue(0);
        }
        if (this.userType) {
            this.orderForm.get('selectUserType')?.setValue(this.userType);
        }
        if (this.userType) {
            this.orderForm.get('selectUserType')?.setValue(this.userType);
        }
        if (this.planId) {
            this.orderForm.get('planId')?.setValue(this.planId);
        }
        if (this.ocrMathScanQuota) {
            this.orderForm.get('ocrMathScanQuota')?.setValue(this.ocrMathScanQuota);
        }
        if (this.ocrMathScanPolicy) {
            this.orderForm.get('ocrMathScanPolicy')?.setValue(this.ocrMathScanPolicy);
        }
        this.loaded = true;
    }

    private createForm() {
        const startDate = (this.startDate) ? new Date(this.startDate) : undefined;
        const expireDate = this.expireDate ? new Date(this.expireDate) : undefined;
        this.orderForm = this.formBuilder.group({
            selectUserType: [''],
            planId: ['', Validators.required],
            fullName: ['', Validators.required],
            userEmail: ['', [Validators.required, Validators.email]],
            startDate: [startDate, Validators.required],
            expireDate: [expireDate, Validators.required],
            origin: [''],
            suspended: [false],
            notes: [''],
            slotCount: [this.slotCount, [Validators.min(this.minSlot), Validators.max(500)]],
            domainLics: [this.domainLics, [Validators.min(0)]],
            ocrMathScanQuota: (this.planId === 'edu') ? [''] : [this.ocrMathScanQuota, [Validators.min(0)]],
            ocrMathScanPolicy: ['']
        });
    }

    populateUserTypes() {
        for (let i = 0; i < this.userTypes.length; i++) {
            if (this.userTypes[i].value !== '') {
                this.userTypes[i].name = this.translateService.instant('IC_USER_TYPE_' + this.userTypes[i].value);
            }
        }
    }

    get f() { return this.orderForm.controls; }

}
