import { Injectable } from '@angular/core';
import { MapStateService } from '../map-state.service';
import { SmService } from 'supermappe-core';
import { GoogleService } from '../../core/google/google.service';
import { SmeService } from '../../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from '../dialog/confirmation.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../core/logger.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Router } from '@angular/router';

import * as FileSaver from 'file-saver';
import { ConvertToImageService } from './convert-to-image.service';

const routes = {
    // apiFindGoogleDocFileIdbyMapId: '/api/findgoogledocfileidbymapid2gen'
};

const logger: Logger = new Logger('CreateMapDocumentService');

@Injectable({
    providedIn: 'root'
})
export class CreateMapDocumentService {

    private confirmSubscription: Subscription | undefined;
    private statShareSubscription: Subscription | undefined;

    constructor(
        private mapStateService: MapStateService,
        private smService: SmService,
        private googleService: GoogleService,
        private smeService: SmeService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private confirmationService: ConfirmationService,
        private convertToImageService: ConvertToImageService,
        private router: Router,
        private http: HttpClient
    ) { }

    private getLocaleStrings(): any {
        let author = '';
        if (this.smService.sm && this.smService.sm.map && this.smService.sm.map.mapProps && this.smService.sm.map.mapProps.author) {
            author = this.smService.sm.map.mapProps.author;
        } else {
            author = 'Anastasis';
        }
        return {
            notes: this.translateService.instant(extract('DOC_NOTES')),
            mapImages: this.translateService.instant(extract('DOC_MAP_IMAGES')),
            author: this.translateService.instant(extract('DOC_AUTHOR')) + ' - ' + author,
            credits: this.translateService.instant(extract('DOC_CREDITS'))
        };
    }

    public createMapDocument(format: string) {
        this.mapStateService.onExportChange.emit(true);
        this.mapStateService.setBusyState(true);
        const json = this.smService.getMapDefaultReadOrderInfo();
        json.image = 'map.jpg';
        const locale = this.getLocaleStrings();
        const notes = this.smService.getNotes();
        Promise.resolve().then(() => {
            this.smeService.createNewDocument(format, this.mapStateService.name, json, locale, notes, this.mapStateService.id).subscribe(
                async (docData: any) => {
                    let mimeType;
                    let ext;
                    switch (format) {
                        case 'pdf':
                            mimeType = 'application/pdf';
                            ext = 'pdf';
                            const filename = await this.convertToImageService.convertToPdfDocument(docData);
                            this.mapStateService.onExportChange.emit(false);
                            const a = document.createElement('a');
                            a['download'] = filename;
                            const e = document.createEvent('MouseEvents');
                            // Use of deprecated function to satisfy TypeScript.
                            if (document.defaultView) {
                                e.initMouseEvent('click', true, false, document.defaultView, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                                a.dispatchEvent(e);
                            }
                            break;
                        case 'docx-ms':
                        case 'docx-lo':
                            this.mapStateService.onExportChange.emit(false);
                            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                            ext = 'docx';
                            const docBlob = new Blob([docData], { type: mimeType });
                            FileSaver.saveAs(docBlob, this.mapStateService.name);
                            break;
                    }
                },
                (error: any) => {
                    // JHUBA: METTERE QUI DIALOG ERRORE
                    this.mapStateService.onExportChange.emit(false);
                    this.mapStateService.setBusyState(false);
                    alert(`ERROR: ${error}`);
                });
        });
    }

}
