import { VirtualKeyboardLayout } from 'mathlive';

export const EN_TRIGONOMETRY_KEYBOARD: VirtualKeyboardLayout = {
    id: "TRIGONOMETRY",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            {
                latex: '\\sin(\\placeholder{})',
                tooltip: 'sine'
            },
            {
                latex: '\\mbox{sen}(\\placeholder{})',
                tooltip: 'sine'
            },
            {
                latex: '\\cos(\\placeholder{})',
                tooltip: 'cosine'
            },
            {
                latex: '\\tan(\\placeholder{})',
                tooltip: 'tangent'
            },
            {
                latex: '\\sec(\\placeholder{})',
                tooltip: 'secant'
            },
            {
                latex: '\\csc(\\placeholder{})',
                tooltip: 'cosecant'
            },
            {
                latex: '\\cot(\\placeholder{})',
                tooltip: 'cotangent'
            }
        ],
        // Second row
        [
            {
                class: 'small',
                latex: '\\arcsin(\\placeholder{})',
                tooltip: 'arcsine'
            },
            {
                class: 'small',
                latex: '\\arccos(\\placeholder{})',
                tooltip: 'arccosine'
            },
            {
                class: 'small',
                latex: '\\arctan(\\placeholder{})',
                tooltip: 'arctangent'
            },
            {
                class: 'small',
                latex: '\\mbox{arccot}(\\placeholder{})',
                tooltip: 'arccotangent'
            }
        ],
        // Third row
        [
            {
                latex: '\\alpha',
                tooltip: '"alpha'
            },
            {
                latex: '\\beta',
                tooltip: 'beta'
            },
            {
                latex: '\\gamma',
                tooltip: 'gamma'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
