import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';


export interface ModificaPasswordDto {
    vecchiaPassword: string;
    password: string;
}

export interface CurrentUserLoggingInDto {
    email: string;
    password: string;
    oldPassword: string;
}

export class PasswordValidator {

    static matchPassword(AC: AbstractControl): any {
        const oldPassword = AC.get('vecchiaPassword')?.value;
        const password = AC.get('password')?.value;
        const confirmPassword = AC.get('ripetiPassword')?.value;

        let errors: boolean = false;
        const validationErrors: any = {};

        if (!password.match('\\w+')) {
            validationErrors['unaLettera'] = true;
            errors = true;
        }

        if (!password.match('[^A-Za-z]')) {
            validationErrors['unSimbolo'] = true;
            errors = true;
        }

        if (!(password.length >= 8)) {
            validationErrors['lunghezza'] = true;
            errors = true;
        }

        if (password == oldPassword) {
            validationErrors['stessaPassword'] = true;
            errors = true;
        }

        if (errors) {
            AC.get('password')?.setErrors(validationErrors);
        }

        if (password != confirmPassword) {
            //AC.get('ripetiPassword')?.setErrors({ matchPassword: true });
            errors = true;
            return { matchPassword: true }
        }


        if (!errors) {
            return null;
        }

        return;

    }

}


@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

    readonly errorTooShort = "La password è troppo corta.";
    readonly errorInvalidChars = "La password contiene caratteri non ammessi.";
    readonly errorMissingUppercase = "La password deve contenere almeno una maiuscola.";
    readonly errorMissingLowercase = "La password deve contenere almeno una minuscola.";
    readonly errorMissingNumbers = "La password deve contenere almeno un numero.";
    readonly errorMissingSymbols = "La password deve contenere almeno un simbolo.";
    readonly errorSamePassword = "La password deve essere diversa dalla precedente.";
    readonly errorDifferentPassword = "Le due password inserite devono essere uguali.";
    readonly errorOldPassword = "La vecchia password inserita è sbagliata.";

    username: string = '';
    hide1: boolean = true;
    hide2: boolean = true;

    formGroup?: FormGroup;
    @Input() erroreSalvataggio: boolean = false;
    @Input() erroreOldPassword: boolean = false;
    saving: boolean = false;
    @Input() isPasswordLessUser = false;
    @Input() email: string = '';
    @Output() onSubmit = new EventEmitter<CurrentUserLoggingInDto>();
    @Output() onCancel = new EventEmitter<void>();
    hide: boolean = true;

    constructor(private router: Router, private firebaseAuthService: FirebaseAuthService) {
    }

    ngOnInit(): void {
        this.username = this.email != '' ? this.email : (this.firebaseAuthService.auth.currentUser?.email ? this.firebaseAuthService.auth.currentUser.email : '');
        // this.isPasswordLessUser = this.firebaseAuthService.isPasswordLessUser;
        if (this.isPasswordLessUser) {
            this.formGroup = new FormGroup({

                password: new FormControl('', Validators.required),
                ripetiPassword: new FormControl('', Validators.required),
            }, [PasswordValidator.matchPassword]);
        } else {
            this.formGroup = new FormGroup({
                vecchiaPassword: new FormControl('', Validators.required),
                password: new FormControl('', Validators.required),
                ripetiPassword: new FormControl('', Validators.required),
            }, [PasswordValidator.matchPassword]);
        }
    }

    isIEOrEdge(): boolean {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    }

    checkControlError(controlName: string, errorName: string): boolean {
        if (this.formGroup) {
            const control = this.formGroup.controls[controlName];
            return control && control.touched && control.errors && control.errors[errorName];
        } else {
            return false;
        }
    }

    checkFormError(errorName: string): boolean {
        return this.formGroup && this.formGroup.touched && this.formGroup.errors && this.formGroup.errors[errorName];
    }

    showHidePsw(): void {
        this.hide = !this.hide;
    }


    cancel() {
        this.onCancel.emit();
    }

    submit() {
        if (!this.saving && this.formGroup?.valid) {
            this.saving = true;
            this.erroreSalvataggio = false;
            const dto: ModificaPasswordDto = {
                vecchiaPassword: this.formGroup?.get('vecchiaPassword')?.value,
                password: this.formGroup?.get('password')?.value
            }
            const currUserData: CurrentUserLoggingInDto = {
                email: this.email,
                password: dto.password,
                oldPassword: dto.vecchiaPassword
            }
            this.onSubmit.emit(currUserData);
            // if (this.isPasswordLessUser) {
            //     const currUserData = {
            //         email : this.email,
            //         password: dto.password
            //     }
            //     this.onSubmit.emit(currUserData);
            //     // this.firebaseAuthService.updatePassword(dto.password).then(() => {

            //     //     this.saving = false;
            //     //     this.dialog.closeAll();

            //     // }).catch((error) => {
            //     //     this.saving = false;
            //     //     this.erroreSalvataggio = true;
            //     // });

            // } else {
            //     // const user = this.firebaseAuthService.auth.currentUser?.email || '';
            //     // this.firebaseAuthService.reauthenticate(user, dto.vecchiaPassword).then(() => {
            //     //     this.firebaseAuthService.updatePassword(dto.password).then(() => {

            //     //         this.saving = false;
            //     //         this.dialog.closeAll();

            //     //     }).catch((error) => {
            //     //         this.saving = false;
            //     //         this.erroreSalvataggio = true;
            //     //     });
            //     // }).catch((error) => {
            //     //     this.saving = false;
            //     //     this.erroreOldPassword = true;

            //     // });
            // }

        }



    }

}

