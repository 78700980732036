import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from '../../core/backend/backend.service';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent {
    public history: Array<any>;
    public title: string | undefined;
    public ok = true;

    constructor(
        public dialogRef: MatDialogRef<OrderHistoryComponent>,
        private backendService: BackendService,
        private translateService: TranslateService
    ) {
        this.history = new Array<any>();
    }

    setData(orderHistory: any) {
        this.title = this.translateService.instant('ORDER-HISTORY-TITLE');
        this.loadData(orderHistory);
    }

    loadData(history: any) {
        if (history) {
            const keys = Object.keys(history);
            for (let i = keys.length - 1; i >= 0; i--) {
                const key = keys[i];
                const hist = history[key];
                //           1         2
                // 012345678901234567890123456789
                // 2021-09-13T05:51:09.806Z-new
                const dts = key.substr(0, 13) + ':' + key.substr(14, 2) + ':' + key.substr(17, 2) + '.' + key.substr(20, 4);
                const dt = new Date(dts);
                hist.date = dt.toLocaleString();
                const key2 = (i > 0 ? keys[i - 1] : keys[i]);
                const hist2 = history[key2];
                hist.order2 = hist2;
                this.history.push(hist);
            }
        }
    }

    public openHistoryItem(idx: number) {
        // console.log(`openHistoryItem: idx=${idx}`);
        const PRICE_NOT_AVAILABLE = this.translateService.instant('ACCESSCONFIG_PRICE_NA');
        const orderId = this.history[idx].refOrderId;
        if (orderId) {
            if (!this.history[idx].price) {
                this.backendService.findOrderPriceInBackend(orderId).subscribe(
                    (resp: any) => {
                        const data = (resp.data ? JSON.parse(resp.data) : null);
                        if (data) {
                            this.history[idx].price = (data.importoIvato ? '€ ' + data.importoIvato : null);
                        }
                    },
                    (error: any) => {
                        // this.history[idx].price = PRICE_NOT_AVAILABLE;
                    });
            }
        } else {
            // this.history[idx].price = PRICE_NOT_AVAILABLE;
        }
    }

    public getLicenseType(licenseType: string, domainLics: number): string {
        switch (licenseType) {
            case 's':
                return this.translateService.instant('ACCESSCONFIG_LICENSE_SINGLE');
            case 'm':
                return this.translateService.instant('ACCESSCONFIG_LICENSE_MULTI');
            case 'd':
                const descNumLicenses = (domainLics <= 0 ?
                    this.translateService.instant('ACCESSCONFIG_UNLIMITED_LICENSES') :
                    this.translateService.instant('ACCESSCONFIG_LIMITED_LICENSES').replace('%s', domainLics)
                );
                return this.translateService.instant('ACCESSCONFIG_LICENSE_DOMAIN') + ' ' + descNumLicenses;
            default:
                return 'undefined';
        }
    }

    public getOriginType(origin: string) {
        return this.translateService.instant('ORIGIN_' + origin.toLocaleUpperCase());
    }

    public getRefOrderId(refId: string, lastAction: string) {
        let s = refId;
        if (lastAction) {
            s += this.translateService.instant('REFORDERID_' + lastAction.toUpperCase());
        }
        return s;
    }

    private lead0(n: number, len: number) {
        let s1 = String(n);
        while (s1.length < len) {
            s1 = '0' + s1;
        }
        return s1;
    }

    public toLocalDateTime(dbDate: string) {
        // In formato yyyy-MM-ddThh:mm.xxx:ssZ
        let s = '';
        if (dbDate) {
            const d = new Date(dbDate);
            s =
                this.lead0(d.getDate(), 2) + '/' +
                this.lead0(d.getMonth() + 1, 2) + '/' +
                this.lead0(d.getFullYear(), 4) + ' ' +
                this.lead0(d.getHours(), 2) + ':' +
                this.lead0(d.getMinutes(), 2) + ':' +
                this.lead0(d.getSeconds(), 2);
        }
        return s;
    }

    public toLocalDate(dbDate: string) {
        // In formato yyyy-MM-ddThh:mm.xxx:ssZ
        let s = '';
        if (dbDate) {
            const d = new Date(dbDate);
            s =
                this.lead0(d.getDate(), 2) + '/' +
                this.lead0(d.getMonth() + 1, 2) + '/' +
                this.lead0(d.getFullYear(), 4);
        }
        return s;
    }

    public c(order: any, field: string) {
        const r = (order[field] === order.order2[field] ? 'transparent' : 'wheat');
        return r;
    }

}
