import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../../core/i18n.service';
import { NewUserProfileComponent } from './new-user-profile.component';


const routes: Routes = [
    { path: 'new-user-profile/:email', component: NewUserProfileComponent, data: { title: extract('USER_PROFILE_TITLE') } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []


})
export class NewUserProfileRoutingModule { }
