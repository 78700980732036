import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { includes } from 'lodash';

import { Logger } from './logger.service';
import * as itIT from '../../translations/it-IT.json';
import * as enGB from '../../translations/en-GB.json';
// import * as caEs from '../../translations/ca-ES.json';
// import * as esEs from '../../translations/es-ES.json';


const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * param {string} s The string to extract for translation.
 * return {string} The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  defaultLanguage = '';
  supportedLanguages: string[] = new Array<string>();

  constructor(private translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('it-IT', itIT);
    translateService.setTranslation('en-GB', enGB);
    // translateService.setTranslation('ca-ES', caEs);
    // translateService.setTranslation('es-ES', esEs);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * param {!string} defaultLanguage The default language to use.
   * param {Array.<String>} supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = '';

    this.translateService.onLangChange
      .subscribe((event: LangChangeEvent) => { localStorage.setItem(languageKey, event.lang); });
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * param {string} language The IETF language code to set.
   */
  set language(language: string | undefined) {
    language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = includes(this.supportedLanguages, language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      if (language) {
        const l = language;
        language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(l)) || '';
      }
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language}`);
    if (language) {
      this.translateService.use(language);
    }
  }



  /**
   * Gets the current language.
   * return {string} The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

}
