import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PasteStructureComponent } from './paste-structure.component';

@Injectable({
    providedIn: 'root'
})
export class PasteStructureService {

    constructor(private dialog: MatDialog) {
        this.dialog = dialog;
    }

    public show(hasDeepContent: boolean): Observable<boolean> {
        const dialogRef: MatDialogRef<PasteStructureComponent> = this.dialog.open(PasteStructureComponent, { width: '500px' });
        dialogRef.componentInstance.hasDeepContent = hasDeepContent;
        return dialogRef.afterClosed();
    }

}
