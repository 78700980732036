import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CustomizeToolbarService } from '../customize-toolbar.service';

import { CustomToolbarDto } from '../custom-toolbar-dto';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss']
})
export class TableToolbarComponent implements OnInit, OnDestroy {

  onChangeToolbarVisibilitySubscription: Subscription | undefined;
  onChangeTotButtonVisibleSubscription: Subscription | undefined;

  public textVisible = false;
  public formatVisible = false;
  public tableVisible = false;
  moreToolbar = false;
  alertToolbar: HTMLElement | undefined;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.hideMoreToolbar();
  }

  hideMoreToolbar() {
    this.moreToolbar = false;
    const at = document.getElementById('alert-table-toolbar');
    if (at) {
      this.alertToolbar = at;
      this.alertToolbar.style.display = 'none';
    }
  }

  showMoreToolbar() {
    this.moreToolbar = !this.moreToolbar;
    const at = document.getElementById('alert-table-toolbar');
    if (at) {
      this.alertToolbar = at;
      this.alertToolbar.style.display = this.moreToolbar ? 'inline' : 'none';
    }
  }

  constructor(private customizeToobarService: CustomizeToolbarService) {
  }

  ngOnInit() {
    this.onChangeToolbarVisibilitySubscription =
      this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
        this.changeSelection(_customData);
      });

    this.changeSelection(this.customizeToobarService.customToolbarDto);
  }

  ngOnDestroy(): void {
    if (this.onChangeToolbarVisibilitySubscription) { this.onChangeToolbarVisibilitySubscription.unsubscribe(); }
  }

  changeSelection(_selectionData: any): any {
    this.textVisible = _selectionData.isTableSelected;
    this.formatVisible = _selectionData.isTableSelected;
    this.tableVisible = _selectionData.onlyNodeInTableSelected;
  }
}
