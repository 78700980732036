export class RecentMapDto {
    public sequence: number;
    // public dataLoaded: boolean;
    public author: string;
    public id: string;
    public googleId: string;
    public name: string;
    public lastUpdated: Date;
    public lastOpened: Date;
    public readonly: boolean;
    public authDesc = '';
    public previewPublicUrl: string;
    public isShared: boolean;
    public isPubliclyShared = false;
    public isMine: boolean;
    public shareType: string;
    public stat_copyShared: number;
    public stat_viewShared: number;
    public stat_copySharedString = '';
    public stat_viewSharedString = '';
    public deleteDate: Date | null;

    private viewStringSing: string;
    private copyStringSing: string;
    private viewStringPlur: string;
    private copyStringPlur: string;

    constructor(sequence: number, public json: any, _viewStringSing: string, _copyStringSing: string, _viewStringPlur: string, _copyStringPlur: string) {
        this.sequence = sequence;
        // this.dataLoaded = false;
        this.author = json.author;
        this.id = json.id;
        this.viewStringSing = _viewStringSing;
        this.copyStringSing = _copyStringSing;
        this.viewStringPlur = _viewStringPlur;
        this.copyStringPlur = _copyStringPlur;
        if (json.name) {
            this.name = json.name;
            if (this.name.toLowerCase().endsWith('.sme')) {
                this.name = this.name.slice(0, this.name.length - 4);
            }
            // if (this.name.length > 48) {
            //   this.name = this.name.substr(0, 48) + '...';
            // }
        } else {
            this.name = '';
        }
        this.readonly = json.shareData?.isReadonly;
        if (this.readonly) {
            this.authDesc = 'READ_ONLY';
        }
        this.previewPublicUrl = json.previewPublicUrl;
        const sLastUpdated = (json.lastUpdated ? json.lastUpdated : json.lastOpened);
        const sLastOpened = (json.lastOpened ? json.lastOpened : json.lastUpdated);
        this.lastUpdated = new Date(sLastUpdated);
        this.lastOpened = new Date(sLastOpened);
        this.googleId = json.googleId;
        this.isShared = (json.shareData && json.shareData.isShared ? true : false);
        this.shareType = (json.shareData && json.shareData.shareType ? json.shareData.shareType : '');
        this.isPubliclyShared = (json.shareData && json.shareData.isPubliclyShared ? json.shareData.isPubliclyShared : false);
        this.isMine = json.shareData?.isMine; // (json.isMine) ? true : false;
        this.stat_viewShared = (json.stat_ViewShared ? json.stat_ViewShared : 0);
        this.stat_copyShared = (json.stat_CopyShared ? json.stat_CopyShared : 0);
        const sDeleteDate = (json.deleteDate ? json.deleteDate : null);
        this.deleteDate = (sDeleteDate ? new Date(sDeleteDate) : null);
        this.updateStrings();
    }
    updateStrings() {
        if (this.stat_viewShared === 1) {
            this.stat_viewSharedString = this.stat_viewShared.toString() + ' ' + this.viewStringSing;
        } else if (this.stat_viewShared > 1) {
            this.stat_viewSharedString = this.stat_viewShared.toString() + ' ' + this.viewStringPlur;
        } else {
            this.stat_viewSharedString = '';
        }
        if (this.stat_copyShared === 1) {
            this.stat_copySharedString = this.stat_copyShared.toString() + ' ' + this.copyStringSing;
        } else if (this.stat_copyShared > 1) {
            this.stat_copySharedString = this.stat_copyShared.toString() + ' ' + this.copyStringPlur;
        } else {
            this.stat_copySharedString = '';
        }
    }

}

