<div class="autodraw-container">
    <div class="autodraw-title-container">
        <div class="autodraw-title-icon">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">draw</mat-icon>
        </div>
        <div class="autodraw-title-text">
            <div translate>DRAW_TITLE</div>
        </div>
        <div class="autodraw-close">
            <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" matTooltipPosition="above" (click)="closeExtra();">
                <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </div>
    </div>

    <div class="autodraw-editor">
        <div class="overlay" #over>
            <div #screen>
                <div class="autodraw-content">
                    <div *ngIf="capturingScreen" class="capture-over" [ngStyle]="{'clip-path': 'polygon(0% 0%, '+percCropX+'% 0%, '+percCropX+'% '+percCropY2+'%, '+percCropX2+'% '+percCropY2+'%, '+percCropX2+'% '+percCropY+'%,'+percCropX+'% '+percCropY+'%,'+percCropX+'% 0%,100% 0%, 100% 100%, 0% 100%)'}"></div>
                    <iframe class="autodraw" id="autodraw" #iframe [src]="url" data-cookieconsent="marketing" allow-top-navigation-by-user-activation allow-top-navigation webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

                </div>
            </div>
        </div>
        <div class="rectangle" #rect></div>

    </div>
</div>
