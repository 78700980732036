<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<!-- <div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </span>
        <span class="access-subtitle">{{getRoleType()}}</span>
    </div> -->
<div [ngClass]="(compressed ? 'access-title-compressed' : 'access-title')">
    <!-- <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="{{logoUrl}}?v=1" />
        </a> -->
    <div *ngIf="!compressLogo" class="smx-logo-extended" routerLink="/home" translate>
        <img class="smx-logo-image-extended" src="{{logoUrlExtended}}?v=1" />
    </div>
    <div *ngIf="compressLogo" class="smx-logo-compressed" routerLink="/home" translate>
        <img class="smx-logo-image-compressed" src="{{logoUrlCompressed}}?v=1" />
    </div>
    <div class="spacer"></div>


    <h1 class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </h1>
    <span class="access-subtitle">{{getRoleType()}}</span>
    <div class="spacer"></div>
    <!-- <span class="access-subtitle">{{getRoleType()}}</span> -->
</div>

<div class="admin-config">

    <mat-tab-group (selectedTabChange)="changeGroup($event)" [selectedIndex]="selectedIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <button mat-flat-button color="accent">
                    <span class="title-span"> {{'SUBSCRIPTIONS_LIST' | translate}} </span>
                </button>
            </ng-template>
        </mat-tab>
        <mat-tab [disabled]="domainDisabled">
            <ng-template mat-tab-label>
                <button mat-button class="title-button" (click)="showHideDomain()">
                    <span class="title-span"> {{'ACCESSCONFIG_INSERT_DOMAIN' | translate}} </span>
                </button>
            </ng-template>
            <div *ngIf="panelOpenState">
                <form [formGroup]="accessConfigForm" (ngSubmit)="onSubmit()">
                    <app-subs-config #domainName [subsForm]="accessConfigForm"></app-subs-config>
                    <app-admin-config [parentForm]="accessConfigForm" [requiredDomain]="true" #domainconfig [canEditDomain]="true" [domainLics]="0" [showDomainLics]="true" [isSingleUser]="false">
                    </app-admin-config>
                    <app-date-config #domainDate [dateForm]="accessConfigForm" [startDate]="startDate" [expireDate]="expireDate" [role]="role" [licenseType]="'d'"></app-date-config>
                    <button color="warn" [disabled]="!enabledDomain()" mat-flat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>
                    <button type="reset" class="cancel-button" (click)="resetForm(1)" mat-flat-button>{{'ACCESSCONFIG_RESET' | translate}}</button>
                </form>
            </div>
        </mat-tab>
        <mat-tab [disabled]="singleDisabled">
            <ng-template mat-tab-label>
                <button mat-button class="title-button" (click)="showHideSingle()">
                    <span class="title-span">{{'ACCESSCONFIG_INSERT_SINGLE' | translate}}</span>
                </button>
            </ng-template>
            <div *ngIf="panelOpenState" class="panel-group">
                <form [formGroup]="singleConfigForm" (ngSubmit)="onSubmit()">
                    <app-subs-config #singleName [subsForm]="singleConfigForm"></app-subs-config>

                    <app-admin-config [parentForm]="singleConfigForm" [requiredDomain]="false" #singleconfig [canEditDomain]="false" [isSingleUser]="true">
                    </app-admin-config>
                    <app-date-config #singleDate [dateForm]="singleConfigForm" [startDate]="startDate" [expireDate]="expireDate" [role]="role" [licenseType]="'s'"></app-date-config>
                    <button color="warn" [disabled]="!enabledSingle()" mat-flat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>
                    <button class="cancel-button" type="reset" (click)="resetForm(2)" mat-flat-button>{{'ACCESSCONFIG_RESET' | translate}}</button>
                </form>
            </div>
        </mat-tab>
        <mat-tab [disabled]="multyDisabled">
            <ng-template mat-tab-label>
                <button mat-button class="title-button" (click)="showHideGroup()">
                    <span class="title-span"> {{'ACCESSCONFIG_INSERT_GROUP' | translate}}</span>
                </button>
            </ng-template>
            <div *ngIf="panelOpenState" class="panel-group">
                <form [formGroup]="multyConfigForm" (ngSubmit)="onSubmit()">
                    <app-subs-config #multyName [subsForm]="multyConfigForm"></app-subs-config>
                    <app-admin-config [parentForm]="multyConfigForm" [requiredDomain]="false" #multyconfig [canEditDomain]="false" [isSingleUser]="false">
                    </app-admin-config>
                    <app-date-config #multyDate [dateForm]="multyConfigForm" [startDate]="startDate" [expireDate]="expireDate" [role]="role" [licenseType]="'m'"></app-date-config>
                    <app-users-list #usersconfig [withDomain]="false" [slotCount]="0" [showSlot]="true" [usersForm]="multyConfigForm"></app-users-list>
                    <button color="warn" [disabled]="!enabledGroup()" mat-flat-button type="submit">{{'ACCESSCONFIG_SAVE' | translate}}</button>
                    <button class="cancel-button" type="reset" (click)="resetForm(3)" mat-flat-button>{{'ACCESSCONFIG_RESET' | translate}}</button>
                </form>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>

<div class="order-list">
    <app-order-list #orderlist [openFirstElement]="false" [isLoading]="isLoading" (startLoading)="startLoadingList()" (endLoading)="endLoadingList()" [enabledDetail]="!enabledDetail()"></app-order-list>
</div>
