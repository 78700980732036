import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';


import { extract } from '../core/i18n.service';
import { SmeService } from '../core/sme/sme.service';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleService } from '../core/google/google.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { MapStateService } from '../shared/map-state.service';
import { map } from 'rxjs/operators';
import { MapOperationType } from '../shared/map-operations';
import { MessageBoxService } from '../shared/dialog/messagebox.service';

const logger = new Logger('GdriveNewComponent');

@Component({
  selector: 'app-gdrive-new',
  templateUrl: './gdrive-new.component.html',
  styleUrls: ['./gdrive-new.component.scss']
})
export class GdriveNewComponent implements OnInit, OnDestroy {
  isLoading = false;
  error = '';
  newMapSubscription: Subscription | undefined;
  statNewMapSubscription: Subscription | undefined;
  statOpenMapSubscription: Subscription | undefined;
  readStateSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private googleService: GoogleService,
    private smeService: SmeService,
    private mapStateService: MapStateService,
    private messageBoxService: MessageBoxService) {
  }

  ngOnInit() {
    this.isLoading = true;
    localStorage.removeItem('redirectPage');
    localStorage.removeItem('redirectParams');
    localStorage.removeItem('redirectQueryParams');
    if (this.readStateSubscription) { this.readStateSubscription.unsubscribe(); }
    this.readStateSubscription = this.readState().subscribe((state: any) => {
      if (!this.authenticationService.credentials) {
        // User not logged
        this.router.navigate(['login', window.location.href]);
      } else if (this.authenticationService.isUserExpired()) {
        // User expired
        this.isLoading = false;
        this.showError('USER_EXPIRED');
      } else {
        // Valid user logged in
        const parentFolderId = state.folderId;

        this.newMap(parentFolderId);

      }
    });
  }

  ngOnDestroy() {
    if (this.newMapSubscription) { this.newMapSubscription.unsubscribe(); }
    if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    if (this.statNewMapSubscription) { this.statNewMapSubscription.unsubscribe(); }
    if (this.readStateSubscription) { this.readStateSubscription.unsubscribe(); }
  }

  public newMap(parentFolderId: string) {
    logger.debug('Create new map from Google Drive');
    this.isLoading = true;

    this.mapStateService.isNew = true;
    if (this.statNewMapSubscription) { this.statNewMapSubscription.unsubscribe(); }
    this.statNewMapSubscription = this.smeService.addCustomMapStat('newmap', MapOperationType.STAT_NEWMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
    this.newMapSubscription = this.smeService.createNewMap(parentFolderId).subscribe({
      next: (mapId: string) => {
        if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
        this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
        if (this.newMapSubscription) { this.newMapSubscription.unsubscribe(); }
        this.isLoading = false;
        logger.info(`new Map ${mapId} created`);
        // window.location.href = 'map-edit/' + mapId;
        this.router.navigate(['map-edit/' + mapId]);
      },
      error: (error: any) => {
        this.isLoading = false;
        let errorCode = '';
        if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
          this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
            this.router.navigate(['home/']);
          });
        } else if (error.status === 504) {
          errorCode = 'GATEWAY_TIMEOUT';
        } else {
          errorCode = (error.code) ? error.code : 'CREATE_MAP_ERROR';
        }
        this.showError(errorCode);
      }
    });

  }

  backToHome() {
    this.router.navigate(['home']);
  }

  private showError(errorCode: string) {
    this.error = this.translateService.instant(extract(errorCode));
  }

  /**
   * Legge lo stato ricevuto da Google Drive tramite query string
   */
  private readState(): Observable<any> {
    return this.route.queryParams
      .pipe(
        map((queryParams: Params) => {
          let stateStr = queryParams['state'];
          stateStr = decodeURIComponent(stateStr);
          let res;
          try {
            res = JSON.parse(stateStr);
          } catch (e) {
            res = stateStr;
          }
          return res;
        }));
  }
}
