<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="'GDRIVE_NEW_TITLE'"></app-loader>

<!-- <div class="error" [ngClass]="{'hidden':!error}" (click)="backToHome()">
  <div class="error-content">
    <div class="error-text-header" translate>OOPS</div>
    <div class="error-text-message">{{error}}</div>
  </div>
</div> -->
<div *ngIf="error" class="container mat-typography">
    <mat-card appearance="outlined" class="message-container">
        <div class="message-title" translate>WARNING</div>
        <div class="message-text">{{error}}</div>
        <div class="ok-button" (click)="backToHome()" translate>BUTTON_OK</div>
    </mat-card>
</div>
