import { SmEventBrokerInterface, SmService } from 'supermappe-core';
import { Observable, Subject } from 'rxjs';
import { DirtyData } from 'supermappe-core';
import { ZoomData } from 'supermappe-core';
import { EditData } from 'supermappe-core';
import { Injectable } from '@angular/core';
import { CALLER, TtsService } from './commands/tts.service';
import { LinksMenuData } from './links-menu-data';
import { TranslateService } from '@ngx-translate/core';
import { extract, I18nService } from '../core/i18n.service';
import { QuickEditService } from '../shared/commands/quick-edit.service';
import { YoutubeService } from './commands/youtube.service';
import { MapShowService } from '../map-show/map-show.service';
import { MapStateService } from './map-state.service';
import { MapOperationType } from './map-operations';
import { MathService } from './commands/math.service';
import { UserPreferenceService } from '../shared/user-preference.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
// export interface SelectionData {
//     scrCenterX: number;
//     scrCenterY: number;
//     mapJson: any;
// }

@Injectable({
    providedIn: 'root'
})
export class SmEventBroker implements SmEventBrokerInterface {
    private speechEngine: any;
    public dirtyData: Observable<DirtyData> | undefined;
    public interactionMode: Observable<boolean> | undefined;
    public mapState: Observable<boolean> | undefined;

    private _dirtyData: Subject<DirtyData> | undefined;
    private dirtyDataStore: { dirtyData: DirtyData } | undefined;

    public ctxMenuItems: Observable<LinksMenuData> | undefined;
    private _ctxMenuItems: Subject<LinksMenuData> | undefined;
    private ctxMenuItemsStore: { ctxItems: LinksMenuData } | undefined;

    private _cursorData: Subject<boolean> | undefined;
    private unavailableLink = '';

    public selectionData: Observable<any> | undefined;
    private _selectionData: Subject<any> | undefined;
    private selectionDataStore: any;

    // private _centerX: any;
    // private _centerY: any;
    // private _mapJson: any;

    public mapClipboardData: Observable<any> | undefined;
    private _mapClipboardData: Subject<any> | undefined;
    private mapClipboardDataStore: any | undefined;

    public selectionMenuData: Observable<any> | undefined;
    private _selectionMenuData: Subject<any> | undefined;
    private selectionMenuDataStore: any | undefined;

    public zoomData: Observable<ZoomData> | undefined;
    private _zoomData: Subject<ZoomData> | undefined;
    private zoomDataStore: { zoomData: ZoomData } | undefined;

    public editData: Observable<any> | undefined;
    private _editData: Subject<any> | undefined;
    private editDataStore: any;

    public mapLanguage: Observable<string> | undefined;
    private _mapLanguage: Subject<string> | undefined;
    private mapLanguageStore = '';

    public showStepsData: Observable<any> | undefined;
    private _showStepsData: Subject<any> | undefined;
    private showStepsDataStore: any;

    public editDeepData: Observable<any> | undefined;
    private _editDeepData: Subject<any> | undefined;
    private editDeepDataStore: any;

    public showImageData: Observable<any> | undefined;
    private _showImageData: Subject<any> | undefined;
    private showImageDataStore: any;

    public videoUrlChangedData: Observable<any> | undefined;
    private _videoUrlChangedData: Subject<any> | undefined;
    private videoUrlChangedDataStore: any;

    public nodeFoundChangedData: Observable<any> | undefined;
    private _nodeFoundChangedData: Subject<any> | undefined;
    private nodeFoundChangedDataStore: any;

    public backgroundChangedData: Observable<any> | undefined;
    private _backgroundChanged: Subject<any> | undefined;
    private backgroundChangedStore: any;

    public formatCopiedData: Observable<any> | undefined;
    private _formatCopied: Subject<any> | undefined;
    private formatCopiedStore: any;

    public onZoomOnSelectionChangedData: Observable<any> | undefined;
    private _onZoomOnSelectionChanged: Subject<any> | undefined;
    private onZoomOnSelectionChangedStore: any;

    public onDataCommandChangedData: Observable<any> | undefined;
    private _onDataCommandChanged: Subject<any> | undefined;
    private onDataCommandChangedStore: any;

    public onPositionFeebackChangedData: Observable<any> | undefined;
    private _onPositionFeebackChanged: Subject<any> | undefined;
    private onPositionFeebackChangedStore: any;

    public onOutlineUpdatedData: Observable<any> | undefined;
    private _onOutlineUpdatedData: Subject<any> | undefined;
    private onOutlineUpdatedDataStore: any;

    public onNodeStateData: Observable<any> | undefined;
    private _onNodeStateData: Subject<any> | undefined;
    private onNodeStateDataStore: any;

    public onStartUpdatingData: Observable<any> | undefined;
    private _onStartUpdatingData: Subject<any> | undefined;
    private onStartUpdatingDataStore: any;

    public onEndUpdatingData: Observable<any> | undefined;
    private _onEndUpdatingData: Subject<any> | undefined;
    private onEndUpdatingDataStore: any;

    public onImageLoadedData: Observable<any> | undefined;
    private _onImageLoadedData: Subject<any> | undefined;
    private onImageLoadedDataStore: any;

    // definito fuori per non cercarlo se si ha già
    private SM_container: any;

    constructor(
        private smService: SmService,
        private ttsService: TtsService,
        private translateService: TranslateService,
        private quickEditService: QuickEditService,
        private youtubeService: YoutubeService,
        private mapShowService: MapShowService,
        private mapStateService: MapStateService,
        private mathService: MathService,
        private userPreferencesService: UserPreferenceService,
        private authService: AuthenticationService,
        private i18nService: I18nService) {
        this.initialize();

    }

    public initialize(): void {
        // , private editTextService: EditDataService) {
        this.unavailableLink = this.translateService.instant(extract('LINK_UNAVAILABLE'));
        // Initialize speech synth (google chrome)
        this.speechEngine = window.speechSynthesis;
        this.dirtyDataStore = { dirtyData: new DirtyData() };
        this._dirtyData = new Subject<DirtyData>();//this.dirtyDataStore.dirtyData
        this.dirtyData = this._dirtyData.asObservable();
        const dd = DirtyData.build(-1, 0, true);
        this._dirtyData.next(dd);
        // Menu contestuale
        this.ctxMenuItemsStore = { ctxItems: new LinksMenuData() };
        this._ctxMenuItems = new Subject<LinksMenuData>();//this.ctxMenuItemsStore.ctxItems
        this.ctxMenuItems = this._ctxMenuItems.asObservable();
        const md = LinksMenuData.build(0, 0, [], this.unavailableLink);
        this._ctxMenuItems.next(md);
        this._cursorData = new Subject<boolean>();
        this.interactionMode = this._cursorData.asObservable();
        // dati selezione
        this.selectionDataStore = { selectionData: {} };
        this._selectionData = new Subject<any>();//this.selectionDataStore.selectionData
        this.selectionData = this._selectionData.asObservable();
        // dati editor
        const ed: EditData = EditData.build(false, false, '', '', 0, 0, 0, 0, 0);
        this.editDataStore = { editData: ed };
        this._editData = new Subject<any>();//this.editDataStore.editData
        this.editData = this._editData.asObservable();
        // dati zoom
        this.zoomDataStore = { zoomData: new ZoomData() };
        this._zoomData = new Subject<ZoomData>();//this.zoomDataStore.zoomData
        this.zoomData = this._zoomData.asObservable();
        const zd = ZoomData.build({});
        this._zoomData.next(zd);
        // Lingua mappa
        this.mapLanguageStore = '';
        this._mapLanguage = new Subject<string>();//this.mapLanguageStore
        this.mapLanguage = this._mapLanguage.asObservable();
        const lang = 'it-IT';
        this._mapLanguage.next(lang);
        // clipboard data
        this.mapClipboardDataStore = { mapClipboardData: '' };
        this._mapClipboardData = new Subject<any>();//this.mapClipboardDataStore.mapClipboardData
        this.mapClipboardData = this._mapClipboardData.asObservable();
        // selection menu
        this.selectionMenuDataStore = { selectionData: '' };
        this._selectionMenuData = new Subject<any>();//this.selectionMenuDataStore.selectionData
        this.selectionMenuData = this._selectionMenuData.asObservable();
        // show steps
        this.showStepsDataStore = { showStepsState: { isPrevEnabled: false, isNextEnabled: false } };
        this._showStepsData = new Subject<any>();//this.showStepsDataStore.showStepsState
        this.showStepsData = this._showStepsData.asObservable();
        //show image
        this.showImageDataStore = { node: { node: null } };
        this._showImageData = new Subject<any>();
        this.showImageData = this._showImageData.asObservable();
        // edit deep
        this.editDeepDataStore = { node: { node: null } };
        this._editDeepData = new Subject<any>();
        this.editDeepData = this._editDeepData.asObservable();
        // video url changed
        this.videoUrlChangedDataStore = { node: { node: null } };
        this._videoUrlChangedData = new Subject<any>(); //this.videoUrlChangedDataStore.node
        this.videoUrlChangedData = this._videoUrlChangedData.asObservable();
        // Find
        this.nodeFoundChangedDataStore = {};
        this._nodeFoundChangedData = new Subject<any>(); //this.nodeFoundChangedDataStore.nodeId
        this.nodeFoundChangedData = this._nodeFoundChangedData.asObservable();
        // Background
        this.backgroundChangedStore = {};
        this._backgroundChanged = new Subject<any>();//this.backgroundChangedStore.color
        this.backgroundChangedData = this._backgroundChanged.asObservable();
        // Copy format
        this.formatCopiedStore = {};
        this._formatCopied = new Subject<any>();
        this.formatCopiedData = this._formatCopied.asObservable();
        // Zoom on selection
        this.onZoomOnSelectionChangedStore = {};
        this._onZoomOnSelectionChanged = new Subject<any>();
        this.onZoomOnSelectionChangedData = this._onZoomOnSelectionChanged.asObservable();
        // Events real time from the core
        this.onDataCommandChangedStore = {};
        this._onDataCommandChanged = new Subject<any>();
        this.onDataCommandChangedData = this._onDataCommandChanged.asObservable();
        // Feedback real time from the core
        this.onPositionFeebackChangedStore = {};
        this._onPositionFeebackChanged = <Subject<any>>new Subject<any>();
        this.onPositionFeebackChangedData = this._onPositionFeebackChanged.asObservable();
        // Outline updated
        this.onOutlineUpdatedDataStore = {};
        this._onOutlineUpdatedData = <Subject<any>>new Subject<any>();
        this.onOutlineUpdatedData = this._onOutlineUpdatedData.asObservable();
        // Node state updated
        this.onNodeStateDataStore = {};
        this._onNodeStateData = <Subject<any>>new Subject<any>();
        this.onNodeStateData = this._onNodeStateData.asObservable();
        // Start/End update
        this.onStartUpdatingDataStore = {};
        this._onStartUpdatingData = new Subject<any>();
        this.onStartUpdatingData = this._onStartUpdatingData.asObservable();
        this.onEndUpdatingDataStore = {};
        this._onEndUpdatingData = new Subject<any>();
        this.onEndUpdatingData = this._onEndUpdatingData.asObservable();
        this._onImageLoadedData = new Subject<any>();
        this.onImageLoadedData = this._onImageLoadedData.asObservable();
        //
        this.SM_container = document.getElementById('sm-container');
    }

    public clearData(): void {
        if (this.dirtyData && this._dirtyData && this._ctxMenuItems) {
            const dd = DirtyData.build(-1, 0, true);
            this._dirtyData.next(dd);
            const md = LinksMenuData.build(0, 0, [], this.unavailableLink);
            this._ctxMenuItems.next(md);
        }
    }

    editHtmlText(isNode: any, isCell: any, language: any, titleHtml: any, x: any, y: any, width: any, height: any, zoom: any): void {
        if (!this.mapShowService.isEditShowMode && this._editData) {
            const ed: EditData = EditData.build(isNode, isCell, language, titleHtml, x, y, width, height, zoom);
            this._editData.next(ed);
        }
    }
    nodePressed(titleText: any, language: any, latex: any): void {
        this.mapStateService.setFocusOnMap(true);

        if (this.smService.isTTSEnabled()) {
            const usermail = (this.authService.credentials ? this.authService.credentials.username : '');
            let savedSpeechRate = 0;
            if (usermail != '') {
                const filter: Array<string> = [usermail, language];
                savedSpeechRate = this.userPreferencesService.getReadSpeedCookie(filter);
            }

            let mathText = '';
            if (latex) {
                if (language.includes('it') || language.includes('en')) {
                    mathText = ' ' + this.mathService.readLatex(latex, language);
                }
            }

            this.ttsService.speak(CALLER.map, titleText + mathText, language, false, savedSpeechRate);
        }
    }
    edgePressed(titleText: any, language: any): void {
        this.mapStateService.setFocusOnMap(true);

        if (this.smService.isTTSEnabled()) {
            const usermail = (this.authService.credentials ? this.authService.credentials.username : '');
            let savedSpeechRate = 0;
            if (usermail != '') {
                const filter: Array<string> = [usermail, language];
                savedSpeechRate = this.userPreferencesService.getReadSpeedCookie(filter);
            }
            this.ttsService.speak(CALLER.map, titleText, language, false, savedSpeechRate);
        }
    }

    pressDown(): void {

        this.youtubeService.ShowMask();
        this.mapStateService.setFocusOnMap(true);

        if (!this.SM_container) {
            this.SM_container = document.getElementById('sm-container');
        }
        if (this.SM_container) {
            this.SM_container.focus();
        }
    }

    pressUp(): void {
        this.youtubeService.HideMask();
    }

    setSelectionData(data: any): void {
        if (this._selectionData) {
            this._selectionData.next(data);
        }
    }
    dirtyUpdate(index: any, length: any, lastIsNew: any): void {
        if (this._dirtyData) {
            const dd: DirtyData = DirtyData.build(index, length, lastIsNew);
            this._dirtyData.next(dd);
        }
    }
    newNode(): void {
        console.log('stat: NEW_NODE');
        this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDNODE);
    }
    newEdge(): void {
        console.log('stat: NEW_EDGE');
        this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDEDGE);
    }
    newTable(): void {
        console.log('stat: NEW_TABLE');
        this.mapStateService.mapOperations?.add(MapOperationType.STAT_ADDTABLE);
    }
    addWords(wordCount: any): void {
        console.log('stat: ADD_WORDS ' + wordCount);
        this.mapStateService.mapOperations?.add(MapOperationType.STAT_WORDS, wordCount);
    }
    newMap(): void {
        console.log('stat: NEW_MAP');
        this.mapStateService.mapOperations?.add(MapOperationType.STAT_NEWMAP);
    }
    findTextResult(data: any): void {
        if (data && this._nodeFoundChangedData) {
            this._nodeFoundChangedData.next(data);
        }
    }
    contentCopied(data: any): void {
        if (this._mapClipboardData) {
            this._mapClipboardData.next(data);
            // copia in clipboard
        }
    }
    zoomUpdated(data: any): void {
        if (this._zoomData) {
            const zd: ZoomData = ZoomData.build(data);
            this._zoomData.next(zd);
        }
    }
    cursorChange(data: boolean): void {
        if (this._cursorData) {
            this._cursorData.next(data);
        }
    }
    notifyMapLanguage(data: any): void {
        if (this._mapLanguage) {
            this._mapLanguage.next(data);
        }
    }
    askMapLanguage(): void {
        if (this._mapLanguage) {
            // set map language as the UI language
            this._mapLanguage.next(this.i18nService.language);
        }
    }
    smReady(): void {
    }

    smUnregistered(): void {
    }

    extraClicked(data: any): void {
        if (this._ctxMenuItems) {
            this.quickEditService.disableAll();
            const items = [];
            if (data.links) {
                for (let i = 0; i < data.links.length; i++) {
                    items.push(data.links[i]);
                }
            }
            const ctxMenuData = LinksMenuData.build(data.event.nativeEvent.pageX,
                data.event.nativeEvent.pageY, items, this.unavailableLink);
            this._ctxMenuItems.next(ctxMenuData);
        }
    }

    mathClicked(data: any): void {
        if (data && data.node && data.node.id && data.latex !== null && data.latex !== undefined) {
            this.quickEditService.disableAll();

            this.mathService.setLatexInEditor(data.latex);
        }
    }

    pressUpMenuButtonFn(data: any): void {
        if (this._selectionMenuData && !this.mapShowService.isEditShowMode) {
            this._selectionMenuData.next(data);
        }
    }

    showStepsUpdate(data: any): void {
        if (this._showStepsData) {
            this._showStepsData.next(data);
        }
    }

    nodeMoving(isMoving: boolean): void {
        const data = { quickEditEnabled: false };
        this.quickEditService.nodeMoving(isMoving);
    }

    openImageClicked(node: any): void {
        if (this._showImageData && !this.mapShowService.isEditShowMode) {
            if (node.image) {
                this._showImageData.next(node);
            }
        }
    }

    openDeepClicked(node: any): void {
        if (this._editDeepData && !this.mapShowService.isEditShowMode) {
            if (node) {
                this.quickEditService.disableAll();
                this._editDeepData.next(node);
            }
        }
    }

    openLinkClicked(uri: any): void {
        if (!this.mapShowService.isEditShowMode) {
            this.quickEditService.disableAll();
            if (uri && (uri.startsWith('data:image')) || (uri.startsWith('http:') || uri.startsWith('https:'))) {
                // Correzione http://data:image in data:image
                if (uri.startsWith('http://data:image')) {
                    uri = uri.substr(7);
                }
                //
                if (this.mapShowService.isMapShow) {
                    const width = 500;
                    let left = window.screen.availWidth - width;
                    if ((window.screenLeft + window.outerWidth + width) < (window.screen.availWidth)) {
                        left = window.screenLeft + window.outerWidth;
                    }
                    const top = window.screenTop;
                    const height = window.innerHeight + (window.outerHeight - window.innerHeight) - 100;
                    const linkwindow = window.open(uri, 'showlink', 'toolbar=yes,top=' + top + ',left=' + left + ',width=' + width + ',height=' + height);
                    linkwindow?.focus();
                } else {
                    if (uri.startsWith('data:image')) {
                        this.showDataUrlInANewWindow(uri);
                    } else {
                        window.open(uri, '_blank');
                    }
                }
            }
        }
    }

    showDataUrlInANewWindow(dataUri: string) {
        const image = new Image();
        image.src = dataUri;
        const w = window.open('');
        w?.document.write('<div>' + image.outerHTML + '</div>');
    }

    // YOUTUBE
    playVideoClicked(node: any): void {
        if (!this.mapShowService.isEditShowMode) {
            this.quickEditService.disableAll();
            this.youtubeService.insertYoutubeVideoInNode(node.id);
        }
    }

    // READORDER
    readOrderEdited(undoData: any) {
        this.mapShowService.canUndo = undoData.canUndo;
        this.mapShowService.canRedo = undoData.canRedo;
        this.mapShowService.onChangeEditShow.emit();
    }

    playRecClicked(data: any): void {
        console.log('playRecClicked');
    }

    playAudioClicked(data: any): void {
        console.log('playAudioClicked');
    }

    backgroundChanged(color: any) {
        if (this._backgroundChanged && color) {
            this._backgroundChanged.next(color);
            this.mapStateService.onChangedBackColor.emit(color);
        }
    }

    videoUrlUpdated(data: any): void {
        if (this._videoUrlChangedData && data && data.node) {
            this._videoUrlChangedData.next(data);
        }
    }

    formatCopied(data: any): void {
        if (this._formatCopied) {
            this._formatCopied.next('');
        }
    }

    notifyZoomOnSelectionChanged(data: any): void {
        if (this._onZoomOnSelectionChanged) {
            this._onZoomOnSelectionChanged.next(data);
        }
    }

    public dataCommand(data: any): void {
        if (this._onDataCommandChanged) {
            this._onDataCommandChanged.next(data);
        }
    }

    public positionFeedback(data: any): void {
        if (this._onPositionFeebackChanged) {
            this._onPositionFeebackChanged.next(data);
        }
    }

    public nodesWithTextAndNoImage(data: any): void {
        // NOTHING (GECO)
    }

    public outlineUpdated(data: any): void {
        if (this._onOutlineUpdatedData) {
            this._onOutlineUpdatedData.next(data);
        }
    }

    public notifyNodeState(data: any, state: boolean) {
        if (this._onNodeStateData) {
            this._onNodeStateData.next({ data: data, state: state });
        }
    }

    public notifyStartUpdating() {
        if (this._onStartUpdatingData) {
            this._onStartUpdatingData.next({});
        }

    }
    public notifyEndUpdating() {
        if (this._onEndUpdatingData) {
            this._onEndUpdatingData.next({});
        }
    }

    public imageLoaded(): void {
        if (this._onImageLoadedData) {
            this._onImageLoadedData.next({});
        }
    }

}
