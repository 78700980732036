import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AnaDesktopComponent } from './ana-desktop.component';


const routes: Routes = [
  { path: 'ana-desktop', component: AnaDesktopComponent, data: {} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AnaDesktopRoutingModule { }
