import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UiConstants } from '../../shared/ui-constants';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { SmxFontStyle } from './smx-font-style';
import { SmxNodeAspect } from '../../map-edit/dialog-preferences/smx-node-aspect';
import { SmxEdgeAspect } from '../../map-edit/dialog-preferences/smx-edge-aspect';
import { MapEditService } from '../map-edit.service';
import { ConfirmationService } from '../../../app/shared/dialog/confirmation.service';
import { Subscription } from 'rxjs';
import { SmService } from 'supermappe-core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material/button-toggle';
import { UserPreferenceService } from 'src/app/shared/user-preference.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DeviceService } from 'src/app/core/device.service';

const SMX_CONFIG = {
    'CONTINUOUS_IMAGE_UPDATE': true,
    'EDIT_ON_TEXT': true,
    'FIT_WHOLE_PAGE': false,
    'STAMP_IMAGE_NAME': 'stamp-image',
    'MAP_QRCODE_SYMBOL_NAME': 'map-qrcode-symbol',
    'MAP_QRCODE_NAME': 'map-qrcode',
    'GDOC_QRCODE_SYMBOL_NAME': 'gdoc-qrcode-symbol',
    'GDOC_QRCODE_NAME': 'gdoc-qrcode',
    'INTERNAL_MOUSEWHEEL': true,
    'MIN_ZOOM_DETAILS': 0.5,
    'SHOW_DEEP_STARS': false,
    'SHOW_NODE_MATH': false, // Impostato oltre
    'SHOW_NODE_REC': false,
    'URL': {
        'ROOT': '',
        'MAPLIST': 'resources',
        'BASE': '', //  TOLTO PER NUOVE FUNCTION IL CORE NON DEVE METTERLO!!!!!
        'ASSETS': './assets'
    },
    'default': {
        'NODE': {
            'NODETYPE': 'rectangle',
            'BRUSHCOLOR': 'rgba(255,255,255,1.0)',
            'PENCOLOR': 'rgba(0,112,192,1.0)',
            'PENWIDTH': 1
        },
        'EDGE': {
            'AUTOCURVED': 'false',
            'PENCOLOR': 'rgba(128,128,128,1.0)',
            'PENWIDTH': 1,
            'FREEEDGE': true,
            'TYPE': 'forward'
        },
        'TABLE': {
            'BRUSHCOLOR': 'rgba(255,255,255,1.0)',
            'PENCOLOR': 'rgba(0,112,192,1.0)',
            'PENWIDTH': 1
        },
        'IMAGE': {
            'FRAME': false
        }
    },
    'isMobile': false
};

@Component({
    selector: 'app-dialog-preferences',
    templateUrl: './dialog-preferences.component.html',
    styleUrls: ['./dialog-preferences.component.scss']
})
export class DialogPreferencesComponent implements OnInit, OnDestroy {

    nodeTabSelected = false;
    edgeTabSelected = false;
    deepTabSelected = false;
    noteTabSelected = false;
    mathTabSelected = false;

    baseKeyboardType = 'BASIC';

    fontFamilies = UiConstants.FONTS.fontFamilies;

    fontSizes = UiConstants.FONTS.fontSizes;
    colors = UiConstants.PALETTE.BASE;
    edgeWidths = UiConstants.EDGE.widths;

    public nodeFontStyle: { [index: string]: any };
    public edgeFontStyle: { [index: string]: any };
    public deepFontStyle: { [index: string]: any };
    public noteFontStyle: { [index: string]: any };
    public nodeAspect: { [index: string]: any };
    public edgeAspect: { [index: string]: any };
    public framedImage: boolean = false;

    // public isMathBasicKbChecked = true;
    public isMathSetsKbChecked = true;
    public isMathGeometryKbChecked = true;
    public isMathDisequationsKbChecked = true;
    public isMathPhysicsKbChecked = true;
    public isMathAnalysisKbChecked = true;
    public isMathTrigonometryKbChecked = true;
    public isMathLogExpKbChecked = true;
    public isMathGreekKbChecked = true;
    private saveMathPrefTimeout: any = undefined;
    public isLab: boolean = false;
    public isFreeEdgeChecked = false;

    @Input() hideButtons: boolean;
    @Input() usedSizeColors: number;
    @Input() usedColorLabel: string;

    private restoreSubscription: Subscription | undefined;

    constructor(
        private translateService: TranslateService,
        private mapEditService: MapEditService,
        private smService: SmService,
        private confirmationService: ConfirmationService,
        private userPreferenceService: UserPreferenceService,
        private authenticationService: AuthenticationService,
        private platform: DeviceService
    ) {
        this.hideButtons = true;
        this.usedSizeColors = 15;
        this.usedColorLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));

        this.nodeFontStyle = UiConstants.nodeFontStyle;
        this.edgeFontStyle = UiConstants.edgeFontStyle;
        this.deepFontStyle = UiConstants.deepFontStyle;
        this.noteFontStyle = UiConstants.noteFontStyle;
        this.framedImage = UiConstants.frameNodeImage;

        this.nodeAspect = UiConstants.nodeAspect;
        this.edgeAspect = UiConstants.edgeAspect;

        if (userPreferenceService.userPrefs) {
            // this.isMathBasicKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('BASIC');
            this.baseKeyboardType = (userPreferenceService.userPrefs.math.keyboardLayouts.includes('BASIC') ? 'BASIC' : 'PRIMARY');
            this.isMathSetsKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('SETS');
            this.isMathGeometryKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('GEOMETRY');
            this.isMathDisequationsKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('DISEQUATIONS');
            this.isMathPhysicsKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('PHYSICS');
            this.isMathAnalysisKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('ANALYSIS');
            this.isMathTrigonometryKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('TRIGONOMETRY');
            this.isMathLogExpKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('LOG_EXP');
            this.isMathGreekKbChecked = userPreferenceService.userPrefs.math.keyboardLayouts.includes('GREEK');
        }

        this.isLab = this.authenticationService.isLab();
    }

    ngOnInit() {
        this.unselect();
        this.nodeTabSelected = true;
        this.updateConfig();
    }

    ngOnDestroy() {
        if (this.restoreSubscription) { this.restoreSubscription.unsubscribe(); }
    }

    public selectTab(tabName: string) {
        this.unselect();
        switch (tabName) {
            case 'node':
                this.nodeTabSelected = true;
                break;
            case 'edge':
                this.edgeTabSelected = true;
                break;
            case 'deep':
                this.deepTabSelected = true;
                break;
            case 'note':
                this.noteTabSelected = true;
                break;
            case 'math':
                this.mathTabSelected = true;
                break;
        }
    }

    private unselect() {
        this.nodeTabSelected = false;
        this.edgeTabSelected = false;
        this.deepTabSelected = false;
        this.noteTabSelected = false;
        this.mathTabSelected = false;
    }

    public editDefaultStyle(type: string, style: string, value: string) {
        let saveChanges = false;
        switch (type) {
            case 'title':
                {
                    saveChanges = (this.nodeFontStyle[style] !== value);
                    this.nodeFontStyle[style] = value;
                }
                break;
            case 'edge':
                {
                    saveChanges = (this.nodeFontStyle[style] !== value);
                    this.edgeFontStyle[style] = value;
                }
                break;
            case 'deep':
                {
                    saveChanges = (this.nodeFontStyle[style] !== value);
                    this.deepFontStyle[style] = value;
                }
                break;
            case 'note':
                {
                    saveChanges = (this.nodeFontStyle[style] !== value);
                    this.noteFontStyle[style] = value;
                }
                break;
        }
        if (saveChanges) {
            UiConstants.nodeFontStyle = this.nodeFontStyle;
            UiConstants.edgeFontStyle = this.edgeFontStyle;
            UiConstants.deepFontStyle = this.deepFontStyle;
            UiConstants.noteFontStyle = this.noteFontStyle;
            this.mapEditService.saveMapStyles();
        }
    }

    private updateConfig() {
        // default node config
        switch (UiConstants.nodeAspect['shape']) {
            case 'PREF_SHAPE_RECT':
                SMX_CONFIG.default.NODE.NODETYPE = 'rectangle';
                break;
            case 'PREF_SHAPE_ELLIPSE':
                SMX_CONFIG.default.NODE.NODETYPE = 'ellipse';
                break;
            case 'PREF_SHAPE_ROUNDRECT':
                SMX_CONFIG.default.NODE.NODETYPE = 'roundrect';
                break;
        }
        if (UiConstants.nodeAspect['background_color'].startsWith('#')) {
            UiConstants.nodeAspect['background_color'] = this.htmlColorToRgba(UiConstants.nodeAspect['background_color']);
        }
        if (UiConstants.nodeAspect['border_color'].startsWith('#')) {
            UiConstants.nodeAspect['border_color'] = this.htmlColorToRgba(UiConstants.nodeAspect['border_color']);
        }
        SMX_CONFIG.default.NODE.BRUSHCOLOR = UiConstants.nodeAspect['background_color'];
        SMX_CONFIG.default.NODE.PENCOLOR = UiConstants.nodeAspect['border_color'];

        // default Edge config
        switch (UiConstants.edgeAspect['type']) {
            case 'PREF_EDGE_STRAIGHT':
                SMX_CONFIG.default.EDGE.AUTOCURVED = 'false';
                break;
            case 'PREF_EDGE_CURVE':
                SMX_CONFIG.default.EDGE.AUTOCURVED = 'true';
                break;
        }
        if (UiConstants.edgeAspect['color'].startsWith('#')) {
            UiConstants.edgeAspect['color'] = this.htmlColorToRgba(UiConstants.edgeAspect['color']);
        }
        SMX_CONFIG.default.EDGE.PENCOLOR = UiConstants.edgeAspect['color'];
        switch (UiConstants.edgeAspect['width']) {
            case '1':
                SMX_CONFIG.default.EDGE.PENWIDTH = 1;
                break;
            case '2':
                SMX_CONFIG.default.EDGE.PENWIDTH = 2;
                break;
            case '3':
                SMX_CONFIG.default.EDGE.PENWIDTH = 3;
                break;
            case '4':
                SMX_CONFIG.default.EDGE.PENWIDTH = 4;
                break;
        }
        SMX_CONFIG.isMobile = this.platform.isMobileOrTabletDevice();
    }

    private hexToDec(hexString: any) {
        hexString = hexString || '';
        const hexSet = '0123456789ABCDEF';
        hexString = hexString.toUpperCase();
        const h1 = hexString.substring(0, 1);
        const h2 = hexString.substring(1, 2);
        return (hexSet.indexOf(h1) * 16) + hexSet.indexOf(h2);
    }

    private htmlColorToRgba(htmlColor: any) {
        htmlColor = htmlColor || '';

        let result = 'rgba(0,0,0,1.0)';
        if (htmlColor.length === 7) {
            // #RRGGBB -> #AARRGGBB
            htmlColor = '#FF' + htmlColor.substring(1, 9);
        }
        if (htmlColor.substring(1, 3) === '00') {
            // Transparent
            const r = this.hexToDec(htmlColor.substring(3, 5));
            const g = this.hexToDec(htmlColor.substring(5, 7));
            const b = this.hexToDec(htmlColor.substring(7, 9));
            result = 'rgba(' + r + ',' + g + ',' + b + ',0.0)';
        } else {
            // Opaque
            const r = this.hexToDec(htmlColor.substring(3, 5));
            const g = this.hexToDec(htmlColor.substring(5, 7));
            const b = this.hexToDec(htmlColor.substring(7, 9));
            result = 'rgba(' + r + ',' + g + ',' + b + ',1.0)';
        }
        return result;
    }

    public editDefaultNodeAspect(type: string, value: string) {
        let saveChanges = false;
        saveChanges = (this.nodeAspect[type] !== value);
        this.nodeAspect[type] = value;

        if (saveChanges) {
            UiConstants.nodeAspect = this.nodeAspect;

            switch (UiConstants.nodeAspect['shape']) {
                case 'PREF_SHAPE_RECT':
                    SMX_CONFIG.default.NODE.NODETYPE = 'rectangle';
                    break;
                case 'PREF_SHAPE_ELLIPSE':
                    SMX_CONFIG.default.NODE.NODETYPE = 'ellipse';
                    break;
                case 'PREF_SHAPE_ROUNDRECT':
                    SMX_CONFIG.default.NODE.NODETYPE = 'roundrect';
                    break;
            }
            if (UiConstants.nodeAspect['background_color'].startsWith('#')) {
                UiConstants.nodeAspect['background_color'] = this.htmlColorToRgba(UiConstants.nodeAspect['background_color']);
            }
            SMX_CONFIG.default.NODE.BRUSHCOLOR = UiConstants.nodeAspect['background_color'];

            if (UiConstants.nodeAspect['border_color'].startsWith('#')) {
                UiConstants.nodeAspect['border_color'] = this.htmlColorToRgba(UiConstants.nodeAspect['border_color']);
            }
            SMX_CONFIG.default.NODE.PENCOLOR = UiConstants.nodeAspect['border_color'];
            SMX_CONFIG.isMobile = this.platform.isMobileOrTabletDevice();
            this.smService.updateConfig(SMX_CONFIG);

            this.mapEditService.saveMapStyles();
        }
    }

    public editDefaultEdgeAspect(type: string, value: string) {
        let saveChanges = false;
        saveChanges = (this.edgeAspect[type] !== value);
        this.edgeAspect[type] = value;

        if (saveChanges) {
            UiConstants.edgeAspect = this.edgeAspect;

            switch (UiConstants.edgeAspect['type']) {
                case 'PREF_EDGE_STRAIGHT':
                    SMX_CONFIG.default.EDGE.AUTOCURVED = 'false';
                    break;
                case 'PREF_EDGE_CURVE':
                    SMX_CONFIG.default.EDGE.AUTOCURVED = 'true';
                    break;
            }
            if (UiConstants.edgeAspect['color'].startsWith('#')) {
                UiConstants.edgeAspect['color'] = this.htmlColorToRgba(UiConstants.edgeAspect['color']);
            }
            SMX_CONFIG.default.EDGE.PENCOLOR = UiConstants.edgeAspect['color'];
            switch (UiConstants.edgeAspect['width']) {
                case '1':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 1;
                    break;
                case '2':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 2;
                    break;
                case '3':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 3;
                    break;
                case '4':
                    SMX_CONFIG.default.EDGE.PENWIDTH = 4;
                    break;
            }
            SMX_CONFIG.isMobile = this.platform.isMobileOrTabletDevice();
            this.smService.updateConfig(SMX_CONFIG);

            this.mapEditService.saveMapStyles();
        }
    }

    public OnFreeEdgeToggleChange(event: any) {
        const e = event as MatSlideToggleChange
        this.edgeAspect['freeEdge'] = (e.checked) ? 'true' : 'false';
        const isCurved: boolean = (this.edgeAspect['type'] === 'PREF_EDGE_CURVE');
        this.smService.toggleFreeEdge(e.checked);
        this.smService.updateAllWidgets();

        this.mapEditService.saveMapStyles();
    }

    clicked(useFrame: boolean) {
        this.framedImage = useFrame;
        UiConstants.frameNodeImage = useFrame;
        this.smService.updateConfigImageFrame(this.framedImage);
        this.mapEditService.saveMapStyles();
    }

    restoreDefault(type: string) {
        this.restoreSubscription = this.confirmationService.confirm(
            this.translateService.instant(extract('PREF_RESET')),
            this.translateService.instant(extract('PREF_RESET_MESSAGE')),
            this.translateService.instant(extract('BUTTON_OK')),
            this.translateService.instant(extract('GENERAL_CANCEL')))
            .subscribe((result: boolean) => {
                if (result) {
                    switch (type) {
                        case 'title':
                            {
                                this.nodeFontStyle = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '14', '#000000');
                                this.nodeAspect = new SmxNodeAspect();
                            }
                            break;
                        case 'edge':
                            {
                                this.edgeFontStyle = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '12', '#000000');
                                this.edgeAspect = new SmxEdgeAspect();
                            }
                            break;
                        case 'deep':
                            {
                                this.deepFontStyle = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '11', '#000000');
                            }
                            break;
                        case 'note':
                            {
                                this.noteFontStyle = new SmxFontStyle('PREF_EFFECT_NONE', 'Arial', '11', '#000000');
                            }
                            break;
                    }
                    UiConstants.nodeFontStyle = this.nodeFontStyle;
                    UiConstants.nodeAspect = this.nodeAspect;
                    UiConstants.edgeFontStyle = this.edgeFontStyle;
                    UiConstants.edgeAspect = this.edgeAspect;
                    UiConstants.deepFontStyle = this.deepFontStyle;
                    UiConstants.noteFontStyle = this.noteFontStyle;
                    this.mapEditService.saveMapStyles();
                    this.updateConfig();
                    this.smService.updateConfig(SMX_CONFIG);
                    this.smService.toggleFreeEdge(this.edgeAspect['freeEdge']);
                    this.smService.updateAllWidgets();
                }
            });
    }

    cancelSelection() {
    }

    //evento toggle group dei layout tastiere
    public onKeyboardLayoutToggleGroupChanged(event: any) {
        const e = event as MatButtonToggleChange
        const toggle = e.source as MatButtonToggle;
        const value = toggle.buttonToggleGroup.value;
        this.baseKeyboardType = value;
        this.updateKeyboardsEnabled();
    }


    //
    public OnKeyboardToggleChange(event: any) {
        const e = event as MatSlideToggleChange
        //console.log("KEYBOARD TOGGLE CHANGE " + e.source.id + " VALUE " + e.checked);
        switch (e.source.id) {
            // case 'BASIC':
            //     this.isMathBasicKbChecked = e.checked;
            //     break;
            case 'SETS':
                this.isMathSetsKbChecked = e.checked;
                break;
            case 'GEOMETRY':
                this.isMathGeometryKbChecked = e.checked;
                break;
            case 'DISEQUATIONS':
                this.isMathDisequationsKbChecked = e.checked;
                break;
            case 'PHYSICS':
                this.isMathPhysicsKbChecked = e.checked;
                break;
            case 'ANALYSIS':
                this.isMathAnalysisKbChecked = e.checked;
                break;
            case 'TRIGONOMETRY':
                this.isMathTrigonometryKbChecked = e.checked;
                break;
            case 'LOG_EXP':
                this.isMathLogExpKbChecked = e.checked;
                break;
            case 'GREEK':
                this.isMathGreekKbChecked = e.checked;
        }
        this.updateKeyboardsEnabled();
    }

    private updateKeyboardsEnabled() {
        const keyboardsEnabled: string[] = [];
        // if (this.isMathBasicKbChecked) keyboardsEnabled.push("BASIC");
        if (this.baseKeyboardType === 'BASIC') {
            keyboardsEnabled.push('BASIC');
            if (this.isMathSetsKbChecked) keyboardsEnabled.push("SETS");
            if (this.isMathGeometryKbChecked) keyboardsEnabled.push("GEOMETRY");
            if (this.isMathDisequationsKbChecked) keyboardsEnabled.push("DISEQUATIONS");
            if (this.isMathPhysicsKbChecked) keyboardsEnabled.push("PHYSICS");
            if (this.isMathAnalysisKbChecked) keyboardsEnabled.push("ANALYSIS");
            if (this.isMathTrigonometryKbChecked) keyboardsEnabled.push("TRIGONOMETRY");
            if (this.isMathLogExpKbChecked) keyboardsEnabled.push("LOG_EXP");
            if (this.isMathGreekKbChecked) keyboardsEnabled.push("GREEK");
        } else {
            keyboardsEnabled.push('PRIMARY');
        }
        //
        if (this.saveMathPrefTimeout !== undefined) {
            clearTimeout(this.saveMathPrefTimeout);
        }
        this.saveMathPrefTimeout = setTimeout(() => {
            // if (keyboardsEnabled.length == 0) {
            //     keyboardsEnabled = ["BASIC"];
            //     this.isMathBasicKbChecked = true;
            // }
            if (this.userPreferenceService.userPrefs) {
                this.userPreferenceService.userPrefs.math.keyboardLayouts = keyboardsEnabled;
                this.userPreferenceService.setUserPreference(this.userPreferenceService.userPrefs);
            }
            this.saveMathPrefTimeout = undefined;
        }, 100);

    }
}
