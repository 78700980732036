<!-- EDIT PRESENTAZIONE TOOLBAR-->
<div *ngIf="!editShowToolbarCompressed" class="edit-toolbar">
    <button mat-icon-button color="primary" [disabled]="!canUndoEditShow" (click)="undoEditShow()" matTooltip="{{'MAIN_TOOLBAR_UNDO' | translate}}">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_UNDO' | translate">undo</mat-icon>
    </button>
    <button mat-icon-button color="primary" [disabled]="!canRedoEditShow" (click)="redoEditShow()" matTooltip="{{'MAIN_TOOLBAR_REDO' | translate}}">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_REDO' | translate">redo</mat-icon>
    </button>
    <button class="toolbar-button" mat-menu mat-icon-button matTooltip="{{'MAIN_TOOLBAR_CLEARREADORDER' | translate}}" color="primary" (click)="clearReadOrder()">
        <mat-icon color="primary" svgIcon="delete-circle-outline"></mat-icon>
    </button>
    <button class="toolbar-button" mat-menu mat-icon-button matTooltip="{{'MAIN_TOOLBAR_DEFAULTREADORDER' | translate}}" color="primary" (click)="setDefaultReadOrder()">
        <mat-icon color="primary" svgIcon="google-circles-extended"></mat-icon>
    </button>
    <button mat-icon-button color="primary" [disabled]="error" matTooltip="{{'MAIN_TOOLBAR_ZOOM-FIT' | translate}}" (click)="centerMap()">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate">settings_overscan</mat-icon>
    </button>
    <button mat-icon-button color="primary" matTooltip="{{showTooltip}}" (click)="mapShowing()" [disabled]="!canShowing">
        <mat-icon fontSet="material-icons-outlined">slideshow</mat-icon>
    </button>
    <button mat-stroked-button color="primary" (click)=" goToEditMode()">
        <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
        <span>{{'MAIN_TOOLBAR_GOTOEDITMODE' | translate}}</span>
    </button>
</div>

<!-- COMPRESSED TOOLBAR-->
<!-- EDIT PRESENTAZIONE TOOLBAR-->
<div *ngIf="editShowToolbarCompressed" class="edit-toolbar">
    <button mat-icon-button color="primary" [disabled]="!canUndoEditShow" (click)="undoEditShow()" matTooltip="{{'MAIN_TOOLBAR_UNDO' | translate}}">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_UNDO' | translate">undo</mat-icon>
    </button>
    <button mat-icon-button color="primary" [disabled]="!canRedoEditShow" (click)="redoEditShow()" matTooltip="{{'MAIN_TOOLBAR_REDO' | translate}}">
        <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_REDO' | translate">redo</mat-icon>
    </button>
    <button mat-icon-button color="primary" [matMenuTriggerFor]="compressedEditShowToolbarMenu">
        <mat-icon color="primary" fontset="material-icons-outlined">more_vert</mat-icon>
    </button>
    <mat-menu #compressedEditShowToolbarMenu="matMenu">
        <!--<button mat-menu-item color="primary" [disabled]="!canUndoEditShow" (click)="undoEditShow()">
            <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_UNDO' | translate">undo</mat-icon>
            <span translate>MAIN_TOOLBAR_UNDO</span>
        </button>
        <button mat-menu-item color="primary" [disabled]="!canRedoEditShow" (click)="redoEditShow()">
            <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_REDO' | translate">redo</mat-icon>
            <span translate>MAIN_TOOLBAR_REDO</span>
        </button>-->
        <button mat-menu-item color="primary" (click)="clearReadOrder()">
            <mat-icon color="primary" svgIcon="delete-circle-outline"></mat-icon>
            <span translate>MAIN_TOOLBAR_CLEARREADORDER</span>
        </button>
        <button mat-menu-item color="primary" (click)="setDefaultReadOrder()">
            <mat-icon color="primary" svgIcon="google-circles-extended"></mat-icon>
            <span translate>MAIN_TOOLBAR_DEFAULTREADORDER</span>
        </button>
        <button mat-menu-item color="primary" [disabled]="error" (click)="centerMap()">
            <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate">settings_overscan</mat-icon>
            <span translate>MAIN_TOOLBAR_ZOOM-FIT</span>
        </button>
        <button mat-menu-item color="primary" matTooltip="{{showTooltip}}" (click)="mapShowing()" [disabled]="!canShowing">
            <mat-icon color="primary" fontSet="material-icons-outlined">slideshow</mat-icon>
            <span translate>MAIN_TOOLBAR_STARTSHOW</span>
        </button>
        <button mat-menu-item color="primary" (click)=" goToEditMode()">
            <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
            <span>{{'MAIN_TOOLBAR_GOTOEDITMODE' | translate}}</span>
        </button>
    </mat-menu>
</div>
