import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-messagebox-dialog',
  templateUrl: './messagebox-dialog.component.html',
  styleUrls: ['./messagebox-dialog.component.scss']
})
export class MessageBoxDialogComponent {

  public mode: string;

  public title = '';

  public html: SafeHtml | null = null;

  constructor(
    public dialogRef: MatDialogRef<MessageBoxDialogComponent>,
    private sanitizer: DomSanitizer) {
    this.mode = 'OK';
  }

  public setHtml(html: string, placeHolders?: Array<any>) {
    if (placeHolders) {
      for (let i = 0; i < placeHolders.length; i++) {
        const placeHolder = placeHolders[i];
        const regExp = new RegExp('%' + placeHolder.ph + '%', 'g');
        html = html.replace(regExp, placeHolder.txt);
      }
    }
    const htmlSafe = this.sanitizer.bypassSecurityTrustHtml(html);
    this.html = htmlSafe;
  }

  public setText(mode: string, text: string, placeHolders: Array<any>) {
    this.mode = mode;
    let html = '<span>' + text + '</span>';
    if (placeHolders) {
      for (let i = 0; i < placeHolders.length; i++) {
        const placeHolder = placeHolders[i];
        const regExp = new RegExp('%' + placeHolder.ph + '%', 'g');
        html = html.replace(regExp, placeHolder.txt);
      }
    }
    const htmlSafe = this.sanitizer.bypassSecurityTrustHtml(html);
    this.html = htmlSafe;
  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
