import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../../../shared/map-state.service';
import { Subscription } from 'rxjs';
import { Logger } from '../../../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../../core/i18n.service';
import { MapShowService } from '../../../map-show/map-show.service';
import { DeviceService } from 'src/app/core/device.service';

const logger: Logger = new Logger('EditShowToolbarComponent');


@Component({
    selector: 'app-edit-show-toolbar',
    templateUrl: './edit-show-toolbar.component.html',
    styleUrl: './edit-show-toolbar.component.scss'
})
export class EditShowToolbarComponent implements OnInit, OnDestroy {
    @Input() mapId = '';
    @Input() editShowToolbarCompressed = false;

    canUndoEditShow = false;
    canRedoEditShow = false;
    canShowing = true;
    ttsEnable = false;
    showTooltip = '';
    mapSearchVisible = false;
    webSearchPanelVisible = false;
    error: any;
    selectiondata: any;
    mapStateOpen = false;

    onMapDirtyStateChangeSubscription: Subscription | undefined;
    onErrorSubscription: Subscription | undefined;
    onChangeEditShowSubscrition: Subscription | undefined;

    constructor(private smService: SmService,
        private mapShowService: MapShowService,
        private mapStateService: MapStateService,
        private translateService: TranslateService,
        public deviceService: DeviceService) {
        this.showTooltip = this.translateService.instant(extract('SHOWING'));
    }

    ngOnInit(): void {
        this.canUndoEditShow = false;
        this.canRedoEditShow = false;
        this.error = false;

        this.onChangeEditShowSubscrition = this.mapShowService.onChangeEditShow.subscribe(() => {
            this.canUndoEditShow = this.mapShowService.canUndo;
            this.canRedoEditShow = this.mapShowService.canRedo;
        });

        this.onMapDirtyStateChangeSubscription = this.mapStateService.onDirtyStateChange.subscribe((value: boolean) => {
            this.canShowing = !value;
            if (this.canShowing) {
                this.showTooltip = this.translateService.instant(extract('SHOWING'));
            } else {
                this.showTooltip = this.translateService.instant(extract('SHOW_DISABLED'));
            }
        });

        this.onErrorSubscription = this.mapStateService.onError.subscribe((error: any) => {
            this.error = error !== '';
            this.ttsEnable = false;

        });
    }

    ngOnDestroy(): void {
        if (this.onErrorSubscription) { this.onErrorSubscription.unsubscribe(); }
        if (this.onChangeEditShowSubscrition) { this.onChangeEditShowSubscrition.unsubscribe(); }
    }


    undoEditShow() {
        this.mapShowService.undo();
    }

    redoEditShow() {
        this.mapShowService.redo();
    }

    mapShowing() {
        if (this.canShowing) {
            this.mapShowService.showing(this.mapStateService.id);
        }
    }

    centerMap() {
        this.smService.resizeMap();
    }

    goToEditMode() {
        this.mapShowService.finishEditShow();
    }

    clearReadOrder() {
        this.mapShowService.clearReadOrder();
    }

    setDefaultReadOrder() {
        this.mapShowService.setDefaultOrder();
    }
}
