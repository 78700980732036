<div [formGroup]="subsForm">
    <div class="access-name">

        <span translate>ACCESSCONFIG_NAME</span>
        <input type="text" placeholder="{{'ACCESSCONFIG_NAME_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" class="map-input-name access-col2" formControlName="accessName" />
        <span *ngIf="f.accessName.errors" class="invalid-feedback">
            <span *ngIf="f.accessName.errors['required']" translate>EMPTY_NAME_NOT_ALLOWED</span>
        </span>
        <span *ngIf="!f.accessName.errors"> </span>
        <span class="access-desc" translate>ACCESSCONFIG_NAME_DESC</span>
    </div>

</div>
