import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShareMapComponent } from './share-map.component';
import { MapOperationType } from '../map-operations';
import { SmeService } from '../../core/sme/sme.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MapStateService } from '../map-state.service';
import { FirebaseService } from '../../core/firebase/firebase.service';
import { GoogleService } from 'src/app/core/google/google.service';

@Injectable({
    providedIn: 'root'
})
export class ShareMapService implements OnDestroy {

    public dialogShare: any;
    private statShareSubscription: Subscription | undefined;
    private afterClosedSubscription: Subscription | undefined;

    constructor(
        public dialog: MatDialog,
        private firebaseService: FirebaseService,
        private smeService: SmeService,
        private googleService: GoogleService,
        private authenticationService: AuthenticationService,
        private mapStateService: MapStateService
    ) {
    }

    public startSharing = new EventEmitter<string>();
    public onIdChange = new EventEmitter<{ uri: string, callback: any }>();
    public googleUri = '';
    public callbackShareGC: any = null;

    closeShareGC() {
        // add stat
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
        this.statShareSubscription = this.smeService.addCustomMapStat(this.mapStateService.gcShareMapId, MapOperationType.STAT_SHARECLASSROOM, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
        this.closeShareMap();
    }

    closeShareMap() {
        this.dialogShare.close();
    }

    ngOnDestroy() {
        if (this.statShareSubscription) { this.statShareSubscription.unsubscribe(); }
    }

    public showShareMapDialog(mapId: string, mapName: string, showClassroom: boolean, showFacebook: boolean, isShared: boolean, showOnlyQrCode: boolean) {
        this.mapStateService.gcShareMapId = mapId;
        this.dialogShare = this.dialog.open(ShareMapComponent, { autoFocus: false, width: '700px', disableClose: true });
        this.dialogShare.componentInstance.mapName = mapName;
        this.dialogShare.componentInstance.mapId = mapId;
        this.dialogShare.componentInstance.showClassroom = showClassroom;
        this.dialogShare.componentInstance.showFacebook = showFacebook;
        this.dialogShare.componentInstance.showOnlyQrCode = showOnlyQrCode;
        this.dialogShare.componentInstance.isShared = isShared;
        if (this.afterClosedSubscription) { this.afterClosedSubscription.unsubscribe(); }
        this.afterClosedSubscription = this.dialogShare.afterClosed().subscribe(() => {
            if (mapId) {
                this.mapStateService.refreshRecentSharing.emit(mapId);
            }
        });
    }

    /* NON USATO (non funziona)
    uploadMapAndGetGoogleId(mapId: string, mapName: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const userId = this.authenticationService.credentials?.firebaseUserId;
            const storageWorkFilePath = `/maps/${userId}/work/${mapId}/map.json`;
            const storageSmeFilePath = `/maps/${userId}/mymaps/${mapId}.sme`;
            this.firebaseService.getDownloadUri(storageSmeFilePath).then((uri: any) => {
                if (!uri) {
                    // Scarica mapWorkJson
                    this.firebaseService.downloadResource(storageWorkFilePath).subscribe({
                        next: (_mapWorkJson: any) => {
                            const mapWorkJson = JSON.stringify(_mapWorkJson);
                            this.smeService.saveMapRequest(mapId, mapWorkJson, null).subscribe({
                                next: (_smeBlob: any) => {
                                    this.googleService.uploadToDrive(_smeBlob, mapName).then((googleUri) => {
                                        return resolve(googleUri);
                                    });
                                },
                                error: (err: any) => {
                                    return resolve('');
                                }
                            });
                        },
                        error: (err: any) => {
                            return resolve('');
                        }
                    });
                } else {
                    this.firebaseService.downloadResourceFromStorageAsBlob(uri, 'application/supermappe').subscribe({
                        next: (_smeBlob: any) => {
                            this.googleService.uploadToDrive(_smeBlob, mapName).then((googleUri) => {
                                return resolve(googleUri);
                            });
                        },
                    });
                }
            });
        });
    }
    */

}
