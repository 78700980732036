export class SpeechRecognitionResult {
    public isFinal: boolean;
    public transcript: string;
    constructor(isFinal: boolean, speech: any) {
        this.isFinal = isFinal;
        this.transcript = '';
        if (speech) {
            for (let i = 0; i < speech.results.length; i++) {
                this.transcript += speech.results[i][0].transcript + ' ';
            }
            this.transcript = this.transcript.trim();
        }
    }
}
