import { VirtualKeyboardLayout } from 'mathlive';

export const EN_PHYSICS_KEYBOARD: VirtualKeyboardLayout = {
    id: "PHYSICS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            '[separator-5]'
        ],
        // Second row
        [
            {
                latex: '\\Delta',
                tooltip: 'capital delta'
            },
            {
                latex: '\\alpha',
                tooltip: 'alpha'
            },
            {
                latex: '\\beta',
                tooltip: 'beta'
            },
            {
                latex: '\\gamma',
                tooltip: 'gamma'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            },
            {
                latex: '\\Omega',
                tooltip: 'capital omega'
            },
            {
                latex: '\\omega',
                tooltip: 'omega'
            }
        ],
        // Third row
        [
            {
                latex: '\\overrightarrow{\\placeholder{}}',
                tooltip: 'vector'
            },
            {
                latex: '\\propto',
                tooltip: 'proportional to'
            },
            {
                latex: '\\parallel',
                tooltip: 'parallel'
            },
            {
                latex: ' \\perp',
                tooltip: 'perpendicular'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
