<div id="custom-toolbar-box">
    <div class="custom-toolbar" [hidden]="isLoading">
        <div *ngIf="isEditShowMode" class="map-show-help">
            <span class="help-text" translate>MAIN_TOOLBAR_SHOWHELP_ADD</span>
            <span class="help-text" translate>MAIN_TOOLBAR_SHOWHELP_REMOVE</span>
        </div>
        <div class="map-show-help" *ngIf="editorIsOpen">
            <span class="help-text" translate>MAIN_TOOLBAR_MODIFYTEXT</span>
        </div>
        <div *ngIf="quickEditVisible" class="quick-edit-toolbar" (click)="disableQuickEdit()">{{'QUICKEDIT_TOOLBAR_MSG' | translate}}</div>
        <div *ngIf="speechEditVisible" class="quick-edit-toolbar" (click)="disableSpeechEdit()">{{'SPEECHEDIT_TOOLBAR_MSG' | translate}}</div>
        <ng-container *ngIf="!isEditShowMode && !editorIsOpen && !quickEditVisible && !speechEditVisible">
            <app-generic-toolbar *ngIf="noSelectionVisible"></app-generic-toolbar>
            <app-table-toolbar *ngIf="formatTableVisible"></app-table-toolbar>
            <app-format-text-toolbar *ngIf="formatTextVisible"></app-format-text-toolbar>
            <mat-divider *ngIf="formatElementVisible" [vertical]="true" style="height: 35px;"></mat-divider>
            <app-format-element-toolbar *ngIf="formatElementVisible"></app-format-element-toolbar>
            <mat-divider *ngIf="!hideImage" [vertical]="true" style="height: 35px;"></mat-divider>
            <app-image-toolbar *ngIf="!hideImage"></app-image-toolbar>
            <mat-divider *ngIf="!hideAlign" [vertical]="true" style="height: 35px;"></mat-divider>
            <app-align-toolbar *ngIf="!hideAlign"></app-align-toolbar>
            <mat-divider *ngIf="!hideSize" [vertical]="true" style="height: 35px;"></mat-divider>
            <app-size-toolbar *ngIf="!hideSize"></app-size-toolbar>
            <mat-divider *ngIf="!hideLanguage" [vertical]="true" style="height: 35px;"></mat-divider>
            <app-language-toolbar *ngIf="!hideLanguage"></app-language-toolbar>
        </ng-container>
    </div>

</div>
