import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChatService } from './chat.service';

@Component({
    selector: 'app-chat-toolbar',
    templateUrl: './chat-toolbar.component.html',
    styleUrls: ['./chat-toolbar.component.scss']
})
export class ChatToolbarComponent implements OnDestroy {

    public isChatToolbarEnabled = false;
    public isChatPanelOpen = false;
    private isChatInitializedSubscription: Subscription;
    private chatEnabledSubscription: Subscription;
    private onShareDataSubscription: Subscription | undefined;

    constructor(
        public chatService: ChatService
    ) {
        this.isChatInitializedSubscription = chatService.onChatInitialized.subscribe(() => {
            this.isChatToolbarEnabled = true;
        });
        this.chatEnabledSubscription = chatService.onToggleChatPanel.subscribe((open: boolean) => {
            this.isChatPanelOpen = open;
        });
        this.isChatToolbarEnabled = true;
    }

    toggleChatPanel() {
        this.chatService.toggleChatPanel();
    }

    ngOnDestroy(): void {
        if (this.isChatInitializedSubscription) { this.isChatInitializedSubscription.unsubscribe(); }
        if (this.chatEnabledSubscription) { this.chatEnabledSubscription.unsubscribe(); }
        if (this.onShareDataSubscription) { this.onShareDataSubscription.unsubscribe(); }
    }
}
