import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvalidCredentialsComponent } from './invalid-credentials.component';


const routes: Routes = [
    { path: 'invalid-credentials', component: InvalidCredentialsComponent, data: {} }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class InvalidCredentialsRoutingModule { }
