import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { MapViewComponent } from './map-view.component';
import { BrowsercheckGuard } from '../core/browsercheck.guard';

const routes: Routes = Route.withShell([
  { path: 'map-shared/:mapId', redirectTo: 'map-view/:mapId' },
  { path: 'map-view/:mapId', component: MapViewComponent, data: { title: extract('MAPVIEW_TITLE') }, canActivate: [BrowsercheckGuard] }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [BrowsercheckGuard]
})
export class MapViewRoutingModule { }
