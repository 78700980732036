<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div *ngIf="showOnlyQrCode" class="modal-placeholder"></div>

<div *ngIf="!showOnlyQrCode" mat-dialog-title translate class=" dialog-title">MAIN_MENU_FILE-SHARE</div>
<div *ngIf="!showOnlyQrCode" mat-dialog-content class="main-content">
    <div *ngIf="isMapMine" class="add-user-container">
        <div class="email-input-container">
            <mat-form-field class="email-input" appearance="outline">
                <mat-label translate>SHAREMAP_ADD_USER</mat-label>
                <input matInput #addUserNameField [matAutocomplete]="auto" [disabled]="!isMapMine" type="text" spellcheck="false" (keyup)="onAddUserKeyUp($event)" (change)="onAddUserChange($event)" />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
                    <mat-optgroup *ngIf="shareEmailsFreq.length > 0" label="{{'SHAREGROUP_FREQ' | translate}}">
                        @for (email of shareEmailsFreq; track email) {
                        <mat-option [value]="email">{{email}}</mat-option>
                        }
                    </mat-optgroup>
                    <mat-optgroup *ngIf="shareEmailsGroup.length > 0" label="{{'SHAREGROUP_GROUP' | translate}}">
                        @for (email of shareEmailsGroup; track email) {
                        <mat-option [value]="email">{{email}}</mat-option>
                        }
                    </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="email-share-type-container">
            <mat-form-field class="share-type-input" appearance="outline">
                <mat-select cdkFocusInitial [disabled]="!isMapMine" [(ngModel)]="shareType">
                    <mat-option class="share-option" *ngFor="let s of addShareTypesOptions" [value]="s.value">
                        <mat-icon color="primary">{{s.icon}}</mat-icon>
                        {{s.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-flat-button color="accent" [disabled]="!isMapMine" (click)="addUser()">
                {{"SHARE_RECENT" | translate}}
            </button>
        </div>
    </div>
    <!--<mat-divider style="width: 100%;"></mat-divider>-->
    <div class="section-title" translate>SHAREMAP_USERSLIST</div>
    <div [ngClass]="{'users-list-min-height': loadUsersList.length >= 2}" class="users-list-container">
        <div *ngFor="let user of loadUsersList" class="user-list-item">
            <img *ngIf="user.picture" class="user-list-image" [src]="user.picture" />
            <div class="user-list-avatar-container" *ngIf="!user.picture">
                <div class="avatar"> {{mapStateService.getInitials(user.name)}}</div>
            </div>
            <div class="user-list-details-container">
                <div class="user-list-details-first-row">
                    <div class="users-list-name">{{user.name}}</div>
                </div>
                <div class="user-list-details-second-row">
                    <div class="users-list-email">{{user.email}}</div>
                </div>
            </div>
            <div class="user-list-tools-container">
                <select *ngIf="!user.isOwner" [disabled]="!isMapMine" class="users-list-type" [(ngModel)]="user.internalRole">
                    <option class="share-option" *ngFor="let s of editShareTypesOptions" [ngValue]="s.value">{{s.name}}</option>
                </select>
                <span *ngIf="user.isOwner" class="users-list-owner" translate>SHAREMAP_OWNER</span>
            </div>
        </div>
    </div>
    <mat-divider style="width: 100%;"></mat-divider>
    <span class="section-title" translate>SHAREMAP_PUBLIC</span>
    <div *ngIf="publicShareType === publicShareTypesOptions[0].value" class="public-share-description" translate>
        <mat-icon color="accent" class="public-share-icon">{{publicShareTypesOptions[0].icon}}</mat-icon>
        PUBLIC_SHARE_LIMIT_ONLY_PERMISSION_DESCRIPTION
    </div>
    <div *ngIf="publicShareType === publicShareTypesOptions[1].value" class="public-share-description" translate>
        <mat-icon color="accent" class="public-share-icon">{{publicShareTypesOptions[1].icon}}</mat-icon>
        PUBLIC_SHARE_VIEW_ONLY_PERMISSION_DESCRIPTION
    </div>
    <div *ngIf="publicShareType === publicShareTypesOptions[2].value" class="public-share-description" translate>
        <mat-icon color="accent" class="public-share-icon">{{publicShareTypesOptions[2].icon}}</mat-icon>
        PUBLIC_SHARE_EDIT_PERMISSION_DESCRIPTION
    </div>
    <div class="public-share-container">
        <mat-form-field appearance="outline">
            <mat-select [disabled]="!isMapMine" [(ngModel)]="publicShareType">
                <mat-option *ngFor="let s of publicShareTypesOptions" [value]="s.value">
                    <mat-icon color="primary">{{s.icon}}</mat-icon>
                    {{s.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-divider style="width: 100%;"></mat-divider>
    <div class="action-buttons-container">
        <button *ngIf="!showOnlyQrCode" mat-stroked-button matTooltip="{{'SHARE_COPY_LINK' | translate}}" (click)="copyLink(true)">
            <mat-icon fontSet="material-icons-outlined">insert_link</mat-icon>
            {{"SHARE_COPY_LINK" | translate}}
        </button>
        <button mat-stroked-button matTooltip="{{'SHARE_LINK_QRCODE' | translate}}" (click)="showQRCodeAndCopyLink()">
            <mat-icon fontSet="material-icons-outlined">qr_code</mat-icon>
            {{"SHARE_LINK_QRCODE" | translate}}
        </button>
        <div *ngIf="showFacebook" class="facebook-button" (click)="shareFacebook()" matTooltip="{{'MAIN_TOOLBAR_SHARE-FB'|translate}}">
            <mat-icon class="social-icon" svgIcon="facebookIcon"></mat-icon>
        </div>
        <div class="classroom-button" *ngIf="showClassroom && authenticationService.isGoogleUser() || authenticationService.linkedWithGoogle()" matTooltip="{{'MAIN_TOOLBAR_SHARE_CLASSROOM' | translate}}" (click)="shareClassroom()">
            <!-- <app-google-classroom class="classroom-component"></app-google-classroom> -->
            <mat-icon class="classroom-component" svgIcon="classroomIcon"></mat-icon>
        </div>
    </div>
</div>
<button *ngIf="!showOnlyQrCode" class="close-button" mat-icon-button color="primary" [mat-dialog-close]="true">
    <mat-icon fontSet="material-icons-outlined">close</mat-icon>
</button>
