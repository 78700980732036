import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SmeService } from '../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MessageBoxService } from '../shared/dialog/messagebox.service';
import { ConfirmationService } from '../shared/dialog/confirmation.service';
import { extract } from '../core/i18n.service';

@Component({
    selector: 'app-admin-list-users',
    templateUrl: './admin-list-users.component.html',
    styleUrls: ['./admin-list-users.component.scss']
})
export class AdminListUsersComponent {

    @Input() isLoading = false;

    @ViewChild('searchtext') searchText: ElementRef | undefined;

    private MIN_LENGTH = 3;

    public searchedText: string | undefined;
    public searchForm: FormGroup = this.formBuilder.group({
        searchText: ['']
    });

    public complete: boolean = true;
    public usersList: any | undefined;
    listUsersSubs: Subscription | undefined;

    constructor(
        private confirmationService: ConfirmationService,
        private messageBoxService: MessageBoxService,
        private formBuilder: FormBuilder,
        private smeService: SmeService,
        private translateService: TranslateService) {
    }

    onSubmit() {
        this.search();
    }

    search() {
        const textSearch = this.searchForm?.get('searchText')?.value.toLowerCase();
        if (textSearch.trim().length >= this.MIN_LENGTH) {
            this.loadData(textSearch);
        } else {
            this.messageBoxService.showTextMessage(
                this.messageBoxService.MODE_TYPE.OK,
                this.translateService.instant('USERS_DELETE'),
                this.translateService.instant('USER_DELETE_SHORT'));
        }
    }

    loadData(search: string) {
        this.isLoading = true;
        if (this.listUsersSubs) this.listUsersSubs.unsubscribe();
        this.listUsersSubs = this.smeService.listUsers(search).subscribe({
            next: (res: any) => {
                this.complete = res.userList.complete;
                this.usersList = [];
                if (res && res.userList && res.userList.users) {
                    // tslint:disable-next-line:forin
                    for (const n in res.userList.users) {
                        const user = res.userList.users[n];
                        user.deleteData = false;
                        this.usersList.push(user);
                    }
                }
                this.isLoading = false;
                this.searchedText = search;
            },
            error: (error: any) => {
                this.isLoading = false;
                console.error(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);
            }
        });

    }

    public reloadData() {
        const textSearch = this.searchForm?.get('searchText')?.value;
        if (textSearch.trim().length >= this.MIN_LENGTH) {
            this.loadData(textSearch);
        }
    }

    resetSearch() {
        this.searchForm?.get('searchText')?.setValue('');
        this.complete = true;
        this.usersList = [];
    }

    deleteSearchUser() {
        this.deleteUser(this.searchForm?.get('searchText')?.value, -1);
    }

    deleteDataClick(index:  number) {
        this.usersList[index].deleteData = !this.usersList[index].deleteData;

    }

    deleteUser(email: string, index: number) {
        if (email) {
            this.confirmationService.confirm(
                this.translateService.instant(extract('WARNING')),
                this.translateService.instant(extract('CONFIRM_USERS_DELETE')),
                this.translateService.instant(extract('BUTTON_OK')),
                this.translateService.instant(extract('GENERAL_CANCEL')))
                .subscribe((result: any) => {
                    if (result === true) {
                        this.isLoading = true;
                        this.smeService.deleteUserByEmail(email, this.usersList[index].deleteData).subscribe({
                            next: (subs: any) => {
                                // this.reloadData();
                                if (index >= 0 && index < this.usersList.length) {
                                    this.usersList[index].deleted = true;
                                    this.usersList[index].uid = subs.result;
                                }
                                this.isLoading = false;
                                this.messageBoxService.showTextMessage(
                                    this.messageBoxService.MODE_TYPE.OK,
                                    this.translateService.instant('USERS_DELETE'),
                                    this.translateService.instant('USER_DELETED') + ' ' + email);
                            },
                            error: (error: any) => {
                                this.isLoading = false;
                                this.messageBoxService.showTextMessage(
                                    this.messageBoxService.MODE_TYPE.OK,
                                    this.translateService.instant('USERS_DELETE'),
                                    this.translateService.instant('USER_DELETE_FAIL') + ' ' + email);
                            }
                        });
                    }
                });
        }
    }

}
