<h2 mat-dialog-title translate>PASTE-OPTIONS</h2>
<div class="paste-container">
    <div class="title-container">
        <mat-icon color="primary" svgIcon="table-plus"></mat-icon>
        <span translate>PASTE-AS-MAP</span>
    </div>
    <div class="button-row-container">
        <button mat-button class="option-button" (click)="clicked('up-down')">
            <div class="option-button-title">
                <span class="option-button-title" translate>PASTE-OPTIONS-MAP-UPDOWN</span>

            </div>
            <img class="option-button-image" src="../../assets/paste-struc/albero.png?v=1" />
        </button>
        <button mat-button class="option-button" (click)="clicked('radial')">
            <div class="option-button-title">
                <span class="option-button-title" translate>PASTE-OPTIONS-MAP-RADIAL</span>
            </div>
            <img class="option-button-image" src="../../assets/paste-struc/raggiera.png?v=1" />
        </button>
    </div>
    <div class="button-row-container">
        <button mat-button class="option-button" (click)="clicked('left-right')">
            <div class="option-button-title">
                <span class="option-button-title" translate>PASTE-OPTIONS-MAP-LEFTRIGHT</span>
            </div>
            <img class="option-button-image" src="../../assets/paste-struc/albero_sx.png?v=1" />
        </button>
        <button mat-button class="option-button" (click)="clicked('down-up')">
            <div class="option-button-title">
                <span class="option-button-title" translate>PASTE-OPTIONS-MAP-DOWNUP</span>
            </div>
            <img class="option-button-image" src="../../assets/paste-struc/albero_basso.png?v=1" />
        </button>
        <button mat-button class="option-button" (click)="clicked('right-left')">
            <div class="option-button-title">
                <span class="option-button-title" translate>PASTE-OPTIONS-MAP-RIGHTLEFT </span>
            </div>
            <img class="option-button-image" src="../../assets/paste-struc/albero_dx.png?v=1" />
        </button>
    </div>
    <div *ngIf="!hasDeepContent"></div>
    <mat-divider *ngIf="hasDeepContent" class="divider" />
    <div *ngIf="hasDeepContent" class="deep-switch-container">
        <span translate>PASTE-AS-DEEP</span>
        <mat-slide-toggle color="accent" [checked]="isPasteAsDeepChecked" id="SETS" (change)="OnDeepToggleChange($event)"></mat-slide-toggle>
    </div>
</div>
