import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeviceService } from '../core/device.service';
import { IframeLoginService } from '../core/authentication/iframe-login-service';
import { MatomoTracker } from 'ngx-matomo-client';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { MapStateService } from '../shared/map-state.service';

@Component({
    selector: 'app-demo',
    templateUrl: './demo.component.html',
    styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
    version: string = environment.version;
    paramMapSubscription: Subscription | undefined;
    error = '';
    isLoading = false;
    redirect = '';
    showingError = false;
    loginErrorMessage = '';
    clickableMsg = false;
    showSchool = false;

    public logoUrlExtended = '';

    public compressLogo = false;
    compressed = false;

    constructor(
        private translateService: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService,
        private deviceService: DeviceService,
        private tracker: MatomoTracker,
        private route: ActivatedRoute,
        private router: Router,
        private mapStateService: MapStateService
    ) {
        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });

        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;
            });

        // this.initialize();
        this.logoUrlExtended = ('../../assets/login/supermappex.png');
    }
    ngOnInit(): void {


        localStorage.setItem('demoFromStart', 'true');


        if (this.route.snapshot.fragment) {
            this.showSchool = this.route.snapshot.fragment.includes('school=1');
        }
    }


    goToWebsiteClick(from: string, type: string) {
        this.mapStateService.goToWebsiteClick(from, type);
    }


    logoutClick() {
        if (this.authenticationService.isAuthenticated()) {
            this.authenticationService.logout().subscribe(() => {

                this.router.navigate(['login', '/home']);
            });
        } else {
            this.router.navigate(['login', '/home']);
        }



    }


    private showError(errorCode: string) {
        console.log(`ERRORCODE: ${errorCode}`);
        if (errorCode) {
            this.loginErrorMessage = this.translateService.instant(errorCode);
            this.clickableMsg = false;
        } else {
            this.loginErrorMessage = this.translateService.instant('LOGIN_COOKIES_URL');
            this.clickableMsg = true;
        }
        this.showingError = true;
        this.isLoading = false;
    }

}
