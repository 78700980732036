import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouteReuseStrategy, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';

@Component({
    selector: 'app-finish-sign-up',
    templateUrl: './finish-sign-up.component.html',
    styleUrls: ['./finish-sign-up.component.scss']
})
export class FinishSignUpComponent implements OnInit {
    public user = {
        id: '',
        uid: '',
        email: '',
        displayName: '',
        picture: '',
        refreshToken: '',
        token: ''
    };

    error = '';
    email: string = '';


    isLoading = false;
    paramsSubscription: any;


    constructor(private firebaseAuthService: FirebaseAuthService, private route: ActivatedRoute, private router: Router, private translateService: TranslateService) { }



    gotoHome() {
        this.router.navigate(['home']);
    }

    /*
    signIn(event: any) {
        this.firebaseAuthService.createUser(event.email, event.password, '', '').then((userCred: any) => {


            this.user = {
                id: userCred.uid,
                uid: userCred.uid,
                email: userCred.email,
                displayName: userCred.displayName ? userCred.displayName : '',
                picture: userCred.photoURL,
                refreshToken: userCred.stsTokenManager.refreshToken,
                token: userCred.stsTokenManager.accessToken
            };
            const UserInfo = {

                state: 'firebase',
                access_token: userCred.stsTokenManager.accessToken,
                email: userCred.email,
                fullName: userCred.displayName ? userCred.displayName : '',
                id: userCred.uid,
                id_token: userCred.stsTokenManager.idToken,
                picture: userCred.photoURL,
                refresh_token: userCred.stsTokenManager.refreshToken
            };


            this.router.navigate(['/loggedin'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });

        });
    }
    */

    ngOnInit(): void {
        this.isLoading = false;
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
        this.paramsSubscription = this.route.params.subscribe(params => {
            const email = params['email'];
            const demo = params['demo'];
            if (demo === 'demo') {
                localStorage.setItem('demoFromStart', true.toString());
            }
            if (email) {
                this.router.navigate(['new-user-profile', email]);
            }



        });
        // let email = window.localStorage.getItem('emailForSignIn');
        // if (!email) {
        //     // User opened the link on a different device. To prevent session fixation
        //     // attacks, ask the user to provide the associated email again. For example:
        //     email = window.prompt(this.translateService.instant('REENTER_EMAIL'));
        // }
        // if (email) {
        //     this.router.navigate(['new-user-profile', email]);
        // }

        // this.firebaseAuthService.completeSignInPasswordLess()
        //     .then((userCred: any) => {
        //         // this.client_id = environment.firebaseClientId;
        //         this.user = {
        //             id: userCred.uid,
        //             uid: userCred.uid,
        //             email: userCred.email,
        //             displayName: userCred.displayName ? userCred.displayName : '',
        //             picture: userCred.photoURL,
        //             refreshToken: userCred.stsTokenManager.refreshToken,
        //             token: userCred.stsTokenManager.accessToken
        //         };
        //         const UserInfo = {

        //             state: 'passwordless',
        //             access_token: userCred.stsTokenManager.accessToken,
        //             email: userCred.email,
        //             fullName: userCred.displayName ? userCred.displayName : '',
        //             id: userCred.uid,
        //             id_token: userCred.stsTokenManager.idToken,
        //             picture: userCred.photoURL,
        //             refresh_token: userCred.stsTokenManager.refreshToken
        //         };
        //         // close();

        //         this.isLoading = false;

        //         this.router.navigate(['/loggedin'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });

        //         //this.router.navigate(['user-profile']);

        //     }).catch(_error => {
        //         this.isLoading = false;
        //         this.error = _error;
        //         console.log(this.error);

        //     });
    }
}
