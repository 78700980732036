import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QuickEditService {
    public ORIGIN = {
        MAP: 'map',
        DEEP: 'deep',
        NOTES: 'notes',
        OUTLINE: 'outline',
    };

    public origin = this.ORIGIN.MAP;

    public onQuickEditEnable = new EventEmitter<boolean>();
    public onSpeechEditEnable = new EventEmitter<boolean>();
    public onCanEdit = new EventEmitter<boolean>();
    private quickEditEnabled: boolean;
    private speechEditEnabled = false;
    private isMoving: boolean;
    public quickEditFromButtonInteraction = false;

    private INPUT_SOURCE = { NONE: 'none', QUICK: 'quick', SPEECH: 'speech' };
    private lastSource = this.INPUT_SOURCE.NONE;

    constructor() {
        this.quickEditEnabled = false;
        this.isMoving = false;
    }

    public disableAll() {
        if (this.isQuickEditEnabled()) {
            this.toggleQuickEdit();
        }
        if (this.isSpeechEditEnabled()) {
            this.toggleSpeechEdit(this.origin);
        }
    }

    public enableQuickEdit() {
        this.quickEditEnabled = true;
    }


    public toggleQuickEdit(): boolean {
        if (this.speechEditEnabled) { this.toggleSpeechEdit(this.origin); }
        this.quickEditEnabled = !this.quickEditEnabled;
        this.onQuickEditEnable.emit(this.quickEditEnabled);
        return this.quickEditEnabled;
    }

    public isQuickEditEnabled(): boolean {
        return this.quickEditEnabled;
    }

    public toggleSpeechEdit(origin: string): boolean {
        if (this.quickEditEnabled) { this.toggleQuickEdit(); }
        this.origin = origin;
        this.speechEditEnabled = !this.speechEditEnabled;
        this.onSpeechEditEnable.emit(this.speechEditEnabled);
        return this.speechEditEnabled;
    }

    public isSpeechEditEnabled(): boolean {
        return this.speechEditEnabled;
    }

    public nodeMoving(isMoving: boolean): void {
        if (isMoving && !this.isMoving && (this.quickEditEnabled || this.speechEditEnabled)) {
            this.isMoving = true;
            if (this.quickEditEnabled) {
                this.lastSource = this.INPUT_SOURCE.QUICK;
                this.toggleQuickEdit();
            } else if (this.speechEditEnabled) {
                this.lastSource = this.INPUT_SOURCE.SPEECH;
                this.toggleSpeechEdit(this.origin);
            } else {
                this.lastSource = this.INPUT_SOURCE.NONE;
            }
        } else if (!isMoving && this.isMoving) {
            if (this.lastSource === this.INPUT_SOURCE.QUICK) {
                this.toggleQuickEdit();
            } else if (this.lastSource === this.INPUT_SOURCE.SPEECH) {
                this.toggleSpeechEdit(this.origin);
            }
            this.isMoving = false;
        }
    }

}
