<!-- UNCOMPRESSED TOOLBAR-->
<!-- EDIT TOOLBAR -->
<div *ngIf="!editToolbarCompressed" class="edit-toolbar">
  <button
    mat-icon-button
    color="primary"
    [disabled]="!canUndo"
    (click)="undoMap()"
    matTooltip="{{ 'MAIN_TOOLBAR_UNDO' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_UNDO' | translate">undo</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="!canRedo"
    (click)="redoMap()"
    matTooltip="{{ 'MAIN_TOOLBAR_REDO' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_REDO' | translate">redo</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [ngClass]="{ 'tts-mode': ttsEnable }"
    (click)="TTSRead()"
    [disabled]="error"
    matTooltip="{{ 'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate" svgIcon="message-processing"></mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="error"
    (click)="centerMap()"
    matTooltip="{{ 'MAIN_TOOLBAR_ZOOM-FIT' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate"> settings_overscan</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="error"
    (click)="quickEdit()"
    [ngClass]="{ 'quickEdit-mode': quickEditEnabled }"
    matTooltip="{{ 'MAIN_TOOLBAR_QUICKEDIT' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_QUICKEDIT' | translate" fontSet="material-icons-outlined">rtt</mat-icon>
  </button>
  <button
    *ngIf="!deviceService.isMobileOrTabletDevice()"
    mat-icon-button
    color="primary"
    [disabled]="error"
    (click)="speechEdit()"
    [ngClass]="{ 'speechedit-mode': speechEditEnabled }"
    matTooltip="{{ 'MAIN_TOOLBAR_SPEECHEDIT' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_SPEECHEDIT' | translate">settings_voice</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="error"
    (click)="toggleSearchWeb()"
    [ngClass]="{ webSearchPanelVisible: webSearchPanelVisible }"
    matTooltip="{{ 'MAIN_TOOLBAR_SEARCHWEB' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_SEARCHWEB' | translate" fontSet="material-icons-outlined">
      travel_explore</mat-icon
    >
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="error"
    (click)="toggleFindInMap()"
    [ngClass]="{ 'active-find': mapSearchVisible }"
    matTooltip="{{ 'MAIN_TOOLBAR_FIND' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_FIND' | translate" svgIcon="file-find"></mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="error"
    (click)="shareMap()"
    matTooltip="{{ 'MAIN_MENU_FILE-SHARE' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_MENU_FILE-SHARE' | translate">share</mat-icon>
  </button>
  <button mat-icon-button color="primary" matTooltip="{{ showTooltip }}" [matMenuTriggerFor]="showMenu">
    <mat-icon color="primary" svgIcon="theater"></mat-icon>
  </button>
  <mat-menu #showMenu [overlapTrigger]="false">
    <button mat-menu-item color="primary" (click)="mapShowing()">
      <mat-icon color="primary" fontSet="material-icons-outlined">slideshow</mat-icon>
      <span translate>MAIN_TOOLBAR_STARTSHOW</span>
    </button>
    <button mat-menu-item color="primary" (click)="editShowing()">
      <mat-icon color="primary" svgIcon="circle-edit-outline"></mat-icon>
      <span translate>MAIN_TOOLBAR_EDITSHOW</span>
    </button>
  </mat-menu>
</div>

<!-- COMPRESSED TOOLBAR-->
<!-- EDIT TOOLBAR -->
<div *ngIf="editToolbarCompressed" class="edit-toolbar">
  <button
    mat-icon-button
    color="primary"
    [disabled]="!canUndo"
    (click)="undoMap()"
    matTooltip="{{ 'MAIN_TOOLBAR_UNDO' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_UNDO' | translate">undo</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="!canRedo"
    (click)="redoMap()"
    matTooltip="{{ 'MAIN_TOOLBAR_REDO' | translate }}"
  >
    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_REDO' | translate">redo</mat-icon>
  </button>
  <button mat-icon-button color="primary" [matMenuTriggerFor]="compressedEditToolbarMenu">
    <mat-icon color="primary" fontset="material-icons-outlined">more_vert</mat-icon>
  </button>
  <mat-menu #compressedEditToolbarMenu="matMenu">
    <!--<button mat-menu-item color="primary" [disabled]="!canUndo" (click)="undoMap()">
            <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_UNDO' | translate">undo</mat-icon>
            <span translate>MAIN_TOOLBAR_UNDO</span>
        </button>
        <button mat-menu-item color="primary" [disabled]="!canRedo" (click)="redoMap()">
            <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_REDO' | translate">redo</mat-icon>
            <span translate>MAIN_TOOLBAR_REDO</span>
        </button>-->
    <button mat-menu-item color="primary" [ngClass]="{ 'tts-mode': ttsEnable }" (click)="TTSRead()" [disabled]="error">
      <mat-icon
        color="primary"
        [attr.aria-label]="'MAIN_TOOLBAR_TEXT-TO-SPEECH' | translate"
        svgIcon="message-processing"
      ></mat-icon>
      <span translate>MAIN_TOOLBAR_TEXT-TO-SPEECH</span>
    </button>
    <button mat-menu-item color="primary" [disabled]="error" (click)="centerMap()">
      <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_ZOOM-FIT' | translate">settings_overscan</mat-icon>
      <span translate>MAIN_TOOLBAR_ZOOM-FIT</span>
    </button>
    <button
      mat-menu-item
      color="primary"
      [disabled]="error"
      (click)="quickEdit()"
      [ngClass]="{ 'quickEdit-mode': quickEditEnabled }"
    >
      <mat-icon
        color="primary"
        [attr.aria-label]="'MAIN_TOOLBAR_QUICKEDIT' | translate"
        fontSet="material-icons-outlined"
        >rtt</mat-icon
      >
      <span translate>MAIN_TOOLBAR_QUICKEDIT</span>
    </button>
    <button
      *ngIf="!deviceService.isMobileOrTabletDevice()"
      mat-menu-item
      color="primary"
      [disabled]="error"
      (click)="speechEdit()"
      [ngClass]="{ 'speechedit-mode': speechEditEnabled }"
    >
      <mat-icon color="primary" [attr.aria-label]="'MAIN_TOOLBAR_SPEECHEDIT' | translate">settings_voice</mat-icon>
      <span translate>MAIN_TOOLBAR_SPEECHEDIT</span>
    </button>
    <button
      mat-menu-item
      color="primary"
      [disabled]="error"
      (click)="toggleSearchWeb()"
      [ngClass]="{ webSearchPanelVisible: webSearchPanelVisible }"
    >
      <mat-icon
        color="primary"
        [attr.aria-label]="'MAIN_TOOLBAR_SEARCHWEB' | translate"
        fontSet="material-icons-outlined"
        >travel_explore</mat-icon
      >
      <span translate>MAIN_TOOLBAR_SEARCHWEB</span>
    </button>
    <button
      mat-menu-item
      color="primary"
      [disabled]="error"
      (click)="toggleFindInMap()"
      [ngClass]="{ 'active-find': mapSearchVisible }"
    >
      <mat-icon
        color="primary"
        [attr.aria-label]="'MAIN_TOOLBAR_FIND' | translate"
        class="material-icons-sharp"
        fontSet="material-icons-filled"
        >find_in_page</mat-icon
      >
      <span translate>MAIN_TOOLBAR_FIND</span>
    </button>
    <button mat-menu-item color="primary" [disabled]="error" (click)="shareMap()">
      <mat-icon color="primary" [attr.aria-label]="'MAIN_MENU_FILE-SHARE' | translate">share</mat-icon>
      <span translate>MAIN_MENU_FILE-SHARE</span>
    </button>
    <button
      mat-menu-item
      color="primary"
      matTooltip="{{ showTooltip }}"
      [matMenuTriggerFor]="showMenu"
      [disabled]="!canShowing"
    >
      <mat-icon color="primary" svgIcon="theater"></mat-icon>
      <span translate>SHOWING</span>
    </button>
  </mat-menu>
  <mat-menu #showMenu [overlapTrigger]="false">
    <button mat-menu-item color="primary" (click)="mapShowing()">
      <mat-icon color="primary" fontSet="material-icons-outlined">slideshow</mat-icon>
      <span translate>MAIN_TOOLBAR_STARTSHOW</span>
    </button>
    <button mat-menu-item color="primary" (click)="editShowing()">
      <mat-icon color="primary" svgIcon="circle-edit-outline"></mat-icon>
      <span translate>MAIN_TOOLBAR_EDITSHOW</span>
    </button>
  </mat-menu>
</div>
