<app-loader *ngIf="isLoading" [isLoading]="isLoading"></app-loader>

<!-- EMAIL REQUEST -->
<ng-container *ngIf="isEmailRequest">
    <app-email-request [demoFromStart]="demoFromStart" (requestPassword)="requestPassword($event)"></app-email-request>
</ng-container>

<!-- PASSWORD REQUEST -->
<ng-container *ngIf="isPasswordRequest">
    <app-password-request (submitPassword)="submitPassword($event)" (forgotPassword)="forgotPassword()"></app-password-request>
</ng-container>

<!-- FORGOTTEN PASSWORD-->
<ng-container *ngIf="isForgotPassword">
    <app-forgot-password [username]="username" (goBackClicked)="gotoPasswordRequest()"></app-forgot-password>
</ng-container>

<div class="error-login">
    <mat-error *ngIf="showingError">{{error}}</mat-error>
</div>
