import { VirtualKeyboardLayout } from 'mathlive';

export const IT_PRIMARY_KEYBOARD: VirtualKeyboardLayout = {
    id: "PRIMARY",
    labelClass: 'MLK__tex',
    rows: [
        // First row
        [
            {
                key: '(',
                label: '(',
                tooltip: 'aperta tonda'
            },
            {
                key: ')',
                label: ')',
                tooltip: 'chiusa tonda'
            },
            '[separator-5]',
            {
                key: '7',
                label: '7',
                tooltip: ''
            },
            {
                key: '8',
                label: '8',
                tooltip: ''
            },
            {
                key: '9',
                label: '9',
                tooltip: ''
            },
            {
                latex: ':',
                label: ':',
                tooltip: 'diviso'
            },
            '[separator-5]',
            {
                latex: '\\pi',
                tooltip: 'pigreco'
            },
            {
                latex: '\\degree',
                tooltip: 'gradi'
            },
            {
                latex: '\\%',
                tooltip: 'percento'
            },
        ],
        // Second row
        [
            {
                key: '[',
                label: '[',
                tooltip: 'aperta quadra'
            },
            {
                key: ']',
                label: ']',
                tooltip: 'chiusa quadra'
            },
            '[separator-5]',
            {
                key: '4',
                label: '4',
                tooltip: ''
            },
            {
                key: '5',
                label: '5',
                tooltip: ''
            },
            {
                key: '6',
                label: '6',
                tooltip: ''
            },
            {
                latex: '\\times',
                tooltip: 'per'
            },
            '[separator-5]',
            {
                class: 'small',
                latex: '\\frac{\\placeholder{}}{\\placeholder{}}',
                tooltip: 'frazione'
            },
            {
                latex: '#@^{#0}}',
                tooltip: 'elevamento a potenza'
            },
            {
                latex: '\\sqrt{#0}',
                tooltip: 'radice quadrata'
            },
        ],
        // Third row
        [
            {
                key: '{',
                label: '{',
                tooltip: 'aperta graffa'
            },
            {
                key: '}',
                label: '}',
                tooltip: 'chiusa graffa'
            },
            '[separator-5]',
            {
                key: '1',
                label: '1',
                tooltip: ''
            },
            {
                key: '2',
                label: '2',
                tooltip: ''
            },
            {
                key: '3',
                label: '3',
                tooltip: ''
            },
            {
                key: '-',
                label: '-',
                tooltip: 'meno'
            },
            '[separator-5]',
            {
                label: '<',
                latex: '<',
                tooltip: 'minore di'
            },
            {
                label: '>',
                latex: '>',
                tooltip: 'maggiore di'
            },
            {
                latex: '\\overline{\\placeholder{}}',
                tooltip: 'barra superiore'
            },
        ],
        // Fourth row
        [
            {
                latex: '\\pm',
                tooltip: 'più o meno'
            },
            {
                latex: '\\neq',
                tooltip: 'diverso da'
            },
            '[separator-5]',
            {
                key: '0',
                label: '0',
                tooltip: ''
            },
            {
                key: ',',
                label: ',',
                tooltip: 'virgola'
            },
            {
                key: '=',
                label: '=',
                tooltip: 'uguale'
            },
            {
                key: '+',
                label: '+',
                tooltip: 'più'
            },
            '[separator-5]',
            {
                label: '≤',
                latex: '\\le',
                tooltip: 'minore o uguale a'
            },
            {
                label: '≥',
                latex: '\\ge',
                tooltip: 'maggiore o uguale a'
            },
            {
                latex: "\\infty",
                tooltip: 'infinito'
            }
        ]
    ]
};
