import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AdminMoveMapsRoutingModule } from './admin-move-maps-routing.module';
import { AdminMoveMapsComponent } from './admin-move-maps.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormField, MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        FormsModule,
        CoreModule,
        ReactiveFormsModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatButtonModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        AdminMoveMapsRoutingModule,
    ],
    declarations: [
        AdminMoveMapsComponent
    ],
    providers: []
})
export class AdminMoveMapsModule { }
