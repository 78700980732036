<div class="notes-container">
    <div class="note-title-container">
        <div class="note-title-icon">
            <mat-icon class="material-icons-sharp" fontSet="material-icons-filled">task</mat-icon>
        </div>
        <div class="note-title-text">
            <input [hidden]="!canEdit" #titleAppuntiEdit matInput type="text" class="title-appunti" (focus)="enterEdit()" (blur)="exitEdit()" (change)="changeTitle($event)" placeholder="{{'TITLE_BLOCKNOTES' | translate}}" autocomplete="off" />
            <div [hidden]="canEdit" #titleAppuntiView class="title-appunti"></div>
        </div>
        <div class="note-close">
            <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" matTooltipPosition="above" (click)="closeExtra();">
                <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </div>
    </div>

    <div class="note-toolbar">
        <div class="note-toolbar-top">
            <div class="note-toolbar-top-left">
                <button class="green-color" *ngIf="!isTalking" mat-icon-button matTooltip="{{'PLAY' | translate}}" [attr.aria-label]="'PLAY' | translate" (click)="TTSRead()" matTooltipPosition="above">
                    <mat-icon class="extra-button">play_arrow</mat-icon>
                </button>
                <button class="red-color" *ngIf="isTalking" mat-icon-button matTooltip="{{'STOP' | translate}}" [attr.aria-label]="'STOP' | translate" (click)="TTSStop()" matTooltipPosition="above">
                    <mat-icon class="extra-button">stop</mat-icon>
                </button>
                <div class="note-toolbar-input-container">
                    <input class="note-toolbar-top-input" #speedInput type="number" min="0.5" max="1.5" step="0.1" (focus)="speedGotFocus()" (blur)="speedLostFocus()" value="{{rateValue}}" (change)="changeSpeechRate($event)" />
                </div>
                <div class="note-toolbar-language-container">
                    <button class="note-toolbar-lang-button" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}" matTooltipPosition="above">
                        <img color="primary" [src]="selectedLangIcon" [matMenuTriggerFor]="languageMenu" />
                    </button>
                    <mat-menu class="language-menu-container" #languageMenu="matMenu" color="primary" [overlapTrigger]="false">
                        <div class="language-menu-item-container" *ngFor="let l of languagesWithFlag" mat-menu-item (click)="changeLanguage($event)" [id]="l.value">
                            <img [src]="l.icon" class="language-icon" />
                            <span translate>{{l.viewValue}}</span>
                        </div>
                        <ng-container *ngIf="languagesNoFlag.length > 0">
                            <mat-divider></mat-divider>
                            <button class="language-menu-item-container" mat-menu-item [matMenuTriggerFor]="languageNoFlagMenu">{{'OTHER_VOICES' | translate}}</button>
                            <mat-menu class="language-menu-container" #languageNoFlagMenu="matMenu" color="primary">
                                <div *ngFor="let l of languagesNoFlag" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
                                    <img [src]="l.icon" mat-icon class="language-icon" />
                                    <span translate>{{l.viewValue}}</span>
                                </div>
                            </mat-menu>
                        </ng-container>
                    </mat-menu>
                </div>
                <button *ngIf="canEdit && editVisible && !deviceService.isMobileOrTabletDevice()" mat-icon-button color="primary" (click)="speechEdit()" [ngClass]="{'speechedit-mode': speechEditEnabled}" matTooltip="{{'MAIN_TOOLBAR_SPEECHEDIT' | translate}}" matTooltipPosition="above">
                    <mat-icon [attr.aria-label]="'MAIN_TOOLBAR_SPEECHEDIT' | translate">settings_voice</mat-icon>
                </button>
            </div>

            <div class="note-toolbar-top-right">
                <button *ngIf="isLab && !deviceService.isMobileOrTabletDevice()" [ngClass]="{'capture-mode':capturingScreen, 'disabled':!canCaptureMath}" [disabled]="!canCaptureMath" mat-icon-button color="primary" matTooltip="{{'CAPTURE_MATH' | translate}}" [attr.aria-label]="'CAPTURE_MATH' | translate" (click)="CaptureMathOCR()" matTooltipPosition="above">
                    <img [src]="mathOCR_icon" class="language-con" />
                </button>
                <button [disabled]="!isTextSelected" mat-icon-button color="primary" matTooltip="{{'INSERT_TEXT' | translate}}" [attr.aria-label]="'INSERT_TEXT' | translate" (click)="InsertText()" matTooltipPosition="above">
                    <mat-icon class="extra-button" fontSet="material-icons-outlined">playlist_add</mat-icon>
                </button>
                <button *ngIf="!deviceService.isMobileOrTabletDevice()" mat-icon-button color="primary" matTooltip="{{'CAPTURE' | translate}}" [attr.aria-label]="'CAPTURE' | translate" (click)="Capture()" [ngClass]="{'capture-mode': capturingScreen}" matTooltipPosition="above">
                    <mat-icon class="extra-button">photo_size_select_large</mat-icon>
                </button>
                <button [disabled]="!isTextSelected" mat-icon-button color="primary" matTooltip="{{'INSERT_DEEP' | translate}}" [attr.aria-label]="'INSERT_DEEP' | translate" (click)="InsertDeep()" matTooltipPosition="above">
                    <mat-icon class="custom-icon" svgIcon="add-deep"></mat-icon>
                </button>
            </div>
        </div>
        <div class="note-toolbar-bottom">
            <div class="capture-info" [hidden]="!capturingScreen">
                <span class="capture-info-text" translate>
                    CAPTURE_INFO
                </span>
            </div>
            <div *ngIf="canEdit" class="speechnotes-container" [hidden]="!speechEditEnabled">
                <mat-icon>mic</mat-icon>
                <span #speechInput> </span>
            </div>
            <div *ngIf="!canEdit" class="notes-readonly-container">
                <div class="notes-readonly-filler"></div>
                <div class="notes-readonly">{{readOnlyMessage}}</div>
            </div>
        </div>
    </div>

    <div class="note-editor">
        <div class="overlay" #over>
            <div #screen>
                <div class="appunti-content">
                    <div *ngIf="capturingScreen" class="capture-over" [ngStyle]="{'clip-path': 'polygon(0% 0%, '+percCropX+'% 0%, '+percCropX+'% '+percCropY2+'%, '+percCropX2+'% '+percCropY2+'%, '+percCropX2+'% '+percCropY+'%,'+percCropX+'% '+percCropY+'%,'+percCropX+'% 0%,100% 0%, 100% 100%, 0% 100%)'}"></div>
                    <div id="appuntieditor-container" class="appuntieditor-container" [ngClass]="{'disable-pointer-events': capturingScreen}" contenteditable="true" [innerHTML]="htmlSafe" (focus)="enterEdit()" (blur)="exitEdit()"> </div>
                </div>
            </div>
        </div>
        <div class="rectangle" #rect></div>
    </div>

</div>
