import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { I18nService } from '../core/i18n.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeviceService } from '../core/device.service';
import { IframeLoginService } from '../core/authentication/iframe-login-service';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { SmeService } from '../core/sme/sme.service';
import { LoginFormComponent } from '../login-form/login-form.component';
import { MatDialog } from '@angular/material/dialog';
import { DemoComponent } from '../demo/demo.component';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    private routes = {
        apiloginGoogle: '/api/logingoogle2gen',
        apiloggein: '/api/loggedin2gen'
    };


    version: string = environment.version;
    paramMapSubscription: Subscription | undefined;
    loginSubscription: Subscription | undefined;
    error = '';
    isLoading = false;
    redirect = '';
    showingError = false;
    loginErrorMessage = '';
    clickableMsg = false;
    loginUri = '';
    googleSignInAvailable = true;
    noGoogleLoginContent = '';
    // GLCC = Google Login Check Count
    nGLCC = 0;
    MAX_GLCC = 10;
    gRendered = false;


    // GLCC = Google Login Check Count
    // nGLCC = 0;
    // MAX_GLCC = 10;
    // gRendered = false;
    public isMobile: boolean;

    APP_ID = environment.configFirebase.messagingSenderId;
    CLIENT_ID = environment.firebaseClientId;

    public user = {
        id: '',
        uid: '',
        email: '',
        displayName: '',
        picture: '',
        refreshToken: '',
        token: ''
    };

    isError = false;
    errorMessage = '';


    username = '';

    public expired = false;
    public id_token = '';
    public client_id = '';
    public doubleAccess = false;
    public askPrivacy = false;
    public isUserAdmin = false;
    public showDemo = false;
    public invalidCredentials = false;
    public eligibleForDemo = false;
    public isDemo = false;
    public logoUrlExtended = '';
    public demoFromStart = false;
    public scopeToAccept: any;
    logoutSubscription: Subscription | undefined;
    //checkAndUnlockAdminOrdersSubscription: Subscription | undefined;
    setUserInfoSubscription: Subscription | undefined;
    hasUserAcceptedPrivacySubscription: Subscription | undefined;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private deviceService: DeviceService,
        public dialog: MatDialog,
        private iframeLoginService: IframeLoginService) {
        this.isMobile = deviceService.isMobileOrTabletDevice();
        this.logoUrlExtended = ('../assets/login/supermappex.png');
    }

    ngOnInit() {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            localStorage.removeItem('demoFromStart');
            localStorage.removeItem('redirectPage');
            localStorage.removeItem('redirectParams');
            localStorage.removeItem('redirectQueryParams');
            localStorage.setItem('demoFromStart', false.toString());
            const par = JSON.stringify(params);
            // this.username = '';
            if (this.route.snapshot.queryParams['username']) {
                this.username = this.route.snapshot.queryParams['username'];

            }


            const redirect = params['redirect'];
            this.loginUri = window.origin + this.routes.apiloginGoogle; //'/api/logingoogle2gen/';


            let page = redirect.substr(0, redirect.indexOf('?'));
            let state = '';
            if (page === '') {
                const par = redirect.split('/');
                if (par[0] === '') {
                    par.splice(0, 1);
                }
                page = par[0];
                par.splice(0, 1);

                let i = 0;
                for (const p of par) {
                    if (i === 0) {
                        state += p;
                    } else {
                        state += ',' + p;
                    }
                    i++;
                }
                if (state !== '') { localStorage.setItem('redirectParams', state); }
            } else {

                const param = redirect.substr(redirect.indexOf('?') + 1);
                if (param) {
                    localStorage.setItem('redirectQueryParams', param);
                }
            }
            if (page) {
                localStorage.setItem('redirectPage', page);
            }

            // // @ts-ignore
            // this.checkGoogleLogin();
            // // @ts-ignore
            // google.accounts.id.prompt((notification: PromptMomentNotification) => { });

            if (this.iframeLoginService.isIframeLogging()) {
                /* La finestra si è aperta su richiesta di un altra finestra che è dentro un iframe:
                 * deve partire subito il login di Google.
                 */
                this.login();
            }



            if (this.username !== '' && this.username !== undefined) {
                this.openLogin();

            }


        });
    }

    ngOnDestroy() {
        if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
    }


    openLogin() {

        const dialogRef = this.dialog.open(LoginFormComponent, {
            disableClose: true,
            autoFocus: false,
            //width: '350px'
        });

        if (this.username !== '' && this.username !== undefined) {
            dialogRef.componentInstance.username = this.username;
            dialogRef.componentInstance.requestPassword((this.username));
            dialogRef.componentInstance.demoFromStart = true;
        } else {
            dialogRef.componentInstance.demoFromStart = false;
        }




    }

    requestDemo() {
        localStorage.setItem('demoFromStart', true.toString());
        // const dialogRef = this.dialog.open(DemoComponent, {
        //     disableClose: false
        // });
        // dialogRef.componentInstance.showSchool = true;

        this.router.navigate(['demo'], { fragment: 'school=1' });



    }

    // checkGoogleLogin(): void {
    //     console.log('*GLCC* #' + this.nGLCC);
    //     if (!this.gRendered && this.nGLCC < this.MAX_GLCC) {
    //         if ((window as any).google) {
    //             google.accounts.id.initialize({
    //                 client_id: environment.firebaseClientId,
    //                 login_uri: this.loginUri,
    //                 auto_select: true,
    //                 context: 'signin',
    //                 ux_mode: 'redirect',
    //                 cancel_on_tap_outside: true,
    //             });
    //             // @ts-ignore
    //             google.accounts.id.renderButton(
    //                 // @ts-ignore
    //                 document.getElementById('google-button'),
    //                 { type: 'standard', theme: 'outline', size: 'large', width: 200 }
    //             );
    //             this.gRendered = true;
    //             console.log('GL button rendered');
    //         } else {
    //             // alert('Google is undefined!!!'); Mettere qui una segnalazione che non è possibile fare LOGIN
    //             // this.googleSignInAvailable = false;
    //             setTimeout(() => {
    //                 this.nGLCC++;
    //                 this.checkGoogleLogin();
    //             }, 100);
    //         }
    //     } else {
    //         if (!this.gRendered) {
    //             this.googleSignInAvailable = false;
    //             console.log('GL not loaded');
    //         }
    //     }
    // }

    loginClick() {
        this.isError = false;
        this.logoutSubscription = this.authenticationService.logout().subscribe(() => {
            // const redirectPage = (this.expired || this.doubleAccess ? '' : localStorage.getItem('redirectPage'));
            // this.router.navigate(['login', redirectPage]);
        });
    }

    // createAccount(event: any) {
    //     this.router.navigate(['user-profile', '']);
    //     // this.isLoading = true;
    //     // this.showingError = false;
    //     // console.log(`Login...`);
    //     // if (navigator.onLine) {
    //     //     console.log(`ONLINE`);

    //     //     this.firebaseAuthService.createUser(event.username, event.password).then((userCred: any) => {
    //     //         alert('Account created!');
    //     //         this.client_id = environment.firebaseClientId;
    //     //         this.user = {
    //     //             id: userCred.uid,
    //     //             uid: userCred.uid,
    //     //             email: userCred.email,
    //     //             displayName: userCred.displayName ? userCred.displayName : '',
    //     //             picture: userCred.photoURL,
    //     //             refreshToken: userCred.stsTokenManager.refreshToken,
    //     //             token: userCred.stsTokenManager.accessToken
    //     //         };
    //     //         const UserInfo = {

    //     //             state: 'firebase',
    //     //             access_token: userCred.stsTokenManager.accessToken,
    //     //             email: userCred.email,
    //     //             fullName: userCred.displayName ? userCred.displayName : '',
    //     //             id: userCred.uid,
    //     //             id_token: userCred.stsTokenManager.idToken,
    //     //             picture: userCred.photoURL,
    //     //             refresh_token: userCred.stsTokenManager.refreshToken
    //     //         };


    //     //         this.router.navigate(['/loggedin'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });
    //     //         //this.loginStep1();
    //     //     });
    //     // } else {
    //     //     console.error(`ERROR: OFFLINE`);
    //     //     this.showError('OFFLINE_LOGIN');
    //     // }
    // }

    // loginNoGoogle(event: any): void {
    //     this.isLoading = true;
    //     this.showingError = false;
    //     console.log(`Login...`);
    //     if (navigator.onLine) {
    //         console.log(`ONLINE`);
    //         //this.firebaseAuthService.createUser(event.username, event.password).then((userCred: any) => {
    //         this.firebaseAuthService.signIn(event.username, event.password).then((userCred: any) => {
    //             this.client_id = environment.firebaseClientId;
    //             this.user = {
    //                 id: userCred.uid,
    //                 uid: userCred.uid,
    //                 email: userCred.email,
    //                 displayName: userCred.displayName ? userCred.displayName : '',
    //                 picture: userCred.photoURL,
    //                 refreshToken: userCred.stsTokenManager.refreshToken,
    //                 token: userCred.stsTokenManager.accessToken
    //             };
    //             const UserInfo = {

    //                 state: 'password',
    //                 access_token: userCred.stsTokenManager.accessToken,
    //                 email: userCred.email,
    //                 fullName: userCred.displayName ? userCred.displayName : '',
    //                 id: userCred.uid,
    //                 id_token: userCred.stsTokenManager.idToken,
    //                 picture: userCred.photoURL,
    //                 refresh_token: userCred.stsTokenManager.refreshToken
    //             };
    //             this.router.navigate(['/loggedin'], { queryParams: { userInfo: JSON.stringify(UserInfo) } });







    //         }).catch(error => {
    //             this.showErrorNoGoogle(error);
    //         })
    //     } else {
    //         console.error(`ERROR: OFFLINE`);
    //         this.showError('OFFLINE_LOGIN');
    //     }
    // }




    login(): void {
        this.isLoading = true;
        this.showingError = false;
        console.log(`Login...`);
        if (this.deviceService.isInIframe()) {
            console.log(`IN IFRAME`);
            this.iframeLoginService.loginOnNewTab().then(() => {
                // L'altro tab è stato chiuso: proviamo a ricaricare la pagina: in teoria ora dovremmo essere loggati.
                // window.location.href = '/home';
                console.log(`Redirect to request page...`);
                this.authenticationService.redirectToRequestPage(true);
            });
        } else if (navigator.onLine) {
            console.log(`ONLINE`);
        } else {
            console.error(`ERROR: OFFLINE`);
            this.showError('OFFLINE_LOGIN');
        }
    }

    // private showErrorNoGoogle(message: string) {
    //     this.loginErrorMessage = message;
    //     this.clickableMsg = true;
    //     this.showingError = true;
    //     this.isLoading = false;
    // }

    private showError(errorCode: string) {
        console.log(`ERRORCODE: ${errorCode}`);
        if (errorCode) {
            this.loginErrorMessage = this.translateService.instant(errorCode);
            this.clickableMsg = false;
        } else {
            this.loginErrorMessage = this.translateService.instant('LOGIN_COOKIES_URL');
            this.clickableMsg = true;
        }
        this.showingError = true;
        this.isLoading = false;
    }

    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }
}
