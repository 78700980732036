import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { Logger } from '../../core/logger.service';
import { SpeechRecognitionResult } from './speech-recognition-result';

interface IWindow extends Window {
    webkitSpeechRecognition: any;
    SpeechRecognition: any;
}

const logger: Logger = new Logger('SpeechRecognitionService');

@Injectable({
    providedIn: 'root'
})
export class SpeechRecognitionService {
    speechRecognition: any;
    constructor(private zone: NgZone) {
        this.speechRecognition = null;
    }

    record(language = 'it-IT'): Observable<SpeechRecognitionResult> {

        this.DestroySpeechObject();

        return new Observable((observer: any) => {
            const { webkitSpeechRecognition }: IWindow = <IWindow>(window as Window);
            this.speechRecognition = new webkitSpeechRecognition();

            // this.speechRecognition = SpeechRecognition;
            this.speechRecognition.continuous = true;
            this.speechRecognition.interimResults = true;
            this.speechRecognition.lang = language;
            this.speechRecognition.maxAlternatives = 1;

            this.speechRecognition.onresult = (speech: any) => {
                // let term = '';
                if (speech.results) {
                    const result = speech.results[speech.resultIndex];

                    const srr = new SpeechRecognitionResult(result.isFinal, speech);

                    //if (result.isFinal) {
                    if (result[0].confidence < 0.3)
                        logger.error('SpeechRecognition: Unrecognized result with confidence ' + result[0].confidence + ' - transcript: ' + srr.transcript);
                    //}

                    this.zone.run(() => {
                        observer.next(srr);
                    });
                }
            };

            this.speechRecognition.onerror = (error: any) => {
                console.log("SpeechRecognition error");
                observer.error(error);
            };

            this.speechRecognition.onend = () => {
                console.log("SpeechRecognition end");
                observer.complete();
            };

            this.speechRecognition.start();
            logger.info('SpeechRecognition: Say something - We are listening !!!');
        });
    }

    DestroySpeechObject() {
        if (this.speechRecognition) {
            this.speechRecognition.stop();
            this.speechRecognition = null;
        }
    }
}
