import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../material.module';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { AdminStatsComponent } from './admin-stats.component';
import { AdminStatsRoutingModule } from './admin-stats.routing.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        CoreModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        AdminStatsRoutingModule,
        FormsModule
    ],
    declarations: [
        AdminStatsComponent
    ],
    providers: [
    ]
})
export class AdminStatsModule { }
