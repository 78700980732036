<div class="demo-request mat-typography">
    <div class="smx-logo-extended brand" routerLink="/home" translate>
        <img class="smx-logo-image-extended brand-logo" src="{{logoUrlExtended}}?v=1" />
    </div>
    <div [ngClass]="compressed ? (showSchool ? 'content-container':'compressed-container') : ((showSchool ? 'content-container':''))">
        <div class="left-content" *ngIf="showSchool">
            <button color="warn" mat-flat-button (click)="goToWebsiteClick('invalid-credentials','school')" type="submit">{{'IC_BTN_WEBSITE_SCHOOL' | translate}}</button>
            <button color="warn" mat-flat-button (click)=" goToWebsiteClick('invalid-credentials','private')" type="submit">{{'IC_BTN_WEBSITE_PRIVATE' | translate}}</button>
            <div class="info-text info-text-top-spacer" translate>IC_WEBSITE</div>
        </div>
        <div [ngClass]="(!showSchool ? 'content-text':'right-content')">


            <div class="info-text info-text-bold info-text-medium" translate>IC_REQUEST_DEMO_1</div>

            <app-email-demo-request></app-email-demo-request>
            <div class="info-text" translate>IC_REQUEST_DEMO_2</div>
            <div class="info-text" translate>IC_USER_NAME_EMAIL</div>

        </div>
    </div>
    <div class="footer">
        <!-- <div class="footer-text" translate>IC_LOGOUT</div> -->
        <div *ngIf="showSchool" class="footer-text-link" (click)="logoutClick()" translate>IC_LOGOUT_BUTTON</div>
        <div *ngIf="!showSchool" class="footer-text-link" (click)="logoutClick()" translate>IC_LOGIN_BUTTON</div>
    </div>
</div>
