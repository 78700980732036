import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Route } from '../core/route.service';
import { extract } from '../core/i18n.service';
import { NobrowserComponent } from './nobrowser.component';

const routes: Routes = [
    { path: 'nobrowser', component: NobrowserComponent, data: { title: extract('NOBROWSER_TITLE') } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class NobrowserRoutingModule { }
