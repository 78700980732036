import { Injectable, OnDestroy, EventEmitter, HostListener } from '@angular/core';
import { SmService } from 'supermappe-core';
import { SmEventBroker } from '../sm-event-broker';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MapFindService implements OnDestroy {

    public findInMapVisible = false;
    public openFindInMap = new EventEmitter<boolean>();
    nodeFoundChangedDataSubscription: Subscription | undefined;
    public foundOccurences: string[] = new Array<string>();
    idxOccurrence = -1;




    constructor(private smService: SmService, private smEventBroker: SmEventBroker) {

        if (this.smEventBroker.nodeFoundChangedData) {
            this.nodeFoundChangedDataSubscription = this.smEventBroker.nodeFoundChangedData.subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.foundOccurences = data;
                    this.idxOccurrence = 0;
                    this.smService.centerEntityInView(data[0], false, true, true);
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.nodeFoundChangedDataSubscription) {
            this.nodeFoundChangedDataSubscription.unsubscribe();
        }
    }

    createWordList(txtToSearch: string) {
        const wordList = new Array<string>();
        let foundApexes: boolean;
        foundApexes = false;
        let txtResult: string;
        txtResult = '';
        let txtApexes: string;
        txtApexes = '';
        txtToSearch.split('').forEach(letter => {
            if (letter === '"') {
                if (foundApexes && txtApexes.trim() !== '') {
                    wordList.push(txtApexes);
                    txtApexes = '';
                }
                foundApexes = !foundApexes;

            } else {
                if (!foundApexes) {
                    txtResult += letter;
                } else {
                    txtApexes += letter;
                }
            }
        });
        const resultList = txtResult.split(' ');
        wordList.forEach(element => {
            resultList.push(element);
        });

        return resultList;
    }

    openFindText() {
        this.findInMapVisible = true;
        this.openFindInMap.emit(this.findInMapVisible);
    }

    closeFindText() {
        this.findInMapVisible = false;
        this.openFindInMap.emit(false);
        this.smService.clearFindText();
    }

    toggleFindText() {
        this.findInMapVisible = !this.findInMapVisible;
        if (this.findInMapVisible) {
            this.openFindText();
        } else {
            this.closeFindText();
        }
    }

    findText(txt: string) {
        const wordList = txt.split(' ');
        this.smService.findText(wordList);
    }

    findPrevious() {
        if (this.idxOccurrence > 0) {
            this.idxOccurrence--;
        } else {
            this.idxOccurrence = this.foundOccurences.length - 1;
        }
        this.smService.centerEntityInView(this.foundOccurences[this.idxOccurrence], false, true, true);

        return (this.idxOccurrence > 0);
    }

    findNext() {
        if (this.idxOccurrence < this.foundOccurences.length - 1) {
            this.idxOccurrence++;
        } else {
            this.idxOccurrence = 0;
        }
        this.smService.centerEntityInView(this.foundOccurences[this.idxOccurrence], false, true, true);
        return (this.idxOccurrence < this.foundOccurences.length);
    }

    clearSearch() {
        this.smService.clearFindText();

        this.foundOccurences = [];
        this.idxOccurrence = 0;
    }

}
