import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FinishSignUpComponent } from './finish-sign-up.component';
import { extract } from 'src/app/core/i18n.service';




const routes: Routes = [
    { path: 'finish-sign-up/:email/:demo', component: FinishSignUpComponent, data: { title: extract('LOGIN_TITLE') } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class FinishSignUpRoutingModule { }


