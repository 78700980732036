import { Injectable } from '@angular/core';

import { FacebookService } from 'ngx-facebook';
import { SmService } from 'supermappe-core';
import { Logger } from '../../core/logger.service';
import { SmeService } from '../../core/sme/sme.service';
import { ShareLinkService } from './share-link.service';

const logger: Logger = new Logger('FbService');

declare const FB: any;

@Injectable({
    providedIn: 'root'
})
export class FbService {

    private mapId = '';
    private imageUrl = '';

    constructor(
        private fb: FacebookService,
        private smService: SmService,
        private smeService: SmeService) {
        this.initialize();
    }

    protected initialize(): void {
        console.log('Initializing Facebook');
        const params = {
            appId: '131948730714150', // SuperMappeX (ex Supermappe Viewer)
            xfbml: true,
            version: 'v21.0'// OLD VALUE: 'v2.12'
        };
        this.fb.init(params)
            .then(() => {
                console.log('Facebook initialized ok!');
            })
            .catch((err) => {
                console.log('ERROR initializing Facebook ');
            });
    }

    public initShareMap(mapId: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.mapId = mapId;
            console.log('Getting map image URL...');
            this.smeService.resolveWorkUrl(mapId, 'map.jpg')
                .then((imageUrl: any) => {
                    this.imageUrl = imageUrl;
                    console.log(`Facebook image URL: ${imageUrl}`);
                    return resolve(true);
                }).catch(() => {
                    console.log('Non riesco a caricare l\'immagine della mappa. Vado comunque avanti');
                    return reject();
                });
        });
    }

    public shareMap(proxiedStaticMapUrl: string) {
        console.log('share on facebook: get map info');
        // Get page url
        // const mapUrl = this.shareLinkService.getLinkToShare(this.mapId);
        console.log('Share proxied static map at url: ' + proxiedStaticMapUrl);
        // Get map info
        const mapName =
            (this.smService.sm.map &&
                this.smService.sm.map.mapProps &&
                this.smService.sm.map.mapProps.mapName ? this.smService.sm.map.mapProps.mapName : '').replace('.sme', '');
        console.log('mapName: ' + mapName);
        const author =
            (this.smService.sm.map &&
                this.smService.sm.map.mapProps &&
                this.smService.sm.map.mapProps.author ? this.smService.sm.map.mapProps.author : '');
        console.log('author: ' + author);
        // const img = (<any>window).location.origin + this.imageUrl;
        const img = this.imageUrl;
        console.log('share on facebook: share ' + img);
        console.log('og:url=' + proxiedStaticMapUrl);
        console.log('og:title=' + mapName);
        console.log('og:site_name=' + 'SuperMappeX');
        console.log('og:description=' + author);
        console.log('og:image=' + img);
        FB.ui({
            method: 'share_open_graph',
            action_type: 'og.shares',
            action_properties: JSON.stringify({
                object: {
                    'og:url': proxiedStaticMapUrl,
                    'og:title': mapName,
                    'og:site_name': 'SuperMappeX',
                    'og:description': author,
                    'og:image': img
                }
            })
        }, (response: any) => {
            console.log('FB response');
        });
    }

}
