export class UserDto {
    public eMail: string;
    public name: string;
    public notes: string;

    constructor(user: any) {
        this.eMail = '';
        this.name = '';
        this.notes = '';

        if (user) {
            if (user.eMail) {
                this.eMail = user.eMail;
            }
            if (user.name) {
                this.name = user.name;
            }
            if (user.notes) {
                this.notes = user.notes;
            }
        }
    }
}
