import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SmService } from 'supermappe-core';
import { CustomizeToolbarService } from '../customize-toolbar.service';
import { CustomToolbarDto } from '../custom-toolbar-dto';
import { Subscription, Observable, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../../../core/i18n.service';
import { MapStateService } from '../../../../shared/map-state.service';
import { NewTableService } from '../../../new-table/new-table.service';
import { UiConstants } from '../../../../shared/ui-constants';
import { SmEventBroker } from '../../../../shared/sm-event-broker';
import { ConfirmationService } from '../../../../shared/dialog/confirmation.service';
import { ArrangeService } from 'src/app/shared/commands/arrange.service';

@Component({
    selector: 'app-generic-toolbar',
    templateUrl: './generic-toolbar.component.html',
    styleUrls: ['./generic-toolbar.component.scss']
})
export class GenericToolbarComponent implements OnInit, AfterViewInit, OnDestroy {

    public totButtonVisible = 1;
    public error = false;

    public canPaste = false;
    public selectedBackgroundColor = '#FFFFFF';
    usedColorLabel = '';
    backgroundTooltip = '';
    backgroundIcon = 'tavolozza';

    colors = UiConstants.PALETTE.BACKGROUND;

    public isGridVisible = this.mapStateService.isGridVisible;

    changedBackgroundcolorSubscription: Subscription | undefined;
    onChangeToolbarVisibilitySubscription: Subscription | undefined;
    errorSubscription: Subscription | undefined;
    newTableServiceSubscription: Subscription | undefined;
    openDialogSubscription: Subscription | undefined;
    onGridChangeSubscription: Subscription | undefined;

    constructor(private smService: SmService,
        private customizeToobarService: CustomizeToolbarService,
        private translateService: TranslateService,
        private mapStateService: MapStateService,
        private newTableService: NewTableService,
        private confirmationService: ConfirmationService,
        private smEventBroker: SmEventBroker,
        private arrangeService: ArrangeService
    ) {
        this.backgroundTooltip = this.translateService.instant(extract('GENERIC_TOOLBAR-BACKGROUND-COLOR'));
        this.usedColorLabel = this.translateService.instant(extract('FORMAT_NODE_TOOLBAR-MYCOLOR'));
    }

    ngOnInit() {
        this.onChangeToolbarVisibilitySubscription =
            this.customizeToobarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
                this.changeSelection(_customData);
            });
        this.errorSubscription = this.mapStateService.onError.subscribe((error: any) => {
            this.error = (error !== '');
        });
        this.mapStateService.onGridChange.subscribe((value: boolean) => {
            this.isGridVisible = value;
        });
    }


    ngAfterViewInit() {
        if (this.smEventBroker && this.smEventBroker.backgroundChangedData) {
            this.changedBackgroundcolorSubscription = this.smEventBroker.backgroundChangedData.subscribe((color: any) => {
                setTimeout(() => {
                    this.selectedBackgroundColor = color;
                }, 100);
            });
        }
    }

    ngOnDestroy() {
        if (this.onChangeToolbarVisibilitySubscription) { this.onChangeToolbarVisibilitySubscription.unsubscribe(); }
        if (this.errorSubscription) { this.errorSubscription.unsubscribe(); }
        if (this.newTableServiceSubscription) { this.newTableServiceSubscription.unsubscribe(); }
        if (this.openDialogSubscription) { this.openDialogSubscription.unsubscribe(); }
        if (this.changedBackgroundcolorSubscription) { this.changedBackgroundcolorSubscription.unsubscribe(); }
        if (this.onGridChangeSubscription) { this.onGridChangeSubscription.unsubscribe(); }
    }

    changeBackgroundColor(e: any) {
        if (e !== '') {
            this.selectedBackgroundColor = e;
            this.mapStateService.setBackgroundColor(e);
        }
    }

    changeSelection(_selectionData: any): any {
        this.customizeToobarService.genericButtonVisible = this.totButtonVisible;
        this.customizeToobarService.updateTotButton();

    }

    addNode() {
        this.smService.addNodeAndSelectIfNoSelection('');
    }

    openDialog() {
        return new Observable((observer: Observer<any>) => {
            // UnsavedChangesDialog defined somewhere else and imported above
            if (this.newTableServiceSubscription) { this.newTableServiceSubscription.unsubscribe(); }
            this.newTableServiceSubscription = this.newTableService.askColRow(this.translateService.instant(extract('GENERIC_TOOLBAR_INSERT_NEW_TABLE')))
                .subscribe({
                    next: result => {
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error) => {
                        observer.next(false);
                        observer.complete();
                    }
                });
        });

    }

    addTable() {
        this.mapStateService.setCanEnableQuickEdit(false);
        if (this.openDialogSubscription) { this.openDialogSubscription.unsubscribe(); }
        this.openDialogSubscription = this.openDialog().subscribe((res: any) => {
            if (res) {
                this.smService.addNewTable(res.col, res.row);
            }
            this.mapStateService.setCanEnableQuickEdit(true);
        });
    }

    setGrid() {
        this.mapStateService.isGridVisible = !this.mapStateService.isGridVisible;
        this.mapStateService.onGridChange.emit(this.mapStateService.isGridVisible);
    }

    arrangeMap() {
        this.arrangeService.arrangeMap();
    }

}
