import { Component, OnInit, ViewChild, Input, AfterViewInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { SmEventBroker } from '../sm-event-broker';
import { LinksMenuData, LinkData } from '../links-menu-data';
import { Logger } from '../../core/logger.service';
import { Point } from '../point';
import { Subscription } from 'rxjs';

const logger: Logger = new Logger('LinksMenuComponent');

@Component({
  selector: 'app-links-menu',
  templateUrl: './links-menu.component.html',
  styleUrls: ['./links-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LinksMenuComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger)
  private menuTrigger: MatMenuTrigger | undefined;
  @ViewChild('menuButton')
  private menuButton: MatButton | undefined;
  @Input() items: Array<LinkData> = [];
  private dataSubscription: Subscription | undefined;

  private menuPosition: Point = new Point(0, 0);

  constructor(private smEventBroker: SmEventBroker) {
  }

  ngAfterViewInit(): void {
    if (this.menuButton && this.smEventBroker && this.smEventBroker.ctxMenuItems) {
      this.dataSubscription = this.smEventBroker.ctxMenuItems.subscribe((ctxMenuData: LinksMenuData) => {
        if (ctxMenuData && ctxMenuData.items.length > 0) {
          this.items = ctxMenuData.items || [];
          this.menuPosition = new Point(ctxMenuData.x, ctxMenuData.y);
          this.show();
        }
      });
    } else {
      logger.error('ctxMenu init: menu not defined!');
    }
    this.show();
  }

  ngOnDestroy(): void {
    logger.debug('linksmenu ngOnDestroy()');
    if (this.items) {
      this.items = [];
    }
    if (this.dataSubscription) { this.dataSubscription.unsubscribe(); }
  }


  private show() {
    if (this.menuButton && this.menuTrigger) {
      this.menuButton._elementRef.nativeElement.style.position = 'absolute';
      this.menuButton._elementRef.nativeElement.style.left = this.menuPosition.x + 'px';
      this.menuButton._elementRef.nativeElement.style.top = this.menuPosition.y + 'px';
      this.menuButton._elementRef.nativeElement.style.display = 'block';
      if (this.items && this.items.length > 0) {
        this.menuTrigger.openMenu();
      }
    }
  }

  public onMenuClosed(): void {
    if (this.menuButton) {
      this.menuButton._elementRef.nativeElement.style.display = 'none';
    } else {
      logger.error('ctxMenu close: menu not defined!');
    }
  }

  itemClicked(item: any): void {
    if (item.type === 'WWW') {
      const uri = decodeURIComponent(item.uri).replace(/\&amp;/g, '\&');
      (<any>window).open(uri);
    }
  }
}
