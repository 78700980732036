import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { GdriveNewRoutingModule } from './gdrive-new-routing.module';
import { GdriveNewComponent } from './gdrive-new.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        //FlexLayoutModule,
        MaterialModule,
        SharedModule,
        GdriveNewRoutingModule
    ],
    declarations: [
        GdriveNewComponent
    ]
})
export class GdriveNewModule { }
