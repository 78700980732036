import { Component, ElementRef, EventEmitter, Host, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PdfService } from '../pdf.service';
import { CdkTreeNodeOutletContext } from '@angular/cdk/tree';
import { MapStateService } from 'src/app/shared/map-state.service';
import { PdfToolsPrefsDto } from './pdf-tools-prefs-dto';
import { TranslateService } from '@ngx-translate/core';


const win: any = window
const createPDFViewer = win.createPDFViewer;
const openNewPDF = win.openNewPDF;
const getPDFBlobData = win.getPDFBlobData;
const outputAnnotations = win.outputAnnotations;
const setConfiguration = win.setConfiguration;
const initLanguage = win.initLanguage;
const setDownloadPdfName = win.setDownloadPdfName;
const setPdfFocus = win.setPdfFocus;
const highlightSelection = win.highlightSelection;
const resetAnnotationButtons = win.resetAnnotationButtons;
const replaceText = win.replaceText;
const captureScreenshot = win.captureScreenshot;
const printMyPdf = win.printMyPdf;


@Component({
    selector: 'app-pdf-master',
    templateUrl: './pdf-master.component.html',
    styleUrls: ['./pdf-master.component.scss']
})


export class PdfMasterComponent {

    //  @Output() onChangeBlob = new EventEmitter<Blob>();
    @Output() onClick = new EventEmitter<any>();
    @Output() readSelectedText = new EventEmitter<string>();
    @Output() loadedPdf = new EventEmitter<string>();
    @Output() dirtyChange = new EventEmitter<boolean>();
    @ViewChild('pdfViewer') pdfViewer: ElementRef | undefined;

    @Output() onChangeConfig = new EventEmitter<any>();
    @Output() onOpenConfig = new EventEmitter<any>();
    @Output() onAnnotationSetText = new EventEmitter<any>();
    @Output() onShowMessage = new EventEmitter<string>();
    // @Output() downloadPDF = new EventEmitter<any>();
    @Output() printPdf = new EventEmitter();
    @Output() imageCaptured = new EventEmitter<any>();

    useKaraoke: boolean = false;
    currLang: string = 'it';

    currentFileName: string = '';
    isInitialized: boolean = false;
    selection: Selection | null = null;
    private _dirty: boolean = false;
    public get dirty() {
        return this._dirty;
    }

    public set dirty(value: boolean) {

        this._dirty = value;
        this.dirtyChange.emit(value);
    }


    startSelection: Node | undefined;
    endSelection: Node | undefined;
    offsetStart: number = 0;
    offsetEnd: number = 0;
    nodesToRead: Node[] = [];
    page: number = 0;

    constructor(private translateService: TranslateService) {
    


    }

    public setViewerFocus() {
        setPdfFocus(true);
    }

    public setGUILanguage(lang: string) {
        initLanguage(lang.substring(0, 2));
        try {
            win.PDFViewerApplicationOptions.set('locale', lang.substring(0, 2));
        } catch (e) {
            console.log(e);
        }
        const extractbtn = document.getElementById('Extractpdf');
        if (extractbtn) extractbtn.title = this.translateService.instant("EXTRACT_PDF");
        const printbtn = document.getElementById('print2');
        if (printbtn) printbtn.title = this.translateService.instant("PRINT_PDF")

    }



    public initializeTools(json: PdfToolsPrefsDto) {
        setConfiguration(json);

    }

    public print() {
        printMyPdf();
    }


    getPDFData(): Promise<Blob> {
        // getPDFBlobData();
        return new Promise((resolve, reject) => {
            return win.PDFViewerApplication.pdfDocument.getData().then((d: Blob) => {

                this.currentFileName = win.PDFViewerApplication.url;
                return resolve(d);
            });

        });



    }

    @HostListener('click', ['$event.target'])
    async onClickEvent(event: Event) {
        const e: any = event;
        this.onClick.emit(e);
    }

    @HostListener('window:message', ['$event'])
    async onMessage(event: Event): Promise<void> {
        const e: any = event;
        if (e.data.source != 'pdfmaster') {
            return;
        }
        const function_name = e.data.function_name;

        switch (function_name) {
            case "downloadPDF":
                const file_name = e.data.content.filename;
                const blob_url = e.data.content.file_blob_data;
                console.log(file_name, blob_url);
                console.log('downloadPDF');
                this.currentFileName = file_name;
                //  this.onChangeBlob.emit(blob_url);

                // if (blob_url.size !== undefined) {

                //     this.onChangeBlob.emit(blob_url);
                // }

                break;
            case "annotationsModified":
                //   this.SavePdf();
                if (!this.dirty) this.dirtyChange.emit(true);
                this.dirty = true;
                // const annotation = outputAnnotations();
                // console.log(annotation);
                break;
            case "pdfLoaded": {
                this.loadedPdf.emit(e.data.content);
                break;
            }
            case "readSelectedText":
                console.log(function_name + ':' + e.data.content);
                this.readSelectedText.emit(e.data.content)

                //   setSelection(e.data.content);
                break;
            case "saveconfig":
                console.log('saveconfig' + JSON.stringify(e.data.content));
                // per configurazione interna. Non si usa più
                this.onChangeConfig.emit(e.data.content);
                break;
            case "openConfig": {
                console.log('openConfig');
                this.onOpenConfig.emit();
                break;
            }
            case "annotationText": {
                const data = e.data.content;

                this.onAnnotationSetText.emit(data);
                break;
            }
            case "message": {
                const data = e.data.content;
                console.log(data);
                this.onShowMessage.emit(data)
                break;
            }
            case "PrintPDF": {
                this.printPdf.emit();
                break;
            }
            case "ImageCaptured": {
                this.imageCaptured.emit(e.data);
                break;
            }
            default:
                break;
        }
    }

    public setPDFJSLanguage() {
        win.PDFViewerApplicationOptions.set('locale', localStorage.getItem('language'));
    }

    public setFocus(value: boolean) {
        try {
            setPdfFocus(value);
            if (win.PDFViewerApplicationOptions) {
                win.PDFViewerApplication.enabled = value;
            }
        } catch (error: any) {
            console.log(error);

        }


    }


    public setDownloadFilename(filename: string) {
        setDownloadPdfName(filename);
    }

    public getCurrentPage(): number {
        return win.PDFViewerApplication.pdfViewer.currentPageNumber - 1;
    }


    public async getCurrentPageText(): Promise<string> {

        let txt: string = "";
        const pageNumber: number = win.PDFViewerApplication.pdfViewer.currentPageNumber;
        console.log("Reading text for page " + pageNumber);
        const page = await win.PDFViewerApplication.pdfViewer.pdfDocument.getPage(pageNumber);
        const textContent = await page.getTextContent();

        textContent.items.forEach((textItem: { str: string; hasEOL: boolean, transform: number[] }) => {
            let str: string = textItem.str;
            const x: number = textItem.transform[4];
            const y: number = textItem.transform[5];
            if (textItem.hasEOL) str += "\n";
            txt += str;
        });
        return Promise.resolve(txt);
        /*
        const matches = document.querySelectorAll("div, span");
        let startRead: boolean = false;
        let currentPage = 1;
        matches.forEach(element => {
            if (!startRead && element.className == "pdfViewer") {
                startRead = true;
            }
            if (startRead) {
                let htmlElement: HTMLElement = element as HTMLElement;
    
                let innerText = htmlElement.innerText;
    
                //console.log("ID: " + htmlElement.id);
                //console.log("text: " + htmlElement.innerText);
    
                if (htmlElement.id === "page-" + currentPage) {
                    if (innerText != "") {
    
                        txt += innerText.replaceAll("\n\n", " ").replaceAll("\n", " ").replaceAll("  ", " ") + " ";
                    }
                    currentPage++;
                }
            }
    
        });
        console.log(txt);
        return txt;
        */
    }


    public getSelectedText(): string {
        const text = document.getSelection()?.toString();
        return text ? text : '';
    }

    public getText(): string {
        let text = '';
        if (this.useKaraoke) {
            this.selection = document.getSelection();


            if (this.selection && this.selection?.rangeCount > 0) {

                this.saveRangeNode();
                //this.getSelectedElementTags();

                let pos = 0;
                let len = 0;
                const currentSel = this.selection;
                const offsetstart: number = currentSel?.anchorOffset ? currentSel?.anchorOffset : 0;
                const offsetend: number = currentSel?.focusOffset ? currentSel?.focusOffset : 0;
                console.log(currentSel);
                if (this.nodesToRead && this.nodesToRead.length > 0) {
                    this.nodesToRead.forEach((node: Node) => {
                        let cuttext = false;
                        if (node === this.startSelection) {
                            pos = offsetstart;
                            cuttext = true;
                        }
                        else {
                            pos = 0;
                        }
                        if (node === this.endSelection) {
                            len = offsetend;
                            cuttext = true;
                        }
                        else {
                            if (node.parentElement) len = node.parentElement.innerHTML.length;
                        }
                        let txt = node.parentElement?.innerHTML;
                        if (txt && cuttext) txt = txt.substring(pos, len);

                        // if (txt && txt.length > offsetstart) txt = txt.substring(offsetstart, len);
                        text = text + txt; //node.parentElement?.innerHTML;
                    })
                }
            }
        } else {
            text = this.getSelectedText();
        }

        return text;


    }

    private saveRangeNode() {

        // Ricava i nodi selezionati per leggerli
        const curSelection = this.selection;
        if (curSelection) {
            this.startSelection = curSelection?.getRangeAt(0).startContainer;

            this.endSelection = curSelection.getRangeAt(curSelection.rangeCount - 1).endContainer;


            this.offsetStart = curSelection?.anchorOffset ? curSelection?.anchorOffset : 0;
            this.offsetEnd = curSelection?.focusOffset ? curSelection?.focusOffset : (curSelection.anchorNode?.textContent) ? curSelection.anchorNode?.textContent?.length : 10;

            // prendere il primo nodo della selezione
            const rangeObject = curSelection?.getRangeAt(0);
            if (rangeObject) {
                const nodes: Node[] = this.getNodesInRange(rangeObject);
                this.nodesToRead = [];
                // Seleziono solo i nodi validi
                nodes.forEach(node => {
                    if (node.parentElement && node.parentElement.TEXT_NODE && this.allNodesChildren(node.parentElement)) {
                        this.nodesToRead.push(node);
                    }
                });

            }
        } else {
            //Da aggiungere se si vuole il karaoke!!! ma da sistemare per fare il cambio pagina era per il componente vecchio
            //    if (this.viewer)
            //     this.nodiDaLeggere = this.textNodesUnder(document.getElementById('viewer') as Node);


        }
    }

    private allNodesChildren(elemento: HTMLElement): boolean {
        // controlla vhe tutti i nodi figli dell'elemento siano di tipo TEXT

        let counter = 0;
        elemento.childNodes.forEach(item => {
            if (item.nodeType !== elemento.TEXT_NODE) {
                counter++;
            }
        });
        return counter === 0;
    }

    private getNodesInRange(range: Range) {
        // estrapola la sequenza dei nodi contenuti nel range

        let startNode: Node | null = range.startContainer.childNodes[range.startOffset]
            || range.startContainer;//it's a text node
        const endNode: Node | null = range.endContainer.childNodes[range.endOffset]
            || range.endContainer;

        if (startNode === endNode && startNode.childNodes.length === 0) {
            return [startNode];
        }

        const nodes = [];
        do {
            if (startNode.nodeValue != null)
                nodes.push(startNode);
        }
        while ((startNode = this.getNextNode(startNode, false, endNode))
            && (startNode !== endNode));
        nodes.push(endNode);
        return nodes;
    }

    private getNextNode(node: Node, skipChildren: boolean, endNode: Node): Node | null {
        //if there are child nodes and we didn't come from a child node
        if (endNode === node) {
            return null;
        }
        if (node.firstChild && !skipChildren) {
            return node.firstChild;
        }
        if (!node.parentNode) {
            return null;
        }
        return node.nextSibling
            || this.getNextNode(node.parentNode, true, endNode);
    }

    karaokeOnPdf(item: any) {
        if (this.nodesToRead.length > 0) {
            if (item.name === 'word') {
                console.log(item);

                let found = false;
                let i = 0;
                let l = 0;


                let curEl = this.nodesToRead[0];
                while (!found && i < this.nodesToRead.length) {
                    curEl = this.nodesToRead[i];
                    if (curEl.parentElement?.innerHTML && curEl.parentElement?.innerHTML.length) {
                        l = curEl.parentElement?.innerHTML.length + l;
                    }
                    if (l - 1 > item.charIndex) {
                        if (curEl.parentElement?.innerHTML && curEl.parentElement?.innerHTML.length) l = l - curEl.parentElement?.innerHTML.length;
                        found = true;
                    } else {


                        i++;
                    }
                }
                const range = new Range();
                const selection = document.getSelection();
                // selection?.setPosition(curEl.parentElement, item.charIndex-l);
                // if (curEl.parentElement)
                //    selection?.extend(curEl.parentElement, item.charLength);
                const startidx = item.charIndex - l;
                let endidx = (item.charIndex - l + item.charLength);


                if (curEl.nodeValue && endidx > curEl.nodeValue?.length) {
                    endidx = curEl.nodeValue.length;
                }

                try {
                    range.setStart(curEl, startidx);
                    range.setEnd(curEl, endidx);
                } catch (err) {
                    console.log(err);
                }

                // // range.setStart(curEl, item.charIndex-l);
                // // range.setEnd(curEl, item.charIndex + item.charLength-l);
                selection?.removeAllRanges();
                selection?.addRange(range);

            } else if (item.name === 'sentence') {
                console.log(item);
                const start = this.startSelection ? this.startSelection : null;


                // document.getSelection()?.setPosition(start, item.charIndex);

            }
        }

    }

    testCreateViewer(pdf_file_path: any) {
        this.isInitialized = true;
        document.getElementById('test_btn')?.click();
        const pdf_viewer_id = 'pdf-viewer';

        createPDFViewer(pdf_viewer_id, pdf_file_path);



    }

    resetAnnotationButtons() {
        resetAnnotationButtons();
    }

    openPdf(pdf: any) {
        if (this.isInitialized) {
            this.dirty = false;
            openNewPDF(pdf);
            resetAnnotationButtons();
        } else {
            this.dirty = false;
            this.testCreateViewer(pdf);

        }
    }

    closePdf() {
        win.closePDF();

    }

    outputAnnotasionsFromAngular() {
        const annotations = outputAnnotations();
        console.log('annotations', annotations);
    }


    onDownloadPdf(event: any) {
        console.log('download pdf' + event);
    }

    // SavePdf() {
    //     //  getPDFBlobData();
    // }

    ReplaceText(text: string) {
        replaceText(text);
    }

    highlightSelection() {
        this.dirty = true;
        highlightSelection();
    }

    CaptureScreenshot() {
        captureScreenshot()
    }
}
