import { Injectable } from '@angular/core';
import { RecentPdfDto } from './pdf-home/recent-pdf-dto';
import { Guid } from 'guid-typescript';
import { FirebaseService } from 'src/app/core/firebase/firebase.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { InputDialogService } from 'src/app/shared/dialog/input-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from 'src/app/core/i18n.service';
import { onSnapshot } from 'firebase/firestore';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { MapStateService } from 'src/app/shared/map-state.service';

@Injectable({
    providedIn: 'root'
})
export class PdfService {

    public currentPdf: RecentPdfDto | undefined;
    public recentsPdf: RecentPdfDto[] = [];

    public recentPdfsSortedFiltered: RecentPdfDto[] = [];
    public searchFilter: string = '';

    private askNameSubscription: Subscription | undefined;
    private getPdfRecentSubscription: Subscription | undefined;
    private renamePdfSubscription: Subscription | undefined;

    public colorPDF = "primary";


    constructor(private smeService: SmeService, private mapStateService: MapStateService, private inputDialogService: InputDialogService, private translateService: TranslateService, private firebaseService: FirebaseService) {

        this.loadRecent();

    }





    addOrUpdatePdf(_pdfId: string, _name: string, _googleId: string, _lastupdate: string, _lastOpened: string, _imported: string, _storageRef: string, link: string, color: string): Promise<RecentPdfDto> {
        return new Promise((resolve, reject) => {
            let newitem: boolean = true;
            let pdf;
            if (_pdfId !== '') {
                pdf = this.recentsPdf.filter((x) => {

                    return x.idPdf === _pdfId
                });
            }
            let item: RecentPdfDto;

            if (_pdfId === '') {
                item = new RecentPdfDto();
                item.idPdf = Guid.create().toString();
            } else {
                if (!pdf || (pdf && pdf.length === 0)) {
                    item = new RecentPdfDto();

                    item.idPdf = _pdfId;
                } else {
                    item = pdf[0];
                    newitem = false;
                }
            }
            item.googleId = _googleId;
            item.name = _name;
            item.lastUpdated = _lastupdate;
            item.lastOpened = _lastOpened;
            item.imported = _imported;
            item.storageRef = _storageRef;
            item.color = color;
            item.link = link;
            if (newitem) {
                this.recentsPdf.push(item)
            }
            this.currentPdf = item;

            this.smeService.addPDfToRecents(item.idPdf, item.name, item.googleId, item.color, item.lastUpdated, item.lastOpened, item.imported, item.storageRef, item.link).subscribe({
                next: (data) => {
                    item.idPdf = data.data.pdfId;
                    item.userId = data.data.userId;

                    this.firebaseService.addPdfRecent(this.mapStateService.guid, item.userId, item.idPdf, 'recents');
                    return resolve(item);
                },
                error: (error) => {
                    return reject(error);
                }
            });

        });



    }

    renamePdf(recent: RecentPdfDto) {
        if (this.renamePdfSubscription) { this.renamePdfSubscription.unsubscribe(); }

        this.renamePdfSubscription = this.openDialog('', recent.name)
            .subscribe((result: any) => {
                if (result && result.name.trim() && result.name !== recent.name) {
                    const name = result.name.trim();
                    recent.name = name;
                    this.addOrUpdatePdf(recent.idPdf, recent.name, recent.googleId, recent.lastUpdated, recent.lastOpened, recent.imported, recent.storageRef, recent.link, recent.color);



                }

            });
    }

    openDialog(urlToOpen: string, name: string) {
        return new Observable((observer: Observer<boolean>) => {

            // UnsavedChangesDialog defined somewhere else and imported above
            if (this.askNameSubscription) { this.askNameSubscription.unsubscribe(); }
            this.askNameSubscription = this.inputDialogService.askName(this.translateService.instant(extract('TITLE_RENAME_MAP')),
                this.translateService.instant(extract('INPUT_MAPNAME')), name,
                '', urlToOpen, '', '')
                .subscribe({
                    next: (result) => {
                        observer.next(result);
                        observer.complete();

                    },
                    error: (error) => {
                        observer.next(false);
                        observer.complete();

                    }
                });
        });
    }




    // dowmnloadPdf(filename: string) {
    //     //   this.pdfEditor?.downloadPdf(filename);
    // }


    getRecentById(idPdf: string): RecentPdfDto | undefined {
        const i = this.getRecentIdxById(idPdf);
        const result = (i > -1) ? this.recentsPdf[i] : undefined;
        return result;
    }

    getRecentIdxById(idPdf: string): number {
        const fx = (x: RecentPdfDto) => x.idPdf === idPdf;
        const i = this.recentsPdf.findIndex(fx);
        return i;
    }

    removePDF(item: RecentPdfDto) {
        const fx = (x: RecentPdfDto) => x.idPdf === item.idPdf;
        const i = this.recentsPdf.findIndex(fx);
        if (i > -1) {

            // cancellare il file da storage
            this.smeService.deleteFromPdf(item.idPdf).subscribe({

                next: () => {
                    console.log('recente rimosso');
                    this.firebaseService.addPdfRecent(this.mapStateService.guid, item.userId, item.idPdf, 'recents');
                },
                error: (error) => {
                    console.log(error);

                }
            });


            this.recentsPdf.splice(i, 1);
        }
        return this.recentsPdf;

    }

    applyFilter(filter: string) {
        this.searchFilter = filter;
        if (this.searchFilter === '') {
            this.recentPdfsSortedFiltered = this.recentsPdf;
        } else {
            console.log('Apply filter: ' + this.searchFilter);
            this.recentPdfsSortedFiltered = [];
            this.recentsPdf.forEach((pdf: RecentPdfDto) => {
                const name = pdf.name.toLowerCase();
                if (name.includes(this.searchFilter)) {
                    this.recentPdfsSortedFiltered.push(pdf);
                }
            });
        }
        return this.recentPdfsSortedFiltered;
    }


    public loadRecent() {
        if (this.getPdfRecentSubscription) this.getPdfRecentSubscription.unsubscribe();
        this.getPdfRecentSubscription = this.getRecents().subscribe({
            next: (recents: any) => {
                this.recentsPdf = recents;
            },
            error: () => {

            }
        });
    }

    getRecents(): Observable<any> {

        return new Observable<any>((observer: any) => {
            this.recentsPdf.splice(0);
            this.smeService.getPdfRecents().subscribe({
                next: (recents: any) => {
                    recents.forEach((recent: any) => {

                        const rm = new RecentPdfDto();
                        rm.idPdf = recent.pdfId;
                        rm.color = recent.color;
                        rm.googleId = recent.googleId;
                        rm.lastOpened = recent.lastOpened;
                        rm.lastUpdated = recent.lastUpdated;
                        rm.imported = recent.imported;
                        rm.name = recent.name;
                        rm.storageRef = recent.storageRef;
                        rm.userId = recent.userId;

                        this.recentsPdf.push(rm);

                        // this.endLoadRecent.emit(rm);
                    });
                    observer.next(this.recentsPdf);
                    observer.complete();

                },
                error: (err: any) => {
                    observer.next([]);
                    observer.complete();

                }
            })
        });




    }



}
