import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotificationsDto } from '../shared/notifications-data-dto';
import { CustomSelectElement } from '../map-edit/toolbar/custom-toolbar/custom-select-element-dto';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { UiConstants } from '../shared/ui-constants';

@Component({
    selector: 'app-admin-edit-notification',
    templateUrl: './admin-edit-notification.component.html',
    styleUrls: ['./admin-edit-notification.component.scss']
})
export class AdminEditNotificationComponent implements OnInit {


    @Input() notificationForm = new FormGroup({
        id: new FormControl(),
        title: new FormControl(),
        active: new FormControl(),
        link: new FormControl(),
        dateCreated: new FormControl(),
        notes: new FormControl(),
        icon: new FormControl()
    });

    @Input() notificationData: NotificationsDto | undefined;
    // @Input() title: string;
    // @Input() link: string;
    // @Input() dateCreated: string;
    // @Input() icon: string;
    // @Input() active: boolean;
    // @Input() notes: string;
    public loaded = false;
    public isLoading = false;
    user = '';
    role = '';
    selectedNotification: CustomSelectElement | undefined;
    selectedIcon: any;
    iconNotifications: CustomSelectElement[] = Array<CustomSelectElement>();
    insertNotificationSubscription: Subscription | undefined;


    constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private translateService: TranslateService) {
    }

    ngOnInit() {

        this.createForm();
        if (this.notificationData) {
            if (this.notificationData.id) {
                this.notificationForm.get('id')?.setValue(this.notificationData.id);
            }
            if (this.notificationData.title) {
                this.notificationForm.get('title')?.setValue(this.notificationData.title);
            }
            if (this.notificationData.link) {
                this.notificationForm.get('link')?.setValue(this.notificationData.link);
            }
            let start = new Date();
            if (this.notificationData.dateCreated) {
                start = new Date(this.notificationData.dateCreated);
            }
            this.notificationForm.get('dateCreated')?.setValue(start);
            if (this.notificationData.typeIcon) {
                this.notificationForm.get('icon')?.setValue(UiConstants.getNotificationIcon(this.notificationData.typeIcon));
            }

            if (this.notificationData.icon) {
                this.notificationForm.get('icon')?.setValue(this.notificationData.icon);
            }
            if (this.notificationData.notes) {
                this.notificationForm.get('notes')?.setValue(this.notificationData.notes);
            }
            if (this.notificationData.active) {
                this.notificationForm.get('active')?.setValue(this.notificationData.active);
            }

        }
        this.iconNotifications = UiConstants.NOTIFICATIONICONS;
        this.iconNotifications.forEach(element => {
            // element.icon = element.icon;
            element.viewValue = this.translateService.instant(element.viewValue);
            if (this.notificationData && this.notificationData.icon === element.icon) {
                this.selectedNotification = element;
            }
        });
        if (!this.selectedNotification) {
            this.selectedNotification = this.iconNotifications[0];
        }
        this.loaded = true;
        this.user = this.authenticationService.getUserName();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials.role : '';

    }




    optionSelected(event: any) {
        // console.log(event.value.icon);
        this.selectedIcon = event.value.icon;
        this.selectedNotification = event.value;
    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role.toLocaleUpperCase() || 'USER'));
    }
    private createForm() {
        this.notificationForm = this.formBuilder.group({
            id: [''],
            title: ['', Validators.required],
            active: false,
            link: [''],
            dateCreated: [new Date()],
            notes: [''],
            icon: [''],
        });
    }

    get f() { return this.notificationForm.controls; }



    showUploadImage() {
        return this.selectedNotification?.value === 'custom';
    }




}
