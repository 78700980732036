<div class="outline-panel-container" (drop)="handleDragEnd($event);" (click)="disableMapFocus()">
    <div class="outline-title-container">
        <div class="outline-title-icon">
            <mat-icon fontSet="material-icons-outlined">list_alt</mat-icon>
        </div>
        <div class="outline-title-text">
            <div translate>OUTLINE</div>
        </div>
        <!-- <div class="outline-warning">
            <div class="warning-1" translate>OUTLINE_WARNING_1</div>
            <div class="warning-2" translate>OUTLINE_WARNING_2</div>
        </div> -->
        <div class="outline-close">
            <button mat-icon-button color="primary" matTooltip="{{'SEARCH_CLOSE' | translate}}" (click)="closeExtra();">
                <mat-icon [attr.aria-label]="'SEARCH_CLOSE' | translate" fontSet="material-icons-outlined">close</mat-icon>
            </button>
        </div>
    </div>
    <!-- TTS TOOLBAR-->
    <div class="tts-toolbar-container" (drop)="handleDragEnd($event);">
        <div class="tts-toolbar">
            <!--PLAY-->
            <button *ngIf="!isTalking" [disabled]="!selectedNodeFlatId" class="green-color" mat-icon-button matTooltip="{{'PLAY' | translate}}" [attr.aria-label]="'PLAY' | translate" (click)="TTSRead()">
                <mat-icon class="extra-button">play_arrow</mat-icon>
            </button>
            <!--STOP-->
            <button *ngIf="isTalking" [disabled]="!selectedNodeFlatId" class="red-color" mat-icon-button matTooltip="{{'STOP' | translate}}" [attr.aria-label]="'STOP' | translate" (click)="TTSStop()">
                <mat-icon class="extra-button">stop</mat-icon>
            </button>
            <!--READ SPEED-->
            <div *ngIf="selectedNodeFlatId !== ''" class="toolbar-input-container">
                <input class="toolbar-input" type="number" min="0.5" max="1.5" step="0.1" (focus)="speedGotFocus()" (blur)="speedLostFocus()" value="{{rateValue}}" (change)="changeSpeechRate($event)" />
            </div>
            <!-- SOLO FLAG LANGUAGE -->
            <button *ngIf="isTalking || (selectedNodeFlatId !== '' && !canEdit())" class="outline-toolbar-language-button" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}">
                <img [src]="selectedLangIcon" />
            </button>
            <!-- LANGUAGE CHANGE BUTTON -->
            <button *ngIf="!isTalking && selectedNodeFlatId !== '' && canEdit()" class="outline-toolbar-language-button" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}" [matMenuTriggerFor]="languageMenu">
                <img class="toolbar-language-icon" [src]="selectedLangIcon" />
            </button>
            <mat-menu class="language-menu-container" #languageMenu="matMenu" #language color="primary">
                <div class="language-menu-item-container" *ngFor="let l of languagesWithFlag" mat-menu-item (click)="changeLanguage($event)" [id]="l.value">
                    <img [src]="l.icon" mat-icon class="language-icon" />
                    <span translate>{{l.viewValue}}</span>
                </div>
                <ng-container *ngIf="languagesNoFlag.length > 0">
                    <mat-divider></mat-divider>
                    <button class="language-menu-item-container" mat-menu-item [matMenuTriggerFor]="languageNoFlagMenu">{{'OTHER_VOICES' | translate}}</button>
                    <mat-menu class="language-menu-container" #languageNoFlagMenu="matMenu" color="primary">
                        <div *ngFor="let l of languagesNoFlag" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
                            <img [src]="l.icon" mat-icon class="language-icon" />
                            <span translate>{{l.viewValue}}</span>
                        </div>
                    </mat-menu>
                </ng-container>
            </mat-menu>
            <button *ngIf="canEdit() && !isMobileOrTabletDevice" [disabled]="!selectedNodeFlatId" [ngClass]="{'speechedit-mode': speechEditEnabled}" mat-icon-button matTooltip="{{'MAIN_TOOLBAR_SPEECHEDIT' | translate}}" color="primary" (click)="speechEdit()">
                <mat-icon class="extra-button">settings_voice</mat-icon>
            </button>
        </div>
    </div>
    <!-- TOOLBAR -->
    <div class="outline-toolbar-container" (drop)="handleDragEnd($event);">
        <div *ngIf="!outlineService.canEdit" class="outline-readonly-message" translate>OUTLINE_READONLY</div>
        <mat-toolbar-row *ngIf="outlineService.canEdit" class="outline-toolbar">
            <button [disabled]="!canQuickInput || isTalking" [ngClass]="{'quick-selected': outlineQuickInputState}" mat-icon-button matTooltip="{{'OUTLINE_QUICK_INPUT' | translate}}" color="primary" (click)="uiToggleQuickInput()">
                <mat-icon class="extra-button" fontSet="material-icons-outlined">rtt</mat-icon>
            </button>
            <button class="outline-toolbar-buttomicn" [disabled]="!canAddSibling || isTalking" mat-icon-button matTooltip="{{'OUTLINE_ADD_ABOVE' | translate}}" color="primary" (click)="uiAddSibling()">
                <mat-icon class="extra-button" svgIcon="outline-crea-fratello"></mat-icon>
            </button>
            <button [disabled]="!canAddChild || isTalking" mat-icon-button matTooltip="{{'OUTLINE_ADD_BELOW' | translate}}" color="primary" (click)="uiAddChild()">
                <mat-icon class="extra-button" svgIcon="outline-crea-figlio"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="{{outlineViewNodeTooltip}}" color="primary" (click)="uiToggleViewNodeImage()">
                <mat-icon fontSet="material-icons-outlined" *ngIf="outlineViewNodeImageState" class="extra-button">visibility_off</mat-icon>
                <mat-icon fontSet="material-icons-outlined" *ngIf="!outlineViewNodeImageState" class="extra-button">visibility</mat-icon>
            </button>
            <button [disabled]="!canMoveUp  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_UP' | translate}}" color="primary" (click)="uiMoveUp()">
                <mat-icon class="extra-button" fontSet="material-icons-outlined">arrow_upward</mat-icon>
            </button>
            <button [disabled]="!canMoveDown  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_DOWN' | translate}}" color="primary" (click)="uiMoveDown()">
                <mat-icon class="extra-button" fontSet="material-icons-outlined">arrow_downward</mat-icon>
            </button>
            <button [disabled]="!canMoveIn  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_IN' | translate}}" color="primary" (click)="uiMoveIn()">
                <mat-icon class="extra-button">format_indent_decrease</mat-icon>
            </button>
            <button [disabled]="!canMoveOut  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_OUT' | translate}}" color="primary" (click)="uiMoveOut()">
                <mat-icon class="extra-button">format_indent_increase</mat-icon>
            </button>
            <button [disabled]="!canEditItem  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_EDIT' | translate}}" color="primary" (click)="uiEditItem(0)">
                <mat-icon class="extra-button" svgIcon="square-edit-outline"></mat-icon>
            </button>
            <!-- BOTTONE SELEZIONA SOTTOALBERO <button [disabled]="!canSelectSubtree" mat-icon-button matTooltip="{{'OUTLINE_SELECT_SUBTREE' | translate}}" color="primary" (click)="uiSelectSubtree()">
                <mat-icon class="extra-button" fontSet="material-icons-outlined">highlight_alt</mat-icon>
            </button>-->
            <button [disabled]="!canDelete  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_DELETE' | translate}}" color="primary" (click)="uiDeleteItem()">
                <mat-icon class="extra-button">delete_sweep</mat-icon>
            </button>
            <button [disabled]="!canArrange  || isTalking" mat-icon-button matTooltip="{{'OUTLINE_ARRANGE' | translate}}" color="primary" (click)="uiArrangeMap()">
                <mat-icon class="extra-button" svgIcon="arrange-map"></mat-icon>
            </button>
        </mat-toolbar-row>
    </div>
    <div id="outlineContainerRef" class="outline-container" (click)="uiSelectItem(null)" tabindex="0" (keydown)="genericKeyCallback($event)">
        <div id="outlineScroll" class="absolute-zero-overflow">
            <span #emptyItem></span>
            <mat-tree class="mat-tree" [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- NODO OUTLINE-->
                <mat-tree-node class="node-tree-item" id="{{node.id}}" [ngClass]="{'node-selected':selectedNodeFlatId===node.id, 'node-talking':talkingNodeFlatId===node.id}" *matTreeNodeDef=" let node" [draggable]="!isMobileOrTabletDevice" matTreeNodePadding matTreeNodePaddingIndent="25" (click)="uiSelectItem(node, $event)" (dblclick)="uiEditItem(0, $event)" (dragstart)="handleDragStart($event, node);" (drag)="handleDrag($event, node, nodeRef);" (dragover)="handleDragOver($event, node);" (dragend)="handleDragEnd($event);">
                    <!-- CONTENITORE TOOLS NODO OUTLINE-->
                    <div [ngStyle]="getNodeStyleWidth()" class="node-tree-edge-container" *ngIf="node.edgeId!==''">
                        <div style=" width: 50px; height: 100%; background-color:#eaeeef;"></div>
                        <div class="node-tree-edge-text">
                            <mat-icon fontSet="material-icons-outlined" style="display: flex; align-items: center; justify-content: center; font-size: 14px; text-align: center;">arrow_downward</mat-icon>
                            <span class="one-line-ellipsmiced-text">{{node.edgeText}}</span>
                        </div>
                        <!-- HIGHLIGHT CURRENT DRAG ITEM EDGE SECTION-->
                        <div *ngIf="this.dragNodeFlat?.id===node.id" class="drop-item-highlight"></div>
                    </div>
                    <div #nodeRef id="{{node.id}}_nodeRef" [ngStyle]="getNodeStyleWidth()" class="node-tree-node-container" (drop)="handleDrop($event, node, 'child')" mwlResizable [enableGhostResize]="true" [resizeSnapGrid]="{ left: 5, right: 5 }" [validateResize]="validate" (resizeEnd)="onResizeNodeEnd($event, nodeRef)" (resizeStart)="onResizeNodeStart($event, nodeRef)" (resizing)="onResizingNode($event, nodeRef)">
                        <div class="node-tree-header-container">
                            <mat-icon *ngIf="node.locked" class="lock-icon">lock</mat-icon>
                            <button *ngIf="hasChild(node) && !node.looped" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.titleText" (click)="uiToggleExpand(node)">
                                <mat-icon fontSet="material-icons-outlined" color="primary">
                                    {{treeControl.isExpanded(node) ? 'remove_circle_outline' : 'add_circle_outline'}}
                                </mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="!hasChild(node) && !node.looped" disabled>
                            </button>
                            <button mat-icon-button *ngIf="node.looped" class="node-loop" disabled>
                                <mat-icon fontSet="material-icons-outlined">airline_stops</mat-icon>
                            </button>
                        </div>
                        <!-- CONTENITORE CONTENUTO NODO OUTLINE-->
                        <div class="node-tree-content-container">
                            <div class="node-tree-content">
                                <div *ngIf="editingNodeFlatId!==node.id" class="node-tree-text one-line-ellipsed-text">{{node.titleText}}</div>
                                <div class="speechedit-anim-icon" *ngIf="editingNodeFlatId===node.id && speechEditEnabled">
                                    <mat-icon>mic</mat-icon>
                                </div>
                                <textarea class="edit-node" autocapitalize="off" *ngIf="editingNodeFlatId===node.id" [(ngModel)]="editTitleText" (keydown)="editorKeyCallback($event)"></textarea>
                            </div>
                        </div>
                        <div *ngIf="getImage(node) && isImageVisible(node)" class="mini-image-container" [ngClass]="this.dragNodeFlat?.id===node.id?'mini-image-container-disable-hover':''">
                            <ng-container *ngIf="!getImage(node).includes('_PLACEHOLDER');then immagineNodo else icona"></ng-container>
                            <ng-template #immagineNodo>
                                <img class="mini-image" [src]="getImage(node)" />
                            </ng-template>
                            <ng-template #icona>
                                <!--<mat-icon *ngIf="node.type === 'node'" fontSet="material-icons-outlined">image_not_supported</mat-icon>-->
                                <mat-icon *ngIf="node.type === 'table'" fontSet="material-icons-outlined">grid_on</mat-icon>
                            </ng-template>
                        </div>
                        <div class="math-button-container" *ngIf="showMathButton(node)" (click)="openMathEditor(node)">
                            <mat-icon class=>functions</mat-icon>
                        </div>
                        <div class="resize-handle" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
                        <!-- HIGHLIGHT CURRENT DRAG ITEM NODE SECTION-->
                        <div *ngIf="this.dragNodeFlat?.id===node.id" class="drop-item-highlight" style="border-top-right-radius: 5px; border-bottom-right-radius: 5px;"></div>
                    </div>
                    <!-- DROP AREA UNDER ITEM + ITEM DROP SHADOW-->
                    <div *ngIf="hoverNodeFlat?.id===node.id && this.dragNodeFlat?.id!==node.id" class="height-animation" [ngStyle]="getNodeDropHoleDimension(this.dragNodeFlat?.id)" (drop)="handleDrop($event, node, 'child')">
                        <div class="height-animation" [ngStyle]="getNodeDropShadow(this.dragNodeFlat?.id)"></div>
                    </div>
                    <!-- HIGHLIGHT LOOPED ITEMS -->
                    <div *ngIf="node.looped" style="background-color: #b2ebf24d; position: absolute; top: 0; bottom: 0; left: 0; right: 0;"></div>
                </mat-tree-node>
            </mat-tree>
        </div>
    </div>
    <div id="drag-ghost-element" class="drag-ghost"></div>
</div>
