import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BrowsercheckService {

    constructor() { }

    isBrowserAllowed(): boolean {
       // return true;
        let result = false;
        if (environment.production || environment.test) {
            const nua = navigator.userAgent;
            const is_android = ((nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf('Android ') > -1 &&
                nua.indexOf('AppleWebKit') > -1) && !(nua.indexOf('Chrome') > -1));
            let isChrome = nua.indexOf('Chrome') > -1;
           

            if (!isChrome) {
                if (navigator.userAgent.indexOf('CriOS') > -1) { isChrome = true; }
            }
            result = isChrome;
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('safari') !== -1) {
                if (ua.indexOf('chrome') > -1) {
                    isChrome = true;
                }
                result = true;
            }

            if (ua.indexOf('edge') > -1) {
                isChrome = false;
                result = true;
            }
            return result;
        }
        return true;
    }
}
