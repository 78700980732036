import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from './logger.service';

import { Observable } from 'rxjs';
import { BrowsercheckService } from './browsercheck.service';



const log = new Logger('BrowsercheckGuard');

@Injectable({
  providedIn: 'root'
})
export class BrowsercheckGuard  {

  constructor(private router: Router,
    private browsercheckService: BrowsercheckService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.browsercheckService.isBrowserAllowed()) {
      log.debug('Not an allowed browser, redirecting...');
      this.router.navigate(['/nobrowser'], { replaceUrl: true });
      return false;
    } else {
      return true;
    }


  }




}
