import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { MapStateService } from '../map-state.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageBoxService } from '../dialog/messagebox.service';
import { MatDialog } from '@angular/material/dialog';
import { GdriveFolderPickerComponent } from 'src/app/gdrive-folder-picker/gdrive-folder-picker.component';
import { Subscription } from 'rxjs';
import { GoogleService } from 'src/app/core/google/google.service';
import { map } from 'lodash';
import { I18nService } from 'src/app/core/i18n.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ExportMapInGoogleDriveService {
    picker: any;

    @Output() exported = new EventEmitter<string>();

    @Output() errorOnExport = new EventEmitter<any>();
    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();
    folderChoosenSubscription: Subscription | undefined;
    mapId: string = '';
    name: string = '';
    constructor(private router: Router, private smeService: SmeService, private googleService: GoogleService, private authenticationService: AuthenticationService, private translateService: TranslateService,
        private mapStateService: MapStateService, private i18nService: I18nService, private messageBoxService: MessageBoxService, private dialogService: MatDialog) { }

    exportMapGoogleDrive(mapId: string, mapName: string) {

        this.mapId = mapId;
        this.name = mapName;

        return this.smeService.exportMapToGDrive('', mapName, mapId);
    }

    public openFolderPickerFromDrive(mapId: string, mapName: string) {
        let accessToken = '';
        this.mapId = mapId;
        this.name = mapName;
        this.startLoading.emit();


        if (this.folderChoosenSubscription) { this.folderChoosenSubscription.unsubscribe(); }
        this.folderChoosenSubscription = this.googleService.folderChoosen.subscribe({
            next: (result: any) => {
                const _folderId = result.folderId;
                this.startLoading.emit();

                this.smeService.exportMapToGDrive(_folderId, this.name, this.mapId).subscribe({
                    next: (result: any) => {
                        this.exported.emit(result);
                        this.endLoading.emit(true);
                        this.messageBoxService.showTextMessage(
                            this.messageBoxService.MODE_TYPE.OK,
                            this.translateService.instant('EXPORT_IN_GOOGLE_DRIVE'),
                            this.translateService.instant('EXPORT_SUCCESS')
                        ).subscribe(() => { });
                    },
                    error: (error: any) => {
                        this.endLoading.emit((false));
                    }
                });


                this.folderChoosenSubscription?.unsubscribe();
            },
            error: (error: any) => {
                this.endLoading.emit((false));
                console.log(error);
            }
        });
        this.smeService.getAccessTokenPromise()
            .then((_accessToken: string) => {
                accessToken = _accessToken;

                return this.googleService.loadPicker();
            }).then(() => {
                const language = this.i18nService.language;
                this.endLoading.emit(true);
                this.googleService.createFolderPicker(accessToken, this.picker, language);
            }).catch((error: any) => {
                this.endLoading.emit(false);
                if (this.authenticationService.credentials && !this.authenticationService.isNativegoogleUser()) {

                    const id = this.authenticationService.credentials.firebaseUserId;
                    this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK,
                        this.translateService.instant('WARNING'),
                        this.translateService.instant('RICONNNECT_GOOGLE_ACCOUNT'), // [fragment]="{userId: '1101',mobileNo:931065465 }
                    ).subscribe(() => {
                        this.router.navigate(['/user-profile', id], { fragment: 'highlight=1' });
                    });
                } else {
                    this.authenticationService.logout().subscribe(() => this.router.navigate(['loggedin'], { fragment: 'expired=1' }));
                }
            });
    }

}

