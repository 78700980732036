import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public title = '';
    private _message = '';
    get message(): string {
        return this._message
    }
    set message(value: string) {
        this._message = value.replace(/\n/g, '<br/>');
    }

    public ok = true;
    public okCaption = '';
    public cancelCaption = '';

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

}
