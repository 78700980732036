<div class="custom-toolbar">
    <div class="select-container mat-tooltip-trigger" matTooltip="{{'LANGUAGE_TOOLBAR' | translate}}" [matMenuTriggerFor]="languageMenu">
        <img mat-icon class="under-select" color="primary" [src]="selectedLangIcon" />
    </div>
    <mat-menu class="language-menu-container" #languageMenu="matMenu" color="primary" [overlapTrigger]="false">
        <div *ngFor="let l of languagesWithFlag" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
            <img [src]="l.icon" mat-icon class="language-icon" />
            <span translate>{{l.viewValue}}</span>
        </div>
        <ng-container *ngIf="languagesNoFlag.length > 0">
            <mat-divider></mat-divider>
            <button class="language-menu-item-container" mat-menu-item [matMenuTriggerFor]="languageNoFlagMenu">{{'OTHER_VOICES' | translate}}</button>
            <mat-menu class="language-menu-container" #languageNoFlagMenu="matMenu" color="primary">
                <div *ngFor="let l of languagesNoFlag" mat-menu-item class="language-menu-item-container" (click)="changeLanguage($event)" [id]="l.value">
                    <img [src]="l.icon" mat-icon class="language-icon" />
                    <span translate>{{l.viewValue}}</span>
                </div>
            </mat-menu>
        </ng-container>
    </mat-menu>
</div>
