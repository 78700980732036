import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../core/i18n.service';
import { UserProfileComponent } from './user-profile.component';


const routes: Routes = [
    { path: 'user-profile/:id', component: UserProfileComponent, data: { title: extract('USER_PROFILE_TITLE') } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],


})
export class UserProfileRoutingModule { }
