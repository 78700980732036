import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AdminNotificationsRoutingModule } from './admin-notifications-routing.module';
import { AdminNotificationsComponent } from './admin-notifications.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminEditNotificationComponent } from '../admin-edit-notification/admin-edit-notification.component';
import { AdminListNotificationsComponent } from '../admin-list-notifications/admin-list-notifications.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PaginatorI18n } from '../access-config/common/order-list/mat-paginatorI18n.service';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        FormsModule,
        CoreModule,
        ReactiveFormsModule,
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        MatButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatPaginatorModule,
        AdminNotificationsRoutingModule,
    ],
    declarations: [
        AdminNotificationsComponent,
        AdminEditNotificationComponent,
        AdminListNotificationsComponent

    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }
    ]
})
export class AdminNotificationsModule { }
