<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''" [versioned]="true"></app-loader>

<!-- <div class="home-container" (dragover)="dragOver($event)" (drop)="drop($event)" (dragend)="dragEnd($event)" (keydown)="onKeyDown($event)" tabindex="0"> -->
<div class="home-container" (dragover)="dragOver($event)" (drop)="drop($event)" (dragend)="dragEnd($event)">
    <app-header class="home-header" [loadingMap]="loadingMap" (startLoading)="startLoadingRecents()" (endLoading)="endLoadingRecents($event)"></app-header>

    <div class="home-content" [style.background]="backColor">
        <app-recent *ngIf="online" [loadingMap]="loadingMap" (errorRecent)="errorRecents($event)" (startLoading)="startLoadingRecents()" (endLoading)="endLoadingRecents($event)"></app-recent>
    </div>
</div>
