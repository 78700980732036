import { Injectable, OnDestroy } from '@angular/core';
import { SmService } from 'supermappe-core';
import { Point } from '../point';
import { TranslateService } from '@ngx-translate/core';
import { ExtraService } from '../../map-edit/commands/extra.service';

@Injectable({
    providedIn: 'root'
})
export class YoutubeService {
    public videoNodes: any[] = [];
    private backedit: HTMLElement | undefined;
    private YTcmd_play = JSON.stringify({ event: 'command', func: 'playVideo' });
    private YTcmd_pause = JSON.stringify({ event: 'command', func: 'pauseVideo' });
    private dragging = false;
    private startDrag: Point | undefined;
    private floatingVideoWidth = 300;
    private floatingVideoStartTop = 100;
    private barHeight = 20;
    private closeButtonWidth = 25;
    private floatingVideoOpen = false;
    private dragMask: any;

    private updating = false;

    public VIDEO_POSITION = {
        ON_NODE: 'on_node',
        CENTER_SCREEN: 'center_screen'
    };
    public videoPosition = this.VIDEO_POSITION.ON_NODE;

    constructor(private smService: SmService,
        private translateService: TranslateService,
        private extraService: ExtraService) {
        this.createBackEdit();
    }

    //#region Backedit
    private createBackEdit() {
        this.backedit = document.createElement('div');
        this.backedit.style.zIndex = '-1000';
        this.backedit.style.width = '100%';
        this.backedit.style.height = '100%';
        this.backedit.style.position = 'absolute';
        this.backedit.style.background = 'gray';
        this.backedit.style.opacity = '0.5';
        this.backedit.onclick = () => {
            if (this.videoPosition === this.VIDEO_POSITION.ON_NODE) {
                this.videoPictureInPicture();
            } else {
                this.hideAllFloatingVideos();
            }
        };
        document.getElementsByTagName('body')[0].appendChild(this.backedit);
    }
    //#endregion

    public insertYoutubeVideoInNode(nodeId: string): any {
        const videoNode = this.smService.sm.map.nodes[nodeId];
        if (videoNode) {
            let youtubediv = document.getElementById('youtube-div-' + videoNode.id);
            let youtubeFrame = document.getElementById('youtube-frame-' + videoNode.id) as HTMLIFrameElement;

            const stageBase = this.smService.sm.stageBase;
            let width = videoNode.geometry.width * stageBase.scaleX;
            const h4_3 = width / 4 * 3;
            const p = stageBase.localToGlobal(videoNode.geometry.x - videoNode.geometry.width / 2, videoNode.geometry.y - videoNode.geometry.height / 2);

            console.log("POSIZIONE INIZIALE " + p.x + " " + p.y);

            let offsetElement: HTMLElement = document.getElementById('sm-container') as HTMLElement;
            if (offsetElement) {
                p.y += offsetElement.offsetTop;
                console.log("AGGIUNGO SM CONTAINER " + p.x + " " + p.y);
            }

            offsetElement = document.getElementById('map-toolbar') as HTMLElement;
            if (offsetElement) {
                p.y += offsetElement.clientHeight;
                console.log("AGGIUNGO LA TOOLBAR DELLA MAPPA IN ALTEZZA " + p.x + " " + p.y);
            }

            offsetElement = document.getElementById('extra-menu') as HTMLElement;
            if (offsetElement) {
                p.x += offsetElement.clientWidth;
                console.log("AGGIUNGO L'EXTRA MENU IN LARGHEZZA " + p.x + " " + p.y);
            }

            if (this.extraService.isExtraPanelOpen) {
                offsetElement = document.getElementById('extra-panel') as HTMLElement;
                if (offsetElement) {
                    p.x += offsetElement.clientWidth;
                    console.log("AGGIUNGO L'EXTRA PANEL " + p.x + " " + p.y);
                }
            }

            this.hideAllFloatingVideos();
            let url = 'https://www.youtube.com';
            if (videoNode.mainLinkType === 'video' && videoNode.mainLinkIndex >= 0 && videoNode.mainLinkIndex < videoNode.links.length) {
                url = videoNode.links[videoNode.mainLinkIndex].uri;
            }


            if (youtubediv === null) {
                youtubediv = document.createElement('div');
                youtubediv.id = 'youtube-div-' + videoNode.id;
                youtubediv.style.zIndex = '9999';
                if (this.backedit) {
                    this.backedit.style.zIndex = '9998';
                }
                document.getElementsByTagName('body')[0].appendChild(youtubediv);
                if (youtubeFrame === null) {
                    youtubeFrame = document.createElement('iframe');
                    youtubeFrame.id = 'youtube-frame-' + videoNode.id;
                    youtubeFrame.className = 'youtube-frame';
                    let start = '';
                    let t = url.lastIndexOf('?t=');
                    if (url && t > 0) {
                        t = t + 2;
                        start = '&start' + url.substring(t);
                    }
                    youtubeFrame.src = videoNode.video.url + start;
                    youtubeFrame.onload = (() => {
                        if (!this.updating) {
                            // Autoplay video
                            this.sendPlayerCommand(videoNode.id, this.YTcmd_play, url);
                        }
                        this.updating = false;
                    });
                    youtubeFrame.setAttribute('type', 'text/html');
                    youtubeFrame.setAttribute('allowfullscreen', '');
                    youtubeFrame.setAttribute('frameborder', '0');
                }
                youtubediv.appendChild(youtubeFrame);
            } else {
                youtubediv.style.zIndex = '9999';
                if (this.backedit) {
                    this.backedit.style.zIndex = '9998';
                }
            }



            if (this.videoPosition === this.VIDEO_POSITION.ON_NODE) {
                // ON NODE
                youtubediv.style.position = 'absolute';
                youtubediv.style.width = width + 'px';
                youtubediv.style.height = h4_3 + 'px';
                youtubediv.style.left = p.x + 'px';
                youtubediv.style.top = p.y + 'px';

                youtubeFrame.style.position = '';
                youtubeFrame.style.left = '0px';
                youtubeFrame.style.top = '0px';
                youtubeFrame.style.width = width + 'px';
                youtubeFrame.style.height = h4_3 + 'px';
            } else {
                // CENTER SCREEN
                const ytRatio = 4 / 3;
                const perc = 0.9;
                const sw = window.innerWidth;
                const sh = window.innerHeight;
                const sr = sw / sh; let w = 0;
                let h = 0;
                if (sr > ytRatio) {
                    // Browser ratio: più largo: usa height
                    h = sh * perc;
                    w = h * ytRatio;
                } else {
                    // Browser ratio: più alto: usa width
                    w = sw * perc;
                    h = w / ytRatio;
                }
                const x = Math.trunc((window.innerWidth - w) / 2);
                const y = Math.trunc((window.innerHeight - h) / 2);
                youtubediv.style.position = 'absolute';
                youtubediv.style.width = w + 'px';
                youtubediv.style.height = h + 'px';
                youtubediv.style.left = x + 'px';
                youtubediv.style.top = y + 'px';
                youtubeFrame.style.position = '';
                youtubeFrame.style.left = '0px';
                youtubeFrame.style.top = '0px';
                youtubeFrame.style.width = youtubediv.style.width;
                youtubeFrame.style.height = youtubediv.style.height;
                width = w;
            }

            // CLOSE VIDEO BUTTON
            this.createCloseButton(youtubediv, videoNode.id, width);

            // DRAG VIDEO BAR WITH TITLE
            this.createDragBar(youtubediv, videoNode.id, width);

            // add video node in list
            let found = false;
            for (let i = 0; i < this.videoNodes.length; i++) {
                if (this.videoNodes[i].id === videoNode.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.videoNodes.push(videoNode);
            }

            // Autoplay video
            let uri = 'https://www.youtube.com';
            if (videoNode.mainLinkType === 'video' && videoNode.mainLinkIndex >= 0 && videoNode.mainLinkIndex < videoNode.links.length) {
                uri = videoNode.links[videoNode.mainLinkIndex].uri;
            }
            this.sendPlayerCommand(videoNode.id, this.YTcmd_play, uri);
        }
    }

    public updateYoutubeVideoInNode(node: any): any {
        if (this.smService) {
            const videoNodeId = node.id;
            if (this.smService.sm && this.smService.sm.map && this.smService.sm.map.nodes) {
                // && this.smService.sm.map.nodes.length < videoNodeId) {
                const videoNode = this.smService.sm.map.nodes[videoNodeId];

                if (videoNode) {
                    const youtubediv = document.getElementById('youtube-div-' + videoNode.id);
                    const youtubeFrame = document.getElementById('youtube-frame-' + videoNode.id) as HTMLIFrameElement;
                    const closeVideoButton = document.getElementById('youtube-exitbtn-' + videoNode.id);
                    if (youtubediv && youtubeFrame && closeVideoButton) {
                        if (node.video.url !== youtubeFrame.src) {
                            this.updating = true;
                            youtubeFrame.src = videoNode.video.url;
                            youtubeFrame.style.position = 'absolute';
                            closeVideoButton.style.position = 'absolute';
                        }
                    }
                }
            }
        }
    }

    public videoPictureInPicture() {
        console.log('pause and hide all videos');
        for (let i = 0; i < this.videoNodes.length; i++) {
            // hide backedit and apply Picture in Picture
            const youtubediv = document.getElementById('youtube-div-' + this.videoNodes[i].id);
            if (youtubediv) {
                if (this.backedit) this.backedit.style.zIndex = '-1000';
                if (youtubediv.style.zIndex !== '-1') {
                    this.showFloatingVideo(this.videoNodes[i].id);
                }
            }
        }
    }

    private showFloatingVideo(nodeId: string) {
        this.floatingVideoOpen = true;
        const width = this.floatingVideoWidth;
        const h4_3 = (width / 4 * 3);
        const youtubediv = document.getElementById('youtube-div-' + nodeId);
        const youtubeFrame = document.getElementById('youtube-frame-' + nodeId) as HTMLIFrameElement;
        const closeVideoButton = document.getElementById('youtube-exitbtn-' + nodeId);
        const dragVideoBar = document.getElementById('youtube-titlebar-' + nodeId);
        const dragMask = document.getElementById('youtube-dragmask-' + nodeId);
        if (youtubediv) {
            youtubediv.style.zIndex = '9999';
            youtubediv.style.width = width + 'px';
            youtubediv.style.height = (h4_3 + this.barHeight) + 'px';
            youtubediv.style.left = (window.innerWidth - width - this.closeButtonWidth) + 'px';
            youtubediv.style.top = this.floatingVideoStartTop + 'px';
        }
        youtubeFrame.style.position = 'absolute';
        youtubeFrame.style.left = '0px';
        youtubeFrame.style.top = '0px';
        youtubeFrame.style.width = width + 'px';
        youtubeFrame.style.height = h4_3 + 'px';
        // Update Bar, close button, drag mask and title length
        if (closeVideoButton) closeVideoButton.style.left = (width - this.closeButtonWidth) + 'px';
        if (dragVideoBar) dragVideoBar.style.width = (width - this.closeButtonWidth) + 'px';
        if (dragMask) {
            dragMask.style.height = h4_3 + this.barHeight + 'px';
            dragMask.style.width = width + 'px';
        }
        if (dragVideoBar) dragVideoBar.innerText = this.fixTitleLength(nodeId, width);
    }

    private hideAllFloatingVideos() {
        this.floatingVideoOpen = false;
        for (let i = 0; i < this.videoNodes.length; i++) {
            const youtubediv = document.getElementById('youtube-div-' + this.videoNodes[i].id);
            this.sendPlayerCommand(this.videoNodes[i].id, this.YTcmd_pause, 'https://www.youtube.com');
            if (youtubediv) {
                youtubediv.style.zIndex = '-1';
                if (this.backedit) this.backedit.style.zIndex = '-1000';
            }
        }
    }

    private sendPlayerCommand(nodeId: string, cmd: any, uri: string) {
        const videoFrame = document.getElementById('youtube-frame-' + nodeId);
        if (videoFrame) {
            const ytplayer = (<any>videoFrame).contentWindow;
            if (ytplayer) {
                ytplayer.postMessage(cmd, uri);

                // ytplayer.postMessage(cmd, 'https://www.youtube.com');
            }
        }
    }

    public clearAllVideos() {
        for (let i = 0; i < this.videoNodes.length; i++) {
            const youtubediv = document.getElementById('youtube-div-' + this.videoNodes[i].id);
            if (youtubediv) {
                const $body = document.getElementsByTagName('body');
                if ($body) {
                    const body = $body[0];
                    if (body) {
                        body.removeChild(youtubediv);
                    }
                }
            }
        }
    }

    public pauseAllVideos() {
        for (let i = 0; i < this.videoNodes.length; i++) {
            this.sendPlayerCommand(this.videoNodes[i].id, this.YTcmd_pause, 'https://www.youtube.com');
        }
    }

    private createCloseButton(youtubediv: any, nodeId: string, width: number) {
        let closeVideoButton = document.getElementById('youtube-exitbtn-' + nodeId);
        if (closeVideoButton === null) {
            closeVideoButton = document.createElement('button');
            closeVideoButton.addEventListener('click', (evt) => {
                this.hideAllFloatingVideos();
            });
            closeVideoButton.id = 'youtube-exitbtn-' + nodeId;
            closeVideoButton.innerText = 'X';
            closeVideoButton.style.position = 'absolute';
            closeVideoButton.style.width = this.closeButtonWidth + 'px';
            closeVideoButton.style.height = this.barHeight + 'px';
            closeVideoButton.style.top = '-' + this.barHeight + 'px';
            closeVideoButton.style.border = '0px';
            closeVideoButton.style.background = 'lightgray';
            closeVideoButton.style.cursor = 'pointer';
            closeVideoButton.title = this.translateService.instant('BUTTON_CLOSE');
            closeVideoButton.className = 'button-close-video';

            youtubediv.appendChild(closeVideoButton);
        }
        closeVideoButton.style.left = (width - this.closeButtonWidth) + 'px';
    }

    public ShowMask() {
        if (this.floatingVideoOpen && this.dragMask) {
            this.dragMask.style.zIndex = '10000';
        }
    }

    public HideMask() {
        if (this.dragMask) {
            this.dragMask.style.zIndex = '-10';
        }
    }

    private createDragBar(youtubediv: any, nodeId: string, width: number) {
        // DRAG MASK (necessaria per l'iframe di youtube)
        this.dragMask = document.getElementById('youtube-dragmask-' + nodeId);
        if (this.dragMask === null) {
            this.dragMask = document.createElement('div');
            this.dragMask.id = 'youtube-dragmask-' + nodeId;
            this.dragMask.style.background = '#ffffff01';
            this.dragMask.style.position = 'absolute';
            this.dragMask.style.top = '-' + this.barHeight + 'px';
            this.dragMask.style.left = '0px';
            const h4_3 = width / 4 * 3;
            this.dragMask.style.height = h4_3 + this.barHeight + 'px';
            this.dragMask.style.width = width + 'px';
            this.dragMask.style.zIndex = '-10';
            this.dragMask.style.cursor = 'move';
            this.dragMask.addEventListener('pointermove', (evt: any) => {
                if (this.dragging) {
                    if (this.startDrag) {
                        youtubediv.style.left = (evt.clientX - this.startDrag.x) + 'px';
                        youtubediv.style.top = (evt.clientY - this.startDrag.y + this.barHeight) + 'px';
                    } else {
                        youtubediv.style.left = (evt.clientX) + 'px';
                        youtubediv.style.top = (evt.clientY + this.barHeight) + 'px';
                    }
                }
            });
            youtubediv.appendChild(this.dragMask);
        }

        // DRAG BAR
        let dragVideoBar = document.getElementById('youtube-titlebar-' + nodeId);
        if (dragVideoBar === null) {
            dragVideoBar = document.createElement('div');
            dragVideoBar.id = 'youtube-titlebar-' + nodeId;
            dragVideoBar.style.background = 'lightgray';
            dragVideoBar.style.position = 'absolute';
            dragVideoBar.style.height = this.barHeight + 'px';
            dragVideoBar.style.top = '-' + this.barHeight + 'px';
            dragVideoBar.style.left = '0px';
            dragVideoBar.style.userSelect = 'none';
            dragVideoBar.style.fontFamily = 'Roboto';
            dragVideoBar.style.fontSize = '12px';
            dragVideoBar.style.padding = '4px';
            dragVideoBar.style.cursor = 'grab';

            dragVideoBar.addEventListener('pointerdown', (evt: any) => {
                if (this.floatingVideoOpen) {
                    this.dragging = true;
                    if (dragVideoBar) dragVideoBar.style.cursor = 'move';
                    this.dragMask.style.zIndex = '10000';
                    if (!this.startDrag) {
                        this.startDrag = new Point(0, 0);
                    }
                    this.startDrag.x = evt.offsetX;
                    this.startDrag.y = evt.offsetY;
                }
            });
            document.addEventListener('pointerup', (evt: any) => {
                this.dragging = false;
                if (dragVideoBar) dragVideoBar.style.cursor = 'grab';
                this.dragMask.style.zIndex = '-10';
            });
            document.addEventListener('pointermove', (evt: any) => {
                if (this.dragging) {
                    if (this.startDrag) {
                        youtubediv.style.left = (evt.clientX - this.startDrag.x) + 'px';
                        youtubediv.style.top = (evt.clientY - this.startDrag.y + this.barHeight) + 'px';
                    } else {
                        youtubediv.style.left = (evt.clientX) + 'px';
                        youtubediv.style.top = (evt.clientY + this.barHeight) + 'px';
                    }
                }
            });
            youtubediv.appendChild(dragVideoBar);
        }

        dragVideoBar.innerText = this.fixTitleLength(nodeId, width);
        dragVideoBar.style.width = (width - this.closeButtonWidth) + 'px';
    }

    /**
     * Fix title bar length based on video width
     * param nodeId
     * param width
     */
    private fixTitleLength(nodeId: string, width: number): string {
        const videoNode = this.smService.sm.map.nodes[nodeId];
        let title: string = videoNode.titleText;
        if (title === '') {
            title = videoNode.titleHtml;
        }
        const textLength = width / 8;
        if (title.length > textLength) {
            title = title.substr(0, textLength) + '...';
        }
        return title;
    }
}
