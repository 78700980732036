import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../array-noduplicate.validator';

@Component({
  selector: 'app-subs-config',
  templateUrl: './subs-config.component.html',
  styleUrls: ['./subs-config.component.scss']
})
export class SubsConfigComponent implements OnInit {

  @Input() subsForm = new FormGroup({
    accessName: new FormControl<string | null>('', { nonNullable: true })//,    licenceType: new FormControl<string | null>('', { nonNullable: true })
  });

  @Input() accessName = '';
  // @Input() licenceType: string = '';
  // @Input() showLicense: boolean = false;


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createForm();
    // this.initializeForm();
  }

  createForm() {

    this.subsForm = this.formBuilder.group({
      accessName: ['', Validators.required]//,
      // licenseType: ['']
    });
  }

  initializeForm() {
    this.subsForm.get('accessName')?.setValue(this.accessName);

    // if (this.showLicense) {
    //   this.subsForm.get('licenseType')?.setValue(this.licenceType as never);
    //   this.subsForm.get('licenseType')?.setValidators([Validators.required, this.licenseTypeValidator]);
    // }
  }

  get f() { return this.subsForm.controls; }

  public licenseTypeValidator(license: FormControl): { [s: string]: boolean } | null {
    if (license && license.value) {
      const value = license.value;
      if (value !== '') {
        if (value !== 'd' && value !== 'm' && value !== 's') {
        } else {
          return { invalidLicense: true };
        }
      }
    }
    return null;
  }

}
