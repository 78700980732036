export class ReadEntityDto {
    text: string = '';
    prefix: string = '';
    suffix: string = '';
    prefixSub: string = '';
    suffixSub: string = '';
    prefixSup: string = '';
    suffixSup: string = '';
    skipChild: boolean = false;

    public found() {
        return this.text || this.prefix || this.suffix || this.prefixSub || this.suffixSub || this.prefixSup || this.suffixSup;
    }
}

export interface MathReaderInterface {
    readEntity(op: string, elm: any, parentElm: any, accent: boolean, child0: any, child1: any): ReadEntityDto;
    getSimpleFraction(num: string, denom: string): string;
    getSimpleElevation(elev: string): string;
    getSimpleZeroFrom(symbol: string): string;
    getddx(): string;
}

