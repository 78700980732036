<div class="container mat-typography">
  <div class="brand">
    <img class="image" src="../../assets/logo-anastasis-sml.png" />
  </div>
  <!-- <div translate class="brand">APP_NAME</div> -->
  <div class="main-container">
    <div class="image-logo">
      <img class="image" src="../../assets/sm-logo-128.png" />
      <br /><br />
      <span class="message" translate>DOWNLOAD_CHROME<br />
        DOWNLOAD_CHROME2</span>
    </div>

    <div class="image-box">
      <a href="https://www.google.com/chrome/ " target="_blank ">
        <img class="image" src="../../assets/error/chrome_logo.png" />
      </a>
    </div>
  </div>
</div>
