<h2 mat-dialog-title class="title-text">{{title}}</h2>
<div mat-dialog-content class="mat-typography">
    <div class="message" [innerHTML]="message"></div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button *ngIf="cancelCaption" (click)="dialogRef.close(false)">
        <div>{{cancelCaption}}</div>
    </button>
    <button mat-flat-button color="primary" cdkFocusInitial (click)="dialogRef.close(ok)" translate>
        <div translate>{{okCaption}}</div>
    </button>
</div>
