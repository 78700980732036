<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="main-content">
    <div class="access-title">
        <a class="brand" routerLink="/home" translate matTooltip="{{'GDRIVE_PICKER_BACK_TO_HOME' | translate}}">
            <img class="img-title" src="../../assets/home/SMX_Doc_Header.png?v=1" />
        </a>
        <span class="access-subtitle">{{'USERDESKTOP_TITLE' | translate}} {{user}} </span>
    </div>

    <div class="list-notification">
        <app-admin-list-users [isLoading]="isLoading"></app-admin-list-users>
    </div>
</div>
