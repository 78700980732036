import { VirtualKeyboardLayout } from 'mathlive';

export const IT_PHYSICS_KEYBOARD: VirtualKeyboardLayout = {
    id: "PHYSICS",
    labelClass: 'MLK__tex',
    tooltip: '',
    rows: [
        // First row
        [
            '[separator-5]'
        ],
        // Second row
        [
            {
                latex: '\\Delta',
                tooltip: 'delta maiuscolo'
            },
            {
                latex: '\\alpha',
                tooltip: 'alfa'
            },
            {
                latex: '\\beta',
                tooltip: 'beta'
            },
            {
                latex: '\\gamma',
                tooltip: 'gamma'
            },
            {
                latex: '\\delta',
                tooltip: 'delta'
            },
            {
                latex: '\\Omega',
                tooltip: 'omega maiuscolo'
            },
            {
                latex: '\\omega',
                tooltip: 'omega'
            }
        ],
        // Third row
        [
            {
                latex: '\\overrightarrow{\\placeholder{}}',
                tooltip: 'vettore'
            },
            {
                latex: '\\propto',
                tooltip: 'proporzionale a'
            },
            {
                latex: '\\parallel',
                tooltip: 'parallelo'
            },
            {
                latex: ' \\perp',
                tooltip: 'perpendicolare'
            }
        ],
        // Fourth row
        [
            '[separator-5]'
        ]
    ]
};
