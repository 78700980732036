import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmEventBroker } from '../sm-event-broker';
import { UsersService } from '../commands/users.service';

interface IMarkerDto {
    left: string;
    top: string;
    icon: string;
    color: string;
    opacity: number;
}

@Component({
    selector: 'app-real-time-markers',
    templateUrl: './real-time-markers.component.html',
    styleUrls: ['./real-time-markers.component.scss']
})

export class RealTimeMarkersComponent implements OnInit, OnDestroy {

    private onPositionFeedbackSubscription: Subscription | undefined;

    public markers: Array<IMarkerDto> = new Array<IMarkerDto>();
    private running = false;

    constructor(
        private smEventBroker: SmEventBroker,
        private usersService: UsersService
    ) {
        this.markers = new Array<IMarkerDto>();
    }

    ngOnInit() {
        this.running = false;
        this.startAnimation();
        // setTimeout(() => {
        //   this.addMarker(0, 0, 'https://lh3.googleusercontent.com/a-/AOh14Gg7ikY_7PqxHDe-SRd_rGPTRAGrFsZpkMWnJ6oz=s96-c', 'Fabrizio Piazza', '#ff0000');
        // }, 10000);

        if (this.smEventBroker && this.smEventBroker.onPositionFeebackChangedData) {
            this.onPositionFeedbackSubscription = this.smEventBroker.onPositionFeebackChangedData.subscribe((data: any) => {
                if (data !== null && data.globalPoints && data.globalPoints.length > 0) {
                    const user = this.usersService.findUserByEmail(data.email);
                    if (user) {
                        for (let i = 0; i < data.globalPoints.length; i++) {
                            this.addMarker(data.globalPoints[i].x, data.globalPoints[i].y, user.user.icon, user.user.color);
                        }
                    }
                }
            });
        }

    }

    ngOnDestroy() {
        if (this.onPositionFeedbackSubscription) { this.onPositionFeedbackSubscription.unsubscribe(); }
    }

    addMarker(x: number, y: number, icon: string, color: string) {
        let marker: IMarkerDto;
        if (x === Number.MIN_VALUE && y === Number.MIN_VALUE) {
            // Near canvas origin (eg: change map background)
            marker = { left: '20px', top: '20px', icon: icon, color: color, opacity: 100.0 };
        } else {
            marker = { left: Math.trunc(x) + 'px', top: Math.trunc(y) + 'px', icon: icon, color: color, opacity: 100.0 };
        }
        this.markers.push(marker);
    }

    removeMarker(index: number) {
        if (index >= 0 && index < this.markers.length) {
            this.markers.splice(index, 1);
        }
    }

    startAnimation() {
        setTimeout(() => {
            this.running = true;
            this.animationStep();
        }, 0);
    }

    stopAnimation() {
        this.running = false;
    }

    animationStep() {
        if (this.running) {
            for (let i = 0; i < this.markers.length; i++) {
                const marker = this.markers[i];
                marker.opacity = marker.opacity * 0.5;
                if (marker.opacity < 0.01) {
                    this.removeMarker(i);
                }
            }
            setTimeout(() => {
                this.animationStep();
            }, 100);
        }
    }

}
