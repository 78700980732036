import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-paste-popup',
  templateUrl: './copy-paste-popup.component.html',
  styleUrls: ['./copy-paste-popup.component.scss']
})
export class CopyPastePopupComponent implements OnInit {

  constructor() { }

  public COPY_COMMAND = 'Ctrl+C';
  public CUT_COMMAND = 'Ctrl+X';
  public PASTE_COMMAND = 'Ctrl+V';
  ngOnInit() {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    if (isMacLike) {
      this.COPY_COMMAND = 'Cmd+C';
      this.CUT_COMMAND = 'Cmd+X';
      this.PASTE_COMMAND = 'Cmd+V';
    }
  }

}
