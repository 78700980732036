import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MapStateService } from '../map-state.service';
import { Subscription } from 'rxjs';
import { ResizeEvent } from 'angular-resizable-element';
import { MapEditService } from '../../map-edit/map-edit.service';
import { SmService } from 'supermappe-core';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { ChatService } from './chat.service';
import { UsersService } from '../commands/users.service';

@Component({
  selector: 'app-chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.scss']
})
export class ChatPanelComponent implements OnInit, OnDestroy {

  @ViewChild('chatHistory') chatHistory: ElementRef | undefined;
  @ViewChild('chatMessage') chatMessage: ElementRef | undefined;
  @ViewChild('unreadMarker') unreadMarker: ElementRef | undefined;

  public style: object = {};
  public styleover: object = {};
  public chatHistoryHeight = '100px'; // Sostituito in fase di init

  onResizeSubscription: Subscription | undefined;
  onChatMessageReceivedSubscription: Subscription | undefined;
  onUserLoadedReceived: Subscription | undefined;
  onChatClearedSupscription: Subscription | undefined;
  resizing = false;
  isChatPanelOpen = false;
  newWidth = 0;

  public items: Array<any>;
  public numUsers: number;
  public isMapOwner: boolean;

  constructor(
    private chatService: ChatService,
    private mapStateService: MapStateService,
    private mapEditService: MapEditService,
    private smService: SmService,
    private quickEditService: QuickEditService,
    private usersService: UsersService
  ) {
    this.items = new Array<any>();
    this.numUsers = 0;
    this.isMapOwner = false;
  }

  ngOnInit() {
    this.isMapOwner = this.chatService.isMapOwner;
    this.onChatClearedSupscription = this.chatService.onChatCleared.subscribe(() => {
      this.items = JSON.parse(JSON.stringify(this.chatService.items));
    });
    this.onUserLoadedReceived = this.usersService.onUsersLoaded.subscribe(() => {
      this.numUsers = this.usersService.users.length;
      this.chatService.buildItemsList();
      this.items = JSON.parse(JSON.stringify(this.chatService.items));
    });
    // gestisci resize
    this.onResizeSubscription = this.mapEditService.onResize.subscribe(() => {
      // console.log(`**********RESIZE SUBSCRIPTION`);
      this.resizeChat();
      this.newWidth = 0;
    });
    this.isChatPanelOpen = true;
    this.chatService.isChatPanelOpen = true;
    // Notifica messaggi Chat
    this.onChatMessageReceivedSubscription = this.chatService.onChatMessageReceived.subscribe(() => {
      const now1 = new Date().toISOString();
      this.chatService.updateReadingTimestamp(now1);
      this.chatService.applyUsersColor();
      this.items = JSON.parse(JSON.stringify(this.chatService.items));
      setTimeout(() => {
        this.scrollDown();
      }, 100);
    });
    // Acquisisce stato iniziale dei messaggi (deep copy)
    this.items = JSON.parse(JSON.stringify(this.chatService.items));
    this.numUsers = this.usersService.users.length;
    const now2 = new Date().toISOString();
    this.chatService.updateReadingTimestamp(now2);
    setTimeout(() => {
      this.resizeChat();
      if (this.unreadMarker && this.unreadMarker.nativeElement) {
        this.unreadMarker.nativeElement.scrollIntoView();
      } else {
        this.scrollDown();
      }
      this.chatMessage?.nativeElement.focus();
    }, 100);
  }

  resizeChat() {
    const height = window.innerHeight - 250;
    this.chatHistoryHeight = height.toString() + 'px';
  }

  scrollDown() {
    if (this.chatHistory?.nativeElement) {
      const element = this.chatHistory.nativeElement;
      element.scrollTop = element.scrollHeight;
    }
  }

  onResizeStart(event: any) {
    // console.log(`++++++++++RESIZE START`);
    this.resizing = true;
    //  this.mapinteraction = true;
    // this.editAppunti(false);
    this.mapStateService.setFocusOnMap(false);
    this.mapStateService.canEnableQuickEdit = false;
    this.smService.setEnableKeyPresses(false);
    this.quickEditService.onCanEdit.emit(false);
    this.mapStateService.setEditingState(true);
  }

  public validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX = 290;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }

  public onResizing(event: ResizeEvent) {
    // console.log(`++++++++++RESIZING`);
    if (this.validate(event)) {
      // this.overPanel.nativeElement.width = event.rectangle.width;
      this.styleover = {
        position: 'fixed',
        left: `${event.rectangle.left}px`,
        top: `${event.rectangle.top}px`,
        width: `${event.rectangle.width}px`,
        height: `${event.rectangle.height}px`
      };
    }
  }

  onResizeEnd(event: any) {
    // console.log(`++++++++++RESIZE END`);
    this.resizing = false;
    // this.overPanel.nativeElement.hidden = true;
    // setTimeout(() => {
    //     this.cd.detectChanges();
    // });
    this.newWidth = event.rectangle.width;
    this.mapStateService.setFocusOnMap(true);
    this.mapStateService.canEnableQuickEdit = true;
    this.smService.setEnableKeyPresses(true);
    this.quickEditService.onCanEdit.emit(true);
    this.mapStateService.setEditingState(false);
    this.style = {
      width: `${event.rectangle.width}px`
    };
    this.styleover = {
      position: 'fixed',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };

    let w = 290;
    if (event.rectangle.width > 290) {
      w = event.rectangle.width;
    }
    this.chatService.resizeChatPanel();
  }

  inputGotFocus() {
    // console.log(`**********INPUT GOT FOCUS`);
    this.mapStateService.setFocusOnMap(false);
    this.mapStateService.canEnableQuickEdit = false;
    this.smService.setEnableKeyPresses(false);
    this.quickEditService.onCanEdit.emit(false);
    // this.mapStateService.setEditingState(true);
  }

  inputLostFocus() {
    // console.log(`**********INPUT LOST FOCUS`);
    this.mapStateService.setFocusOnMap(true);
    this.mapStateService.canEnableQuickEdit = true;
    this.smService.setEnableKeyPresses(true);
    this.quickEditService.onCanEdit.emit(true);
    // this.mapStateService.setEditingState(false);
  }

  closeChat() {
    if (this.quickEditService.isQuickEditEnabled()) {
      this.quickEditService.toggleQuickEdit();
    }
    if (this.quickEditService.isSpeechEditEnabled()) {
      this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.NOTES);
    }
    this.chatService.toggleChatPanel();
  }

  keypress(event: any) {
    if (event.charCode === 13 && event.altKey === false && event.ctrlKey === false && event.metaKey === false && event.shiftKey === false) {
      this.sendMessage();
    }
  }

  sendMessage() {
    if (this.chatMessage?.nativeElement.value) {
      const msg = {
        timestamp: new Date().toISOString(),
        text: this.chatMessage.nativeElement.value.trimEnd()
      };
      this.chatService.sendMessage(msg);
      this.chatMessage.nativeElement.value = '';
      setTimeout(() => {
        if (this.chatMessage)  this.chatMessage.nativeElement.value = '';
      }, 100);
    }
  }

  clearChat() {
    this.chatService.clearChat();
  }

  ngOnDestroy(): void {
    this.smService.setEnableKeyPresses(true);
    if (this.onResizeSubscription) { this.onResizeSubscription.unsubscribe(); }
    if (this.onChatMessageReceivedSubscription) { this.onChatMessageReceivedSubscription.unsubscribe(); }
    if (this.onUserLoadedReceived) { this.onUserLoadedReceived.unsubscribe(); }
    this.quickEditService.onCanEdit.emit(true);
    this.mapStateService.setEditingState(false);
    this.isChatPanelOpen = false;
    this.chatService.isChatPanelOpen = false;
  }
}
