import { Component, OnInit, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfMasterComponent } from '../pdf-master/pdf-master.component';
import { subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { Subscription } from 'rxjs';
import { param } from 'jquery';
import { PdfService } from '../pdf.service';
import { RecentPdfDto } from '../pdf-home/recent-pdf-dto';
import { SmeService } from 'src/app/core/sme/sme.service';

const win: any = window;

@Component({
    selector: 'app-pdf-print',
    templateUrl: './pdf-print.component.html',
    styleUrls: ['./pdf-print.component.scss']
})
export class PdfPrintComponent implements AfterViewInit {
    @ViewChild('pdfMaster') pdfMaster: PdfMasterComponent | undefined;
    public filename: string = "";
    public decodedFilename: string | null = "";
    paramsSubscription: Subscription | undefined;
    currentPdf: RecentPdfDto | undefined;



    constructor(private route: ActivatedRoute, private smeService: SmeService) {

    }

    ngAfterViewInit(): void {
        this.paramsSubscription = this.route.params.subscribe(params => {
            this.filename = params['filename'];
            this.smeService.getPdfStorageById(this.filename).subscribe({
                next: (data) => {

                    this.openPDF(data.storageRef);
                },
                error: (error) => {
                    console.log(error);

                }
                // this.pdfService.getRecents().subscribe((pdfs: Array<RecentPdfDto>) => {
                //     //this.decodedFilename = atob(this.filename);
                //     this.currentPdf = this.pdfService.getRecentById(this.filename);
                //     if (this.currentPdf) {
                //         this.openPDF(this.currentPdf?.storageRef);
                //     }
                // });

            });


        });
    }








    private openPDF(pdf: any, name: string = '') {
        this.pdfMaster?.openPdf(pdf);
        if (name !== '') this.pdfMaster?.setDownloadFilename(name);


    }
    endLoadedPdf(data: any) {
        console.log("End load PDF");
        if (win.PDFViewerApplication.pdfViewer.pageViewsReady) {
            //window.print();
            // const btn = document.getElementById("print");
            // btn?.click();
            //win.PDFViewerApplication.triggerPrinting();
            this.pdfMaster?.print();

        } else {
            setTimeout(() => {
                this.endLoadedPdf(data);
            }, 100);
        }
    }
}
