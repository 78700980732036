<!-- [validateResize]="validate" -->
<div id="extra-container" [ngStyle]="style" class="extra-container" mwlResizable [enableGhostResize]="false" [validateResize]="validate" (resizeEnd)="onResizeEnd($event)" (resizeStart)="onResizeStart($event)" (resizing)="onResizing($event)" [resizeSnapGrid]="{ left: 1, right: 1 }">
    <app-notepad [hidden]="extraOpenDto.extraItem !== 'open_notepad'"></app-notepad><!--*ngIf="extraOpenDto.extraItem === 'open_notepad'"-->
    <app-outline *ngIf="extraOpenDto.extraItem === 'open_outline'"></app-outline>
    <app-web-panel *ngIf="extraOpenDto.extraItem === 'open_browser'" [editUrl]="true" [url]="extraOpenDto.safeUrl"></app-web-panel>
    <app-autodraw *ngIf="extraOpenDto.extraItem === 'open_autodraw'" [url]="extraOpenDto.safeUrl"></app-autodraw>
    <app-pdf-editor [hidden]="extraOpenDto.extraItem !== 'open_pdf'"></app-pdf-editor>
    <div id="resize-handle" class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
</div>

<div class="over-panel" [ngStyle]="styleover" #overpanel *ngIf="resizing"></div>
