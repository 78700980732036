import { Component, OnInit, OnDestroy } from '@angular/core';
import { HistoryMapDto } from './history-map-dto';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MapStateService } from '../shared/map-state.service';
import { SmeService } from '../core/sme/sme.service';

import { Subscription } from 'rxjs';
import { CopyAndOpenMapService } from '../shared/commands/copy-and-open-map.service';
import { Title } from '@angular/platform-browser';

import { MessageBoxService } from '../shared/dialog/messagebox.service';

@Component({
  selector: 'app-map-history',
  templateUrl: './map-history.component.html',
  styleUrls: ['./map-history.component.scss']
})


export class MapHistoryComponent implements OnInit, OnDestroy {

  public recentPreview = false;
  public historyMaps: HistoryMapDto[] = new Array<HistoryMapDto>();
  public historyMapsSorted: HistoryMapDto[] = new Array<HistoryMapDto>();
  public historyMapsMain: HistoryMapDto[] = new Array<HistoryMapDto>();
  public isLoading = false;
  public selectedItem: any;
  public selectedHistory: HistoryMapDto | undefined;
  public imageBase64: any;
  public panelOpenState: boolean = false;
  error = '';
  noMap = false;
  mapId = '';
  public mapName = '';
  initialized = false;
  currRevId = '';

  paramMapSubscription: Subscription | undefined;
  userMapsRevisionsSubscription: Subscription | undefined;
  restoreRevisionSubscription: Subscription | undefined;
  createWorkMapFromGoogleDriveSubscription: Subscription | undefined;
  getMapJpegFromRevisionSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router, private smeService: SmeService,
    private mapStateService: MapStateService,
    private titleService: Title,
    private copyAndOpenMapService: CopyAndOpenMapService,
    private messageBoxService: MessageBoxService
  ) {
  }


  ngOnInit() {
    this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
      this.mapId = params['mapId'];
      this.initialized = false;
      this.getUserMapsRevisions();
      const newTitle: string = this.mapStateService.name;
      this.mapName = newTitle;
      this.titleService.setTitle(newTitle);
    });
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
    if (this.userMapsRevisionsSubscription) { this.userMapsRevisionsSubscription.unsubscribe(); }
    if (this.restoreRevisionSubscription) { this.restoreRevisionSubscription.unsubscribe(); }
    if (this.createWorkMapFromGoogleDriveSubscription) { this.createWorkMapFromGoogleDriveSubscription.unsubscribe(); }
    if (this.getMapJpegFromRevisionSubscription) { this.getMapJpegFromRevisionSubscription.unsubscribe(); }
  }

  trackByFn(index: any, item: HistoryMapDto) {
    return item.id; // unique id corresponding to the item
  }

  private getUserMapsRevisions() {
    this.historyMaps = [];
    this.historyMapsMain = [];
    this.isLoading = true;


    console.log('map Id' + this.mapId);
    if (this.userMapsRevisionsSubscription) { this.userMapsRevisionsSubscription.unsubscribe(); }
    this.userMapsRevisionsSubscription = this.smeService.userMapsRevisions(this.mapId).subscribe({
      next: (resp: any) => {
        console.log('resp:' + resp);
        const maps: HistoryMapDto[] = [];
        if (resp.result.revisions) {
          let lastdate = 'undefined';
          resp.result.revisions.forEach((history: any) => {
            this.historyMaps.push(new HistoryMapDto(history));
            if (history.modifiedTime.indexOf(lastdate) < 0) {
              lastdate = history.modifiedTime.substr(0, history.modifiedTime.indexOf('T'));
              maps.push(new HistoryMapDto(history));
              //                this.historyMapsMain.push(new HistoryMapDto(history));

            }
          });
          this.sortMain(maps);
          // this.sortHistory();
          // this.viewMapImage(this.historyMapsSorted[0].id);
          this.viewMapImage(this.historyMapsMain[0]);

          this.isLoading = false;
        }
      },
      error: ((error: Response) => {
        this.error = error.statusText;
      })
    });
  }

  public loadHistoryMap(date: any) {
    const maps: HistoryMapDto[] = [];
    this.historyMapsSorted = [];
    this.historyMaps.forEach((history: HistoryMapDto) => {
      if (history.lastDayUpdate.toLocaleLowerCase() === (date.toLocaleLowerCase())) {
        maps.push(history);
      }

    });
    this.sortHistory(maps);

  }

  public sortHistory(maps: HistoryMapDto[]) {
    this.historyMapsSorted = maps.sort((n1, n2) => {
      return n2.lastUpdate.getTime() - n1.lastUpdate.getTime();
    });
  }

  public sortMain(mainMaps: HistoryMapDto[]) {
    this.historyMapsMain = mainMaps.sort((n1, n2) => {
      return n2.lastUpdate.getTime() - n1.lastUpdate.getTime();
    });
  }

  restoreRevision() {
    this.isLoading = true;

    if (this.restoreRevisionSubscription) { this.restoreRevisionSubscription.unsubscribe(); }
    this.restoreRevisionSubscription = this.smeService.restoreRevision(this.mapId, this.currRevId).subscribe({
      next: (googleFileId) => {
        console.log('fileID' + googleFileId);
        if (this.createWorkMapFromGoogleDriveSubscription) { this.createWorkMapFromGoogleDriveSubscription.unsubscribe(); }
        this.createWorkMapFromGoogleDriveSubscription = this.smeService.createWorkMapFromGoogleDrive(googleFileId).subscribe({
          next: (data: any) => {
            this.router.navigate(['/map-edit', data.mapId]);
          },
          error: ((error: Response) => {
            this.error = error.statusText;
            this.isLoading = false;
          })
        });
      }, error: ((error: any) => {
        if (error.status === 500 && error.error && error.error.message === 'The domain administrators have disabled Drive apps.') {
          this.messageBoxService.showAdminDisabled3rdPartyApps().subscribe(() => {
            this.router.navigate(['home/']);
          });
        } else {
          this.error = error.statusText;
          this.isLoading = false;
        }
      })
    });


  }

  copyRevision() {
    this.copyAndOpenMapService.copyAndOpenRevision(this.currRevId, this.mapId, this.mapStateService.name);
  }

  goback() {
    this.router.navigate(['/map-edit', this.mapId]);
  }

  viewMapImage(history: HistoryMapDto) {
    // if (this.initialized) {

    if (!this.isLoading && this.historyMaps) {
      this.selectedItem = this.historyMaps.find(x => x.id === history.id)?.lastUpdate.toLocaleString();
      this.selectedHistory = history;
      this.currRevId = history.id;

      this.isLoading = true;

      console.log('map Id' + this.mapId);
      if (this.getMapJpegFromRevisionSubscription) { this.getMapJpegFromRevisionSubscription.unsubscribe(); }
      this.getMapJpegFromRevisionSubscription = this.smeService.getMapJpegFromRevision(this.mapId, history.id,
        this.selectedItem).subscribe({
          next: (resp: any) => {
            console.log('image:' + resp.result);
            // const ref = resp.result;
            this.imageBase64 = resp.result;
            this.isLoading = false;
          },
          error: ((error: Response) => {
            this.error = error.statusText;
            this.isLoading = false;
          })
        });


    }
  }

}
