import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ExtraItem, ExtraService } from '../../commands/extra.service';
import { Subject, Subscription, take, tap } from 'rxjs';
import { SmService } from 'supermappe-core';
import { MapStateService } from '../../../shared/map-state.service';
import { UiConstants } from '../../../shared/ui-constants';
import { CustomSelectElement } from '../../toolbar/custom-toolbar/custom-select-element-dto';
import { TtsService } from '../../../shared/commands/tts.service';
import { TranslateService } from '@ngx-translate/core';
import { QuickEditService } from '../../../shared/commands/quick-edit.service';

import { CropDimensions, NgxCaptureService } from 'ngx-capture';
import { SpeechRecognitionService } from '../../../shared/speech/speechrecognition.service';
import { ImageMapsService } from '../../commands/image-maps.service';
import { MapClipboardService } from '../../commands/map-clipboard.service';
import { GoogleService } from 'src/app/core/google/google.service';
import { I18nService, extract } from 'src/app/core/i18n.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { ResourceUpload } from 'src/app/core/firebase/resource-upload';

import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UserDesktopComponent } from 'src/app/access-config/user-desktop/user-desktop.component';
import { RecentPdfDto } from './recent-pdf-dto';
import { PdfService } from '../pdf.service';
import { Html2CanvasOptions } from 'jspdf';
import { ConfirmationService } from 'src/app/shared/dialog/confirmation.service';
import { FirebaseService } from 'src/app/core/firebase/firebase.service';
import { onSnapshot } from 'firebase/firestore';
import { MessageBoxService } from 'src/app/shared/dialog/messagebox.service';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';


@Component({
    selector: 'app-pdf-home',
    templateUrl: './pdf-home.component.html',
    styleUrls: ['./pdf-home.component.scss']
})


// eslint-disable-next-line @angular-eslint/component-class-suffix
// export class PdfRecentDto {
//     public title: string = '';
//     public storageRef: any;
//     public preview: any;
//     public lastOpened: Date | undefined;

// }
export class PdfHomeComponent implements OnInit {

    @Output() fromLocal: EventEmitter<any> = new EventEmitter<any>();
    @Output() fromLink: EventEmitter<any> = new EventEmitter<any>();
    @Output() fromDrive: EventEmitter<any> = new EventEmitter<any>();
    @Output() fromRecent: EventEmitter<RecentPdfDto> = new EventEmitter<RecentPdfDto>();
    @Output() dowloadRecent: EventEmitter<RecentPdfDto> = new EventEmitter<RecentPdfDto>();
    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();

    @Output() renamePDF: EventEmitter<RecentPdfDto> = new EventEmitter<RecentPdfDto>();
    @ViewChild('linkpdf') linkpdf: any;

    public currentItem: RecentPdfDto = new RecentPdfDto();

    public pdfRecents: RecentPdfDto[] = [];
    public recentPdfsSortedFiltered: RecentPdfDto[] = [];
    getPdfRecentSubscription: Subscription | undefined;
    dbListenerPdfUnsubscribeRef: any;
    dbListenerPdfUnsubscribe: any;
    credential: any;

    dragAreaClass: string = '';
    error: string = '';
    googleUser: boolean = false;


    constructor(private pdfService: PdfService, private smService: SmService, private mapStateService: MapStateService, private confirmationService: ConfirmationService,
        private translateService: TranslateService, private messageBoxService: MessageBoxService, private firebaseAuthService: FirebaseAuthService, private authenticationService: AuthenticationService) {
        // const userId = this.authenticationService.credentials?.firebaseUserId;
        // this.credential = this.authenticationService.credentials;
        // if (userId) {
        //     this.initFirestoreListener(userId)
        // }
        this.googleUser = this.firebaseAuthService.isNativeGoogleUser() || this.linkedWithGoogle();
        this.pdfRecents = this.pdfService.recentsPdf;
        this.recentPdfsSortedFiltered = this.pdfService.applyFilter('');
        this.sortRecentPdfs('date');

        //this.pdfRecents = this.pdfService.recentsPdf;

    }

    linkedWithGoogle() {
        // return (this.autenticationService.credentials && this.autenticationService.credentials.username ? true : false);
        return this.authenticationService.linkedWithGoogle();
    }



    // initFirestoreListener(userId: string, idPdf: string = '') {
    //     const promises: Array<any> = [];
    //     const events: Array<any> = [];
    //     this.dbListenerPdfUnsubscribeRef = this.firebaseService.getFirestoreRecentPdfListener(userId);
    //     this.dbListenerPdfUnsubscribe = onSnapshot(this.dbListenerPdfUnsubscribeRef, (querySnapshot: any) => {
    //         querySnapshot.docChanges().forEach((change: any) => {


    //             console.log(`----->LISTENER: A new transaction has been ADDED with ID: ${change.doc.id}`);
    //             const promise = this.firebaseService.getPdfRecent(this.credential.firebaseUserId, change.doc.id, events);
    //             promises.push(promise);

    //             //  }
    //             /// 
    //             //   }

    //         });
    //         let endPromise = false;
    //         Promise.all(promises).then(() => {
    //             if (events && events.length > 0) {
    //                 let i = 0;
    //                 while (i < events.length && !endPromise) {
    //                     const ev = events[i];
    //                     if (this.mapStateService.guid !== ev.guid) {
    //                         this.startLoading.emit();
    //                         endPromise = true;
    //                         this.getRecents();
    //                     } else {
    //                         i++;
    //                     }
    //                 }



    //             }

    //         });

    //     });
    // }


    public getRecents() {
        if (this.getPdfRecentSubscription) this.getPdfRecentSubscription.unsubscribe();
        this.getPdfRecentSubscription = this.pdfService.getRecents().subscribe({
            next: (recents) => {
                this.startLoading.emit();

                this.pdfRecents = this.loadRecent(recents);
                this.recentPdfsSortedFiltered = this.pdfService.applyFilter('');
                this.sortRecentPdfs('date');
                this.endLoading.emit();
            },
            error: (error) => {
                console.log(error);
            }
        });

    }

    private loadRecent(recents: RecentPdfDto[]): RecentPdfDto[] {
        const result: RecentPdfDto[] = [];
        recents.forEach(recent => {

            recent.dateLastOpened = new Date(recent.lastOpened);
            recent.dateLastUpdated = new Date(recent.lastUpdated);
            result.push(recent);


        });
        return result;

    }

    public sortRecentPdfs(type: string) {
        if (type === 'alphabetical') {
            this.recentPdfsSortedFiltered = this.pdfRecents.sort((n1, n2) => {
                const nameA: string = n1.name.toLowerCase();
                const nameB: string = n2.name.toLowerCase();
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }
                return 0;
            });
        } else {
            this.recentPdfsSortedFiltered = this.pdfRecents.sort((n1, n2) => {
                const n1Time = (n1.dateLastOpened ? n1.dateLastOpened.getTime() : new Date().getTime());
                const n2Time = (n2.dateLastOpened ? n2.dateLastOpened.getTime() : new Date().getTime());
                return (n2Time - n1Time);
                // const n1Time = (n1.dateLastOpened ? n1.dateLastOpened : new Date());
                // const n2Time = (n2.dateLastOpened ? n2.dateLastOpened : new Date());
                // if (n1Time < n2Time) {
                //     return 1;
                // }
                // if (n1Time > n1Time) {
                //     return -1;
                // }
                // return 0;
            });
        }

    }


    onFileChange(event: any) {
        const files: FileList = event.target.files;
        this.saveFiles(files);
    }
    ngOnInit() {
        this.dragAreaClass = "dragarea";
    }
    @HostListener("dragover", ["$event"]) onDragOver(event: any) {
        this.dragAreaClass = "droparea";
        event.preventDefault();
    }
    @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
        this.dragAreaClass = "droparea";
        event.preventDefault();
    }
    @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
        this.dragAreaClass = "dragarea";
        event.preventDefault();
    }
    @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
        this.dragAreaClass = "dragarea";
        event.preventDefault();
    }
    @HostListener("drop", ["$event"]) onDrop(event: any) {
        this.dragAreaClass = "dragarea";
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            const files: FileList = event.dataTransfer.files;
            this.saveFiles(files);
        }
    }

    saveFiles(files: FileList) {

        if (files.length > 1) this.error = "Only one file at time allow";
        else {
            this.error = "";
            console.log(files[0].size, files[0].name, files[0].type);
            if (files[0].type === 'application/pdf') {

                this.fromLocal.emit(files[0]);

            } else {
                this.messageBoxService.showTextMessage('OK', this.translateService.instant(extract('WARNING')), this.translateService.instant(extract('INVALID_PDF')));

            }
            //this.fromLocal.emit(files[0]);
        }
    }
    onChangeUrl(event: any) {
        //
        if (event && event.target)
            this.fromLink.emit(event.target.value);

    }

    disableMap() {
        this.smService.setEnableKeyPresses(false);
        this.mapStateService.setFocusOnMap(false);
    }


    // downloadPdf(filename: string) {
    //     this.pdfService.dowmnloadPdf(filename);
    // }

    downloadRecent(item: RecentPdfDto) {
        this.dowloadRecent.emit(item);

    }

    removePDF(item: RecentPdfDto) {
        this.confirmationService.confirm(
            this.translateService.instant(extract('WARNING')),
            this.translateService.instant(extract('CONFIRM_DELETE_PDF')),
            this.translateService.instant(extract('BUTTON_OK')),
            this.translateService.instant(extract('GENERAL_CANCEL')))
            .subscribe((result: any) => {
                if (result === true) {
                    this.pdfRecents = this.pdfService.removePDF(item);
                    this.recentPdfsSortedFiltered = this.pdfRecents;
                    this.sortRecentPdfs('date');
                }
            });

    }

    renamePdf(item: RecentPdfDto) {
        this.disableMap();
        this.renamePDF.emit(item);
    }
    //(openPdf)="loadPdfFromRecent(recentPdf)"  (renamePdf)="renamePdf(recentPdf)"  (downloadPdf)="downloadPdf(recentPdf)"></app-pdf-recents-menu>

    loadPdfFromRecent(item: RecentPdfDto) {
        this.fromRecent.emit(item);
    }





    itemMenuClick(item: RecentPdfDto, event: any) {

    }



    openPDFFromLocal(event: any) {
        if (event.target.files && event.target.files.length > 0 && event.target.files[0].type === 'application/pdf') {
            this.fromLocal.emit(event);
        } else {
            this.messageBoxService.showTextMessage('OK', this.translateService.instant(extract('WARNING')), this.translateService.instant(extract('INVALID_PDF')));
        }
    }


    openPDFFromDrive() {
        this.fromDrive.emit();
    }

    openPDFLink() {
        this.fromLink.emit(this.linkpdf.nativeElement.value);
        //
    }
}
