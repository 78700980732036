import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TokenInterceptor } from '../../core/http/token.interceptor';

export class ImageData {
  private title: string;
  private imageUrl: string;
  private imageHeight: number;
  private imageWidth: number;
  private thumbnailUrl: string;
  private thumbnailHeight: number;
  private thumbnailWidth: number;
  constructor(title: string, imageUrl: string, imageHeight: number, imageWidth: number,
    thumbnailUrl: string, thumbnailHeight: number, thumbnailWidth: number) {
    this.title = title;
    this.imageUrl = imageUrl;
    this.imageHeight = imageHeight;
    this.imageWidth = imageWidth;
    this.thumbnailUrl = thumbnailUrl;
    this.thumbnailHeight = thumbnailHeight;
    this.thumbnailWidth = thumbnailWidth;
  }
  public safeDownloadURL(): string {
    let safeURL: string = this.thumbnailUrl;
    if (this.imageUrl.endsWith('.png') || this.imageUrl.endsWith('.jpg')) {
      safeURL = this.imageUrl;
    }
    return safeURL;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleImagesService {

  // private BASE_URL = 'https://www.googleapis.com/customsearch/v1';
  // private PROXY_BASE_URL = '/googleimages';
  private PROXY_BASE_URL = 'https://www.google.com/imghp?safe=active&tbs=sur:fm&tbm=isch';
  private API_KEY = 'AIzaSyARMsA_bJV0AXWPS2qVgaNEQ2HtazB4uvg';
  private CSE_ID = '004756291684158485207:zhj0cimuatm';
  private NUM_RESULTS = 10; // 1-10 values ONLY!!!

  // Observable
  private imageList: BehaviorSubject<ImageData[]> | undefined;
  // Data store
  private _imageList: ImageData[] | undefined;
  // Search Terms
  private searchTerms = '';
  // Current Index (for currentPage)
  private currentIndex = 1;
  private prevIndex = 1;
  private nextIndex = 1;


  constructor(private http: HttpClient) {
    this.http = http;
    this._imageList = new Array<ImageData>();
    this.imageList = new BehaviorSubject<ImageData[]>(this._imageList);
  }

  public getImageList() {
    return this.imageList;
  }

  public getCurrentPage() {
    return ((this.currentIndex - 1) / this.NUM_RESULTS) + 1;
  }

  public search(searchTerms: string): void {
    this.currentIndex = 1;
    this.searchTerms = searchTerms;
    this.doSearch(this.searchTerms, this.currentIndex);
  }

  public prevPage(): boolean {
    if (this.searchTerms && this.searchTerms !== '') {
      this.doSearch(this.searchTerms, this.prevIndex);
      return true;
    }
    return false;
  }

  public nextPage(): boolean {
    if (this.searchTerms && this.searchTerms !== '') {
      this.doSearch(this.searchTerms, this.nextIndex);
      return true;
    }
    return false;
  }

  private doSearch(searchTerms: string, startIndex: number) {
    const url = this.makeRequest(searchTerms, startIndex);
    console.log(url);
    this.http.get(url, { headers: TokenInterceptor.buildNoTokenHeaders() }).subscribe({
      next: (value) => {
        console.log('OK! ' + value);
        this.updateImageData(value);
      },
      error: (error) => {
        console.log('ERROR ' + error.status + ': ' + error.text() + ' URL=' + error.url);
      }
    });

  }

  // https://www.google.com/imghp?safe=active&tbs=sur:fm&tbm=isch
  // serve parametro rights=cc_publicdomain
  private makeRequest(searchTerms: string, startIndex: number) {
    const url = this.PROXY_BASE_URL +
      '?key=' + this.API_KEY +
      '&cx=' + this.CSE_ID +
      '&searchType=image' +
      '&q=' + searchTerms +
      '&num=' + this.NUM_RESULTS +
      '&start=' + startIndex;
    return url;
  }

  private updateImageData(response: any): void {
    const json = response.json();
    if (json) {
      if (json.queries) {
        if (json.queries.request) {
          this.currentIndex = json.queries.request[0].startIndex || 1;
        }
        if (json.queries.previousPage) {
          this.prevIndex = json.queries.previousPage[0].startIndex || 1;
        } else {
          this.prevIndex = 1;
        }
        if (json.queries.nextPage) {
          this.nextIndex = json.queries.nextPage[0].startIndex || 1;
        } else {
          this.nextIndex = 1;
        }
      } else {
        console.warn('Warning: queries is empty!');
      }
      if (json.items) {
        console.log('Updating image data...');
        const newList = new Array<ImageData>();
        for (const item of json.items) {
          const imageData = new ImageData(
            item.title, item.link, item.image.height, item.image.width,
            item.image.thumbnailLink, item.image.thumbnailHeight, item.image.thumbnailWidth
          );
          newList.push(imageData);
        }
        this.imageList?.next(newList);
        console.log('Image data updated!');
      } else {
        console.warn('Warning: items is empty!');
      }
    } else {
      console.error('Error: response is empty!');
    }
  }
}
