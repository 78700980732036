<div mat-menu-item class="custom-item" (click)="openItem()">
    <mat-icon color="primary" class="custom-icon" svgIcon="arrow-top-right"></mat-icon>
    <span class="custom-span" translate>OPEN</span>
</div>

<div mat-menu-item class="custom-item" (click)="renameRecent()">
    <mat-icon class="custom-icon" color="primary" svgIcon="rename-box"></mat-icon>
    <span class="custom-span" translate>RENAME_RECENT_MAP</span>
</div>
<div mat-menu-item class="custom-item" [matMenuTriggerFor]="colorBackgroundMenu">
    <mat-icon mat-icon class="custom-icon" color="primary" svgIcon="tavolozza"></mat-icon>
    <span class="custom-span" translate>CHANGE_COLOR</span>
</div>
<mat-menu class="color-picker-container mouseleave" #colorBackgroundMenu="matMenu">
    <app-menu-color-picker [usedColorLabel]="usedColorLabel" [selectedColor]="selectedColor" (onChangeColor)="changeColor($event)" [colors]="backgroundColors" [showTransparent]="false"></app-menu-color-picker>
</mat-menu>
<!-- <div mat-menu-item class="custom-item" (click)="changeColor()">
    <mat-icon class="custom-icon" svgIcon="palette"></mat-icon>
    <span class="custom-span" translate>CREATE_RECENT_COPY</span>
  </div> -->
<!-- <div mat-menu-item class="custom-item" (click)="printPDF()">
    <mat-icon class="custom-icon" color="primary" svgIcon="print"></mat-icon>
    <span class="custom-span" translate>PRINT</span>
</div> -->
<div mat-menu-item class="custom-item" (click)="downloadPdf()">
    <mat-icon class="custom-icon" color="primary" svgIcon="download"></mat-icon>
    <span class="custom-span" translate>DOWNLOAD_RECENT</span>
</div>
<hr />
<div mat-menu-item class="custom-item" (click)="deletePDF()">
    <mat-icon class="custom-icon" color="primary">delete</mat-icon>
    <span class="custom-span" translate>PDF_DELETE</span>
</div>
