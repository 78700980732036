export class SmxEdgeAspect {
    public width: string;
    public color: string;
    public type: string;
    public freeEdge: string;

    constructor(width?: string, color?: string, type?: string, freeEdge?: string) {
        this.width = width || '1';
        this.color = color || 'rgba(128,128,128,1.0)';
        this.type = type || 'PREF_EDGE_STRAIGHT';
        this.freeEdge = freeEdge || 'true';
    }
}
