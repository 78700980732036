import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { //FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from '../core/core.module';

import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { AccessConfigRoutingModule } from './access-config-routing.module';
import { AccessConfigComponent } from './access-config.component';
import { OrderListComponent } from './common/order-list/order-list.component';
import { UserDesktopComponent } from './user-desktop/user-desktop.component';
import { UserDesktopRoutingModule } from './user-desktop/user-desktop-routing.module';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PaginatorI18n } from './common/order-list/mat-paginatorI18n.service';
import { AidDesktopComponent } from './aid-desktop/aid-desktop.component';
import { SpainDesktopComponent } from './spain-desktop/spain-desktop.component';
import { AdminConfigComponent } from './common/admin-config/admin-config.component';
import { AidDesktopRoutingModule } from './aid-desktop/aid-desktop-routing.module';
import { SpainDesktopRoutingModule } from './spain-desktop/spain-desktop-routing.module';
import { AnaDesktopRoutingModule } from './ana-desktop/ana-desktop-routing.module';
import { AnaDesktopComponent } from './ana-desktop/ana-desktop.component';
import { UsersListComponent } from './common/users-list/users-list.component';
import { SubsConfigComponent } from './common/subs-config/subs-config.component';
import { OrderConfigComponent } from './common/order-list/order-config/order-config.component';
import { SingleUserConfigComponent } from './single-user-config/single-user-config.component';
import { SingleUserConfigRoutingModule } from './single-user-config/single-user-config-routing.module';
import { DateConfigComponent } from './common/date-config/date-config.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        SharedModule,
        //FlexLayoutModule,
        MaterialModule,
        FormsModule,
        CoreModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        AccessConfigRoutingModule,
        SingleUserConfigRoutingModule,
        UserDesktopRoutingModule,
        AidDesktopRoutingModule,
        SpainDesktopRoutingModule,
        MatPaginatorModule,
        AnaDesktopRoutingModule,
    ],
    declarations: [
        AccessConfigComponent,
        OrderConfigComponent,
        OrderListComponent,
        UserDesktopComponent,
        AidDesktopComponent,
        SpainDesktopComponent,
        AdminConfigComponent,
        AnaDesktopComponent,
        UsersListComponent,
        SingleUserConfigComponent,
        SubsConfigComponent,
        DateConfigComponent,
        SpainDesktopComponent
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }
    ]
})
export class AccessConfigModule { }
