<div class="main-container">
    <div class="header-container" [style.background]="headerBackgroundColor">
        <div class="header-left-tools-container">
            <div *ngIf="!isHeaderCompressed" class="header-title-container">
                <div class="header-title" translate>RECENT_MAPS</div>
                <div class="header-subtitle"> {{numMap}}</div>
            </div>
            <div class="header-filter-container">
                <mat-button-toggle-group #group="matButtonToggleGroup" [value]="currentRecentGroup" (change)="onRequestedRecentsValueChange(group.value)">
                    <mat-button-toggle value="all">
                        {{"ALL_MAPS"|translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="mine">
                        {{"MY_MAPS"|translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="shared">
                        {{"OTHERS_MAPS"|translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="trash">
                        {{"TRASH_BIN"|translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="fill-space"></div>
        <div class="header-right-tools-container">
            <mat-button-toggle-group #itemStyleGroup="matButtonToggleGroup" [(value)]="currentRecentsStyle">
                <mat-button-toggle value="preview" (click)="recentShowPreviews()">
                    <mat-icon color="primary">view_module</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="list" (click)="recentShowList()">
                    <mat-icon color="primary">view_list</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>

            <mat-button-toggle-group #itemStyleGroup="matButtonToggleGroup" [(value)]="defaultSortRecentsBy">
                <mat-button-toggle value="alphabetical" (click)="sortRecentMaps('alphabetical')">
                    <mat-icon color="primary">sort_by_alpha</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="date" (click)="sortRecentMaps('date')">
                    <mat-icon color="primary">date_range</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div *ngIf="isUserExpired" class="expired-warning-container">
        <div class="expired-warning">
            <span translate>EXPIRED_WARNING_1</span><span translate> {{expirationDate | date: "dd/MM/yyyy"}}.</span>
            <span translate>EXPIRED_WARNING_2</span>
            <button mat-flat-button color="warn" (click)="renewSubscription()">{{'ACCESSCONFIG_RENEW_SUBSCRIPTION' | translate}}</button>
        </div>
    </div>

    <div *ngIf="currentRecentGroup==='trash'" class="trash-warning-container">
        <span class="trash-warning" translate>TRASH_WARNING</span>
        <button mat-stroked-button class="trash-button" color="primary" [disabled]="!emptyTrashEnabled" (click)="emptyTrash()">
            <mat-icon fontSet="material-icons-filled" class="material-icons-sharp">delete_forever</mat-icon>
            <span translate>TRASH_EMPTY</span>
        </button>
    </div>

    <!-- PREVIEWS -->
    <div [hidden]="isRecentsLoading || noMap" class="recent-body-container">
        <div #recentMapsContainer class="recent-maps-container" *ngIf="recentPreview">
            <ng-container *ngFor="let recentMap of recentMapsSortedFiltered">
                <div class="recent-map-card-container">
                    <div class="recent-map-card" (click)="loadMapFromRecent(recentMap.id)" (contextmenu)="itemMenuClick(recentMap,undefined)">
                        <div class="recent-map-card-preview-container">
                            <img #imgrecent src="{{recentMap.previewPublicUrl}}" (error)="replaceDefaultUrl($event)" />
                        </div>

                        <mat-divider></mat-divider>
                        <div class="recent-map-card-info-container">
                            <div class="recent-map-card-name-container">
                                <div class="recent-map-card-name-singleline" *ngIf="(recentMap.isMine && recentMap.isShared && (recentMap.stat_viewShared>0 ||recentMap.stat_copyShared>0))">
                                    {{recentMap.name}}
                                </div>
                                <div class="recent-map-card-name-multiline" *ngIf="!(recentMap.isMine && recentMap.isShared && (recentMap.stat_viewShared>0 ||recentMap.stat_copyShared>0))">
                                    {{recentMap.name}}
                                </div>
                            </div>
                            <div class="recent-map-card-shared-info-container" *ngIf="(recentMap.isMine && recentMap.isShared && (recentMap.stat_viewShared>0 || recentMap.stat_copyShared>0))">
                                <span class="num-view" *ngIf="recentMap.isShared && recentMap.stat_viewShared>0">{{recentMap.stat_viewSharedString}}</span>
                                <span class="num-copy" *ngIf="recentMap.isShared && recentMap.stat_copyShared>0">{{recentMap.stat_copySharedString}}</span>
                            </div>
                            <div class="recent-map-card-lastrow">
                                <div class="recent-map-card-icons-container">
                                    <mat-icon class="recent-icon" *ngIf="recentMap.isMine && recentMap.isShared && recentMap.shareType==='none'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)">share</mat-icon>
                                    <mat-icon class="recent-icon" *ngIf="recentMap.isMine && recentMap.isShared && recentMap.shareType==='view'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)">share</mat-icon>
                                    <mat-icon class="recent-icon" *ngIf="recentMap.isMine && recentMap.isShared && recentMap.shareType==='edit'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)">share</mat-icon>
                                    <mat-icon class="recent-icon" *ngIf="!recentMap.isMine && recentMap.isShared && recentMap.shareType==='view'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)" fontSet="material-symbols-outlined">visibility</mat-icon>
                                    <mat-icon class="recent-icon" *ngIf="!recentMap.isMine && recentMap.isShared && recentMap.shareType==='edit'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)" fontSet="material-icons-outlined">people</mat-icon>
                                </div>
                                <div *ngIf="currentRecentGroup!=='trash'" class="recent-map-card-preview-date">
                                    {{recentMap.lastUpdated.toLocaleDateString()}}
                                </div>
                                <div *ngIf="currentRecentGroup==='trash'" class="recent-map-card-preview-date">
                                    {{recentMap.deleteDate ? recentMap.deleteDate.toLocaleDateString() : ''}}
                                </div>
                                <div class="recent-map-card-menu-button-container">
                                    <mat-icon class="recent-map-card-menu-button" color="primary" matTooltip="{{'OTHER' | translate}}" [matMenuTriggerFor]="itemMenu" (click)="itemMenuClick(recentMap,$event)">
                                        more_vert
                                    </mat-icon>
                                </div>

                            </div>
                            <mat-menu #itemMenu="matMenu" [overlapTrigger]="false">
                                <app-recent-menu [recentMap]="recentMap" (trashOp)="trashOp($event)" (openMap)="loadMapFromRecent(recentMap.id)" (stopSharing)="stopSharing(recentMap.id)" (copyMap)="copyMap(recentMap)" (renameMap)="renameMap(recentMap)" (shareRecent)="shareMap(recentMap)" (downloadRecent)="downloadMap(recentMap)"></app-recent-menu>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- LIST -->
        <div *ngIf="!recentPreview" class="recent-list-container">
            <mat-list>
                <div matSubheader>
                    <span translate>MAP_NAME</span>
                    <span *ngIf="!isListCompressed && currentRecentGroup!=='trash'" translate>MAP_LASTUPDATE</span>
                    <span *ngIf="!isListCompressed && currentRecentGroup==='trash'" translate>MAP_DELETEDATE</span>
                </div>
                <mat-list-item class="recent-map-list-item" *ngFor="let recentMap of recentMapsSortedFiltered">
                    <div class="recent-map-list-line" (click)="loadMapFromRecent(recentMap.id)">
                        <mat-icon mat-list-icon class="recent-map-list-item-icon color-smx-blue" svgIcon="recent_list_item" (click)="loadMapFromRecent(recentMap.id)" matTooltip="{{'OPEN_RECENT_MAP' | translate}}"></mat-icon>
                        <div class="recent-map-list-name-container">
                            <span class="recent-map-list-name">{{recentMap.name}}</span>
                        </div>
                        <div class="recent-map-list-info-container">
                            <span class="recent-map-list-view-shared" *ngIf="(recentMap.isMine && recentMap.isShared && (recentMap.stat_viewShared>0 || recentMap.stat_copyShared>0))">{{recentMap.stat_viewSharedString}} </span>
                            <span class="recent-map-list-copy-shared" *ngIf="(recentMap.isMine && recentMap.isShared && (recentMap.stat_viewShared>0 || recentMap.stat_copyShared>0))">{{recentMap.stat_copySharedString}} </span>
                            <mat-icon class="recent-map-list-item-icon-share" *ngIf="recentMap.isMine && recentMap.isShared && recentMap.shareType==='none'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)">share</mat-icon>
                            <mat-icon class="recent-map-list-item-icon-share" *ngIf="recentMap.isMine && recentMap.isShared && recentMap.shareType==='view'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)">share</mat-icon>
                            <mat-icon class="recent-map-list-item-icon-share" *ngIf="recentMap.isMine && recentMap.isShared && recentMap.shareType==='edit'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)">share</mat-icon>
                            <mat-icon class="recent-map-list-item-icon-share" *ngIf="!recentMap.isMine && recentMap.isShared && recentMap.shareType==='view'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)" fontSet="material-symbols-outlined">visibility</mat-icon>
                            <mat-icon class="recent-map-list-item-icon-share" *ngIf="!recentMap.isMine && recentMap.isShared && recentMap.shareType==='edit'" color="primary" [matTooltip]="getShareIconTooltip(recentMap)" fontSet="material-icons-outlined">people</mat-icon>
                            <span *ngIf="!isListCompressed && currentRecentGroup!=='trash'" class="recent-map-list-lastupdate"> {{recentMap.lastUpdated.toLocaleString()}} </span>
                            <span *ngIf="!isListCompressed && currentRecentGroup==='trash'" class="recent-map-list-lastupdate"> {{recentMap.deleteDate ? recentMap.deleteDate.toLocaleDateString() : ''}} </span>
                            <div class="recent-map-list-menubutton" color="primary" mat-icon-button matTooltip="{{'OTHER' | translate}}" [matMenuTriggerFor]="itemMenu" (click)="itemMenuClick(recentMap, $event); false;">
                                <mat-icon color="primary">more_vert</mat-icon>
                                <mat-menu color="primary" #itemMenu="matMenu" [overlapTrigger]="false">
                                    <app-recent-menu [recentMap]="recentMap" (trashOp)="trashOp($event)" (openMap)="loadMapFromRecent(recentMap.id)" (stopSharing)="stopSharing(recentMap.id)" (copyMap)="copyMap(recentMap)" (renameMap)="renameMap(recentMap)" (shareRecent)="shareMap(recentMap)" (downloadRecent)="downloadMap(recentMap)"></app-recent-menu>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>

    <div *ngIf="noMap" class="no-maps-container">
        <div *ngIf="currentRecentGroup === 'all'" class="no-maps-all">
            <span class="no-maps-text" translate>NO_MAPS_ALL</span>
            <img src="../../../assets/home/nessuna_mappa.png" />
        </div>
        <div *ngIf="currentRecentGroup === 'mine'" class="no-maps-mine">
            <div class="no-maps-text" translate>NO_MAPS_MINE</div>
            <img src="../../../assets/home/no_mie_mappe.png" />
        </div>
        <div *ngIf="currentRecentGroup === 'shared'" class="no-maps-shared">
            <div class="no-maps-text" translate>NO_MAPS_OTHERS</div>
            <img src="../../../assets/home/no_mappe.png" />
        </div>
        <div *ngIf="currentRecentGroup === 'trash'" class="no-maps-trashed">
            <div class="no-maps-text" translate>NO_MAPS_TRASH</div>
            <img src="../../../assets/home/cestino_vuoto.png" />
        </div>
    </div>
</div>
