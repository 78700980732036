import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-user-desktop',
    templateUrl: './user-desktop.component.html',
    styleUrls: ['./user-desktop.component.scss']
})
export class UserDesktopComponent implements OnInit {

    public logoUrl: string;
    public isLoading = false;
    public user = '';
    public role = '';
    logoUrlExtended = '';
    public logoUrlCompressed = '';
    compressLogo: boolean = false;
    compressed: boolean = false;

    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.user !== this.firebaseAuthService.auth.currentUser?.email && this.firebaseAuthService.auth.currentUser !== null) {
            {
                this.authenticationService.reloadCredentials();
                this.user = this.authenticationService.getUserEmail();
                this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials?.role : '';
                this.accessConfig();
            }
        }

    }


    accessConfig() {

        let redirectUri;
        if (this.role === '') {
            redirectUri = '/user-desktop';
        } else
            if (this.role === 'spain') {
                redirectUri = /*window.location.origin + */ '/spain-desktop';
            } else if (this.role === 'aid') {
                redirectUri = /*window.location.origin + */ '/aid-desktop';
            } else if (this.role === 'anastasis') {
                redirectUri = /*window.location.origin + */ '/ana-desktop';
            } else if (this.role === 'admin') {
                redirectUri = /*window.location.origin + */ '/admin-page';
            }


        this.router.navigate([redirectUri]);
    }

    constructor(
        private authenticationService: AuthenticationService,
        private firebaseAuthService: FirebaseAuthService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private translateService: TranslateService) {
        this.logoUrl = (this.authenticationService.isLab() ? '../../assets/home/SMX_Doc_Header_LAB.png' : '../../assets/home/SMX_Doc_Header_EDU.png');
        this.authenticationService.setIcon();
        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });
        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;
            });
        this.authenticationService.reloadCredentials();

        this.user = this.authenticationService.getUserEmail();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials.role : '';

    }

    ngOnInit() {
        this.authenticationService.reloadCredentials();

        this.user = this.authenticationService.getUserEmail();
        this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials.role : '';
        this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');



    }

    getRoleType() {
        return this.translateService.instant('ROLE_' + (this.role.toLocaleUpperCase() || 'USER'));
    }

    startLoadingList() {
        this.isLoading = true;
    }

    endLoadingList() {
        this.isLoading = false;
    }
}
