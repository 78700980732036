<button mat-icon-button [matMenuTriggerFor]="fileMenu">
    <mat-icon color="primary" fontSet="material-icons-outlined">more_vert</mat-icon>
</button>
<mat-menu #fileMenu="matMenu" [overlapTrigger]="false">
    <div mat-menu-item color="primary" [ngClass]="{'tts-mode': ttsEnable}" (click)="TTSRead()">
        <mat-icon color="primary" svgIcon="message-processing"></mat-icon>
        <span color="primary" translate>PLAY</span>
    </div>
    <div mat-menu-item (click)="centerMap()">
        <mat-icon color="primary">settings_overscan</mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM-FIT</span>
    </div>
    <div mat-menu-item (click)="zoom(+1)">
        <mat-icon color="primary" svgIcon="magnify-plus"></mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM_IN</span>
    </div>
    <div mat-menu-item (click)="zoom(-1)">
        <mat-icon color="primary" svgIcon="magnify-minus"></mat-icon>
        <span translate>MAIN_TOOLBAR_ZOOM_OUT</span>
    </div>
    <div mat-menu-item (click)="convertToImage(true)">
        <mat-icon color="primary">filter</mat-icon>
        <span translate>MAIN_TOOLBAR_EXPORT-TO-IMAGE</span>
    </div>
    <div mat-menu-item *ngIf="!isUserExpired" (click)="copyMap()">
        <mat-icon color="primary" svgIcon="content-duplicate"></mat-icon>
        <span translate>COPY_MAP</span>
    </div>
    <div mat-menu-item (click)="printMap(true)">
        <mat-icon color="primary">print</mat-icon>
        <span translate>MAIN_MENU_FILE-PRINT</span>
    </div>
    <div mat-menu-item (click)="shareMap()">
        <mat-icon color="primary">share</mat-icon>
        <span translate>MAIN_MENU_FILE-SHARE</span>
    </div>
    <div mat-menu-item (click)="mapShowing()">
        <mat-icon color="primary" svgIcon="theater"></mat-icon>
        <span translate>SHOWING</span>
    </div>
    <button *ngIf="canEdit" mat-menu-item color="primary" matTooltip="{{'EDIT_MODE' | translate}}" (click)="navigateToEdit()">
        <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">edit</mat-icon>
        <span translate>EDIT_MODE</span>
    </button>
</mat-menu>
