<!-- <div class="error" (click)="closePage()">
  <div class="error-content">
    <div class="error-text-header" translate>WAIT</div>
    <div class="error-text-message" translate>{{textMessage}}</div>
  </div>
  <div class="loader-container">
    <app-loader class="loader" [isLoading]="isLoading" size="5"></app-loader>
  </div>
</div> -->
<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<div class="container mat-typography" (click)="closePage()">
    <mat-card appearance="outlined" class="message-container">
        <div class="message-title" translate>WAIT</div>
        <div class="message-text">{{textMessage}}</div>
        <!-- <div class="ok-button" (click)="backToHome()" translate>BUTTON_OK</div> -->
    </mat-card>
</div>
