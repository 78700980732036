import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowsercheckGuard } from '../core/browsercheck.guard';

import { extract } from '../core/i18n.service';
import { AccessConfigComponent } from './access-config.component';

const routes: Routes = [
    { path: 'access-config/:orderId/:randomId', component: AccessConfigComponent, data: { title: extract('ACCESS_CONFIG') }, canActivate: [BrowsercheckGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [BrowsercheckGuard]

})
export class AccessConfigRoutingModule { }
