import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PdfService } from '../../pdf.service';
import { RecentPdfDto } from '../recent-pdf-dto';
import { UiConstants } from 'src/app/shared/ui-constants';

@Component({
    selector: 'app-pdf-menu-recents',
    templateUrl: './pdf-menu-recents.component.html',
    styleUrls: ['./pdf-menu-recents.component.scss']
})
export class PdfMenuRecentsComponent {

    @Input() recentPdf: RecentPdfDto = new RecentPdfDto();
    @Output() removePdf = new EventEmitter<RecentPdfDto>();
    @Output() openPdf = new EventEmitter<RecentPdfDto>();
    @Output() renamePdf = new EventEmitter<RecentPdfDto>();
    @Output() downloadRecent = new EventEmitter<RecentPdfDto>();
    @Output() changeIconColor = new EventEmitter<RecentPdfDto>();


    backgroundColors = UiConstants.PALETTE.PDFCOLOR;
    selectedColor = 'primary';
    usedColorLabel = '';

    constructor() {

    }

    openItem() {
        this.openPdf.emit(this.recentPdf);
    }

    renameRecent() {
        this.renamePdf.emit(this.recentPdf);
    }
    deletePDF() {
        this.removePdf.emit(this.recentPdf);

    }

    downloadPdf() {
        this.downloadRecent.emit(this.recentPdf);
    }



    changeColor(e: any) {
        this.selectedColor = e;
        this.recentPdf.color = e;
        this.changeIconColor.emit(this.recentPdf);


    }

}
